import React from 'react';

import cx from 'classnames';

import Slider from 'components/form/Slider';

import styleMap from '../../styleMap';

import styles from './SliderInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function SliderInputBlock({
  value,
  max,
  min,
  disabled = false,
  onInput = () => {},
  style,
  className,
}: any) {
  if (min) {
    min = parseInt(min, 10);
  }

  if (max) {
    max = parseInt(max, 10);
  }

  return (
    <div
      className={cx(styles.SliderInputBlock, className)}
      style={styleMap(false, style)}
    >
      <Slider
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        min={min}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        max={max}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        value={value || min}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        onChange={onInput}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        disabled={disabled}
      />
    </div>
  );
}

export default SliderInputBlock;
