import React, { useState, useEffect } from 'react';

import { Flex, Line } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ProductItem,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { H4, M } from 'components/typography';

import styles from './ChargeSummary.scss';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { getDraftTotalAmounts } from 'lane-shared/domains/billingPayments/helpers/getDraftTotalAmounts';
import { SupportedLocaleEnum } from 'localization';

export function ChargeSummary({
  items,
  currency,
  locale,
  fullWidth = false,
  children,
}: {
  items: ProductItem[];
  locale: SupportedLocaleEnum;
  currency: SupportedPaymentCurrency;
  fullWidth?: boolean;
  children?: React.ReactElement;
}) {
  const { t } = useTranslation();

  const [summary, setSummary] = useState({
    subTotal: '-',
    tax: '-',
    total: '-',
  });

  const twoDecimalsWithCurrency = currencyFormatter({
    currency,
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    const { subTotal, tax, total } = getDraftTotalAmounts(
      items,
      currency,
      locale
    );

    if (items.length === 0) {
      setSummary({
        subTotal: '-',
        tax: '-',
        total: '-',
      });
    } else {
      setSummary({
        subTotal: twoDecimalsWithCurrency(subTotal),
        tax: twoDecimalsWithCurrency(tax),
        total: twoDecimalsWithCurrency(total),
      });
    }
  }, [items]);

  const renderActionButtons = () => {
    if (children) {
      return children;
    }
    return null;
  };

  return (
    <Flex
      className={styles.ChargeSummary}
      direction="column"
      gap={5}
      p={5}
      width={fullWidth ? 'full' : undefined}
    >
      <H4>{t('abp.charges.chargeManager.summary.title')}</H4>
      <Flex justify="space-between">
        <M>{t('abp.charges.chargeManager.summary.subtotal.label')}</M>
        <M className={styles.Subtotal} data-cy="draft-charge-summary-subtotal">
          {summary.subTotal}
        </M>
      </Flex>
      <Flex justify="space-between">
        <M>{t('abp.charges.chargeManager.summary.tax.label')}</M>
        <M className={styles.Tax} data-cy="draft-charge-summary-tax">
          {summary.tax}
        </M>
      </Flex>
      <Line className={styles.SummaryTotalLine} />
      <Flex justify="space-between">
        <M>{t('abp.charges.chargeManager.summary.total.label')}</M>
        <M className={styles.Total} data-cy="draft-charge-summary-total">
          {summary.total}
        </M>
      </Flex>
      {renderActionButtons()}
    </Flex>
  );
}
