import React, { useState } from 'react';

import { Button, Checkbox, Dropdown, Icon } from 'design-system-web';

import { Flex, Input, Label, Toggle } from 'lane-web/src/components';
import { P } from 'lane-web/src/components/typography';

import { useTranslation } from 'react-i18next';

import {
  meterReadingNotificationTrigger,
  notificationTriggerOptions,
} from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingNotificationTrigger';
import { meterReadingUnitOptions } from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingUnitOptions';
import styles from './MeterReadingSettings.scss';

export interface MeterReadingSettingsProps {
  meterReadingSetting: any;
  index: number;
  onDelete: (index: number) => void;
  onUpdate: (index: number, meterReadingSetting: any) => void;
  validated?: boolean;
}

function MeterReadingSettings({
  meterReadingSetting,
  index,
  onDelete,
  onUpdate,
  validated = false,
}: MeterReadingSettingsProps) {
  const { t } = useTranslation();

  const [enableNotification, setEnableNotification] = useState<boolean>(
    meterReadingSetting?.enableNotification || false
  );
  const [notificationTrigger, setNotificationTrigger] = useState<any>(
    meterReadingSetting?.notificationTrigger || ''
  );
  const [unit, setUnit] = useState<any>(meterReadingSetting?.unit || '');
  const [name, setName] = useState<any>(meterReadingSetting?.name || '');
  const [
    enableEmailNotification,
    setEnableEmailNotification,
  ] = useState<boolean>(
    meterReadingSetting?.notificationTypes &&
      meterReadingSetting?.notificationTypes?.length > 0 &&
      meterReadingSetting?.notificationTypes.includes('email')
  );
  const [enablePushNotification, setEnablePushNotification] = useState<boolean>(
    meterReadingSetting?.notificationTypes &&
      meterReadingSetting?.notificationTypes?.length > 0 &&
      meterReadingSetting?.notificationTypes.includes('push')
  );

  const updateNotificationTypes = (value: string) => {
    const notificationTypes = meterReadingSetting.notificationTypes
      ? [...meterReadingSetting.notificationTypes]
      : [];

    if (value === 'email') {
      setEnableEmailNotification(!enableEmailNotification);
    } else if (value === 'push') {
      setEnablePushNotification(!enablePushNotification);
    }

    if (notificationTypes.indexOf(value) < 0) {
      notificationTypes.push(value);
    } else {
      notificationTypes.splice(notificationTypes.indexOf(value), 1);
    }

    onUpdate(index, {
      ...meterReadingSetting,
      notificationTypes,
    });
  };

  return (
    <Flex gap={5} className={styles.equipmentFormBlock} direction="column">
      <Flex gap={5} className={styles.MeterReadingLabels}>
        <div className={styles.MeterReadingInput}>
          <Dropdown
            dataCy="meter-reading-setting-unit-input"
            value={unit}
            label={t('web.admin.serviceRequest.equipment.MeterReading.unit')}
            items={meterReadingUnitOptions}
            isRequired
            fixedLabel
            isFullWidth
            onChange={(value: any) => {
              setUnit(value.value);
              onUpdate(index, { ...meterReadingSetting, unit: value.value });
            }}
            invalid={validated ? unit === '' : undefined}
            errors={
              validated && unit === ''
                ? [t('web.admin.serviceRequest.field.required')]
                : undefined
            }
          />
        </div>
        <div className={styles.MeterReadingInput}>
          <Input
            dataCy="meter-reading-setting-name-input"
            value={name}
            label={t('web.admin.serviceRequest.equipment.MeterReading.name')}
            fixedLabel
            onChange={(value: string) => {
              setName(value);
              onUpdate(index, { ...meterReadingSetting, name: value });
            }}
            placeholder={t(
              'web.admin.serviceRequest.equipment.MeterReading.name.placeholder'
            )}
          />
        </div>
      </Flex>

      <div className={styles.lineBreak} />

      <Toggle
        value={enableNotification}
        onChange={(value: boolean) => {
          setEnableNotification(value);
          onUpdate(index, {
            ...meterReadingSetting,
            enableNotification: value,
          });
        }}
        doTranslate
        text="web.admin.serviceRequest.equipment.MeterReading.enableNotification.title"
        description="web.admin.serviceRequest.equipment.MeterReading.enableNotification.desc"
      />

      {enableNotification && (
        <Flex gap={5} className={styles.MeterReadingLabels} direction="column">
          <Flex gap={5}>
            <div className={styles.MeterReadingInput}>
              <Dropdown
                dataCy="meter-reading-setting-notification-trigger"
                label={t(
                  'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.title'
                )}
                value={notificationTrigger}
                items={notificationTriggerOptions}
                isRequired
                fixedLabel
                doTranslation
                isFullWidth
                onChange={(value: any) => {
                  setNotificationTrigger(value.value);
                  const cloneMR = { ...meterReadingSetting };
                  delete cloneMR.upperBound;
                  delete cloneMR.lowerBound;
                  onUpdate(index, {
                    ...cloneMR,
                    notificationTrigger: value.value,
                  });
                }}
                invalid={validated ? notificationTrigger === '' : undefined}
                errors={
                  validated && notificationTrigger === ''
                    ? [t('web.admin.serviceRequest.field.required')]
                    : undefined
                }
              />
            </div>
            {!notificationTrigger && (
              <div className={styles.MeterReadingInput} />
            )}
            {notificationTrigger ===
              meterReadingNotificationTrigger.GREATER_THAN && (
              <div className={styles.MeterReadingInput}>
                <Input
                  dataCy="meter-reading-setting-max-value"
                  label={t(
                    'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.max'
                  )}
                  type="number"
                  value={meterReadingSetting.upperBound}
                  isRequired
                  fixedLabel
                  onChange={(value: string) =>
                    onUpdate(index, {
                      ...meterReadingSetting,
                      upperBound: value === '' ? undefined : Number(value),
                    })
                  }
                  error={
                    validated && meterReadingSetting.upperBound === undefined
                      ? [t('web.admin.serviceRequest.field.required')]
                      : undefined
                  }
                />
              </div>
            )}

            {notificationTrigger ===
              meterReadingNotificationTrigger.LESS_THAN && (
              <div className={styles.MeterReadingInput}>
                <Input
                  dataCy="meter-reading-setting-min-value"
                  label={t(
                    'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.min'
                  )}
                  type="number"
                  value={meterReadingSetting.lowerBound}
                  isRequired
                  fixedLabel
                  onChange={(value: string) =>
                    onUpdate(index, {
                      ...meterReadingSetting,
                      lowerBound: value === '' ? undefined : Number(value),
                    })
                  }
                  error={
                    validated && meterReadingSetting.lowerBound === undefined
                      ? [t('web.admin.serviceRequest.field.required')]
                      : undefined
                  }
                />
              </div>
            )}

            {notificationTrigger ===
              meterReadingNotificationTrigger.OUTSIDE_RANGE_OF && (
              <>
                <div className={styles.MeterReadingInput}>
                  <Input
                    dataCy="meter-reading-setting-lowest-value"
                    label={t(
                      'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.lowest'
                    )}
                    type="number"
                    value={meterReadingSetting.lowerBound}
                    isRequired
                    fixedLabel
                    onChange={(value: string) =>
                      onUpdate(index, {
                        ...meterReadingSetting,
                        lowerBound: value === '' ? undefined : Number(value),
                      })
                    }
                    error={
                      validated && meterReadingSetting.lowerBound === undefined
                        ? [t('web.admin.serviceRequest.field.required')]
                        : undefined
                    }
                  />
                </div>
                <div className={styles.MeterReadingInput}>
                  <Input
                    dataCy="meter-reading-setting-highest-value"
                    label={t(
                      'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.highest'
                    )}
                    type="number"
                    value={meterReadingSetting.upperBound}
                    isRequired
                    fixedLabel
                    onChange={(value: string) =>
                      onUpdate(index, {
                        ...meterReadingSetting,
                        upperBound: value === '' ? undefined : Number(value),
                      })
                    }
                    error={
                      validated && meterReadingSetting.upperBound === undefined
                        ? [t('web.admin.serviceRequest.field.required')]
                        : undefined
                    }
                  />
                </div>
              </>
            )}
          </Flex>
          <Flex className={styles.MeterReadingLabels} direction="column">
            <Label mt={0}>
              {t(
                'web.admin.serviceRequest.equipment.MeterReading.notificationType.title'
              )}
              <span className={styles.required}>*</span>
            </Label>
            {validated &&
              !enablePushNotification &&
              !enableEmailNotification && (
                <P className={styles.error}>
                  {t('web.admin.serviceRequest.field.required')}
                </P>
              )}
            <Flex direction="row" className={styles.boxGroup}>
              <Checkbox
                value="push"
                selected={enablePushNotification}
                onChange={(value: string) => updateNotificationTypes(value)}
                text={t(
                  'web.admin.serviceRequest.equipment.MeterReading.notificationType.push'
                )}
              />
              <Checkbox
                value="email"
                selected={enableEmailNotification}
                onChange={(value: string) => updateNotificationTypes(value)}
                text={t(
                  'web.admin.serviceRequest.equipment.MeterReading.notificationType.email'
                )}
              />
            </Flex>
          </Flex>
        </Flex>
      )}

      <Flex>
        <Button
          variant="text"
          startIcon={<Icon name="trash-alt" />}
          dataCy="remove-option"
          onClick={() => onDelete(index)}
        >
          {t('web.admin.serviceRequest.equipment.MeterReading.remove')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default MeterReadingSettings;
