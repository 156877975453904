import React from 'react';

import { Button, ErrorMessage, ControlMenu } from 'components';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { EmailAlreadyInUseError, UnverifiedEmailError } from 'activate-errors';
import { pause, isThisError } from 'lane-shared/helpers';
import emitter, { EVENT_AUTH_TOKEN } from 'lane-shared/helpers/emitter';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import styles from './Signup.scss';

type Props = {
  onNext?: () => void;
  disabled?: Boolean;
};

export default function SignUpSubmitButton({ onNext, disabled }: Props) {
  const history = useHistory();
  const searchParams = qs.parse(history?.location?.search || '');

  const contentPathSearchParam = Array.isArray(searchParams?.contentPath)
    ? searchParams?.contentPath[0]
    : searchParams?.contentPath;

  async function createAccount() {
    try {
      const { jti, token, warnings } = await doSignUp();

      emitter.emit(EVENT_AUTH_TOKEN, {
        authToken: { jti, token },
        warnings,
        route: contentPathSearchParam,
      });
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onNext();
    } catch (submitError) {
      if (isThisError(submitError, UnverifiedEmailError)) {
        await pause();
        history.push(routes.signUpValidateEmail);
      }
    }
  }

  function enterEmailClicked() {
    updateSignUp({ submitting: false, submitError: null });
    history.push({
      pathname: routes.signUp,
      search: history.location.search,
    });
  }
  const {
    doSignUp,
    submitting,
    submitError,
    updateSignUp,
  } = useSignUpContext();
  const { t } = useTranslation();

  return (
    <>
      <ErrorMessage error={submitError} className={styles.errorMessage} />
      <ControlMenu className={styles.menu}>
        {isThisError(submitError, EmailAlreadyInUseError) && (
          <Button
            className={welcomeFormStyles.actionButton}
            dataCy="enterEmailButton"
            onClick={enterEmailClicked}
            variant="contained"
          >
            {t('Enter a different email address')}
          </Button>
        )}
        <Button
          loading={submitting}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          disabled={disabled}
          className={welcomeFormStyles.actionButton}
          onClick={createAccount}
          dataCy="continueButton"
        >
          {t('Continue')}
        </Button>
      </ControlMenu>
    </>
  );
}
