import React from 'react';

import cx from 'classnames';

import TimeRangePicker from 'components/form/DatePickers/TimeRangePicker';
import ValidationMessage from 'components/general/ValidationMessage';

import styleMap from '../../styleMap';

import styles from './TimeRangeInputBlock.scss';

/**
 * @deprecated use PropertyInputBock instead.
 */
export default function TimeRangeInputBlock({
  value,
  style,
  onInput,
  disabled,
  isValid,
  loading,
  minRangeSize,
  maxRangeSize,
  slotSize,
  placeholder = 'Select Time Range',
  validationMessage = 'Required.',
  className,
  isTopLevel,
  blockId,
  timeZone,
  unavailableDateRanges,
  editMode,
}: any) {
  return (
    <div
      className={cx(className, styles.TimeRangeInputBlock)}
      style={styleMap(false, style)}
      data-is-edit-mode={editMode}
      data-is-top={isTopLevel}
      data-block-id={isTopLevel ? blockId : null}
    >
      <TimeRangePicker
        className={styles.timeRange}
        loading={loading}
        disabled={disabled}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; loading: any; disabled: an... Remove this comment to see the full error message
        placeholder={placeholder}
        unavailableDateRanges={unavailableDateRanges}
        onChange={onInput}
        slotSize={slotSize}
        minRangeSize={minRangeSize}
        maxRangeSize={maxRangeSize}
        displayAllOptions
        value={value}
        timeZone={timeZone}
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
      <div className={styles.wrapper} />
    </div>
  );
}

TimeRangeInputBlock.defaultProps = {
  value: null,
  style: {},
  onInput: () => {},
  disabled: false,
  isValid: true,
  placeholder: 'Select Time Range',
  validationMessage: 'Required.',
};
