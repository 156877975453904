/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react/forbid-component-props */
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { recurrenceOptions } from '../../constants/recurentTypes';

import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { Dropdown, Flex, Label, Radio } from 'components';
import { H5 } from 'lane-web/src/components/typography';
import { DatePickerButton, Input, TextArea } from 'design-system-web';
import {
  AssignedMemberDropdown,
  StaffTeamsMultiSelectDropdown,
} from 'pages/portal/admin/channel/service-requests/details/components';

import {
  Assignable,
  Schedule,
  ScheduleFormValidationErrors,
  ScheduleRequest,
} from './types';

import { ScheduleEndDateType } from './constants';
import styles from './ScheduleDetails.scss';

interface ScheduleDetailsProps {
  channel: any;
  schedule: Schedule | undefined;
  onScheduleFieldChange: (field: keyof ScheduleRequest, value: string) => void;
  validationErrors: ScheduleFormValidationErrors | undefined;
  assignedMember: Assignable | undefined;
  setAssignedMember: (member: Assignable) => void;
  assignedTeams: Assignable[];
  setAssignedTeams: (teams: Assignable[]) => void;
  endDateType: ScheduleEndDateType;
  setEndDateType: (type: ScheduleEndDateType) => void;
}

const REQUIRED_IDENTIFIER = '*';

export const ScheduleDetails = ({
  channel,
  schedule,
  onScheduleFieldChange,
  validationErrors,
  assignedMember,
  setAssignedMember,
  assignedTeams,
  setAssignedTeams,
  endDateType,
  setEndDateType,
}: ScheduleDetailsProps) => {
  const { t } = useTranslation();
  const translate = (key: string) => {
    return t(`web.admin.workOrder.preventiveMaintenance.schedule.Form.${key}`);
  };

  const [assignableMembers, _, assignableTeams] = useAssignableMembers(
    channel?._id
  );

  useEffect(() => {
    if (assignableMembers?.length > 0 && schedule?.assignee) {
      const assignedUser = assignableMembers.find(
        (u: { value: string }) =>
          convertToUUID(u.value) === schedule.assignee?.id
      );
      setAssignedMember(assignedUser as Assignable);
    }

    if (assignableTeams?.length > 0 && schedule?.assigneeGroups) {
      const assignedGroups = assignableTeams.filter(t =>
        schedule.assigneeGroups.find(
          id => convertToUUID(id) === convertToUUID(t.value)
        )
      );

      setAssignedTeams(assignedGroups);
    }
  }, [
    assignableMembers.length,
    assignableTeams.length,
    schedule?.assignee,
    setAssignedMember,
    setAssignedTeams,
  ]);

  return (
    <Flex direction="column">
      {/* Schedule Title */}
      <Flex direction="column" className={styles.detailsSection}>
        <Label mt={0}>
          {translate('scheduleTitle')}
          <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
        </Label>
        <TextArea
          dataCy="schedule-title"
          value={schedule?.title ?? ''}
          onChange={(value: string) => onScheduleFieldChange('title', value)}
          maxLength={50}
          showLengthIndicator={(schedule?.title || '').length > 0}
          errors={validationErrors?.title}
        />
      </Flex>

      {/* Schedule Assignees */}
      <Flex gap={3} className={styles.assigneeSection}>
        <StaffTeamsMultiSelectDropdown
          items={assignableTeams}
          isSearchable
          value={assignedTeams as any}
          onChange={(newTeams: any[]) => {
            setAssignedTeams(newTeams ?? []);
          }}
          placeholder={translate('select.teams')}
          label={translate('assign.teams')}
          className={styles.SelectDropdown}
          doTranslate={false}
        />

        <AssignedMemberDropdown
          fieldName="assign-to-person"
          users={assignableMembers}
          setAssignedMember={setAssignedMember}
          assignedMember={assignedMember}
          placeholder={translate('select.person')}
          label={translate('assign.person')}
          dataCy="assignedMemberDropdown"
          doTranslate={false}
        />
      </Flex>

      {/* Schedule Notes */}
      <Flex direction="column" className={styles.notesSection}>
        <Label mt={0}>{translate('notes')}</Label>
        <TextArea
          dataCy="schedule-notes"
          value={schedule?.notes ?? ''}
          onChange={(value: string) => onScheduleFieldChange('notes', value)}
          maxLength={3_000}
          showLengthIndicator
          minRows={4}
        />
      </Flex>

      {/* Schedule Estimates */}
      <Flex direction="column" className={styles.estimateSection}>
        <Label mt={0}>
          {translate('estimatedTimeToComplete')}
          <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
        </Label>
        <Flex direction="row">
          <Input
            className={styles.estimateInput}
            dataCy="schedule-estimate"
            value={schedule?.timeToComplete}
            onChange={(value: string) =>
              onScheduleFieldChange('timeToComplete', parseFloat(value) as any)
            }
            fieldName="time-to-complete"
            error={validationErrors?.timeToComplete}
            type="number"
            showClear
          />
          <div className={styles.helperText}>{translate('hours')}</div>
        </Flex>
      </Flex>

      {/* Schedule Recurrence */}
      <Flex direction="column" className={styles.recurrenceSection}>
        <Label mt={0}>
          {translate('repeats')}
          <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
        </Label>
        <Dropdown
          id="schedule-repeats"
          dataCy="schedule-repeats"
          value={schedule?.repeats}
          items={recurrenceOptions}
          onChange={({ value }: any) => onScheduleFieldChange('repeats', value)}
          name="schedule-repeats"
          className={styles.recurrenceDropdown}
        />
      </Flex>

      {/* Schedule Due Date */}
      <Flex direction="column" className={styles.dueDateSection}>
        <Label mt={0}>
          {translate('firstDueDate')}
          <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
        </Label>
        <DatePickerButton
          dataCy="schedule-due-date"
          className={styles.dueDatePicker}
          value={schedule?.nextDueDate}
          onChange={(d: Date) => {
            onScheduleFieldChange('nextDueDate', d as any);
          }}
          minDate={new Date()}
          dateError={validationErrors?.nextDueDate}
          placeholder={t(
            'web.admin.workOrder.preventiveMaintenance.schedule.Form.select.date'
          )}
          hideOnSelect
          hideLabel
        />
      </Flex>

      {/* Schedule Advance */}
      <Flex gap={2} className={styles.advanceSection}>
        <Flex direction="column">
          <Label mt={0}>
            {translate('daysAhead')}
            <span className={styles.required}>{REQUIRED_IDENTIFIER}</span>
          </Label>
          <Input
            className={styles.advanceInput}
            dataCy="schedule-advance"
            value={schedule?.daysAhead}
            onChange={(value: string) =>
              onScheduleFieldChange(
                'daysAhead',
                value === '' ? '' : (Number(value) as any)
              )
            }
            type="number"
            maxExpand={false}
            error={validationErrors?.daysAhead}
            fieldName="days-ahead"
          />
        </Flex>
      </Flex>

      {/* Schedule Ends */}
      <Flex direction="column" gap={2} className={styles.endSection}>
        <H5>{translate('ends')}</H5>
        <Radio
          dataCy="schedule-ends-never"
          name="schedule-ends-never"
          key={ScheduleEndDateType.Never}
          selected={endDateType}
          onChange={() => {
            setEndDateType(ScheduleEndDateType.Never);
            onScheduleFieldChange('untilDate', undefined as any);
          }}
          value={ScheduleEndDateType.Never}
          text={translate('never')}
        />
        <Flex gap={2} align="center">
          <Radio
            dataCy="schedule-ends-specific"
            name="schedule-ends-specific"
            key={ScheduleEndDateType.Specific}
            selected={endDateType}
            onChange={() => setEndDateType(ScheduleEndDateType.Specific)}
            value={ScheduleEndDateType.Specific}
            text={translate('onSpecificDate')}
            doTranslate={false}
          />
          <DatePickerButton
            dateInputLabel={translate('endDate')}
            value={schedule?.untilDate}
            onChange={(d: Date) => {
              onScheduleFieldChange('untilDate', d as any);
            }}
            minDate={schedule?.nextDueDate!}
            disabled={endDateType !== ScheduleEndDateType.Specific}
            dateError={
              endDateType === ScheduleEndDateType.Specific &&
              validationErrors?.untilDate
                ? validationErrors.untilDate
                : undefined
            }
            hideOnSelect
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
