import React from 'react';

import { Button, TemplateLibrary } from 'components';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { LANE_TEMPLATE_LIBRARY } from 'lane-shared/helpers/constants/library';

import styles from '../../admin/channel/templates/ChannelTemplateLibrary.scss';

export default function LaneTemplates() {
  const match = useRouteMatch(routes.portalManagementVTSTemplates) as {
    path: string;
  };
  const location = useLocation();

  return (
    <div className={styles.Templates}>
      <TemplateLibrary
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'LibraryType' is not assignable to type 'neve... Remove this comment to see the full error message
        library={LANE_TEMPLATE_LIBRARY}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '({ template, children }: any) => JSX.Element... Remove this comment to see the full error message
        TemplateWrapper={({ template, children }: any) => (
          <Link
            to={{
              pathname: `${match.path}/${template._id}/edit`,
              state: { from: location.pathname },
            }}
          >
            {children}
          </Link>
        )}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'never'.
        additionalMenu={
          <Link
            to={{
              pathname: `${match.path}/new`,
              state: { from: location.pathname },
            }}
          >
            <Button variant="contained">New Template</Button>
          </Link>
        }
      />
    </div>
  );
}
