import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { WorkOrderModuleSettings } from 'lane-shared/domains/workOrder';
import { MEDIUM_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import {
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
} from 'lane-shared/helpers/constants/interactions';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { convertTo62, convertToUUID } from 'lane-shared/helpers/convertId';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import {
  useFlag,
  useChannelServiceRequestFeaturesQuery,
  useChannelProfileQuery,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { AddAttachment, Button, Flex, Checkbox } from 'lane-web/src/components';
import { UserThumbnailWithLoader } from 'lane-web/src/components/lane/UserThumbnailWithLoader';
import { ExternalEntityType } from 'lane-shared/domains/billingPayments/types';
import { ServiceRequestStatusEnum } from 'lane-web/src/domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import {
  getServiceRequestByUCIIdQuery,
  updateServiceRequestStatusByUCIIdMutation,
} from 'graphql-queries';
import { Modal } from 'components/lds';
import { M } from 'components/typography';

import { MemberServiceRequestEdit } from './MemberServiceRequestEdit';
import { StatusChip } from './StatusChip';

import styles from './MemberServiceRequestDetail.scss';
import { MemberChargeList } from 'lane-web/src/domains/billingAndPayments/components/MemberChargeList';
import {
  ChannelType,
  ActiveChannelTypeEnum,
} from 'lane-shared/types/ChannelType';

export interface MemberServiceRequestDetailProps {
  interactionId?: String;
  settings: WorkOrderModuleSettings;
  channel: ChannelType;
}

type CustomQuestion = {
  Question: string | undefined;
  Answer: string;
};

export function MemberServiceRequestDetail({
  interactionId,
  settings,
  channel,
}: MemberServiceRequestDetailProps) {
  const { t } = useTranslation();
  const { data, refetch } = useQuery(getServiceRequestByUCIIdQuery, {
    variables: {
      uciId: interactionId,
    },
  });
  const { channel: channelProfile } = useChannelProfileQuery({
    channelId: channel?._id,
  });

  const serviceRequest = data?.serviceRequestByUCI;
  const canEdit = serviceRequest?.status !== INTERACTION_CLOSED || false;
  const [isEditing, setIsEditing] = useState(false);
  const [openCancelRequestModel, setOpenCancelRequestModel] = useState(false);
  const [serviceRequestFeatures, setServiceRequestFeatures] = useState({
    createServiceRequest: true,
    updateServiceRequest: true,
  });

  const [hideAttachmentLabel, setHideAttachmentLabel] = useState(false);

  useChannelServiceRequestFeaturesQuery(channel, setServiceRequestFeatures);

  const hasInvoicingEnabled = useFlag(
    FeatureFlag.WorkOrdersServiceRequestInvoicing,
    false
  );
  useEffect(() => {
    setIsEditing(false);
  }, [interactionId]);

  const cancelRequest = async () => {
    try {
      await getClient().mutate({
        mutation: updateServiceRequestStatusByUCIIdMutation,
        variables: {
          uciId: safeConvertToUUID(serviceRequest.extRefId),
          status: ServiceRequestStatusEnum.Cancelled,
        },
      });
      window.Toast.show(t('web.member.serviceRequest.update.success'));
      setOpenCancelRequestModel(false);
      window.location.reload();
    } catch (err) {
      window.Toast.show(t('web.member.serviceRequest.update.failure'));
    }
  };

  const isPropertyChannel =
    channelProfile?.type === ActiveChannelTypeEnum.Property;

  if (!serviceRequest) {
    return <></>;
  }

  return (
    <div>
      {!isEditing ? (
        <>
          <div className={styles.headingContainer}>
            <div className={styles.leftColumn}>
              <p className={styles.heading}>{serviceRequest?.category}</p>
              <span className={styles.headingSubtitle}>
                {t`web.member.serviceRequest.created` +
                  dateFormatter(serviceRequest?.createdAt, MEDIUM_DATE_TIME)}
              </span>
            </div>
            {canEdit && (
              <div className={styles.rightColumn}>
                <Button size="small" onClick={() => setIsEditing(!isEditing)}>
                  {t`web.member.serviceRequest.edit`}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.fieldSection}>
            <p>{t`web.member.serviceRequest.request`}</p>
            <span>{serviceRequest?.userFriendlyID}</span>
          </div>
          <div className={styles.statusChipContainer}>
            <StatusChip
              status={serviceRequest.status}
              className={styles.statusChip}
            />
            {serviceRequest.isBillable && (
              <div className={styles.billableContainer}>
                <Checkbox
                  selected={serviceRequest.isBillable}
                  value={serviceRequest.isBillable}
                  interfaceStyle="activate"
                  className={styles.billableCheckbox}
                  disabled
                />
                <p
                  className={styles.billableText}
                >{t`web.admin.serviceRequest.billable.desc`}</p>
              </div>
            )}
          </div>

          <div className={styles.workOrderDataContainer}>
            <div className={styles.section}>
              {serviceRequest?.workOrderId && (
                <div className={styles.fieldSection}>
                  <p>{t`web.member.serviceRequest.request`}</p>
                  <span>{convertTo62(serviceRequest?.workOrderId)}</span>
                </div>
              )}
              <div className={styles.fieldSection}>
                <p>{t`web.member.serviceRequest.category`}</p>
                <span>{serviceRequest?.category}</span>
              </div>

              <div className={styles.fieldSection}>
                <p>{t`web.admin.serviceRequest.issue`}</p>
                <span>{serviceRequest?.issue}</span>
              </div>

              <div className={styles.fieldSection}>
                <p>{t`web.admin.serviceRequest.description`}</p>
                <span>{serviceRequest?.description}</span>
              </div>

              <div className={styles.fieldSection}>
                <p>{t`web.member.serviceRequest.issueLocation`}</p>
                <span>{serviceRequest?.location}</span>
              </div>
              {isPropertyChannel && (
                <>
                  {serviceRequest?.floor && (
                    <div className={styles.fieldSection}>
                      <p>{t`web.admin.serviceRequest.floor`}</p>
                      <span>{serviceRequest?.floor}</span>
                    </div>
                  )}
                  {serviceRequest?.suite && (
                    <div className={styles.fieldSection}>
                      <p>{t`web.admin.serviceRequest.suite`} </p>
                      <span>{serviceRequest?.suite}</span>
                    </div>
                  )}
                </>
              )}
              <div className={styles.fieldSection}>
                {serviceRequest.customQuestions &&
                  serviceRequest.customQuestions.map(
                    (customQuestion: CustomQuestion, index: number) => (
                      <div className={styles.fieldSection} key={index}>
                        <p>{customQuestion.Question}</p>
                        <span>{customQuestion.Answer}</span>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <MemberServiceRequestEdit
          close={() => setIsEditing(false)}
          settings={settings}
          serviceRequest={serviceRequest}
          refetch={refetch}
        />
      )}
      <div className={cx(styles.divider)}>
        {serviceRequest.attachmentRefId && (
          <Flex direction="column" className={styles.fieldSection} width="full">
            {!hideAttachmentLabel && (
              <p>{t`web.admin.serviceRequest.attachment.heading`}</p>
            )}
            {serviceRequestFeatures.updateServiceRequest && (
              <span>{t`web.admin.serviceRequest.attachment.disclaimer`}</span>
            )}
            <Flex
              className={styles.attachmentsField}
              direction="column"
              width="full"
            >
              <AddAttachment
                entityId={serviceRequest.attachmentRefId}
                entityType={ContentTypeEnum.WorkOrder}
                editMode={false}
                variant={AttachmentVariantEnum.WorkOrder}
                canAddAttachments={serviceRequestFeatures.updateServiceRequest}
                hideAttachmentLabel={setHideAttachmentLabel}
                preventDelete={!serviceRequestFeatures.updateServiceRequest}
                acceptedFileTypes={[
                  ...Object.values(AttachmentImageContentTypeEnum),
                  AttachmentDocumentContentTypeEnum.pdf,
                ]}
              />
            </Flex>
          </Flex>
        )}
        <div className={styles.userContainer}>
          <div className={styles.fieldSection}>
            <p>{t`web.admin.serviceRequest.submittedBy`}</p>
            <UserThumbnailWithLoader
              id={serviceRequest.createdBy?.id}
              className={styles.user}
              labelPosition="right"
              splitName={false}
            />
          </div>

          {serviceRequest?.assignee && (
            <div className={styles.fieldSection}>
              <p>{t`web.admin.serviceRequest.assignedTo`}</p>
              <UserThumbnailWithLoader
                className={styles.user}
                id={serviceRequest?.assignee?.id}
                labelPosition="right"
                splitName={false}
              />
            </div>
          )}
        </div>
      </div>
      {hasInvoicingEnabled && (
        <MemberChargeList
          channel={channel}
          externalEntityType={ExternalEntityType.EXTERNAL_ENTITY_TYPE_WORKORDER}
          externalEntityId={convertToUUID(serviceRequest.serviceRequestId)}
        />
      )}
      <div className={cx(styles.divider)}>
        {serviceRequest.status !== INTERACTION_CANCELLED && !isEditing && (
          <Button
            className={styles.cancelRequest}
            size="small"
            onClick={() => setOpenCancelRequestModel(true)}
          >
            {t`web.member.serviceRequest.cancel.request`}
          </Button>
        )}

        <Modal
          isOpen={openCancelRequestModel}
          onClose={() => setOpenCancelRequestModel(false)}
          size="large"
          closeButtonSize="small"
          title={t`web.member.serviceRequest.cancel.modal.action.header`}
          actions={
            <Button
              variant="contained"
              size="medium"
              onClick={cancelRequest}
              className={styles.cancelButton}
            >
              {t`web.member.serviceRequest.cancel.modal.action.confirm`}
            </Button>
          }
        >
          <M
            variant="secondary"
            mt={3}
          >{t`web.member.serviceRequest.cancel.modal.action.message`}</M>
        </Modal>
      </div>
    </div>
  );
}
