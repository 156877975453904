import React from 'react';

import { MultiselectField } from 'components';
import { v4 as uuid } from 'uuid';

import { Label } from 'lane-web/src/components';

import ValidationMessage from 'components/general/ValidationMessage';

type Schema = {
  label: string;
  value: string;
};

type OwnProps = {
  /** callback for user */
  onChange: (options: any[]) => void;
  /** identifier */
  name: string;
  /** select field items */
  items: any[];
  /** schema to map data  */
  schema: Schema;
  /** placeholder */
  placeholder: string;
  /** max height for menu */
  isSearchable: boolean;
  value: any[];
  className?: string;
  errors?: string[];
  modifyValue?: boolean;
  label?: string;
  disabled?: boolean;
  dataCy?: string;
  doTranslate?: boolean;
};

StaffTeamsMultiSelectDropdown.defaultProps = {
  schema: {
    label: 'label',
    value: 'value',
    isDisabled: 'isDisabled',
  },
  placeholder: 'Select Option',
  name: `MultiselectField-${uuid()}`,
  isSearchable: true,
  disabled: false,
};

type Props = OwnProps & typeof StaffTeamsMultiSelectDropdown.defaultProps;

export function StaffTeamsMultiSelectDropdown({
  items,
  name,
  value = [],
  onChange,
  placeholder,
  schema,
  isSearchable,
  errors,
  className,
  disabled,
  label,
  dataCy,
  doTranslate = true,
}: Props) {
  function constructOption(item: any) {
    return {
      label: item[schema.label],
      value: item[schema.value],
      isDisabled: item[schema.isDisabled],
    };
  }

  return (
    <div className={className} data-cy={dataCy}>
      {label && <Label mt={0}>{label}</Label>}

      <MultiselectField
        value={value}
        name={name}
        isSearchable={isSearchable}
        onChange={onChange}
        placeholder={placeholder}
        items={items.map(constructOption)}
        disabled={disabled}
        truncateSelectedItems
        doTranslation={doTranslate}
      />
      <ValidationMessage errors={errors} />
    </div>
  );
}
