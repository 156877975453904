import React, { useState } from 'react';
import { BulkUsers } from './BulkUsers';
import { InputType, InputTypeEnum, AddUserType } from './types';
import { useTranslation } from 'react-i18next';
import { useChannelGroupRolesQuery } from 'lane-shared/hooks';
import { UserStatusEnum } from 'lane-shared/types/User';
import { routes } from 'lane-shared/config';
import { useHistory } from 'react-router-dom';
import { getClient } from 'lane-shared/apollo';
import { createUserAndAddToTeams } from 'lane-shared/graphql/user';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

export function UserBulkAdd(channelInput: { channel: any }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: channelInput.channel._id,
    includeCounts: true,
    includeWorkplaceMember: true,
  });
  const channelId = channelInput.channel._id;
  const isPropertyChannel =
    channelInput.channel.type === ChannelTypeEnum.Property;

  const initialColumns: AddUserType = {
    name: '',
    email: '',
    phoneNumber: '',
    marketingOptIn: false,
    teams: [],
    userStatus: UserStatusEnum.Active,
    addToTenants: false,
    ...(isPropertyChannel ? { addToTenants: false } : {}),
  };

  const inputs: InputType[] = [
    {
      label: 'Full Name',
      placeholder: 'Full Name',
      key: 'name',
      type: InputTypeEnum.String,
    },
    {
      label: 'Email',
      placeholder: 'Email',
      key: 'email',
      type: InputTypeEnum.String,
    },
    {
      label: 'Phone Number',
      placeholder: 'Phone Number',
      key: 'phoneNumber',
      type: InputTypeEnum.String,
    },
    {
      label: 'Teams',
      placeholder: 'Teams',
      key: 'teams',
      type: InputTypeEnum.MultiSelect,
      items: groupRoles.map(role => {
        return { label: role.name, value: role._id };
      }),
    },
    {
      label: 'Marketing Opt-In',
      placeholder: 'Marketing Opt-In',
      key: 'marketingOptIn',
      type: InputTypeEnum.Boolean,
    },
    {
      label: 'User Status',
      placeholder: 'User Status',
      key: 'userStatus',
      type: InputTypeEnum.Dropdown,
      items: Object.keys(UserStatusEnum)
        .filter(status => status !== UserStatusEnum.System)
        .map(status => {
          return {
            label: status,
            value: status,
          };
        }),
    },
    ...(isPropertyChannel
      ? [
          {
            label: 'Add User to Tenant(s)',
            placeholder:
              'Add user to tenants of this channel as workplace member',
            key: 'addToTenants',
            type: InputTypeEnum.Boolean,
          },
        ]
      : []),
  ];

  function onCancel() {
    history.push(routes.channelAdminTeams.replace(':id', channelId));
  }

  async function onSubmit(rows: AddUserType[]) {
    setLoading(true);
    try {
      for (const row of rows) {
        // Add user to teams within this channel
        await getClient().mutate({
          mutation: createUserAndAddToTeams,
          variables: {
            name: row.name,
            email: row.email,
            phoneNumber: row.phoneNumber,
            emailOptIn: row.marketingOptIn,
            userStatus: row.userStatus,
            teamsToAdd: row.teams.map(team => team.label),
            channelId: convertToUUID(channelId),
            addUserToTenants: row.addToTenants,
          },
        });
      }

      window.Toast.show('Done');
    } catch (err) {
      console.error(err);
      await window.Alert.alert({
        title: t(`Failed`),
        message: t(`Adding users failed`),
        error: err,
      });
    }
    setLoading(false);
  }

  return (
    <BulkUsers
      loading={loading}
      channelInput={channelInput}
      initialColumns={initialColumns}
      title={t('web.pages.portal.admin.channel.team.bulkAdd.title', {
        channelName: channelInput.channel.name,
      })}
      description={t('web.pages.portal.admin.channel.team.bulkAdd.description')}
      tableTitle={t('web.pages.portal.admin.channel.team.addUsers')}
      buttonTitle={t('web.pages.portal.admin.channel.team.addUsers')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      inputs={inputs}
      url={routes.channelAdminTeamsView.replace(':id', channelId)}
      breadcrumbTitle={t(
        'web.admin.channel.teamManagement.team.bulkAdd.breadCrumbTitle'
      )}
    />
  );
}
