import { Button } from 'design-system-web';
import { Modal } from 'components/lds';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryDetails } from '../../categories/CreateCategoryForm';
import cx from 'classnames';
import styles from './CreateCategoryFormModal.scss';
import { ProductType } from 'lane-shared/domains/billingPayments/types';
import { createCategoryMutation } from '../../helpers/createCategoryMutation';
import { Input, RadioGroup, TextArea } from 'components/form';
import { ErrorMessage } from 'components';
import { useChannelAdminContext } from 'hooks';

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  onSave: () => void;
  dataCy?: string;
};

/**
 * Modal variant of @function CreateCategoryForm
 */
export function CreateCategoryFormModal({
  modalVisible,
  onClose,
  onSave,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [newCategoryDetails, setNewCategoryDetails] = useState<CategoryDetails>(
    {
      productType: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      name: '',
      description: '',
    }
  );

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setNewCategoryDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const isSaveButtonDisabled = (): boolean => {
    const { name, productType } = newCategoryDetails;

    return !(name && productType);
  };

  const handleSaveCategory = async () => {
    try {
      await createCategoryMutation(
        {
          ...newCategoryDetails,
        },
        channel?._id || ''
      );
      onSave();
      onClose();
      window.Toast.show(
        t('abp.productsServices.createCategory.createSuccessfull')
      );
    } catch (err) {
      console.error(err);
      setError(err as Error);
    }
  };

  const productTypeOptions = [
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
      label: 'abp.productsServices.createCategory.productServiceType.materials',
      description:
        'abp.productsServices.createCategory.productServiceType.materialDescription',
    },
    {
      id: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      label: 'abp.productsServices.createCategory.productServiceType.labour',
      description:
        'abp.productsServices.createCategory.productServiceType.labourDescription',
    },
  ];

  return (
    <Modal
      isOpen={modalVisible}
      onClose={onClose}
      dataCy={dataCy}
      closeButtonSize="large"
      size="large"
      disableContentPadding
      title={t('abp.productsServices.createCategory.heading')}
      className={styles.createCategoryFormModal}
    >
      <div className={styles.form}>
        <div className={styles.formInputs}>
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              {t('abp.productsServices.createCategory.type')}
            </label>

            <div className={styles.fieldset}>
              <RadioGroup
                key="type"
                name="productType"
                selected={newCategoryDetails.productType}
                className={styles.radioLabel}
                schema={{
                  id: 'id',
                  text: 'label',
                  subtext: 'description',
                }}
                items={productTypeOptions}
                onChange={(value: string) => {
                  handleFieldUpdate(value, 'productType');
                }}
              />
            </div>
          </fieldset>
          <Input
            type="text"
            label={t('abp.productsServices.createCategory.name')}
            fixedLabel
            onChange={(value: string) => {
              handleFieldUpdate(value, 'name');
            }}
            fieldName="name"
            className={cx(styles.fieldset, styles.categoryName)}
            value={newCategoryDetails.name}
          />
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              {t('abp.productsServices.createCategory.description')}
            </label>
            <TextArea
              onChange={(value: string) => {
                handleFieldUpdate(value, 'description');
              }}
              value={newCategoryDetails.description}
              maxLength={200}
              showLengthIndicator
              minRows={4}
            />
          </fieldset>
        </div>
        <div className={styles.formButtons}>
          <Button
            className={styles.button}
            type="submit"
            disabled={isSaveButtonDisabled()}
            onClick={handleSaveCategory}
            size="large"
            variant="primary"
          >
            {t('abp.productsServices.createCategory.save')}
          </Button>
          <Button
            className={styles.button}
            type="button"
            onClick={() => onClose()}
            size="large"
            variant="secondary"
          >
            {t('abp.productsServices.createCategory.cancel')}
          </Button>
        </div>
        {error && <ErrorMessage error={error} className={styles.error} />}
      </div>
    </Modal>
  );
}
