import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Loading, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { BuildingSearchResult } from 'lane-shared/contexts/SignUpContext/useBuildingSearch';
import { CompanyLocationsSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { ChannelSearchCircleListView } from 'components/lane';
import { Text, H4, H5 } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import SignUpChannelListItem from './SignUpChannelListItem';

import styles from './Signup.scss';

type OnboardingBuildingInputProps = {
  data: {
    building: ChannelType | null;
    company: ChannelType | null;
    parentCompany: ChannelType | null;
    companySearch: string;
    buildingSearch: string;
    companyLocationsResult: CompanyLocationsSearchResult;
    buildingsResult: BuildingSearchResult;
    whiteLabelName: string;
  };
  config: {
    isLocked: boolean;
  };
  handlers: {
    onCompanySelectionChange: (company: ChannelType | null) => void;
    onBuildingSelectionChange: (building: ChannelType | null) => void;
    onCompanySearchTextChange: (companySearch: string) => void;
    onBuildingSearchTextChange: (buildingSearch: string) => void;
  };
  ContinueButton: React.ReactNode;
};

export function OnboardingBuildingInput({
  data: {
    building,
    company,
    parentCompany,
    companySearch,
    buildingSearch,
    companyLocationsResult,
    buildingsResult,
    whiteLabelName,
  },
  config: { isLocked },
  handlers: {
    onCompanySelectionChange,
    onBuildingSelectionChange,
    onCompanySearchTextChange,
    onBuildingSearchTextChange,
  },
  ContinueButton,
}: OnboardingBuildingInputProps) {
  const { t } = useTranslation();
  const {
    translationKeys: { locationPrompt, locationPlaceholder },
  } = useContinueAsGuest();

  const [invitedToBuilding, setInvitedToBuilding] = useState(false);
  const [invitedToCompany, setInvitedToCompany] = useState(false);

  useEffect(() => {
    if (isLocked) {
      if (building) {
        setInvitedToBuilding(true);
      }
      if (company && !parentCompany) {
        setInvitedToCompany(true);
      }
    }
  }, [building, company, isLocked, parentCompany]);

  return (
    <div>
      {!building ? (
        <div>
          <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
            {t(locationPrompt, { whiteLabelName })}
          </H4>
          <Input
            onChange={onBuildingSearchTextChange}
            value={buildingSearch}
            placeholder={t(locationPlaceholder)}
            icon="search"
            iconRight
            dataCy="buildingLocation"
          />
          <br />
          <Text className={styles.resultsHeader}>{t('Suggestions')}</Text>
          <div className={styles.results}>
            {buildingsResult.buildings.map(channel => (
              <SignUpChannelListItem
                key={channel._id}
                showName
                channel={channel}
                onClick={onBuildingSelectionChange}
                className={styles.channelResult}
              />
            ))}
            {buildingsResult.loading && <Loading className={styles.loading} />}
          </div>
        </div>
      ) : (
        <div>
          <H5 className={welcomeFormStyles.formDescription}>
            {t(locationPlaceholder)}
          </H5>
          <div className={styles.selectedChannelContainer}>
            <SignUpChannelListItem
              className={cx([styles.channelResult, styles.selected])}
              showName={false}
              channel={building}
              onClick={() => {
                if (!invitedToBuilding) {
                  onBuildingSelectionChange(null);
                }
              }}
            />
            <Icon
              name={invitedToBuilding ? 'lock' : 'edit'}
              className={styles.checkIcon}
              set={ICON_SET_FONTAWESOME}
              type={FONT_AWESOME_REGULAR}
            />
          </div>
        </div>
      )}
      {invitedToBuilding && !company && (
        <>
          <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
            {t('Select your Company')}
          </H4>
          <Input
            className={styles.channelSearchInput}
            onChange={onCompanySearchTextChange}
            value={companySearch}
            placeholder={t('Company Name')}
            dataCy="companyName"
            icon="search"
            showClear
          />
          <div className={cx(styles.results, styles.reducedHeight)}>
            {companyLocationsResult.locations.map(location => (
              <SignUpChannelListItem
                key={(location as any)._id}
                channel={location.channel}
                onClick={() => onCompanySelectionChange(location.channel)}
                showName
                showCompanyIcon
              />
            ))}
            {companyLocationsResult.loading && (
              <Loading className={styles.loading} />
            )}
          </div>
        </>
      )}
      {company && (
        <div>
          <H5 className={welcomeFormStyles.formDescription}>
            {t('Your company')}
          </H5>
          <div className={styles.selectedChannelContainer}>
            <ChannelSearchCircleListView
              className={cx([styles.channelResult, styles.selected])}
              key={company._id}
              channel={company}
              onClick={() => {
                if (!invitedToCompany) {
                  onCompanySelectionChange(null);
                }
              }}
            />
            <Icon
              name={invitedToCompany ? 'lock' : 'edit'}
              className={styles.checkIcon}
              set={ICON_SET_FONTAWESOME}
              type={FONT_AWESOME_REGULAR}
            />
          </div>
        </div>
      )}
      {(building || company) && ContinueButton}
    </div>
  );
}
