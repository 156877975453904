import React from 'react';

import { Controller, Control } from 'react-hook-form';

import { Dropdown } from 'lane-web/src/components/form';

type Item<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  control: Control<any>;
  errorMessage?: string[] | null;
  name: string;
  items: Item<T>[];
  isFullWidth?: boolean;
  placeholder?: string;
  label?: string;
  fixedLabel?: boolean;
  doTranslation?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  onValueChange?: any;
  isRequired?: boolean;
  dataCy?:string;
};

export function HookFormDropdown<T>({
  control,
  name,
  items,
  placeholder,
  isFullWidth,
  label,
  fixedLabel,
  ariaLabel,
  doTranslation,
  disabled,
  onValueChange,
  isRequired,
  dataCy
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Dropdown
          onChange={e => {
            onChange(e);
            if (onValueChange) {
              onValueChange(e);
            }
          }}
          value={value}
          items={items}
          placeholder={placeholder}
          isFullWidth={isFullWidth}
          ariaLabel={ariaLabel}
          label={label}
          fixedLabel={fixedLabel}
          doTranslation={doTranslation}
          disabled={disabled}
          isRequired={isRequired}
          dataCy={dataCy ?? ""}
        />
      )}
    />
  );
}
export default HookFormDropdown;
