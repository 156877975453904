import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Button, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { WorkOrderModuleCategoriesSettings } from 'lane-shared/domains/workOrder';
import { AdminUser } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';

import { CategoryAssignment } from './components/CategoryAssignment';
import SimpleOptions from './components/inputs/SimpleOptions';
import { OptionType } from './types';

import styles from './serviceCategory.scss';
import { useChannelServiceRequestFeaturesContext } from 'lane-shared/hooks';

type Props = {
  category: WorkOrderModuleCategoriesSettings;
  onSave: (category: WorkOrderModuleCategoriesSettings) => void;
  onDelete: (category: WorkOrderModuleCategoriesSettings) => void;
  assignableTeams: AdminUser[];
  assignableUsers: AdminUser[];
};

export function arrayToOptions(arr?: string[]): OptionType[] {
  return (
    arr?.map((str: string) => ({
      value: str,
      name: str,
    })) || []
  );
}
export function optionsToArray(arr: OptionType[]): string[] {
  return arr.map((opt: OptionType) => opt.name);
}

export const ServiceCategory = ({
  category,
  assignableTeams,
  assignableUsers,
  onSave,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [issues, setIssues] = useState(category?.issues);
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const allowCategoryEdit = serviceRequestFeatures?.updateServiceRequest;

  const update = (issuesOptions: OptionType[]) => {
    const issuesArray = optionsToArray(issuesOptions);
    setIssues(issuesArray);
    onSave({
      ...category,
      issues: issuesArray,
    });
  };

  const updateName = (name: string) => {
    onSave({
      ...category,
      firendlyName: name,
    });
  };

  const setCategoryTeams = (assigneeGroups: string[]) => {
    onSave({
      ...category,
      assigneeGroups,
      assignee: '',
    });
  };

  const setCategoryAssignee = (assignee: string) => {
    onSave({
      ...category,
      assignee,
      assigneeGroups: [],
    });
  };

  return (
    <div className={styles.categoryContainer}>
      <div className={styles.headerContainer}>
        <p className={styles.categoryName}>{category?.firendlyName}</p>
        <p
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={styles.collapseButton}
          data-cy="collapseButton"
        >
          {isCollapsed ? t`web.expand` : t`web.collapse`}
        </p>
      </div>

      {!isCollapsed && (
        <div className={styles.configContainer}>
          <div>
            <span className={styles.fieldLabel}>
              {t`web.admin.serviceRequest.form.categoryName`}
            </span>
            <Input
              placeholder={t`web.admin.serviceRequest.form.categoryName`}
              value={category?.firendlyName}
              error={
                !category?.firendlyName
                  ? [t`This field is required.`]
                  : undefined
              }
              onChange={updateName}
              readOnly={!allowCategoryEdit}
              showClear={allowCategoryEdit}
              dataCy="categoryName"
            />
          </div>
          <div className={styles.columnContainer}>
            <div
              className={cx(
                styles.column,
                allowCategoryEdit ? styles.borderRight : ''
              )}
            >
              <p>{t`web.admin.serviceRequest.form.issues`}</p>
              <SimpleOptions
                disableDragging
                options={arrayToOptions(issues)}
                setOptions={update}
                addOptionTitle={t`web.admin.serviceRequest.form.addIssue`}
                allowEdit={allowCategoryEdit}
                hideAddOption={!allowCategoryEdit}
              />
            </div>
            {allowCategoryEdit && (
              <div className={styles.column}>
                <CategoryAssignment
                  category={category}
                  assignableTeams={assignableTeams}
                  assignableUsers={assignableUsers}
                  setCategoryTeams={setCategoryTeams}
                  setCategoryAssignee={setCategoryAssignee}
                />
              </div>
            )}
          </div>
          {allowCategoryEdit && (
            <div className={styles.deleteContainer}>
              <Button
                startIcon={<Icon name="trash" set="Feather" />}
                variant="text-icon"
                onClick={() => onDelete(category)}
                className={styles.delete}
              >
                {t`Delete`}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
