import styles from './invoiceAutopayment.scss';
import { Button, Icon, PopupMenu } from 'design-system-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FONT_AWESOME_SOLID } from 'lane-shared/helpers/constants/icons';

export function AutopaymentCta({
  isAutopaymentEnabled,
  setIsAddModalOpen,
  setIsTurnOffModalOpen,
}: {
  isAutopaymentEnabled: boolean;
  setIsAddModalOpen: (value: boolean) => void;
  setIsTurnOffModalOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const buttonOptions = [
    {
      label: t(
        'abp.routes.accountInvoices.autopayment.turnOffAutomaticPayments'
      ),
      onSelect: () => {
        setIsTurnOffModalOpen(true);
      },
    },
  ];
  return (
    <div>
      {isAutopaymentEnabled ? (
        <PopupMenu
          items={buttonOptions}
          trigger={
            <Button
              endIcon={<Icon type={FONT_AWESOME_SOLID} name="caret-down" />}
              variant="secondary"
              dataCy="ManageAutopaymentButton"
            >
              {t(
                'abp.routes.accountInvoices.autopayment.manageAutomaticPayments'
              )}
            </Button>
          }
        />
      ) : (
        <Button
          className={styles.turnOnButton}
          dataCy="TurnOnAutopaymentButton"
          variant="secondary"
          onClick={() => setIsAddModalOpen(true)}
        >
          {t('abp.routes.accountInvoices.autopayment.turnOnAutomaticPayments')}
        </Button>
      )}
    </div>
  );
}
