import React, { useState } from 'react';

import { Icon, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { shortAddress } from 'lane-shared/helpers/formatters';
import type { ChannelType } from 'lane-shared/types/ChannelType';
import type { GroupRole } from 'lane-shared/types/GroupRole';
import type { Audience } from 'lane-shared/types/audiences/Audience';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';

import Pill from 'components/general/Pill';
import Tooltip from 'components/general/Tooltip';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import { GroupRoleCircleListView } from 'components/lane/GroupRoleCircleListView';
import { H2, H3, H5, M } from 'components/typography';

import { DeletionConfirmationModal } from '../DeletionConfirmationModal';

import style from '../ShowAudience.scss';

type Props = {
  channel: ChannelType;
  audience: Audience;
  teams: GroupRole[];
  channels: ChannelType[];
  reachCount: number;
};

export function DetailsTab({
  audience,
  teams,
  channels,
  channel,
  reachCount,
}: Props) {
  const { t } = useTranslation();
  const { audienceTracker } = useAudienceAnalytics({ audience, channels });

  const location = useLocation();

  const targetedContentIsEmpty = audience?.contentToTarget?.length === 0;
  const isDefaultAudience = audience?.isDefault;

  const [
    deleteAudienceModalOpen,
    setDeleteAudienceModalOpen,
  ] = useState<boolean>(false);

  const disabledDelete =
    isDefaultAudience || (!targetedContentIsEmpty && !isDefaultAudience);

  let unableToDeleteReason = '';

  if (audience?.contentToTarget?.length !== 0) {
    unableToDeleteReason = t('web.admin.audience.deleteButtonTooltip');
  }

  if (isDefaultAudience) {
    unableToDeleteReason = t('web.admin.audience.deleteButtonTooltipDefault');
  }

  function getChannelLink(channel: ChannelType) {
    return routes.channel.replace(':id', channel?.slug);
  }

  function getTeamLink(groupRole: GroupRole) {
    return routes.channelAdminTeamsView
      .replace(':id', channel?.slug)
      .replace(':groupRoleId', groupRole?._id);
  }

  const deleteButton = () => (
    <Button
      variant="secondary"
      size="medium"
      className={style.buttonStyle}
      disabled={disabledDelete}
      dataCy="openDeleteModal"
      startIcon={<Icon name="trash-alt" set="FontAwesome" />}
      endIcon={<Icon name="info-circle" />}
      onClick={() => {
        setDeleteAudienceModalOpen(true);
        audienceTracker.Delete.Start();
      }}
    >
      {t('web.admin.audience.deleteButtonText')}
    </Button>
  );

  const deleteButtonWithTooltip = () => (
    <div className={style.tooltipWrapper}>
      <Tooltip placement="top" TooltipComponent={unableToDeleteReason}>
        {deleteButton()}
      </Tooltip>
    </div>
  );

  const editButton = () => (
    <Button
      interfaceStyle="light"
      variant="secondary"
      size="large"
      dataCy="editAudienceButton"
      disabled={isDefaultAudience}
      startIcon={<Icon name="pencil" />}
      className={style.editButtonStyle}
      onClick={() => {
        audienceTracker.Update.Start();
      }}
    >
      {t('Edit')}
    </Button>
  );

  const editLink = () => (
    <Link
      className={style.link}
      to={{
        pathname: `${audience.id}/edit`,
        state: { from: location.pathname },
      }}
    >
      {editButton()}
    </Link>
  );

  const disabledLinkWithTooltip = () => (
    <div className={style.link}>
      <Tooltip
        TooltipComponent={t('web.admin.audience.editButtonTooltipDefault')}
      >
        {editButton()}
      </Tooltip>
    </div>
  );
  const buildDeleteButton = () =>
    disabledDelete ? deleteButtonWithTooltip() : deleteButton();

  return (
    <div className={style.detailsTab}>
      <div className={style.editRow}>
        <H3 className={style.audienceNameSmall}>{audience!.name}</H3>
        {isDefaultAudience ? disabledLinkWithTooltip() : editLink()}
      </div>
      <div className={style.audiencePanel}>
        <div className={style.selectedTeamsAndChannelHeaderContainer}>
          <H2 className={style.selectedTeamsAndChannelText}>
            {t(
              'web.pages.portal.admin.audiences.show.selectedTeamsAndChannels'
            )}
          </H2>
        </div>
        <div className={style.impactCard} data-cy="impactCard">
          <div className={style.impactCardContainer}>
            <H5>{t('web.pages.portal.admin.audiences.show.impactSummary')}</H5>
            <div className={style.impactCardRightContainer}>
              <div className={style.impactItem}>
                <M
                  className={style.impactSummaryNumber}
                  data-testid="channel-impact-number"
                >
                  {audience!.channelTargetingRules.length}
                </M>

                <M
                  className={style.impactSummaryLabel}
                  data-testid="channel-impact-label"
                  variant="secondary"
                >
                  {t('web.pages.portal.admin.audiences.show.channel', {
                    count: audience!.channelTargetingRules.length,
                  })}
                </M>
              </div>

              <div className={style.impactItem}>
                <M
                  className={style.impactSummaryNumber}
                  data-testid="team-impact-number"
                >
                  {audience!.groupRoleTargetingRules.length}
                </M>

                <M
                  className={style.impactSummaryLabel}
                  data-testid="team-impact-label"
                  variant="secondary"
                >
                  {t('web.pages.portal.admin.audiences.show.team', {
                    count: audience!.groupRoleTargetingRules.length,
                  })}
                </M>
              </div>
              <div className={style.impactItem}>
                <M
                  className={style.impactSummaryNumber}
                  data-testid="react-impact-number"
                >
                  {reachCount}
                </M>

                <M
                  className={style.impactSummaryLabel}
                  data-testid="reach-impact-label"
                  variant="secondary"
                >
                  {t('web.pages.portal.admin.audiences.show.reach')}
                </M>
              </div>
            </div>
          </div>
        </div>
        {teams.map((team: any) => (
          <div className={style.card} key={team?._id}>
            <Link
              to={getTeamLink(team)}
              className={style.linkContainer}
              target="_blank"
            >
              <GroupRoleCircleListView
                groupRole={team}
                description={team?.channel?.name}
                style={style}
                className={style.audienceCircleListView}
              />
              <Pill
                text={t('web.admin.audience.details.teamPill')}
                color="blue"
                size="medium"
              />
            </Link>
          </div>
        ))}
        {channels.map((oneChannel: any) => (
          <div className={style.card} key={oneChannel?._id}>
            <Link
              to={getChannelLink(oneChannel)}
              className={style.linkContainer}
              target="_blank"
            >
              <ChannelCircleListView
                channel={oneChannel}
                description={shortAddress(oneChannel?.address!)}
                style={style}
                className={style.audienceCircleListView}
              />
              <Pill
                text={t('web.admin.audience.details.channelPill')}
                color="gray"
                size="medium"
              />
            </Link>
          </div>
        ))}
      </div>
      {buildDeleteButton()}
      <DeletionConfirmationModal
        isModalOpen={deleteAudienceModalOpen}
        setIsModalOpen={setDeleteAudienceModalOpen}
        channel={channel}
        audienceId={audience!.id}
        audienceName={audience!.name}
      />
    </div>
  );
}
