import React, { useRef, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  ErrorMessage,
  IconButton,
  Input,
} from 'components';

import { getValidationMessages } from 'lane-shared/helpers';
import { H4 } from 'components/typography';
import styles from '../teamBulkInvite/styles.scss';
import { useTranslation } from 'react-i18next';

import CardContainer from 'components/cards/CardContainer';
import {
  Button,
  Icon,
  Loading,
  M,
  Multiselect,
  DropdownItem,
} from 'design-system-web';
import { Flex, PageHeader } from 'components/layout';
import { InputType, InputTypeEnum, AddUserType } from './types';
import { UsersCsvImportButton } from './UsersCsvImportButton';

export function BulkUsers({
  loading,
  channelInput,
  initialColumns,
  inputs,
  title,
  description,
  tableTitle,
  buttonTitle,
  onSubmit,
  onCancel,
  url,
  breadcrumbTitle,
}: {
  loading: boolean;
  channelInput: any;
  initialColumns: AddUserType;
  title: string;
  inputs: InputType[];
  description: string;
  tableTitle: string;
  buttonTitle: string;
  onSubmit: (rows: AddUserType[]) => void;
  onCancel: () => void;
  url: string;
  breadcrumbTitle: string;
}) {
  const myRef = useRef(null);
  const { t } = useTranslation();

  // Channel info
  const channel = channelInput.channel;

  // User rows
  const [rows, setRows] = useState<AddUserType[]>(
    Array(5)
      .fill(1)
      .map(() => initialColumns)
  );

  function addRow() {
    setRows([
      ...rows,
      {
        ...initialColumns,
      },
    ]);

    // using setTimeout to scroll to last item in list after render
    setTimeout(() => {
      if (myRef && myRef.current) {
        (myRef.current as HTMLElement).lastElementChild?.scrollIntoView(true);
      }
    }, 0);
  }

  const pageHeaderProps = {
    header: title,
    breadcrumbs: [
      {
        label: t('web.pages.portal.admin.users.index.header'),
        url,
      },
      {
        label: breadcrumbTitle,
      },
    ],
  };

  const areAllRowsEmpty =
    rows.filter((item: AddUserType) => {
      return item.name || item.email;
    }).length === 0;

  if (!channel)
    return <Loading testId="loadingBulkInvite" style={{ margin: 'auto' }} />;

  function handleOnChange({
    index,
    value,
    key,
  }: {
    index: number;
    value: string | boolean | string[];
    key: string;
  }) {
    setRows(
      rows.map((row: AddUserType, i: number) => {
        if (i === index) {
          return {
            ...row,
            [key]: value,
          };
        }
        return row;
      })
    );
  }

  return (
    <div className={styles.outerWrapper}>
      <PageHeader
        {...pageHeaderProps}
        headerLevel="h3"
        description={description}
      />
      <Flex direction="column" m={[0, 5]} className={styles.wrapper}>
        <CardContainer className={styles.BulkSendInvite}>
          <div className={styles.actionButtons}>
            <div>
              <H4 style={{ marginRight: 10 }}>{tableTitle}</H4>
              <M className={styles.subtitle}>
                {t(
                  'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.subtitle',
                  { numberOfRows: rows.length }
                )}
              </M>
            </div>
            <div>
              <UsersCsvImportButton
                loading={false}
                setRows={setRows}
                inputs={inputs}
              />
              <Button
                className={styles.button}
                variant="tertiary"
                startIcon={<Icon size="small" name="plus" />}
                onClick={addRow}
                dataCy="add-row-to-user-input-list"
              >
                {t('web.pages.portal.admin.channel.team.addUserButton')}
              </Button>
            </div>
          </div>
          <ul
            ref={myRef}
            style={{ maxHeight: 480, overflow: 'auto' }}
            data-cy="user-input-list"
          >
            {rows.map((row: AddUserType, i: number) => (
              <li key={`row-${i}`} data-testid="user-input-list-row">
                <ErrorMessage error={row.error} />
                {inputs.map(({ key, label, placeholder, type, items }) => {
                  if (type === InputTypeEnum.String) {
                    return (
                      <Input
                        key={label}
                        className={styles.input}
                        label={label}
                        error={getValidationMessages(row.validation, key)}
                        value={row[key as keyof AddUserType] as string}
                        placeholder={placeholder}
                        disabled={row.wasSent}
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value: value as string,
                            key,
                          });
                        }}
                      />
                    );
                  }
                  if (type === InputTypeEnum.Boolean) {
                    return (
                      <Checkbox<boolean>
                        key={label}
                        className={styles.input}
                        text={label}
                        value={row[key as keyof AddUserType] as boolean}
                        selected={row[key as keyof AddUserType] as boolean}
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value: !value as boolean,
                            key,
                          });
                        }}
                      />
                    );
                  }
                  if (type === InputTypeEnum.Dropdown) {
                    return (
                      <Dropdown
                        key={label}
                        className={styles.input}
                        label={label}
                        placeholder={placeholder}
                        items={items}
                        value={row[key as keyof AddUserType] as string}
                        onValueChange={value => {
                          handleOnChange({
                            index: i,
                            value: value as string,
                            key,
                          });
                        }}
                      />
                    );
                  }
                  if (type === InputTypeEnum.MultiSelect) {
                    return (
                      <Multiselect<DropdownItem<string>[]>
                        key={label}
                        items={items}
                        value={
                          row[
                            key as keyof AddUserType
                          ] as DropdownItem<string>[]
                        }
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value,
                            key,
                          });
                        }}
                      />
                    );
                  }

                  return null;
                })}

                <IconButton
                  icon="times"
                  data-testid="removeRow"
                  inverted
                  className={styles.removeButton}
                  onClick={() =>
                    setRows(
                      rows.filter(
                        (row: AddUserType, index: number) => index !== i
                      )
                    )
                  }
                />
              </li>
            ))}
          </ul>
        </CardContainer>
        <div className={styles.actionButtonsBottom}>
          <Button
            dataCy="addUsers"
            variant="primary"
            size="large"
            className={styles.button}
            onClick={() => {
              // Skip empty rows
              const validRows = rows.filter(item => {
                return item.name && item.email;
              });
              onSubmit(validRows);
            }}
            loading={loading}
            disabled={areAllRowsEmpty}
          >
            {buttonTitle}
          </Button>

          <Button
            className={styles.button}
            variant="tertiary"
            size="large"
            onClick={onCancel}
          >
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.clearInvitesButtonText'
            )}
          </Button>
        </div>
      </Flex>
    </div>
  );
}
