import React from 'react';

import { TextArea } from 'components/form';

import styles from './UserNotes.scss';

const TEXT_MAX_LENGTH = 200;
const DEFAULT_LABEL = 'user_notes';

type Props = {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
};

export function UserNotes({
  label = DEFAULT_LABEL,
  onChange,
  placeholder,
  value,
}: Props) {
  return (
    <div>
      <div className={styles.labelContainer}>
        <label className={styles.label}>{label}</label>
      </div>
      <TextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        dataCy="userNotes"
        showLengthIndicator
        maxLength={TEXT_MAX_LENGTH}
        className={styles.textArea}
      />
    </div>
  );
}
