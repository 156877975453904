/* eslint-disable react/forbid-component-props */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Flex, Input, TextArea } from 'design-system-web';
import { RadioGroup } from 'components';
import { StickyFooter } from 'components/general';

import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { useLazyQuery } from '@apollo/client';
import {
  createMeterReadingValueMutation,
  updateMeterReadingValueMutation,
  searchMeterReadingValue,
} from 'graphql-queries';
import { SortDirection } from 'graphql-query-contracts';

import { StepExecutionType } from './types';
import styles from './StepExecutionForm.scss';

export interface StepExecutionFormProps {
  channel: any;
  steps: StepExecutionType[];
  onStepChange: (step: StepExecutionType) => void;
  disabled?: boolean;
  task?: any;
}

export const StepExecutionForm = ({
  channel,
  steps,
  onStepChange,
  disabled = false,
  task,
}: StepExecutionFormProps) => {
  const [previousValues, setPreviousValues] = useState<any>([]);
  const [localSteps, setLocalSteps] = useState<StepExecutionType[]>(steps);
  const [saveText, setSaveText] = useState<string>('');
  const { t } = useTranslation();

  const [fetchMeterReadingValues, { data }] = useLazyQuery(
    searchMeterReadingValue,
    {
      onCompleted: () => {
        if (
          data?.searchMeterReadingValue?.meterReadingValues &&
          data?.searchMeterReadingValue?.meterReadingValues?.length > 0
        ) {
          const newPreviousValues = [
            ...previousValues,
            {
              meterReadingId:
                data.searchMeterReadingValue.meterReadingValues[0]
                  .meterReadingId,
              value: data.searchMeterReadingValue.meterReadingValues[0].value,
              date:
                data.searchMeterReadingValue.meterReadingValues[0].createdAt,
            },
          ];
          setPreviousValues(newPreviousValues);
        }
      },
      fetchPolicy: 'network-only',
    }
  );

  const getPreviousValue = (id: string) => {
    if (id) {
      fetchMeterReadingValues({
        variables: {
          channelId: channel?._id,
          search: {
            sortBy: {
              key: 'created_at',
              dir: 'desc' as SortDirection,
            },
          },
          pagination: {
            start: 0,
            perPage: 1,
          },
          meterReadingId: id,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  useEffect(() => {
    if (previousValues.length === 0) {
      steps.forEach(step => {
        if (step.meterReadingId) {
          getPreviousValue(step.meterReadingId);
        }
      });
    }
  }, [steps]);

  const getStepHeader = (step: StepExecutionType) => {
    if (step.type === 'instruction') {
      return t('web.admin.workOrder.steps.headers.instruction');
    }
    if (step.type === 'acknowledge') {
      return t('web.admin.workOrder.steps.headers.acknowledge');
    }
    if (step.type === 'response') {
      if (step.options.subtype === 'short') {
        return t('web.admin.workOrder.steps.headers.response.short');
      }
      if (step.options.subtype === 'long') {
        return t('web.admin.workOrder.steps.headers.response.long');
      }
      if (step.options.subtype === 'numerical') {
        return t('web.admin.workOrder.steps.headers.response.numerical');
      }
    }
    if (step.type === 'choice') {
      if (step.options.subtype === 'single') {
        return t('web.admin.workOrder.steps.headers.choice.single');
      }
      if (step.options.subtype === 'multiple') {
        return t('web.admin.workOrder.steps.headers.choice.multi');
      }
    }
    if (step.type === 'meter') {
      return t('web.admin.workOrder.steps.headers.meter');
    }

    return '';
  };

  const handleAcknowledgeChange = (step: StepExecutionType) => {
    const currentResponse = step?.response?.[0];
    const newResponse = currentResponse === 'true' ? 'false' : 'true';
    onStepChange({ ...step, response: [newResponse] });
    updateSaveText();
  };

  const handleResponseChange = (step: StepExecutionType, value: string) => {
    onStepChange({ ...step, response: [value] });
    updateSaveText();
  };

  const handleMultiChoiceChange = (step: StepExecutionType, choice: string) => {
    const newResponse = step.response?.includes(choice)
      ? step.response?.filter(res => res !== choice)
      : [...(step.response || []), choice];
    onStepChange({ ...step, response: newResponse });
    updateSaveText();
  };

  const handleSingleChoiceChange = (
    step: StepExecutionType,
    choice: string
  ) => {
    if (disabled) return;
    onStepChange({ ...step, response: [choice] });
    updateSaveText();
  };

  const handleMeterChange = (step: StepExecutionType, value: string) => {
    const tempStep = { ...step, response: [value.toString()] };
    const newSteps = localSteps.map(ls => (ls.id === tempStep.id ? tempStep : ls));
    setLocalSteps(newSteps);
  };

  const saveMeterChange = () => {
    localSteps.forEach(ls => {
      const actualValue = steps.find(s => s.id === ls.id)?.response?.[0];
      if (ls.type === 'meter') {
        if (actualValue !== undefined && actualValue !== null) {
          onStepChange(ls);
          updateMeterReading(ls.meterReadingId, ls, ls.response?.[0]);
        } else {
          onStepChange(ls);
          createMeterReading(ls.meterReadingId, ls, ls.response?.[0]);
        }
      }
    });
    updateSaveText();
  };

  const getLocalStepResponse = (step: StepExecutionType) => {
    const localStep = localSteps.find(ls => ls.id === step.id);
    return localStep?.response?.[0];
  };

  const getPreviousReadingValue = (meterReadingId: string | undefined) => {
    const previousValue = previousValues.findLast(
      (value: any) => value.meterReadingId === meterReadingId
    );
    return previousValue?.value;
  };

  const getPreviousReadingDate = (meterReadingId: string | undefined) => {
    const previousValue = previousValues.findLast(
      (value: any) => value.meterReadingId === meterReadingId
    );
    return previousValue?.date;
  };

  const createMeterReading = async (mid: any, step: any, val: any) => {
    if (!val) return;

    const meterReadingValuesToSave: any = {
      meterReadingId: mid,
      sourceType: MeterReadingValueType.TASK,
      value: parseFloat(val),
      notes: '',
      taskId: convertToUUID(task?.id),
      taskUserFriendlyId: task?.userFriendlyID,
      stepExecutionId: step.id,
    };

    await getClient().mutate({
      mutation: createMeterReadingValueMutation,
      variables: {
        createMeterReadingValue: meterReadingValuesToSave,
        channelId: channel?._id || '',
      },
    });

    window.Toast.show(
      t('web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast')
    );
  };

  const updateMeterReading = async (mid: any, step: any, val: any) => {
    if (!val) return;

    const meterReadingValuesToSave: any = {
      id: step.id,
      value: parseFloat(val),
      notes: '',
      sourceType: MeterReadingValueType.TASK,
    };

    await getClient().mutate({
      mutation: updateMeterReadingValueMutation,
      variables: {
        updateMeterReadingValue: meterReadingValuesToSave,
        channelId: channel?._id || '',
      },
    });

    window.Toast.show(
      t('web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast')
    );
  };

  const updateSaveText = () => {
    setSaveText(
      t("web.admin.workOrder.steps.autosave", {
        time: new Date().toLocaleTimeString()
      })
    );
  };

  return (
    <Flex direction="column">
      {/* Steps List */}
      {steps.map((step: StepExecutionType, index) => (
        <Flex key={step.id} direction="row" className={styles.executionStep}>
          <Flex direction="column" className={styles.rowNumber}>
            {index + 1}.
          </Flex>
          <Flex direction="column" className={styles.questionBody}>
            <span className={styles.questionText}>{getStepHeader(step)}</span>
            <span className={styles.questionText}>{step.name}</span>

            {/* Acknowledge Type */}
            {step.type === 'acknowledge' ? (
              <Checkbox
                className={styles.checkbox}
                disabled={disabled}
                selected={!!step.response && step.response[0] === 'true'}
                value={!!step.response && step.response[0] === 'true'}
                onChange={() => handleAcknowledgeChange(step)}
              />
            ) : null}

            {/* Response Type */}
            {step.type === 'response' &&
            step.options.subtype !== 'numerical' ? (
              <TextArea
                className={styles.textArea}
                disabled={disabled}
                value={step.response?.[0] || ''}
                onChange={res => handleResponseChange(step, res)}
                minRows={step.options.subtype === 'long' ? 3 : 1}
              />
            ) : null}

            {/* Numerical Response Type */}
            {step.type === 'response' &&
            step.options.subtype === 'numerical' ? (
              <Flex direction="row" className={styles.numericalResponse}>
                <Input
                  className={styles.numericalInput}
                  disabled={disabled}
                  value={step.response?.[0] || ''}
                  type="number"
                  helperText={t(
                    'web.admin.workOrder.steps.numericalHelperText'
                  )}
                  onChange={res => handleResponseChange(step, res)}
                />
                <span className={styles.numericalUnit}>
                  {step.options?.unit}
                </span>
              </Flex>
            ) : null}

            {/* Multiple Choice Type */}
            {step.type === 'choice' && step.options.subtype === 'multiple' ? (
              <Flex direction="column">
                {step.options?.choices?.map((choice, choiceIndex) => (
                  <Flex key={choiceIndex} direction="row">
                    <Checkbox
                      className={styles.multiChoiceBox}
                      disabled={disabled}
                      selected={
                        !!step.response && step.response.includes(choice)
                      }
                      value={!!step.response && step.response.includes(choice)}
                      onChange={() => handleMultiChoiceChange(step, choice)}
                    />
                    <span className={styles.multiChoiceText}>{choice}</span>
                  </Flex>
                ))}
              </Flex>
            ) : null}

            {/* Single Choice Type */}
            {step.type === 'choice' && step.options.subtype === 'single' ? (
              <Flex direction="column">
                <RadioGroup
                  items={
                    step.options?.choices?.map(choice => ({
                      id: choice,
                      text: choice,
                    })) || []
                  }
                  onChange={res => handleSingleChoiceChange(step, res)}
                  selected={step.response?.[0] || null}
                  doTranslate={false}
                />
              </Flex>
            ) : null}

            {/* Meter Type */}
            {step.type === 'meter' ? (
              <Flex direction="column">
                <span className={styles.meterDesc}>
                  {step.options?.description}
                </span>

                <Flex direction="row" className={styles.meterBody}>
                  <Input
                    className={styles.meterInput}
                    disabled={disabled}
                    value={getLocalStepResponse(step) || ''}
                    fixedLabel
                    label="Value"
                    onChange={res => handleMeterChange(step, res)}
                  />
                  <span className={styles.meterUnit}>{step.options?.unit}</span>
                </Flex>
                {getPreviousReadingValue(step.meterReadingId) ? (
                  <Flex direction="column" className={styles.previousValue}>
                    <span>
                      {t('web.admin.workOrder.steps.previousMeterReading')}
                    </span>
                    <span>
                      {t('web.admin.workOrder.steps.meterReadingText', {
                        value:
                          getPreviousReadingValue(step.meterReadingId) || '',
                        unit: step.options.unit,
                        date: getPreviousReadingDate(step.meterReadingId)
                          ? new Date(
                              getPreviousReadingDate(step.meterReadingId)
                            ).toDateString()
                          : '',
                      })}
                    </span>
                  </Flex>
                ) : null}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      ))}

      {/* Footer */}
      {disabled ? null : (
        <>
          <div className={styles.footerPadding} />
          <StickyFooter
            submitText="Save"
            cancelText="Cancel"
            detailText={saveText}
            handleSubmit={saveMeterChange}
            handleCancel={() => {}}
            enableSubmit
          />
        </>
      )}
    </Flex>
  );
};
