import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  NativeFilterTypes,
  QueryString,
  getPageSizeFromQueryString,
  Table,
  convertStringsToDates,
  FilterType,
} from 'design-system-web';

import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { UserDataContext } from 'lane-shared/contexts';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import {
  AdminUser,
  useAssignableMembers,
} from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { queryChannels } from 'lane-shared/graphql/channel';
import { convertTo62, convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { hasPermission, safeConvertToUUID } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE } from 'lane-shared/helpers/constants/permissions';
import { exportCSV } from 'lane-web/src/domains/workOrder/helpers/exportCSV';
import { usePersistedParams } from 'lane-web/src/hooks';

import { getAllEquipmentQuery, searchPMSchedulesQuery } from 'graphql-queries';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PMSchedule } from 'activate-modules/work-order/schedule/server/interfaces/coreModels';

import { IdNamePair, Equipment } from 'graphql-query-contracts';

import styles from './styles.scss';
import { EmptyPageView } from 'components/layout';
import { DangerousTranslate } from 'components/DangerousTranslate';

const PER_PAGE = 25;

const TABLE_STATE_STORAGE_VARIABLE = 'work-orders.schedule-table-state';

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'created_at',
  sortDirection: 'desc',
  // filters
  assignee: '',
  assignee_groups: '',
  equipment: '',
  keyword: '',
  location: '',
  next_due_date: '',
  recurrence: '',
};

type ScheduleQueryString = {
  equipment: string;
  tab: string;
} & QueryString;

function ScheduleList({ channel }: any) {
  const { t } = useTranslation();
  const dataImporterFlag = useFlag(FeatureFlag.DataImporter, false);
  const isWorkplaceEnablementEnabled = useFlag(
    FeatureFlag.WorkOrdersWorkplaceEnablement,
    false
  );

  const storedTableState = window.localStorage.getItem(
    TABLE_STATE_STORAGE_VARIABLE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;
  const [searchParams, setSearchParams] = useQueryString<ScheduleQueryString>(
    initialTableParams
  );

  const { user } = useContext(UserDataContext);

  const canCreateSchedule =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE],
      channel?._id,
      false
    );

  const [equipments, setEquipments] = useState<Equipment[]>([]);

  const isCrossProperty = channel?.settings?.hasWorkOrderCrossPropertyEnabled;

  const subChannelsResult = useQuery(queryChannels, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        // isSub: true,
        parent: {
          _id: channel?._id,
        },
      },
    },
  });

  let subChannelIds: string[] = [];
  if (isCrossProperty) {
    subChannelIds =
      subChannelsResult?.data?.channels?.items?.map(
        (subChannel: ChannelType) => subChannel?._id
      ) || [];
  }
  const queryVariables = {
    refIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
    search: {
      ...(searchParams.sortBy
        ? {
            sortBy: {
              key: searchParams.sortBy,
              dir: searchParams.sortDirection,
            },
          }
        : {}),
      ...(searchParams.keyword
        ? {
            search: {
              type: 'like',
              value: searchParams.keyword,
            },
          }
        : {}),
    },
    pagination: {
      start: ((searchParams?.page || 0) as number) * PER_PAGE,
      perPage: getPageSizeFromQueryString(searchParams?.pageSize),
    },
    filter: {
      equipments: searchParams?.equipment
        ? searchParams?.equipment?.split(',')
        : [],
      ...(searchParams?.assignee
        ? {
            assignees: searchParams?.assignee.split(',').map(safeConvertToUUID),
          }
        : {}),
      ...(searchParams?.assignee_groups
        ? {
            assigneeGroups: searchParams?.assignee_groups
              .split(',')
              .map(safeConvertToUUID),
          }
        : {}),
      ...(searchParams?.location
        ? {
            locations: searchParams?.location.split(','),
          }
        : {}),
      ...(searchParams?.recurrence
        ? {
            recurrences: searchParams?.recurrence.split(','),
          }
        : {}),
      ...(searchParams?.next_due_date
        ? {
            nextDueDateStart: convertStringsToDates(searchParams?.next_due_date)
              ?.startDate,
            nextDueDateEnd: convertStringsToDates(searchParams?.next_due_date)
              ?.endDate,
          }
        : {}),
    },
  };

  const { data, loading: schedulesLoading } = useQuery(searchPMSchedulesQuery, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  });

  const totalSchedules = data?.findSchedules?.pageInfo?.total;

  const [_, __, assignableTeams] = useAssignableMembers(channel?._id);
  const [filterData, setFilterData] = useState<any>();

  const teams = assignableTeams?.map((s: AdminUser) => ({
    label: s.label,
    value: convertTo62(s.value),
  }));

  useEffect(() => {
    if (totalSchedules) {
      setSearchParams({ total: totalSchedules });
    }
  }, [data?.findSchedules?.pageInfo]);

  usePersistedParams({
    searchParams,
    initialTableParams,
    tableStorageVariable: TABLE_STATE_STORAGE_VARIABLE,
  });

  useEffect(() => {
    setSearchParams(initialTableParams);
  }, [searchParams?.tab]);

  const [fetchEquipment, { data: equipmentData, loading }] = useLazyQuery(
    getAllEquipmentQuery,
    {
      onCompleted: () => {
        if (equipmentData?.getAllEquipment) {
          setEquipments(equipmentData.getAllEquipment);
        }
      },
    }
  );

  useEffect(() => {
    if (channel?._id) {
      fetchEquipment({
        variables: {
          channelId: channel._id,
        },
      });
    }
  }, [channel?._id, fetchEquipment]);

  const equipmentMap = useMemo(() => {
    const equipmentMap = new Map<string, Equipment>();
    if (!data?.findSchedules?.schedules || equipments.length === 0)
      return equipmentMap;

    const { schedules } = data.findSchedules;

    for (let i = 0; i < schedules.length; i++) {
      const { equipmentIds } = schedules[i];
      if (!equipmentIds || equipmentIds.length === 0) {
        continue;
      }
      equipmentIds.forEach((eqId: string) => {
        if (equipmentMap.has(eqId)) return;
        const equipment = equipments.find((e: Equipment) => e.id === eqId);
        if (!equipment) return;
        equipmentMap.set(eqId, equipment);
      });
    }

    return equipmentMap;
  }, [data?.findSchedules?.schedules, equipments]);

  let columns = [
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.title`,
      key: 'title',
      disableVisibilityToggle: true,
      renderCell: (title: string, row: PMSchedule) => (
        <Link
          to={routes.channelAdminWorkOrdersPMScheduleDetails
            .replace(':id', channel?.slug)
            .replace(':scheduleId', row.scheduleId)}
        >
          {title}
        </Link>
      ),
      renderForCSV: (title: string) => title,
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.equipment`,
      key: 'equipmentIds',
      disableSorting: true,
      renderCell: (equipmentIds: string[]) => {
        const equipmentsOnSchedule: string[] = [];
        equipmentIds.forEach((eqId: string) => {
          const equipment = equipmentMap.get(eqId);
          if (!equipment) return;
          equipmentsOnSchedule.push(equipment.name);
        });
        return equipmentsOnSchedule.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.location`,
      key: 'location',
      renderCell: (location: string, row: PMSchedule) => {
        if (location) {
          return <div className={styles.locationCell}>{location}</div>;
        }
        const equipmentLocations: string[] = [];
        row.equipmentIds.forEach((eqId: string) => {
          const equipment = equipmentMap.get(eqId);
          if (!equipment || !equipment.location) return;
          equipmentLocations.push(equipment.location);
        });
        return equipmentLocations.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.floor`,
      key: 'floor',
      disableSorting: true,
      renderCell: (_: any, row: PMSchedule) => {
        const equipmentFloors: string[] = [];
        row.equipmentIds.forEach((eqId: string) => {
          const equipment = equipmentMap.get(eqId);
          if (!equipment || !equipment.floor) return;
          equipmentFloors.push(equipment.floor);
        });
        return equipmentFloors.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.suite`,
      key: 'suite',
      disableSorting: true,
      renderCell: (_: any, row: PMSchedule) => {
        const equipmentSuites: string[] = [];
        row.equipmentIds.forEach((eqId: string) => {
          const equipment = equipmentMap.get(eqId);
          if (!equipment || !equipment.suite) return;
          equipmentSuites.push(equipment.suite);
        });
        return equipmentSuites.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.assignedTo`,
      key: 'assignee',
      renderCell: (assignee: IdNamePair) => {
        return assignee ? assignee.name || '' : '';
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.assignedTeams`,
      key: 'assigneeGroups',
      disableSorting: true,
      renderCell: (teamIds: string[]) => {
        const assignedTeamNames: string[] = [];

        teamIds.forEach(teamId => {
          const t = assignableTeams.find(
            team => convertToUUID(team.value) === convertToUUID(teamId)
          );
          if (!t) return;
          assignedTeamNames.push(t?.label);
        });
        return assignedTeamNames.join(', ');
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.recurrence`,
      key: 'repeats',
      renderCell: (repeats: string) => {
        const repeatsKey = repeats.replace(/-/g, '');
        return t(
          `web.admin.workOrder.preventiveMaintenance.schedule.${repeatsKey}`
        );
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.nextDueDate`,
      type: 'date',
      key: 'next_due_date',
    },
  ];

  if (isCrossProperty) {
    columns.splice(1, 0, {
      key: 'channel',
      header: t('web.admin.serviceRequest.property'),
      type: 'text',
    });
  }

  if (
    isWorkplaceEnablementEnabled &&
    !channel?.settings?.hasWorkOrderEquipmentEnabled
  ) {
    columns = columns.filter(column => column.key !== 'equipmentIds');
  }

  const handleExportToCSV = async () => {
    const { data } = await getClient().query({
      query: searchPMSchedulesQuery,
      variables: {
        ...queryVariables,
        forExport: true,
      },
      fetchPolicy: 'network-only',
    });

    const schedules = data?.findSchedules?.schedules.map(
      (schedule: PMSchedule) => ({
        ...schedule,
        equipment: schedule.equipmentIds,
        next_due_date: schedule.nextDueDate,
      })
    );

    exportCSV(
      schedules,
      columns,
      `preventative-maintenance-schedules-${new Date().toISOString()}.csv`
    );
  };

  const exportOptions = [
    {
      label: t`web.admin.workOrder.preventiveMaintenance.schedule.exportCSV`,
      onClick: handleExportToCSV,
    },
  ];

  const hasFilterValue =
    searchParams.equipment ||
    searchParams.keyword ||
    searchParams.assignee ||
    searchParams.assignee_groups ||
    searchParams.completed_at ||
    searchParams.due_date ||
    searchParams.equipment ||
    searchParams.location ||
    searchParams.next_due_date ||
    searchParams.recurrence ||
    searchParams.schedule ||
    searchParams.status;

  const tableRows = useMemo(() => {
    if (!data?.findSchedules?.schedules) return [];
    const rows = data.findSchedules.schedules.map((schedule: PMSchedule) => ({
      ...schedule,
      equipment: schedule.equipmentIds,
      next_due_date: schedule.nextDueDate,
      channel: schedule.extRefId?.name,
    }));
    setFilterData(data?.findSchedules?.filterOptions);
    return rows;
  }, [data?.findSchedules?.schedules]);

  let filters: FilterType[] = [
    {
      key: 'equipment',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.equipment'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.equipments?.map((eq: { name: string; id: string }) => ({
          label: eq.name,
          value: eq.id,
        })) || [],
    },
    {
      key: 'location',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.location'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.locations?.map((loc: string) => ({
          label: loc,
          value: loc,
        })) || [],
    },
    {
      key: 'assignee',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.assignee'
      ),
      type: NativeFilterTypes.Multiselect,
      options: [
        {
          label: t('web.admin.serviceRequest.unassigned'),
          value: 'unassigned',
        },
        ...(filterData?.assignees?.map((s: { name: string; _id: string }) => ({
          label: s.name,
          value: s._id,
        })) || []),
      ],
    },
    {
      key: 'assignee_groups',
      label: t('web.admin.serviceRequest.assigneeGroups'),
      type: NativeFilterTypes.Multiselect,
      options: teams.unshift({
        label: t('web.admin.serviceRequest.unassigned'),
        value: 'unassigned',
      })
        ? teams
        : [],
    },
    {
      key: 'recurrence',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.recurrence'
      ),
      type: NativeFilterTypes.Multiselect,
      options:
        filterData?.recurrences?.map((rec: string) => ({
          label: t(
            `web.admin.workOrder.preventiveMaintenance.schedule.${rec.replace(
              /-/g,
              ''
            )}`
          ),
          value: rec,
        })) || [],
    },
    {
      key: 'next_due_date',
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tableColumn.nextDueDate'
      ),
      type: NativeFilterTypes.DateRange,
    },
  ];

  if (
    isWorkplaceEnablementEnabled &&
    !channel?.settings?.hasWorkOrderEquipmentEnabled
  ) {
    filters = filters.filter(column => column.key !== 'equipment');
  }

  const isLoading = loading || schedulesLoading;

  return (totalSchedules === undefined || Number(totalSchedules) === 0) &&
    !isLoading &&
    canCreateSchedule &&
    !hasFilterValue ? (
    <EmptyPageView
      icon="tools"
      title={t(
        'web.admin.workOrder.preventiveMaintenance.schedule.emptyTable.title'
      )}
      message={
        <DangerousTranslate
          translationKey="web.admin.workOrder.preventiveMaintenance.schedule.emptyTable.subtext"
          values={{}}
        />
      }
      primaryButton={{
        href: routes.channelAdminWorkOrdersPMScheduleCreate.replace(
          ':id',
          channel?.slug
        ),
        label: t`web.admin.workOrder.preventiveMaintenance.addSchedule`,
      }}
      secondaryButton={
        dataImporterFlag
          ? {
              href: routes.channelAdminDataImportNew.replace(
                ':id',
                channel?.slug
              ),
              label: t`web.admin.importer.workorders.list.newImport`,
            }
          : undefined
      }
    />
  ) : (
    <Table
      isLoading={isLoading}
      columns={columns}
      hasKeywordFilter
      showColumnVisibility
      keywordFilterLabel={t(
        'web.admin.workOrder.preventiveMaintenance.search.schedule.label'
      )}
      emptyMessage={t('web.admin.serviceRequest.emptyTable.message')}
      data={tableRows}
      totalRows={Number(searchParams.total)}
      tableKey="workOrder.scheduleTable"
      filters={filters}
      exportOptions={exportOptions}
      pagination="server"
      queryStringsEnabled
    />
  );
}

export default ScheduleList;
