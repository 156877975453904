import { InvoiceAutopayment } from '../InvoiceAutopayment';
import { InvoiceList } from '../InvoiceList';
import styles from './styles.scss';
import ButtonStrip, {
  Button as ButtonType,
} from 'components/general/ButtonStrip';
import { H5 } from 'components/typography';
import { UserDataContext } from 'lane-shared/contexts';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_COMPANY_INVOICES } from 'lane-shared/helpers/constants/permissions';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function AccountInvoices() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const [invoiceType, setInvoiceType] = useState<'user' | 'company'>('user');

  const canAccessCompanyInvoices =
    user?.isSuperUser ||
    hasPermission(user?.roles, [PERMISSION_COMPANY_INVOICES]);

  const toggleButtons = [
    {
      value: 'user',
      name: 'abp.personalButton',
    } as ButtonType<string>,
    {
      value: 'company',
      name: 'abp.companyButton',
    } as ButtonType<string>,
  ];

  return (
    <>
      {canAccessCompanyInvoices && (
        <div className={styles.toggleButtonContainer}>
          <ButtonStrip
            buttons={toggleButtons}
            selected={invoiceType}
            onClick={buttonValue => setInvoiceType(buttonValue as any)}
          />
        </div>
      )}
      {invoiceType === 'user' && <InvoiceAutopayment user={user} />}

      <div className={styles.listGroup}>
        <div className={styles.heading}>
          <H5>{t('abp.routes.accountInvoices.unpaidInvoices.web')}</H5>
        </div>
        <InvoiceList type={invoiceType} subType="unpaid" renderType="card" />
      </div>
      <br />
      <div className={styles.listGroup}>
        <div className={styles.heading}>
          <H5>{t('abp.routes.accountInvoices.paidInvoices.web')}</H5>
        </div>
        <InvoiceList type={invoiceType} subType="paid" renderType="table" />
      </div>
    </>
  );
}
