import React from 'react';

import { useTranslation } from 'react-i18next';

import { capitalize } from 'lane-shared/helpers/formatters';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { Button, Flex } from 'lane-web/src/components';
import { M } from 'lane-web/src/components/typography';

import { CustomPropertyType } from '../../types';

import styles from './CollapsedInput.scss';

type Props = {
  field: CustomPropertyType;
  onEdit: (field: PropertyType) => void;
  isEditDisabled?: boolean;
};
export default function CollapsedInput({
  field,
  onEdit,
  isEditDisabled,
}: Props) {
  const { t } = useTranslation();
  return (
    <Flex justify="space-between" align="center" className={styles.container}>
      <Flex direction="column" gap={2}>
        <M
          className={styles.FieldTitle}
          bold
          data-cy={`collapsed-input-${field.name}`}
        >
          {typeof field?.name === 'string'
            ? capitalize(field?.name)
            : field?.name}
        </M>
        <M className={styles.FieldDescription}>{field.friendlyName}</M>
      </Flex>
      {!field.isEditing && (
        <Button
          variant="text"
          size="small"
          disabled={isEditDisabled}
          onClick={() => onEdit(field)}
          dataCy={`edit-${field.name}`}
        >
          <M className={isEditDisabled ? styles.disabled : styles.FieldEditBtn}>
            {t('web.member.serviceRequest.edit')}
          </M>
        </Button>
      )}
    </Flex>
  );
}
