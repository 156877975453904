import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { simpleDate } from 'lane-shared/helpers/formatters';
import { useChannelGroupRolesQuery, useFlag } from 'lane-shared/hooks';

import { ControlMenu, Button } from 'lane-web/src/components';
import Table from 'lane-web/src/components/lds/table';
import { routes } from 'lane-shared/config';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { UserDataContext } from 'lane-shared/contexts';

import styles from './styles.scss';

const formatter = new Intl.NumberFormat();

export default function GroupRoles({ channel }: any) {
  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: channel?._id,
    includeCounts: true,
    includeWorkplaceMember: true,
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const teamsBulkAddUsers = useFlag(FeatureFlag.TeamsBulkAddUsers, false);

  useEffect(() => {
    if (!history?.location?.search) {
      history.replace(`${history.location.pathname}?dir=asc&sortKey=role`);
    }
  }, [history?.location?.search]);

  const handleBulkAdd = () => {
    const url = routes.channelAdminTeamsBulkAdd.replace(
      ':id',
      channel?.slug || ''
    );
    history.push(url);
  };

  return (
    <div className={styles.GroupRoles}>
      <ControlMenu>
        <hr />
        {user?.isSuperUser && teamsBulkAddUsers && (
          <Button onClick={handleBulkAdd} dataCy="buttonBulkAddUsers">
            {t('web.pages.portal.admin.channel.team.addUsers')}
          </Button>
        )}
        <Link to={`/l/channel/${channel?.slug}/admin/teams/new`}>
          <Button variant="contained">
            {t('web.pages.portal.admin.channel.team.newTeamButton')}
          </Button>
        </Link>
      </ControlMenu>

      <Table
        sortingIsDoneOn="client"
        columns={[
          {
            header: t('web.pages.portal.admin.channel.team.teamHeader'),
            key: 'role',
          },
          {
            header: t('web.pages.portal.admin.channel.team.createdHeader'),
            key: 'createdAt',
          },
          {
            header: t('web.pages.portal.admin.channel.team.updatedHeader'),
            key: 'updatedAt',
          },
          {
            header: t('web.pages.portal.admin.channel.team.members'),
            key: 'members',
          },
        ]}
        rows={groupRoles.map(groupRole => ({
          id: groupRole._id,
          items: [
            {
              key: 'role',
              type: 'string',
              value: groupRole.name,
              renderer: groupRoleName => (
                <Link
                  to={`/l/channel/${channel?.slug}/admin/teams/${groupRole._id}/view`}
                >
                  {groupRoleName}
                </Link>
              ),
            },
            {
              key: 'createdAt',
              type: 'date',
              value: simpleDate(groupRole._created),
            },
            {
              key: 'updatedAt',
              type: 'date',
              value: simpleDate(groupRole._updated),
            },
            {
              key: 'members',
              type: 'number',
              value: groupRole?.stats?.count ?? 0,
              renderer: formatter.format,
            },
          ],
        }))}
        dataCy="teamTable"
      />
    </div>
  );
}
