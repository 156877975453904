import React from 'react';

import { Document, Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';

import { Equipment } from 'graphql-query-contracts';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClientPMSchedule } from 'activate-modules/work-order/schedule/server/interfaces/coreModels';

import { styles } from './styles';

type ScheduleDetailsPDFProps = {
  scheduleData: ClientPMSchedule;
  createdUserName: string;
  equipmentData: Equipment[];
  assignedMemberName: string;
  recurrenceLabel: string;
  isPropertyChannel?: boolean;
  t: (val: string, opts?: any) => string;
};

const line = () => (
  <Svg height="20" width="550" style={styles.line}>
    <Line
      x1="1000"
      y1="0"
      x2="0"
      y2="0"
      strokeWidth={1}
      stroke="rgb(128,128,128)"
    />
  </Svg>
);

const ScheduleDetailsPDF = ({
  scheduleData,
  createdUserName,
  equipmentData,
  assignedMemberName,
  recurrenceLabel,
  isPropertyChannel = true,
  t,
}: ScheduleDetailsPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>{scheduleData?.title}</Text>
        <Text style={[styles.subheadings, styles.byLine]}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.schedule.details.createdBy',
            {
              date: simpleDate(scheduleData?.createdAt),
              name: createdUserName,
            }
          )}
        </Text>
        {line()}
        <Text style={styles.header}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.schedule.details.heading'
          )}
        </Text>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.assignedTo'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{assignedMemberName}</Text>
        </View>
        {scheduleData?.equipmentIds.length === 0 ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.subheadings}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location'
              )}
            </Text>
            <Text style={styles.subHeadingValues}>
              {scheduleData?.location}
            </Text>
          </View>
        ) : (
          <>
            <View style={styles.rowFieldsContainer}>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.category'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.equipment'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location'
                  )}
                </Text>
              </View>
              {isPropertyChannel && (
                <>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.floor'
                      )}
                    </Text>
                  </View>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {t(
                        'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.suite'
                      )}
                    </Text>
                  </View>
                </>
              )}
            </View>
            {equipmentData?.map(equipmentData => (
              <View style={styles.rowFieldsContainer} key={equipmentData.id}>
                <View style={styles.columnFieldContainer}>
                  <Text style={styles.subheadings}>
                    {equipmentData.category}
                  </Text>
                </View>
                <View style={styles.columnFieldContainer}>
                  <Text style={styles.subheadings}>{equipmentData.name}</Text>
                </View>
                <View style={styles.columnFieldContainer}>
                  <Text style={styles.subheadings}>
                    {equipmentData.location}
                  </Text>
                </View>
                {isPropertyChannel && (
                  <>
                    <View style={styles.columnFieldContainer}>
                      <Text style={styles.subheadings}>
                        {equipmentData.suite}
                      </Text>
                    </View>
                    <View style={styles.columnFieldContainer}>
                      <Text style={styles.subheadings}>
                        {equipmentData.floor}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            ))}
          </>
        )}

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.notes'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{scheduleData?.notes}</Text>
        </View>
        {line()}

        <Text style={styles.header}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.schedule.details.recurrence'
          )}
        </Text>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.repeats'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{recurrenceLabel}</Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.timeToComplete'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {scheduleData?.timeToComplete}{' '}
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.hoursUnit'
            )}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.nextDueDate'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {simpleDate(scheduleData?.nextDueDate!)}
          </Text>
        </View>
        {line()}
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.steps'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{scheduleData?.steps}</Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.meterReading'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {scheduleData?.meterReading}
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) =>
            t('web.admin.workOrder.pdf.pgNumber', { pageNumber })
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default ScheduleDetailsPDF;
