import React from 'react';

import { Label } from 'components';
import { useTranslation } from 'react-i18next';

import { H3, P } from 'components/typography';

import { showOnlyFileName } from 'pages/portal/admin/channel/children/auto-setup-wizard/helpers';
import {
  AutoSetupConfig,
  CesOptionsType,
  DataSourceEnum,
  DataSourceType,
  RiseMigrationFeaturesType,
  SourceLocations,
  Template,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import { DATA_SOURCES, RISE_MIGRATION_FEATURES } from './constants';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import styles from './styles.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

export function Confirm({
  currentValues,
  cesOptions,
  riseMigrationFeatures,
}: {
  currentValues: AutoSetupConfig & { sourceLocations: SourceLocations };
  cesOptions: CesOptionsType;
  riseMigrationFeatures: RiseMigrationFeaturesType;
}) {
  const { t } = useTranslation();

  const reviewText = t`web.admin.channel.autoSetupWizard.review`;
  const reviewMessageText = t`web.admin.channel.autoSetupWizard.reviewMessage`;
  const sourceLabelText = t`web.admin.channel.autoSetupWizard.source`;
  const riseMigrationTitle = t`web.admin.channel.autoSetupWizard.riseMigrationPlaceholder`;

  const {
    dataSource,
    sourceLocations,
    templateId,
    templateType,
  } = currentValues;

  const dataSourceItem = DATA_SOURCES.find(
    ({ value }: DataSourceType) => value === dataSource
  );

  const dataSourceTitle = dataSourceItem?.text ?? '';

  return (
    <section className={styles.Section} data-testid="wizard-confirm-page">
      <H3 mb={2}>{reviewText}</H3>
      <div className={styles.Grid}>
        <div>
          <Label className={styles.Faded}>{sourceLabelText}</Label>
          {t(dataSourceTitle)}
        </div>
        <TemplateOrFeatures {...{ dataSource, templateType }} />
        {dataSource === DataSourceEnum.RISE && (
          <div>
            <Label className={styles.Faded}>{riseMigrationTitle}</Label>
            {sourceLocations[0]?.value}
          </div>
        )}
        {dataSource === DataSourceEnum.CES && (
          <div>
            <Label className={styles.Faded}>CES Template ID</Label>
            {cesOptions.cesOrganizationId}
          </div>
        )}
        {dataSource === DataSourceEnum.CES && (
          <div>
            <Label className={styles.Faded}>VCM Buildings</Label>
            {cesOptions.vcmBuildingIds}
          </div>
        )}
        <MigrationSource
          {...{ dataSource, sourceLocations, riseMigrationFeatures }}
        />
        {templateType === Template.EXISTING && (
          <div>
            <Label className={styles.Faded}>Cloning Channel Template Id</Label>
            {templateId}
          </div>
        )}
      </div>
      {dataSource === 'sftp' && <P mt={5}>{reviewMessageText}</P>}
    </section>
  );
}

const TemplateOrFeatures = ({
  dataSource,
  templateType,
}: {
  dataSource: DataSourceEnum | null;
  templateType: Template | null;
}) => {
  const { t } = useTranslation();
  if (dataSource === DataSourceEnum.RISE) {
    return null;
  }
  return (
    <div>
      <Label className={styles.Faded}>
        {t`web.admin.channel.autoSetupWizard.template`}
      </Label>
      {templateType === Template.BLANK &&
        t`web.admin.channel.autoSetupWizard.createBlank`}
      {templateType === Template.EXISTING &&
        t`web.admin.channel.autoSetupWizard.fromExisting`}
    </div>
  );
};

const MigrationSource = ({
  dataSource,
  sourceLocations,
  riseMigrationFeatures,
}: {
  dataSource: DataSourceEnum | null;
  sourceLocations: SourceLocations;
  riseMigrationFeatures: RiseMigrationFeaturesType;
}) => {
  const { t } = useTranslation();
  const addRandomSuffixEnabled = useFlag(
    FeatureFlag.RiseMigrationAddRandomSuffix,
    false
  );
  const migrateTenantsEnabled = useFlag(
    FeatureFlag.RiseMigrationExportTenants,
    false
  );

  let label = '';
  let value = null;

  switch (dataSource) {
    case DataSourceEnum.sFTP:
      label = 'web.admin.channel.autoSetupWizard.sftpFolder';
      value = sourceLocations.map(location => location.value).join(', ');
      break;
    case DataSourceEnum.CSV:
      label = 'web.admin.channel.autoSetupWizard.csvFile';
      value = showOnlyFileName(sourceLocations[0]?.value);
      break;
    case DataSourceEnum.RISE:
      label = 'web.admin.channel.autoSetupWizard.features';
      value = (
        <div>
          {Object.keys(riseMigrationFeatures).map(feature => {
            const featureProps = RISE_MIGRATION_FEATURES({
              addRandomSuffixEnabled,
              migrateTenantsEnabled,
            }).find(({ key }) => key === feature);
            if (featureProps === undefined) return null;

            const featureValue =
              riseMigrationFeatures[feature as keyof RiseMigrationFeaturesType];

            if (feature === 'migrateCatUsers') {
              const icon =
                (featureValue as string[]).length > 0 ? (
                  <FaCheckCircle color="green" />
                ) : (
                  <FaTimesCircle color="red" />
                );
              return (
                <div
                  key={feature}
                  className={(styles.CatUsersDisplay, styles.DisplayFeatures)}
                >
                  <div className={styles.DisplayFeatureName}>
                    {featureProps?.name}
                  </div>
                  <div>{icon}</div>
                  <ul>
                    {(featureValue as string[]).map(user => (
                      <li>{user}</li>
                    ))}
                  </ul>
                </div>
              );
            }

            const icon = featureValue ? (
              <FaCheckCircle color="green" />
            ) : (
              <FaTimesCircle color="red" />
            );

            return (
              <div className={styles.DisplayFeatures} key={feature}>
                <div className={styles.DisplayFeatureName}>
                  {featureProps?.name}
                </div>
                <div>{icon}</div>
              </div>
            );
          })}
        </div>
      );
      break;
  }

  if (label === '' || value === null) {
    return null;
  }

  return (
    <div>
      <Label className={styles.Faded}>{t(label)}</Label>
      {typeof value === 'string' ? value : <>{value}</>}
    </div>
  );
};
