/* eslint-disable no-console */
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Loading } from 'design-system-web';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useEscalationRules } from 'activate-modules/work-order/escalation-rule/reactHooks/useEscalationRules';

import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';

import WorkflowBuilder from 'components/renderers/WorkflowBuilder';
import { Workflow, WorkflowTypeEnum } from 'lane-shared/types/workflow';
import { useWorkflows } from 'lane-shared/hooks';
import {
  WorkflowActionEnum,
  WorkflowStatusEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';
import {
  EVENT_SERVICE_REQUEST_CREATED,
  EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
} from 'lane-shared/helpers/constants/events';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { saveEscalationRulesFromWorkflows } from '../helpers/handleEscalationRuleUpdates';

import styles from './NotificationsTab.scss';
import { constructDefaultWorkflowsForServiceRequestNotifications } from 'lane-shared/domains/workOrder/emails/helpers/constructDefaultWorkflowNotifications';

export function NotificationsTab({
  channel,
  user,
}: {
  channel: ChannelType;
  user: UserType;
}) {
  const {
    loading: loadingWorkflows,
    workflows,
    addWorkflow,
    saveWorkflows,
    editWorkflow,
    removeWorkflow,
    cloneWorkflow,
  } = useWorkflows(channel._id, WorkflowTypeEnum.ServiceRequest);

  const [loading, setLoading] = useState(false);

  const {
    data: escalationRules,
    update: updateEscalationRule,
    create: createEscalationRule,
    archive: archiveEscalationRule,
    refetch: refetchEscalationRules,
  } = useEscalationRules(channel?._id);

  const onEscalationWorkflowsSaved = async (savedWorkflows: Workflow[]) => {
    if (!savedWorkflows) return;

    await saveEscalationRulesFromWorkflows(
      escalationRules,
      archiveEscalationRule,
      updateEscalationRule,
      createEscalationRule,
      savedWorkflows,
      channel?._id!
    );
    await refetchEscalationRules();
    return true;
  };

  const {
    loading: loadingEscalationWorkflows,
    workflows: escalationWorkflows,
    addWorkflow: addEscalationWorkflow,
    saveWorkflows: saveEscalationWorkflows,
    editWorkflow: editEscalationWorkflow,
    removeWorkflow: removeEscalationWorkflow,
    cloneWorkflow: cloneEscalationWorkflow,
  } = useWorkflows(
    channel._id,
    WorkflowTypeEnum.ServiceRequestEscalation,
    onEscalationWorkflowsSaved
  );

  const { t } = useTranslation();

  const onAddNotificationWorkflow = async () => {
    const workflowToAdd = constructWorkflow({
      when: WorkflowWhenEnum.Immediate,
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_SERVICE_REQUEST_CREATED,
      type: WorkflowTypeEnum.ServiceRequest,
      targetType: 'ALL',
      channelId: channel._id,
      order: workflows.length,
      userId: user._id,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    });
    addWorkflow(workflowToAdd);
    return workflowToAdd;
  };

  const onAddEscalationWorkflow = async () => {
    const workflowToAdd = constructWorkflow({
      when: WorkflowWhenEnum.After,
      whenContext: WorkflowWhenContextEnum.Escalation,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
      type: WorkflowTypeEnum.ServiceRequestEscalation,
      targetType: 'ALL',
      channelId: channel._id,
      order: escalationWorkflows.length,
      userId: user._id,
      workflow: {
        on: 'status',
        value: WorkflowStatusEnum.Created,
      },
      time: 300000,
    });
    addEscalationWorkflow(workflowToAdd);
    return workflowToAdd;
  };

  const handleRestoreDefaultNotifications = async () => {
    if (workflows.length !== 0) {
      try {
        await window.Alert.confirm({
          title: t`web.admin.serviceRequest.settings.notificationTab.notifications.restoreDefaults.modal.header`,
          message: t`web.admin.serviceRequest.settings.notificationTab.notifications.restoreDefaults.modal.message`,
          confirmText: t`web.admin.serviceRequest.settings.notificationTab.notifications.restoreDefaults.modal.confirm`,
          cancelText: t`web.admin.serviceRequest.settings.notificationTab.notifications.restoreDefaults.modal.cancel`,
        });
      } catch (err) {
        // user cancelled
        return;
      }
    }
    setLoading(true);

    for (const workflow of workflows) {
      removeWorkflow(workflow._id);
    }

    const defaultServiceRequestNotifications = constructDefaultWorkflowsForServiceRequestNotifications(
      {
        channelId: channel._id,
        userId: user._id,
      }
    );
    await saveWorkflows(defaultServiceRequestNotifications);
    setLoading(false);
  };

  const restoreDefaultsButton = (
    <Button
      variant="tertiary"
      size="large"
      onClick={handleRestoreDefaultNotifications}
    >
      {t`web.admin.serviceRequest.settings.notificationTab.notifications.restoreDefaults.button`}
    </Button>
  );

  if (loading || loadingWorkflows || loadingEscalationWorkflows) {
    return <Loading />;
  }

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (channel?.address?.geo) {
    const [longitude, latitude] = channel.address.geo;
    timeZone = getTimeZoneByGeoLocation({ longitude, latitude });
  }
  return (
    <Flex direction="column" gap={5} className={styles.NotificationsTab}>
      <WorkflowBuilder
        title={t(
          `web.admin.serviceRequest.settings.notificationTab.escalations.title`
        )}
        channel={channel}
        onAddWorkflow={onAddEscalationWorkflow}
        onRemoveWorkflow={removeEscalationWorkflow}
        onCloneWorkflow={index => cloneEscalationWorkflow(index, user._id)}
        onReorderWorkflow={saveEscalationWorkflows}
        onUpdateWorkflow={editEscalationWorkflow}
        onSaveWorkflow={saveEscalationWorkflows}
        workflows={escalationWorkflows}
        timeZone={timeZone}
        translationKeys={{
          addWorkflowButton:
            'web.admin.serviceRequest.settings.notificationTab.escalations.emptyState.add',
          noWorkflowsToDisplayDescription:
            'web.admin.serviceRequest.settings.notificationTab.escalations.emptyState.description',
          noWorkflowsToDisplayText:
            'web.admin.serviceRequest.settings.notificationTab.escalations.emptyState.text',
          workflowSaved:
            'web.admin.serviceRequest.settings.notificationTab.escalations.toast.saved',
          workflowDeleted:
            'web.admin.serviceRequest.settings.notificationTab.escalations.toast.deleted',
          workflowDuplicated:
            'web.admin.serviceRequest.settings.notificationTab.escalations.toast.duplicated',
        }}
      />
      <WorkflowBuilder
        title={t(
          `web.admin.serviceRequest.settings.notificationTab.notifications.title`
        )}
        secondaryButton={restoreDefaultsButton}
        channel={channel}
        onAddWorkflow={onAddNotificationWorkflow}
        onRemoveWorkflow={removeWorkflow}
        onCloneWorkflow={index => cloneWorkflow(index, user._id)}
        onReorderWorkflow={saveWorkflows}
        onUpdateWorkflow={editWorkflow}
        onSaveWorkflow={saveWorkflows}
        workflows={workflows}
        timeZone={timeZone}
        translationKeys={{
          addWorkflowButton:
            'web.admin.serviceRequest.settings.notificationTab.notifications.emptyState.add',
          noWorkflowsToDisplayDescription:
            'web.admin.serviceRequest.settings.notificationTab.notifications.emptyState.description',
          noWorkflowsToDisplayText:
            'web.admin.serviceRequest.settings.notificationTab.notifications.emptyState.text',
          workflowSaved:
            'web.admin.serviceRequest.settings.notificationTab.notifications.toast.saved',
          workflowDeleted:
            'web.admin.serviceRequest.settings.notificationTab.notifications.toast.deleted',
          workflowDuplicated:
            'web.admin.serviceRequest.settings.notificationTab.notifications.toast.duplicated',
        }}
      />
    </Flex>
  );
}
