import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import Image from 'components/general/Image';
import { S, H3, H5, M } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import styles from './MagicLink.scss';

type Props = {
  className?: string;
  error: Error | null;
  image: string;
  header: string;
  email: string;
  sendMagicLink?: () => void;
  navigateBack?: () => void;
  loading?: boolean;
};

export default function InfoViewAfterAction({
  className,
  image,
  header,
  email,
  sendMagicLink,
  navigateBack,
  error,
  loading,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      data-cy="magicLinkForm"
      className={cx(styles.magicLinkForm, className)}
    >
      <Button
        variant="text-icon"
        size="small"
        color="inherit"
        className={styles.returnButton}
        startIcon={<Icon name="chevron-left" type="far" set="FontAwesome" />}
        onClick={navigateBack}
      >
        <S className={styles.darkGreyText}>{t('Back')}</S>
      </Button>

      <Image style={{ width: '80px' }} src={image} alt="magic-link-img" />
        <H3 mt={6} mb={4}>
          {t(header)}
        </H3>
        <M mb={6}>
          {' '}
          <M>{t('We sent you a magic link to')}</M> <b>{email}</b>
          <M> {t('so that you can sign in to all your workplaces at once.')}</M>
        </M>
      <H5 className={styles.darkGreyText} mb={2}>
          {t("Didn't receive it?")}
        </H5>
        <Button
          className={styles.resendBtn}
          loading={loading}
          onClick={sendMagicLink}
        >
          {t('Resend link')}
        </Button>
      <ErrorMessage className={welcomeFormStyles.errorMessage} error={error} />
    </div>
  );
}
