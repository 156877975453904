// @ts-nocheck FIXME: Ignored due failing CI after React update
import React, { useContext, useEffect, useState } from 'react';

import gql from 'graphql-tag';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, Route, Redirect } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { colors, routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  ThemeFragment,
  FullProfileWithThemeFragment,
  FullContentFragment,
} from 'lane-shared/graphql/fragments';
import { findAccessRestrictedError } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelErrorData } from 'activate-errors';

import WhitelabeledLogo from 'components/general/WhitelabeledLogo';
import { H4, H5 } from 'components/typography';

import defaultBackgroundPortrait from 'static/img/default-background-portrait.svg';

import RestrictedNotice from './RestrictedNotice';
import useAuthModal from 'hooks/useAuthModal';

import styles from './styles.scss';

const publicContentQuery = gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${FullContentFragment}

  query getPublicContent($id: UUID!, $search: ContentSearch) {
    content(_id: $id, search: $search) {
      ...FullContentFragment
    }
  }
`;

const contentQuery = gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${FullContentFragment}

  query contentQuery($id: UUID!, $search: ContentSearch) {
    me {
      content(_id: $id, search: $search) {
        ...FullContentFragment
        info {
          _id
          rating
          viewed
          details
          hidden
          deleted
          pinned
          starred
          liked
        }
      }
    }
  }
`;

const getChannelQuery = gql`
  query getChannelBySlug($channelSlug: String!) {
    channel(slug: $channelSlug) {
      _id
      _updated
      slug
      name
      pages {
        _id
        _created
        _updated
        _order
        content {
          _id
          _created
          _updated
        }
        name
        slug
        icon
        iconSet
        iconWeight
        label
        label_l10n
      }
    }
  }
`;

const getSectionQuery = gql`
  query getSectionForRestricted($id: UUID!) {
    section(_id: $id) {
      _id
      _created
      name
      channel {
        _id
      }
    }
  }
`;

export default function RestrictedPage() {
  const [query] = useQueryString<{
    id?: string;
    type: 'channel' | 'content' | 'section' | undefined;
    tabSlug?: string;
    channelSlug?: string;
  }>();
  const { id, type, tabSlug, channelSlug } = query;

  const VALID_TYPES = ['channel', 'content', 'section'];

  const canRenderRestricted =
    typeof type === 'string' &&
    VALID_TYPES.includes(type.toLowerCase()) &&
    (Boolean(id) || Boolean(tabSlug) || Boolean(channelSlug));

  if (!canRenderRestricted) {
    return <Redirect to={routes.signUp} />;
  }

  return (
    <PrivateRestrictedPage
      type={type!}
      id={id}
      tabSlug={tabSlug}
      channelSlug={channelSlug}
    />
  );
}

type PrivateRestrictedPageProps = {
  type: 'channel' | 'content' | 'section';
  id?: string;
  tabSlug?: string;
  channelSlug?: string;
};

function PrivateRestrictedPage({
  id,
  type,
  tabSlug,
  channelSlug,
}: PrivateRestrictedPageProps) {
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [channel, setChannel] = useState<ChannelErrorData | null>(null);

  const { user } = useContext(UserDataContext);
  const client = getClient();
  const history = useHistory();

  const [returnTab, setReturnTab] = useState<String | undefined>(
    tabSlug || undefined
  );

  const { showAuthModal } = useAuthModal({});

  const slide = {
    title: channel?.profile?.name,
    subTitle: channel?.address?.street1,
  };

  const types = {
    channel: {
      query: {
        query: getChannelQuery,
        variables: { channelSlug },
      },
      setData: setChannelData,
      hasAccess: Boolean(channel) && !(channel as any)?.isError,
      returnUrl: routes.customTab
        .replace(':channelSlug', (channel as any)?.slug)
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        .replace(':tabSlug', returnTab),
    },
    content: {
      query: {
        query: user ? contentQuery : publicContentQuery,
        variables: { id },
      },
      setData: setContentData,
      hasAccess: Boolean(post),
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      returnUrl: routes.content.replace(':id', id),
    },
    section: {
      query: {
        query: getSectionQuery,
        variables: { id },
      },
      setData: setSectionData,
      hasAccess: Boolean(post),
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      returnUrl: routes.section.replace(':sectionId', id),
    },
  };

  const hasParams = (id || channelSlug) && type && type in types;

  function setChannelData(data: any) {
    if (data?.channel) {
      setChannel(data.channel);
      const firstTab = data.channel.pages?.find(
        (page: any) => page._order === 1
      ).slug;
      setReturnTab(tabSlug || firstTab);
    }
  }

  function setContentData(data: any) {
    const content = data?.me?.content || data?.content;
    if (content) {
      setPost(content);
      setChannel(content.channel);
    }
  }

  function setSectionData(data: any) {
    const section = data?.section;
    if (section) {
      setPost(section);
      setChannel(section.channel);
    }
  }

  async function loadData() {
    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ query: DocumentNode; variables... Remove this comment to see the full error message
      const { data } = await client.query(types[type].query);
      types[type].setData(data);
    } catch (err) {
      const channelData = findAccessRestrictedError(err);
      if (channelData) {
        (channelData as any).isError = true;
        setChannel(channelData);
        return;
      }
      errorOut();
    }
  }

  async function errorOut() {
    await window.Alert.alert({
      title: t(`You're trying to access an invalid post`),
      confirmText: t('Redirect to Home'),
      warning: true,
    });

    history.push(routes.home);
  }

  useEffect(() => {
    if (hasParams) {
      loadData();
    } else {
      errorOut();
    }
  }, [hasParams]);

  useEffect(() => {
    if (types[type].hasAccess) {
      history.push(types[type].returnUrl);
    }
  }, [post, channel]);

  return (
    <div className={styles.restrictedPage}>
      <div
        className={styles.fullSizeImage}
        style={{
          backgroundImage: `url(${imageUrl(
            channel?.profile?.image || defaultBackgroundPortrait
          )})`,
        }}
      >
        <Link to="/">
          <WhitelabeledLogo color={colors.laneWhite} />
        </Link>
        <div className={styles.restrictedSlide}>
          <H4 style={{ color: colors.laneWhite }}>{t(slide.title)}</H4>
          <H5 style={{ color: colors.laneWhite }}>{t(slide.subTitle)}</H5>
        </div>
      </div>
      <div className={styles.restrictedComponent}>
        <Route
          exact
          path={routes.restricted}
          render={() => (
            <RestrictedNotice
              onNextLoggedIn={() => history.push(routes.home)}
              onNext={showAuthModal}
            />
          )}
        />
      </div>
    </div>
  );
}
