import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import {
  getLibraryOptions,
  getValidationMessages,
  toSchema,
} from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { CONTENT_CATEGORIES } from 'lane-shared/types/content/ContentCategoryEnum';
import {
  ContentTypeEnum,
  FRIENDLY_CONTENT_TYPE_OPTIONS,
} from 'lane-shared/types/content/ContentTypeEnum';
import { ContentGroupByEnum } from 'lane-shared/types/filters/SearchOptions';

import {
  SectionMetatag,
  SectionEditProps,
} from 'lane-shared/types/sections/SectionType';
import { SectionTypeEnum } from 'lane-shared/types/sections/SectionTypeEnum';
import { getTags } from 'lane-shared/graphql/content';

import { Tooltip } from 'lane-web/src/components';

import { H4, H5, S } from 'components/typography';
import { Flex } from 'components/layout';

import Dropdown from 'components/form/Dropdown';
import MultiselectField from 'components/form/MultiselectField';

import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import IconButton from 'components/general/IconButton';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import MetatagLibrary from 'components/lane/MetatagLibrary';
import MetatagListItem from 'components/lane/MetatagListItem';
import PresetContentFilterListItem from 'components/lane/PresetContentFilterListItem';
import PresetContentSortListItem from 'components/lane/PresetContentSortListItem';
import Radio from 'components/form/Radio';
import type { ContentTag } from 'lane-shared/types/content/ContentTag';
import { GeneralInfo } from './GeneralInfo';
import styles from './SectionFormLegacy.scss';

// cjr: a couple types aren't ready yet.
const availableTypes = FRIENDLY_CONTENT_TYPE_OPTIONS.filter(
  ({ value }) =>
    ![
      ContentTypeEnum.ScheduledContent,
      ContentTypeEnum.ScheduledNotice,
    ].includes(value)
);

const CONTENT_CATEGORIES_WITH_SELECT_ALL = [
  ...CONTENT_CATEGORIES,
  {
    label: 'web.admin.section.contentTypes.selectAll',
    value: 'All',
  },
];

SectionFormLegacy.defaultProps = {
  validation: null,
  onSectionUpdated: () => null,
  onSectionAddMetatag: () => null,
  onSectionRemoveMetatag: () => null,
  forCreate: false,
};

export function SectionFormLegacy({
  channel,
  section,
  validation = null,
  onSectionUpdated,
  forCreate = false,
}: SectionEditProps) {
  const { t } = useTranslation();

  const [isMetatagLibraryOpen, setIsMetatagLibraryOpen] = useState(false);
  const [isContentTagsSelected, setIsContentTagsSelected] = useState(
    section.tagsOnSection?.length > 0
  );
  const [selectedMetatag, setSelectedMetatag] = useState<null | SectionMetatag>(
    null
  );

  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  const { data, loading: tagsLoading } = useQuery(getTags, {
    variables: { channelId: channel?._id },
    skip: !areContentTagsEnabled || !channel,
  });

  const tags: ContentTag[] = data?.tags;

  const pageDivider = <div className={styles.pageDivider} />;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.marginTop}>
        <S variant="secondary">
          {t('web.admin.content.sections.edit.description')}
        </S>
      </div>

      <GeneralInfo
        channel={channel}
        section={section}
        validation={validation}
        onSectionUpdated={onSectionUpdated}
      />
      {section.type === SectionTypeEnum.Dynamic && (
        <>
          {pageDivider}
          <H4>{t('web.admin.content.sections.edit.dynamic.title')}</H4>
          <S>
            {t('web.admin.content.sections.edit.dynamic.label')}
            <span className={styles.redText}> *</span>
          </S>
          {areContentTagsEnabled ? (
            <>
              <Flex justify="space-between">
                <Radio
                  selected={
                    isContentTagsSelected ? 'contentTags' : 'contentCategories'
                  }
                  value="contentTags"
                  name="contentWithTags"
                  onChange={() => {
                    setIsContentTagsSelected(true);
                    onSectionUpdated({
                      query: {
                        ...section.query,
                        contentCategories: [],
                      },
                    });
                  }}
                  doTranslate={false}
                  text={t('web.admin.section.contentTags')}
                />
                <MultiselectField
                  placeholder={t(
                    'web.admin.content.draftContent.info.contentSearchability.tags.inputPlaceHolder'
                  )}
                  disabled={!isContentTagsSelected}
                  dataCy="tagSelect"
                  isLoading={tagsLoading}
                  items={tags?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  value={section.tagsOnSection?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  className={styles.multiselect}
                  onChange={contentTags => {
                    onSectionUpdated({
                      tagsOnSection: contentTags.map(({ label, value }) => ({
                        id: value,
                        name: label,
                      })),
                    });
                  }}
                  doTranslation={false}
                />
              </Flex>

              <Flex justify="space-between" style={{ marginTop: '12px' }}>
                <Radio
                  selected={
                    isContentTagsSelected ? 'contentTags' : 'contentCategories'
                  }
                  value="contentCategories"
                  name="contentWithCategories"
                  onChange={() => {
                    setIsContentTagsSelected(false);
                    onSectionUpdated({
                      query: {
                        ...section.query,
                      },
                      tagsOnSection: [],
                    });
                  }}
                  text={t('web.admin.section.contentCategories')}
                  doTranslate={false}
                />
                <MultiselectField
                  disabled={isContentTagsSelected}
                  placeholder={t('Select categories')}
                  dataCy="categorySelect"
                  items={CONTENT_CATEGORIES_WITH_SELECT_ALL}
                  value={section.query!.contentCategories?.map(toSchema)}
                  className={styles.multiselect}
                  onChange={contentCategories => {
                    if (contentCategories.some(c => c.value === 'All')) {
                      contentCategories = CONTENT_CATEGORIES;
                    }

                    onSectionUpdated({
                      query: {
                        ...section.query,
                        contentCategories: contentCategories.map(
                          ({ value }) => value
                        ),
                      },
                    });
                  }}
                />
              </Flex>
            </>
          ) : (
            <MultiselectField
              disabled={isContentTagsSelected}
              placeholder={t('Select categories')}
              dataCy="categorySelect"
              items={CONTENT_CATEGORIES_WITH_SELECT_ALL}
              value={section.query!.contentCategories?.map(toSchema)}
              className={styles.multiselect}
              onChange={contentCategories => {
                if (contentCategories.some(c => c.value === 'All')) {
                  contentCategories = CONTENT_CATEGORIES;
                }

                onSectionUpdated({
                  query: {
                    ...section.query,
                    contentCategories: contentCategories.map(
                      ({ value }) => value
                    ),
                  },
                });
              }}
            />
          )}

          <S mt={4} mb={2}>
            {t('web.admin.section.contentTypes')}
          </S>
          <MultiselectField
            errors={getValidationMessages(validation, 'query.contentTypes')}
            className={styles.fullWidthMultiselect}
            items={availableTypes}
            dataCy="contentTypeSelect"
            value={section?.query?.contentTypes?.map(toSchema)}
            onChange={contentTypes =>
              onSectionUpdated({
                query: {
                  ...section.query,
                  contentTypes: contentTypes.map(({ value }) => value),
                },
              })
            }
          />

          <S mt={4} mb={2}>
            {t('web.admin.section.toggleText')}
          </S>
          <Tooltip
            TooltipComponent={t('web.admin.section.toggleTooltipText')}
            placement="bottom"
          >
            <Toggle
              dataCy="includeAllChannelsToggle"
              className={styles.toggle}
              value={section?.query?.includeChildren}
              onChange={includeChildren =>
                onSectionUpdated({
                  query: { ...section.query, includeChildren },
                })
              }
            />
          </Tooltip>
        </>
      )}
      {!forCreate && (
        <>
          {pageDivider}
          <H4>{t('web.admin.content.sections.edit.search')}</H4>
          <div className={styles.marginTop}>
            <S variant="secondary">
              {t('web.admin.content.sections.edit.search.description')}
            </S>
          </div>
          <S mb={2}>
            <span>{t('web.admin.section.standardFilters')}</span>
            <IconButton
              icon="plus-circle"
              iconSet={ICON_SET_FONTAWESOME}
              dataCy="buttonStandardFilters"
              onClick={() =>
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
                !section.filters.includes('') &&
                onSectionUpdated({
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | PresetContentSort' is not assignable to... Remove this comment to see the full error message
                  filters: [...section.filters, ''],
                })
              }
            />
          </S>
          <ul className={styles.filters} data-cy="filterList">
            {section.filters?.map(filter => (
              <PresetContentFilterListItem
                key={filter}
                className={styles.listItem}
                sectionType={section.type}
                filter={filter}
                filters={section.filters}
                onChange={(filters: any) => onSectionUpdated({ filters })}
              />
            ))}
          </ul>
          <S mt={4} mb={2}>
            <span>{t('web.admin.section.sorting')}</span>
            <IconButton
              icon="plus-circle"
              iconSet={ICON_SET_FONTAWESOME}
              onClick={() =>
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
                !section.sorts.includes('') &&
                onSectionUpdated({
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | PresetContentSort' is not assignable to... Remove this comment to see the full error message
                  sorts: [...section.sorts, ''],
                })
              }
            />
          </S>
          <ul className={styles.sorts}>
            {section.sorts?.map(sort => (
              <PresetContentSortListItem
                key={sort}
                className={styles.listItem}
                filter={sort}
                filters={section.sorts}
                onChange={(sorts: any) => onSectionUpdated({ sorts })}
              />
            ))}
          </ul>

          <S mt={4} mb={2}>
            {t('web.admin.section.groupBy')}
          </S>
          <Dropdown
            items={Object.values(ContentGroupByEnum).map(toSchema)}
            value={section.groupBy}
            onValueChange={groupBy => onSectionUpdated({ groupBy })}
          />
          <div className={styles.column}>
            <H5 mt={6} mb={2}>
              {t('web.admin.section.customFilters')}
            </H5>
            <S mb={2}>
              <span>{t('web.admin.section.customFilters')}</span>
              <IconButton
                icon="plus-circle"
                iconSet={ICON_SET_FONTAWESOME}
                onClick={() => setIsMetatagLibraryOpen(true)}
              />
            </S>
            <ul className={styles.filters}>
              {section.sectionMetatags?.reduce<Array<React.ReactElement>>(
                (prev, sectionMetatag, index) => {
                  if (sectionMetatag.metatag.toRemove) {
                    return prev;
                  }
                  prev.push(
                    <li key={sectionMetatag.metatag._id}>
                      <MetatagListItem
                        className={styles.metatagListItem}
                        metatag={sectionMetatag.metatag}
                      />
                      <IconButton
                        className={styles.deleteButton}
                        icon="times"
                        iconSet={ICON_SET_FONTAWESOME}
                        onClick={() => {
                          let deletedSectionMetaTag;
                          if (sectionMetatag._id) {
                            sectionMetatag.metatag.toRemove = true;
                          } else {
                            deletedSectionMetaTag = section.sectionMetatags?.splice(
                              index,
                              1
                            );
                          }
                          onSectionUpdated({
                            sectionMetatags: section.sectionMetatags,
                          });
                          return deletedSectionMetaTag;
                        }}
                      />
                    </li>
                  );
                  return prev;
                },
                []
              )}
            </ul>
          </div>
        </>
      )}
      <ModalBackground
        onClose={() => setIsMetatagLibraryOpen(false)}
        isOpen={isMetatagLibraryOpen}
        className={styles.background}
      >
        <ResizableWindow
          name="sectionEditMetatag"
          className={styles.wrapper}
          onClose={() => setIsMetatagLibraryOpen(false)}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
          showHeader
        >
          <MetatagLibrary
            className={styles.metatagLibrary}
            libraries={getLibraryOptions({ channel })}
            channelId={channel?._id}
            onMetatagSelected={metatag => setSelectedMetatag(metatag)}
          />
          <ControlMenu>
            <hr />
            <Button onClick={() => setIsMetatagLibraryOpen(false)}>
              {t('Cancel')}
            </Button>
            <Button
              disabled={!selectedMetatag}
              onClick={() => {
                setIsMetatagLibraryOpen(false);
                if (selectedMetatag) {
                  const selectMetaTags = section.sectionMetatags || [];
                  const filteredTags = selectMetaTags.find(
                    item => item.metatag._id === selectedMetatag._id
                  );

                  if (!filteredTags) {
                    onSectionUpdated({
                      sectionMetatags: selectMetaTags.concat({
                        metatag: selectedMetatag,
                      }),
                    });
                  } else if (filteredTags && filteredTags?.metatag?.toRemove) {
                    delete filteredTags.metatag.toRemove;
                  }

                  if (selectMetaTags.length === 0) {
                    onSectionUpdated({
                      sectionMetatags: selectMetaTags.concat({
                        metatag: selectedMetatag,
                      }),
                    });
                  }
                }
              }}
              variant="contained"
            >
              {t('Add')}
            </Button>
          </ControlMenu>
        </ResizableWindow>
      </ModalBackground>
    </div>
  );
}
