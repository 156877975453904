import React, { useEffect, useMemo, useState } from 'react';

import { Flex, Tooltip } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { Button, Icon, PageSizeType, Table } from 'design-system-web';

import {
  archiveMeterReadingValueMutation,
  getMeterReadingValueForExport,
  searchMeterReadingValue,
} from 'graphql-queries';
import {
  MeterReadingValueResponseType,
  SortDirection,
  StringSearchType,
} from 'graphql-query-contracts';
import { exportCSV } from 'lane-web/src/domains/workOrder/helpers/exportCSV';

import { H4, M } from 'components/typography';

import { getMeterReadingUnitLabel } from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingUnitOptions';
import MeterReadingModal from '../../../form/details/MeterReadingModal';
import styles from './MeterReadingTable.scss';

const PER_PAGE = 50;
export interface MeterReadingTableProps {
  channel?: any;
  meterReadingSetting: any;
  refetching?: boolean;
  setRefetching?: (refetching: boolean) => void;
}

export function MeterReadingTable({
  channel,
  meterReadingSetting,
  refetching,
  setRefetching,
}: MeterReadingTableProps) {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<PageSizeType>(PER_PAGE);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    'desc' as SortDirection
  );
  const [keyword, setKeyword] = useState<string>('');
  const [
    meterReadingValueModalOpen,
    setMeterReadingValueModalOpen,
  ] = useState<boolean>(false);
  const [editMeterReadingValue, setEditMeterReadingValue] = useState<any>(
    undefined
  );

  const [showTable, setShowTable] = useState<boolean>(false);

  const [fetchMeterReadingValues, { data, loading, refetch }] = useLazyQuery(
    searchMeterReadingValue,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (meterReadingSetting?.id) {
      fetchMeterReadingValues({
        variables: {
          channelId: channel?._id,
          search: {
            sortBy: {
              key: sortBy,
              dir: sortDirection,
            },
            ...(keyword !== ''
              ? {
                  search: {
                    type: 'like' as StringSearchType,
                    value: keyword,
                  },
                }
              : {}),
          },
          pagination: {
            start: ((page || 0) as number) * perPage,
            perPage,
          },
          meterReadingId: convertToUUID(meterReadingSetting?.id),
          includeArchived: true,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [
    page,
    perPage,
    sortBy,
    sortDirection,
    keyword,
    fetchMeterReadingValues,
    refetching,
  ]);

  const totalMRValues = data?.searchMeterReadingValue?.pageInfo?.total;

  const onEdit = (row: any) => {
    setEditMeterReadingValue(row);
    setMeterReadingValueModalOpen(true);
  };

  const tableRows = useMemo(() => {
    if (!data?.searchMeterReadingValue?.meterReadingValues) return [];
    const rows = data?.searchMeterReadingValue?.meterReadingValues.map(
      (meterReadingValue: MeterReadingValueResponseType) => ({
        ...meterReadingValue,
        created_at: meterReadingValue.createdAt,
        created_by: meterReadingValue.createdBy?.name,
        task_user_friendly_id: meterReadingValue.taskUserFriendlyId,
      })
    );
    return rows;
  }, [data?.searchMeterReadingValue]);

  const columns = [
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.createdAt`,
      key: 'created_at',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.createdAt),
    },
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.value`,
      key: 'value',
      renderCell: (value: string, row: any) => {
        if (row.isArchived) {
          return (
            <Flex gap={4} className={styles.valueBox}>
              <Flex direction="column">
                <M className={styles.valueCrossed}>{value}</M>
                <M className={styles.valueUnitCrossed}>
                  {meterReadingSetting.unit}
                </M>
              </Flex>
              <Tooltip
                TooltipComponent={t(
                  'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.value.archived.tooltip'
                )}
                placement="top"
              >
                <Icon
                  name="info-circle"
                  className={styles.infoIcon}
                  set={ICON_SET_FONTAWESOME}
                  type="far"
                />
              </Tooltip>
            </Flex>
          );
        }

        return (
          <Flex direction="column">
            <M>{value}</M>
            <M className={styles.valueUnit}>{meterReadingSetting.unit}</M>
          </Flex>
        );
      },
      renderForCSV: (value: any) => `${value} ${meterReadingSetting.unit}`,
    },
    {
      key: 'created_by',
      header: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.createdBy'
      ),
      type: 'text',
      renderForCSV: (_: any, row: any) => row.createdBy?.name,
    },
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.taskId`,
      key: 'task_user_friendly_id',
      renderCell: (task_user_friendly_id: string, row: any) => (
        <Link
          to={routes.channelAdminWorkOrdersPMTaskDetails
            .replace(':id', channel?.slug)
            .replace(':taskId', row?.taskId)}
        >
          {task_user_friendly_id}
        </Link>
      ),
      renderForCSV: (task_user_friendly_id: any) => task_user_friendly_id,
    },
    {
      key: 'notes',
      header: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.notes'
      ),
      type: 'text',
      renderCell: (notes: any) => <M className={styles.notesBox}>{notes}</M>,
      renderForCSV: (notes: any) => notes,
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.actions.editValue'
      ),
      onClick: onEdit,
    },
    {
      label: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.actions.archieveValue'
      ),
      onClick: async (row: any) => {
        await getClient().mutate({
          mutation: archiveMeterReadingValueMutation,
          variables: {
            meterReadingValueId: row?.id,
            channelId: channel?._id,
          },
        });
        window.Toast.show(
          t(
            'web.admin.serviceRequest.equipment.MeterReadingInput.archive.successToast'
          )
        );
        if (refetch) {
          await refetch();
        }
      },
    },
  ];

  const handleExportToCSV = async () => {
    const { data } = await getClient().query({
      query: getMeterReadingValueForExport,
      variables: {
        meterReadingId: meterReadingSetting?.id,
        channelId: channel?._id,
        includeArchived: false,
      },
      fetchPolicy: 'network-only',
    });
    const meterReadingValueCSVData =
      data?.getMeterReadingValueForExport?.meterReadingValues || [];

    const fileNamePrefix =
      meterReadingSetting?.name || meterReadingSetting?.unit;
    exportCSV(
      meterReadingValueCSVData,
      columns,
      `${fileNamePrefix}-${new Date().toISOString()}.csv`
    );
  };

  const exportOptions = [
    {
      label: t`web.admin.serviceRequest.equipment.meterReadingValues.csvName`,
      onClick: handleExportToCSV,
    },
  ];

  return (
    <Flex direction="column" className={styles.meterReadingTable} gap={5}>
      <Flex className={styles.tableHeader}>
        <H4>
          {meterReadingSetting?.name ||
            t(getMeterReadingUnitLabel(meterReadingSetting?.unit))}
        </H4>
        <Button
          variant="text"
          startIcon={<Icon name={showTable ? 'chevron-up' : 'chevron-down'} />}
          onClick={() => {
            if (refetching && setRefetching) {
              setRefetching(false);
            }
            setShowTable(!showTable);
          }}
          fullWidth={false}
          dataCy="add-option"
        >
          {t(
            showTable
              ? 'web.admin.serviceRequest.equipment.meterReadingValues.CollapseTable'
              : 'web.admin.serviceRequest.equipment.meterReadingValues.ExpandTable'
          )}
        </Button>
      </Flex>
      {showTable && (
        <Table
          maxHeight="65vh"
          isLoading={loading}
          columns={columns}
          data={tableRows}
          totalRows={Number(totalMRValues)}
          pagination="server"
          rowActions={rowActions}
          exportOptions={exportOptions}
          hasKeywordFilter
          onKeywordFilterChange={(keyword: string) => {
            setKeyword(keyword);
          }}
          onPageChange={(page: number) => {
            setPage(page);
          }}
          onPageSizeChange={(pageSize: PageSizeType) => {
            setPerPage(pageSize);
          }}
          onSortChange={(sort: any) => {
            if (sort?.id) {
              setSortBy(sort?.id || 'created_at');
            }
            if (sort?.direction) {
              setSortDirection((sort?.direction || 'desc') as SortDirection);
            }
          }}
        />
      )}

      <MeterReadingModal
        meterReadingSettings={[meterReadingSetting]}
        source_type={MeterReadingValueType.ADHOC}
        channel={channel}
        meterReadingValueModalOpen={meterReadingValueModalOpen}
        setMeterReadingValueModalOpen={setMeterReadingValueModalOpen}
        setEditMeterReadingValue={setEditMeterReadingValue}
        refetch={refetch}
        startingMeterReadingValues={[editMeterReadingValue]}
        editMode
      />
    </Flex>
  );
}
