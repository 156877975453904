import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { Button } from 'lane-web/src/components/general';

import { H4 } from 'components/typography';

import { CategoriesTable } from '../categories/CategoriesTable';

import styles from '../styles.scss';

type Props = {
  formRoute?: string;
  canManage?: boolean;
};

export function CategoriesTab({ formRoute, canManage }: Props) {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const history = useHistory();

  const handleCreateCategory = () => {
    history.push(
      formRoute ?? routes.channelAdminCreateCategoryForm.replace(':id', id)
    );
  };
  return (
    <>
      <div className={styles.heading}>
        <H4>{t('abp.productsServices.categories.heading')}</H4>
        {canManage && (
          <Button
            dataCy="newCategory"
            variant="contained"
            onClick={handleCreateCategory}
          >
            {t('abp.productsServices.categories.button')}
          </Button>
        )}
      </div>
      <CategoriesTable />
    </>
  );
}
