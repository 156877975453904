import React from 'react';

import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import Loading from 'components/general/Loading';

import NonPropertyChannelRelationships from './NonPropertyChannelRelationships';
import { PropertyChannelRelationships } from './PropertyChannelRelationships';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

export default function ChannelRelationships() {
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return <Loading />;
  }

  if (channel.type === ChannelTypeEnum.Property) {
    return <PropertyChannelRelationships />;
  }

  return <NonPropertyChannelRelationships />;
}
