import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { MediaPickerButton, Tooltip, Label } from 'components';
import { kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import {
  getLibraryOptions,
  getAggregatedValidationMessage,
  toSchema,
} from 'lane-shared/helpers';
import { ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED } from 'lane-shared/helpers/constants/analytics';
import {
  PROPERTY_TYPES,
  CHANNEL_DATA_TAGS,
} from 'lane-shared/helpers/constants/channel';
import { imageUrl } from 'lane-shared/helpers/formatters';
import useFormattedNumberInput from 'lane-shared/hooks/useFormattedNumberInput';
import { AddressType } from 'lane-shared/types/AddressType';
import {
  ActiveChannelTypeEnum,
  ChannelType,
  ChannelTypeEnum,
  ChannelExperienceTypeEnum,
} from 'lane-shared/types/ChannelType';
import { ProfileType } from 'lane-shared/types/ProfileType';
import { ContentType } from 'lane-shared/types/content/Content';

import RadioGroup from 'lane-web/src/components/form/RadioGroup';

import { Dropdown, Input, Toggle } from 'components/form';
import ShowId from 'components/lane/ShowId';
import { H4 } from 'components/typography';

import AddressEditInline from '../AddressEditInline';

import styles from './ChannelInfoEditMultifamily.scss';

type Props = {
  onChannelUpdated: (channel: Partial<ChannelType>) => void;
  channel: ChannelType;
  validation: any;
  className?: string;
  style?: React.CSSProperties;
  dataCy?: undefined;
  shouldShowStats?: boolean;
  shouldShowType?: boolean;
  isInfoPage?: boolean;
  channelForDataIdentifiers: ChannelType;
  forCreate?: boolean;
};

enum AutofilledField {
  uniqueLink = 'slug',
  displayName = 'name',
}

export default function ChannelInfoEditMultifamily({
  onChannelUpdated,
  channel,
  validation = null,
  className,
  style,
  shouldShowStats = false,
  shouldShowType = false,
  isInfoPage = false,
  channelForDataIdentifiers,
  forCreate = false,
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);

  const [propertyType, setPropertyType] = useState(
    ChannelExperienceTypeEnum.office
  );
  const propertyTypeOptions = [
    {
      _id: ChannelExperienceTypeEnum.office,
      text: t(
        'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.officeExperience'
      ),
      subtext: t(
        'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.propertyTypeSubtext'
      ),
    },
    {
      _id: ChannelExperienceTypeEnum.multifamily,
      text: t(
        'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.multifamilyExperience'
      ),
      subtext: t(
        'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.propertyTypeSubtext'
      ),
    },
  ];

  function updatePropertyType(experienceType: ChannelExperienceTypeEnum) {
    setPropertyType(experienceType);
    updateChannel({ fields: { experienceType } });
  }

  function updateChannel({
    fields = {},
    profileFields,
  }: {
    fields?: Partial<ChannelType>;
    profileFields?: Partial<ProfileType>;
  }) {
    const updatedProfile = {
      ...(channel?.profile || {}),
      ...(profileFields || {}),
    } as ProfileType;

    if (profileFields && updatedProfile.content?._id) {
      // There maybe full content here loaded from the server.
      // but we don't want to set full content info back.
      // Just the id.  ProfileInput expects a ContentIdInput.
      updatedProfile.content = ({
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        _id: channel.profile?.content._id,
      } as Partial<ContentType>) as ContentType;
    }

    onChannelUpdated({
      ...fields,
      ...(profileFields ? { profile: updatedProfile } : {}),
    });
  }

  const isCreateAction = Boolean(!channel.__typename);

  function reducer(
    dirtyFields: { [key in AutofilledField]?: boolean },
    action: { field: AutofilledField }
  ) {
    return {
      ...dirtyFields,
      [action.field]: true,
    };
  }

  const [dirtyFields, dispatchFieldModified] = useReducer(reducer, {
    [AutofilledField.displayName]: false,
    [AutofilledField.uniqueLink]: false,
  });

  const shouldAutofill =
    isCreateAction &&
    (!dirtyFields[AutofilledField.displayName] ||
      !dirtyFields[AutofilledField.uniqueLink]);

  function setTag(tag: string, value: boolean): void {
    const tags = new Set(channelForDataIdentifiers.tags);
    if (value) {
      tags.add(tag);
    } else {
      tags.delete(tag);
    }

    updateChannel({ fields: { tags: Array.from(tags) } });
  }

  function getTag(tagName: string): boolean {
    return channelForDataIdentifiers.tags.includes(tagName);
  }

  const channelTypeOptions = useMemo(
    () => Object.values(ActiveChannelTypeEnum).map(toSchema),
    []
  );

  const {
    maskedValue: headcountDisplayValue,
    inputOnChange: onChangeHeadcount,
    inputOnBlur: onBlurHeadcount,
  } = useFormattedNumberInput({
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    value: channel.stats?.subscribers,
    allowEmptyInput: isCreateAction,
    maximumFractionDigits: 0,
    onValueChange: subscribers =>
      updateChannel({
        fields: {
          stats: {
            ...channel.stats,
            subscribers,
          },
        },
      }),
  });
  const shouldRenderPhysicalBuildingTag = useMemo(() => {
    // only render for child channels or sub channels
    return (
      channelForDataIdentifiers.type === ActiveChannelTypeEnum.Property &&
      channelForDataIdentifiers.parent
    );
  }, [channelForDataIdentifiers.type, channelForDataIdentifiers.parent]);

  const {
    maskedValue: squareFootageDisplayValue,
    inputOnChange: onChangeSquareFootage,
    inputOnBlur: onBlurSquareFootage,
  } = useFormattedNumberInput({
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    value: channel.stats?.sf,
    allowEmptyInput: isCreateAction,
    maximumFractionDigits: 0,
    onValueChange: sf =>
      updateChannel({
        fields: { stats: { ...channel.stats, sf } },
      }),
  });

  function autofillFields() {
    const slugUpdate = dirtyFields[AutofilledField.uniqueLink]
      ? {}
      : { slug: kebabCase(channel?.name) };
    const displayNameUpdate = dirtyFields[AutofilledField.displayName]
      ? {}
      : { name: channel?.name };

    updateChannel({
      fields: {
        name: channel?.name,
        ...slugUpdate,
      },
      profileFields: displayNameUpdate,
    });
  }

  useEffect(() => {
    if (shouldAutofill) {
      autofillFields();
    }
  }, [channel?.name, shouldAutofill, dirtyFields]);

  // isCustomer is a new field with no default value, let's check if it has been set already
  const isChannelIsCustomerDefined = !(
    typeof channel.isCustomer === 'undefined' || channel.isCustomer === null
  );

  const getValidationMessage = (path: string) => {
    const validationMessage = getAggregatedValidationMessage(validation, path);
    if (!validationMessage) {
      return null;
    }

    return [validationMessage];
  };

  const getCustomAddressValidationMessage = () => {
    const addressPaths = [
      'address.street1',
      'address.city',
      'address.country',
      'address.code',
    ];

    const isAnyPathRequired = validation?.inner?.some(
      (innerValidation: ValidationError) =>
        addressPaths.includes(innerValidation.path)
    );

    if (isAnyPathRequired) {
      return t('web.admin.channel.profile.info.address.required');
    }

    return undefined;
  };

  const channelTypeErrors = getValidationMessage('type');

  const isChildPropertyChannel =
    PROPERTY_TYPES.includes(channel.type as ActiveChannelTypeEnum) &&
    channel.parent;

  const EXPERIENCE_TYPE_NAMES = {
    office: t(
      'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.channelExperienceEnum.office'
    ),
    multifamily: t(
      'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.channelExperienceEnum.multifamily'
    ),
  };

  return (
    <section
      className={cx(styles.ChannelInfoEditMultifamily, className)}
      style={style}
    >
      <div className={styles.subTitle}>
        <H4>
          {t(
            'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.subTitle'
          )}
        </H4>
        {isChildPropertyChannel && !forCreate && (
          <div>
            <Label mt={0} className={styles.existingPropertyType}>
              {t(
                'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.setExperienceType',
                {
                  experienceType:
                    EXPERIENCE_TYPE_NAMES[
                      channel?.experienceType ||
                        ChannelExperienceTypeEnum.office
                    ],
                }
              )}
            </Label>
          </div>
        )}
      </div>
      {isChildPropertyChannel && forCreate && (
        <div>
          <Label mt={0} className={styles.propertyType}>
            {t(
              'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.propertyType'
            )}
            <Tooltip
              TooltipComponent={
                <p>
                  {t(
                    'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.propertyTypeTooltip'
                  )}
                </p>
              }
              placement="right"
              className={styles.tooltip}
            >
              <Icon name="info-circle" set="FontAwesome" type="far" />
            </Tooltip>
          </Label>
          <div className={styles.radioGroup}>
            <RadioGroup
              name="propertyType"
              schema={{
                id: '_id',
                text: 'text',
                disabled: 'disabled',
                subtext: 'subtext',
              }}
              selected={propertyType || channel.type}
              items={propertyTypeOptions}
              showBorder
              onChange={experienceType =>
                updatePropertyType(experienceType as ChannelExperienceTypeEnum)
              }
            />
          </div>
        </div>
      )}
      {shouldShowType && (
        <Dropdown<ChannelTypeEnum>
          isFullWidth
          dataCy="channelTypeDropdown"
          placeholder={t(
            'web.admin.channel.profile.info.channelType.placeHolder'
          )}
          invalid={Boolean(channelTypeErrors)}
          errors={channelTypeErrors}
          items={channelTypeOptions}
          value={channel.type}
          onValueChange={type => updateChannel({ fields: { type } })}
        />
      )}
      <Input
        fieldName="name"
        label={t(
          'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.internalName'
        )}
        fixedLabel
        dataCy="channelNameInput"
        value={channel.name || ''}
        error={getValidationMessage('name')}
        onChange={name => updateChannel({ fields: { name } })}
        placeholder={t(
          'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.internalName.placeHolder'
        )}
        isRequired
      />
      <Input
        fieldName="displayName"
        value={channel.profile?.name || ''}
        label={t('web.admin.channel.profile.info.displayName')}
        fixedLabel
        dataCy="profileDisplayName"
        placeholder={t(
          'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.displayName.placeHolder'
        )}
        onChange={(name: string) => {
          if (!dirtyFields[AutofilledField.displayName]) {
            dispatchFieldModified({ field: AutofilledField.displayName });
          }
          updateChannel({ profileFields: { name } });
        }}
        error={getValidationMessage('profile.name')}
        isRequired
      />
      <Input
        fieldName="uniqueLink"
        value={channel.slug}
        label={t('web.admin.channel.profile.info.uniqueLink')}
        fixedLabel
        dataCy="channelUniqueLinkInput"
        onChange={(slug: string) => {
          if (!dirtyFields[AutofilledField.uniqueLink]) {
            dispatchFieldModified({ field: AutofilledField.uniqueLink });
          }
          updateChannel({ fields: { slug } });
        }}
        error={getValidationMessage('slug')}
        placeholder={t('web.admin.channel.profile.info.uniqueLink.placeHolder')}
        isRequired
      />
      <Input
        fieldName="description"
        value={channel.description || ''}
        label={t(
          'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.description'
        )}
        fixedLabel
        dataCy="channelDescriptionInput"
        onChange={(description: string) =>
          updateChannel({ fields: { description } })
        }
        error={getValidationMessage('description')}
        placeholder={t(
          'web.components.lane.ChannelSettingsEdit.ChannelInfoEdit.description.placeHolder'
        )}
      />

      {shouldShowStats && (
        <>
          <Input
            label={t('web.admin.channel.profile.info.channelHeadCountDefault')}
            fixedLabel
            error={getValidationMessage('stats.subscribers')}
            placeholder={t(
              'web.admin.channel.profile.info.channelHeadCount.placeHolder'
            )}
            dataCy="headcount"
            value={headcountDisplayValue}
            onChange={onChangeHeadcount}
            onBlur={onBlurHeadcount}
          />

          <Input
            label={t(
              'web.admin.channel.profile.info.channelSquareFootageDefault'
            )}
            fixedLabel
            error={getValidationMessage('stats.sf')}
            placeholder={t(
              'web.admin.channel.profile.info.channelSquareFootage.placeHolder'
            )}
            dataCy="squareFootage"
            value={squareFootageDisplayValue}
            onChange={onChangeSquareFootage}
            onBlur={onBlurSquareFootage}
          />
        </>
      )}

      <Input
        fieldName="website"
        value={channel.website || ''}
        fixedLabel
        label={t('web.admin.channel.profile.info.website')}
        dataCy="websiteInput"
        placeholder={t('web.admin.channel.profile.info.website.placeholder')}
        onChange={website => updateChannel({ fields: { website } })}
        error={getValidationMessage('website')}
      />

      <div className={styles.address}>
        <AddressEditInline
          address={channel.address || ({} as AddressType)}
          onAddressUpdated={(address: AddressType) =>
            onChannelUpdated({ address })
          }
          isNewAddress={isCreateAction}
          isRequired
          error={getCustomAddressValidationMessage()}
        />
      </div>

      <Input
        type="email"
        label={t('web.components.lane.ChannelSettingsEdit.supportEmail')}
        placeholder={t(
          'web.components.lane.ChannelSettingsEdit.supportEmail.placeHolder'
        )}
        fixedLabel
        value={channel.profile?.email || ''}
        onChange={email => updateChannel({ profileFields: { email } })}
        error={getValidationMessage('profile.email')}
        dataCy="profileDisplayEmail"
      />

      <div>
        <H4 mb={4} mt={4}>
          {t('web.admin.channel.profile.info.logo')}
        </H4>
        <MediaPickerButton
          libraries={getLibraryOptions({
            channel: forCreate && channel.parent ? channel.parent : channel,
          })}
          errors={getValidationMessage('profile.logo')}
          media={{ _id: channel.profile?.logo || '' }}
          storageKey={channel?._id}
          onMediaSelected={media => {
            updateChannel({ profileFields: { logo: media?._id } });
            analytics.track(ANALYTIC_CHANNEL_PROFILE_LOGO_CHANGE_ATTEMPTED, {
              attemptedChannelId: channel?._id,
              attemptedChannelName: channel?.name,
            });
          }}
          className={styles.imageButton}
          dataCy="profileLogoMediaPicker"
        />

        <div className={styles.imageWrapper}>
          <div
            className={cx(styles.image, styles.logo)}
            style={{
              backgroundImage: `url(${imageUrl(channel.profile?.logo)})`,
            }}
          />
        </div>
      </div>
      {!isInfoPage && (
        <div>
          <H4 mb={4}>{t('web.admin.channel.profile.info.dataIdentifiers')}</H4>
          <div className={styles.dataIdentifiersWrapper}>
            <div className={styles.toggle}>
              <Toggle
                value={getTag(CHANNEL_DATA_TAGS.demo)}
                onChange={value => setTag(CHANNEL_DATA_TAGS.demo, value)}
                text={t(
                  'web.admin.channel.profile.info.dataIdentifiers.demo.text'
                )}
                description={t(
                  'web.admin.channel.profile.info.dataIdentifiers.demo.description'
                )}
                dataCy="demoChannelToggle"
              />
            </div>
            <div className={styles.toggle}>
              <Toggle
                value={getTag(CHANNEL_DATA_TAGS.contentDistribution)}
                onChange={value =>
                  setTag(CHANNEL_DATA_TAGS.contentDistribution, value)
                }
                text={t(
                  'web.admin.channel.profile.info.dataIdentifiers.contentDistribution.text'
                )}
                description={t(
                  'web.admin.channel.profile.info.dataIdentifiers.contentDistribution.description'
                )}
                dataCy="contentDistributionChannelToggle"
              />
            </div>
            <div className={styles.toggle}>
              <Toggle
                value={getTag(CHANNEL_DATA_TAGS.flexCompany)}
                onChange={value => setTag(CHANNEL_DATA_TAGS.flexCompany, value)}
                text={t(
                  'web.admin.channel.profile.info.dataIdentifiers.flexCompany.text'
                )}
                description={t(
                  'web.admin.channel.profile.info.dataIdentifiers.flexCompany.description'
                )}
                dataCy="flexCompanyChannelToggle"
              />
            </div>
            {shouldRenderPhysicalBuildingTag && (
              <div className={styles.toggle}>
                <Toggle
                  value={getTag(CHANNEL_DATA_TAGS.physicalBuilding)}
                  onChange={value =>
                    setTag(CHANNEL_DATA_TAGS.physicalBuilding, value)
                  }
                  text={t(
                    'web.admin.channel.profile.info.dataIdentifiers.physicalBuilding.text'
                  )}
                  description={t(
                    'web.admin.channel.profile.info.dataIdentifiers.physicalBuilding.description'
                  )}
                  dataCy="physicalPropertyChannelToggle"
                />
              </div>
            )}
            <div className={styles.toggle}>
              <Toggle
                value={isChannelIsCustomerDefined ? channel.isCustomer : false}
                onChange={() => {
                  updateChannel({
                    fields: { isCustomer: !channel.isCustomer },
                  });
                }}
                disabled={
                  Boolean(channel.parent) || Boolean(!user?.isSuperUser)
                }
                text={t('web.admin.channel.profile.info.isCustomer.text')}
                description={t(
                  'web.admin.channel.profile.info.isCustomer.description'
                )}
                dataCy="isCustomerChannelToggle"
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.showId}>
        <ShowId id={channel._id} dataCy="channelId" />
      </div>
    </section>
  );
}
