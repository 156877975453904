import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { useChannelProfileQuery } from 'lane-shared/hooks';

import ErrorMessage from 'components/general/ErrorMessage';
import ThemeDetails from 'components/lane/ThemeDetails';
import ThemePaletteEdit from 'components/lane/ThemePaletteEdit';
import ThemePreview from 'components/lane/ThemePreview';
import AdminPage from 'components/layout/AdminPage';
import Alert, { AlertType } from 'components/lds/Alert';
import { M, H3 } from 'components/typography';

import ChannelAdminContext from '../ChannelAdminContext';

import styles from './styles.scss';

export default function ChannelTheme({ theme, onThemeUpdated, error }: any) {
  const { channel, error: channelError } = useContext(ChannelAdminContext);

  const { t } = useTranslation();
  const { channel: themeChannel } = useChannelProfileQuery({
    channelId: theme?.channel?._id,
  });

  // does this theme belong to this channel or not?  If not, you can't edit
  // it from here.  But you can export it, clone it, create a new theme. etc.
  const isChannelTheme = channel?._id === theme?.channel?._id;

  return (
    <AdminPage className={styles.ChannelTheme}>
      <ErrorMessage error={error || channelError} />

      {!isChannelTheme && (
        <Alert type={AlertType.info} className={styles.alert} fullWidth>
          <M variant="secondary">
            {t(
              'web.admin.channel.settings.profile.themesTab.parentchannel.alert',
              {
                parentChannel: themeChannel?.name.trim(),
              }
            )}
          </M>
        </Alert>
      )}

      <div className={styles.panels}>
        <div data-cy="themePalette" className={styles.left}>
          {!isChannelTheme && (
            <>
              <H3 mb={4}>
                {t('web.admin.channel.settings.profile.themesTab.theme.title')}
              </H3>
              <ThemeDetails theme={theme} />
            </>
          )}

          {isChannelTheme && theme && (
            <>
              <H3 mb={4}>
                {t(
                  'web.admin.channel.settings.profile.themesTab.theme.palette.title'
                )}
              </H3>
              <ThemePaletteEdit
                theme={theme}
                onThemeUpdated={update => onThemeUpdated(update)}
              />
            </>
          )}
        </div>
        <div data-cy="themePreview" className={styles.right}>
          <H3 mb={4}>
            {t(
              'web.admin.channel.settings.profile.themesTab.theme.preview.title'
            )}
          </H3>
          <ThemePreview theme={theme} />
        </div>
      </div>
    </AdminPage>
  );
}
