import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';

import { Icon, DatePickerButton, TimePicker } from 'design-system-web';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule, rrulestr } from 'rrule';
import * as yup from 'yup';

import AnalyticsContext from 'lane-shared/contexts/AnalyticsContext';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import {
  getTimeZoneByGeoLocation,
  getValidationMessages,
} from 'lane-shared/helpers';
import { emitVisitCreated } from 'lane-shared/helpers/analytics/emitVisit';
import { validateStartAndEndTime } from 'lane-shared/helpers/constants/integrations/BuildingEnginesPrismVisitors';
import {
  visitors as visitClient,
  users as userClient,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client';
import { UsersRightsEnum } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/users';
import {
  ContactPerson,
  VisitPatchData,
  VisitReqData,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/visitors';
import {
  FloorWithSpaces,
  fetchRequiredSpaces,
  fetchWorkorderFormData,
  getOrganizationsData,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrism/requestHelpers';
import { getToken } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import {
  definition,
  validationShape,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors';
import { getErrorMessage } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/getErrorMessage';
import {
  VisitType,
  VisitEditType,
  VisitorType,
  visitorStatus,
  dataOptions,
  SpacesResponseType,
  DropdownValueProp,
  VISIT_TYPE,
  rRuleOptions,
  FrequencyValue,
  recurringEndsValue,
  recurringFrequency,
  recurringEndsEnum,
  emailRegex,
} from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types/VisitTypes';
import { ContentType } from 'lane-shared/types/content/Content';

import Input from 'lane-web/src/components/form/Input/index';

import { ValidationMessage } from 'components/general';

import history from '../../../../helpers/history';
import {
  Dropdown,
  RadioGroup,
  ValidatedDropdown,
  ValidatedInput,
} from '../../../form';
import Checkbox from '../../../form/Checkbox';
import ValidatedTextArea from '../../../form/ValidatedTextArea';
import Button from '../../../general/Button';
import EmailChip from './EmailChip';
import { StatusPill } from './StatusPill';
import { VisitorCard } from './VisitorCard';
import VisitorForm from './VisitorForm';
import { ParsedOptions } from 'rrule/dist/esm/types';

import styles from './VisitForm.scss';

function constructDefaultVisit(): VisitType {
  return {
    organizationId: '',
    spaceId: '',
    isTimeRange: false,
    startDate: null,
    instructions: '',
    emailRecipients: [],
  };
}

function buildVisitor() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    id: String(Date.now()),
    status: '',
    deletable: true,
  };
}

interface VisitFormProps {
  content: ContentType;
  interaction?: {
    visitId: string;
    visitorId: string;
  };
}

export default function VisitForm({ content, interaction }: VisitFormProps) {
  const analytics = useContext(AnalyticsContext);
  const { refetchFocus, primaryChannel } = useContext(ChannelsContext);
  const { user } = useContext(UserDataContext);

  const [visit, setVisit] = useState<VisitType>(constructDefaultVisit());
  const buildingId: string = content?.integration?.settings?.buildingId;
  const [storedToken, setStoredToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [visitors, setVisitors] = useState<VisitorType[]>([buildVisitor()]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hideFromVisitor, setHideFromVisitor] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [spaces, setSpaces] = useState<DropdownValueProp[]>([]);
  const [spacesResponse, setSpacesResponse] = useState<SpacesResponseType>();
  const [organizations, setOrganizations] = useState<DropdownValueProp[]>([]);
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [defaultVisitor, setDefaultVisitor] = useState<VisitorType>();
  const [editVisitData, setEditVisitData] = useState<VisitEditType>({});
  const [disableButton, setDisableButton] = useState(true);
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>();
  const [frequency, setFrequency] = useState<string | null>(null);
  const [recurringVisit, setRecurringVisit] = useState<boolean>(false);
  const [recurringEnds, setRecurringEnds] = useState<string | null>(null);
  const [noOfOccurance, setNoOfOccurance] = useState<number | null>(null);
  const [isEndTimeValid, setIsEndTimeValid] = useState<boolean>(true);
  const [isRecurringVisitValid, setIsRecurringVisitValid] = useState<boolean>(
    true
  );
  const [rights, setRights] = useState<UsersRightsEnum[]>([]);

  const { t } = useTranslation();

  function updateVisit(props: Partial<VisitType>) {
    setVisit({
      ...visit,
      ...props,
    });
  }

  const updateEditVisit = (props: Partial<VisitEditType>) => {
    setEditVisitData({
      ...editVisitData,
      ...props,
    });
    setDisableButton(false);
  };

  const updateEmails = (emailToBeRemoved: string) => {
    const updatedEmailList = emails.filter(email => email !== emailToBeRemoved);
    setEmails(updatedEmailList);
    updateEditVisit({
      emailRecipients: updatedEmailList.map(email => ({ email })),
    });
    setDisableButton(false);
  };

  const getOnlyTime = (time: string): string => {
    const dateTimeWithTimeZone = DateTime.fromJSDate(new Date(time), {
      zone: timeZone,
    }).toISOTime();
    return dateTimeWithTimeZone.split('.')[0] || '';
  };

  const getRequiredSpaces = (
    tenantId: string,
    spacesData: { floors: FloorWithSpaces[] }
  ) => {
    const spaceIDs: DropdownValueProp[] = fetchRequiredSpaces(
      tenantId,
      spacesData?.floors
    );
    setSpaces(spaceIDs);
  };

  const setRecurringStates = (options: ParsedOptions) => {
    setRecurringVisit(true);
    switch (options.freq) {
      case Frequency.DAILY:
        if (!options.byweekday) {
          setFrequency('Daily');
        } else {
          setFrequency('WorkDays');
        }
        break;
      case Frequency.WEEKLY:
        setFrequency('Weekly');
        break;
      case Frequency.MONTHLY:
        if (options.interval === 1) {
          setFrequency('Monthly');
        } else {
          setFrequency('Quarterly');
        }
        break;
      case Frequency.YEARLY:
        setFrequency('Annual');
        break;
    }
    if (!options.count && !options.until) {
      setRecurringEnds(recurringEndsEnum.Never);
    } else if (options.count && !options.until) {
      setRecurringEnds(recurringEndsEnum.Occurance);
      setNoOfOccurance(options.count);
    } else if (options.until) {
      setRecurringEnds(recurringEndsEnum.SpecificDate);
    }
  };

  const hydrateForm = async (spacesData: { floors: FloorWithSpaces[] }) => {
    if (interaction) {
      const response = await visitClient.get(storedToken!, interaction.visitId);
      const visitData = await response.data;

      const userResponse = await userClient.currentUser(storedToken!);

      const { options }: RRule = rrulestr(visitData.schedule!);

      if (userResponse.ok) {
        const userData = await userResponse.data;
        setRights(userData.rights);
      }

      getRequiredSpaces(visitData.organization_id!, spacesData);

      if (options.until) {
        setEndDate(options.until);
      }
      setStartTime(visitData.start_time!);
      setEndTime(visitData.end_time!);
      setVisitors(visitData.contact_persons!);
      setDefaultVisitor(
        visitData.contact_persons!.find(
          visitor => visitor.visitor_id === interaction.visitorId
        )
      );
      if (visitData.email_recipients) {
        setEmails(visitData.email_recipients);
      }
      if (visitData.guard_instructions !== null) {
        setHideFromVisitor(true);
      }
      if (visitData.is_recurring) {
        setRecurringStates(options);
      }
      updateVisit({
        startDate: options.dtstart,
        endDate: options.until,
        startTime,
        endTime,
        instructions: visitData.instructions,
        spaceId: visitData.space_id,
        organizationId: visitData.organization_id,
        isTimeRange: !!(visitData.start_time && visitData.end_time),
      });
    }
  };
  const loadData = async () => {
    const organizationsData = await getOrganizationsData(
      storedToken!,
      buildingId
    );
    setOrganizations(organizationsData);
    const spacesData = await getSpacesData();
    setSpacesResponse(spacesData);
    await hydrateForm(spacesData);
    setLoading(false);
  };

  async function getSpacesData() {
    const responseFormData = await fetchWorkorderFormData(
      storedToken!,
      buildingId
    );
    return responseFormData;
  }

  useEffect(() => {
    if (!storedToken) {
      return;
    }
    loadData();
  }, [storedToken]);

  useEffect(() => {
    async function getTokenOnLoad() {
      setStoredToken(await getToken());
    }

    getTokenOnLoad();
  }, []);

  const timeZone = useMemo(
    () =>
      content?.geo
        ? getTimeZoneByGeoLocation({
            latitude: content.geo[1],
            longitude: content.geo[0],
          })
        : undefined,
    [content?._id, content?.geo]
  );

  const isEditableVisit = (visitor: VisitorType) => {
    const hasEditRights = rights.includes(
      userClient.UsersRightsEnum.CanManageVisits
    );
    return (
      hasEditRights &&
      defaultVisitor?.id === visitor?.id &&
      visitor.status === visitorStatus.Scheduled
    );
  };

  const isCancelable = (visitor: VisitorType) => {
    const hasCancelRights =
      rights.includes(userClient.UsersRightsEnum.CanCancelVisitor) ||
      rights.includes(userClient.UsersRightsEnum.CanManageVisits);
    return (
      hasCancelRights &&
      defaultVisitor?.id === visitor.id &&
      visitor.status === visitorStatus.Scheduled &&
      new Date(visit.startDate!)
        .toISOString()
        .slice(0, 10)
        .localeCompare(new Date().toISOString().slice(0, 10)) >= 0
    );
  };

  const headerContent = (visitor: VisitorType) => {
    return (
      <>
        <div className={styles.headerPills}>
          {' '}
          <StatusPill visitStatus={visitor.status!} />{' '}
        </div>
        <div className={styles.headerDisplay}>
          <div className={styles.headerPadding}>
            <span className={styles.visitorName}>
              {' '}
              {isEditableVisit(visitor)
                ? t('Edit Visit')
                : t('View Visit')} - {visitor!.first_name} {visitor!.last_name}{' '}
            </span>
          </div>
          <div className={styles.headerPadding}>
            {isCancelable(visitor) && (
              <Button
                variant="text"
                size="small"
                labelClassName={styles.buttonTextColor}
                onClick={() => {
                  handleCancel(visitor.visitor_id!);
                }}
              >
                {t('Cancel Visit')}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const endTimeValidationErrorComponent: ReactNode = (
    <ValidationMessage
      errors={[
        'shared.integrations.buildingEnginesVisitors.form.endTimeValidationError',
      ]}
    />
  );

  const frequencyValidationErrorComponent: ReactNode = (
    <ValidationMessage
      errors={[
        'shared.integrations.buildingEnginesVisitors.form.frequencyValidationError',
      ]}
    />
  );

  const visitEndOnValidationErrorComponent: ReactNode = (
    <ValidationMessage
      errors={[
        'shared.integrations.buildingEnginesVisitors.form.visitEndsOnValidationError',
      ]}
    />
  );

  const numberOfOccurencesValidationErrorComponent: ReactNode = (
    <ValidationMessage
      errors={[
        'shared.integrations.buildingEnginesVisitors.form.frequencyValidationError',
      ]}
    />
  );

  const endDateValidationErrorComponent: ReactNode = (
    <ValidationMessage
      errors={[
        'shared.integrations.buildingEnginesVisitors.form.visitEndsOnValidationError',
      ]}
    />
  );

  const validateRecurringVisit = (): boolean => {
    if (!frequency || !recurringEnds) {
      return false;
    }
    let isValid = true;
    switch (recurringEnds) {
      case 'specificDate':
        isValid = !!endDate;
        break;
      case 'occurance':
        isValid = !!noOfOccurance;
        break;
      default:
        break;
    }
    return isValid;
  };

  function validate() {
    try {
      setValidationErrors(null);
      let timeValidation = true;
      if (visit.isTimeRange) {
        timeValidation = validateStartAndEndTime({
          startTime,
          endTime,
        });
        setIsEndTimeValid(timeValidation);
      }
      let recurringValidation = true;
      if (recurringVisit) {
        recurringValidation = validateRecurringVisit();
        setIsRecurringVisitValid(recurringValidation);
      }
      yup
        .object()
        .shape(validationShape)
        .validateSync({ ...visit, visitors });
      return timeValidation || recurringValidation;
    } catch (err) {
      setValidationErrors(err);
      return false;
    }
  }

  function checkForRemoveButton() {
    const toShowRemoveButton = visitors.length > 1 && !defaultVisitor;
    return toShowRemoveButton;
  }

  const updateVisitor = (visitor: VisitorType) => {
    const visitorIndex: number = visitors.findIndex(v => v.id === visitor.id);
    const cloneVisitors = [...visitors];
    cloneVisitors[visitorIndex] = visitor;
    setVisitors(cloneVisitors);
  };

  const removeVisitor = (visitor: VisitorType) => {
    if (visitor.deletable) {
      const visitorIndex: number = visitors.findIndex(v => v.id === visitor.id);
      const cloneVisitors = [...visitors];
      cloneVisitors.splice(visitorIndex, 1);
      setVisitors(cloneVisitors);
    } else {
      updateVisitor({ ...visitor, status: visitorStatus.Canceled });
    }
  };

  const validVisitors = (visitors: VisitorType[]): VisitorType[] => {
    return defaultVisitor
      ? visitors.filter(
          visitor =>
            visitor.status !== visitorStatus.Canceled &&
            defaultVisitor.visitor_id !== visitor.visitor_id
        )
      : visitors.filter(visitor => visitor.status !== visitorStatus.Canceled);
  };

  const showVisitorCard = (visitors: VisitorType[]) => {
    return (
      <section className={styles.section}>
        <h3 className={styles.sectionHeader}> {t('Related Visitors')} </h3>
        <div className={styles.sectionContainer}>
          {validVisitors(visitors).map((visitor: VisitorType) => (
            <VisitorCard visitor={visitor} key={visitor.visitor_id} />
          ))}
        </div>
      </section>
    );
  };

  const scheduleWithRRule = ({
    freq,
    startDate,
    endDate,
    occurance,
    weekDays,
    interval,
  }: rRuleOptions) => {
    const byWeekDay = weekDays
      ? [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
      : null;
    if (freq === Frequency.DAILY && !endDate && !recurringVisit && !occurance) {
      occurance = 1;
    }
    const intervalObj = !interval ? {} : { interval };
    const defaultOptions = {
      freq,
      count: occurance,
      dtstart: startDate,
      byweekday: byWeekDay,
      until: endDate,
      interval,
    };
    const options = { ...defaultOptions, ...intervalObj };
    return new RRule(options).toString();
  };

  const mapFrequencyValue = (freq: string | null) => {
    switch (freq) {
      case 'Work Days':
        return FrequencyValue.WorkDays;
      case 'Weekly':
        return FrequencyValue.Weekly;
      case 'Monthly':
        return FrequencyValue.Monthly;
      case 'Quarterly':
        return FrequencyValue.Monthly;
      case 'Annual':
        return FrequencyValue.Annual;
      default:
        return FrequencyValue.Daily;
    }
  };

  const verfiyAndUpdateEmailList = (inputEmail: string) => {
    const isEmailExists: boolean = emails.includes(inputEmail);
    if (emailRegex.test(inputEmail) && !isEmailExists) {
      const updatedEmails = [...emails, inputEmail];
      setEmails(updatedEmails);
      updateEditVisit({
        emailRecipients: updatedEmails.map(email => ({
          email,
        })),
      });
      setCurrentEmail('');
    }
  };

  const renderRelevantInput = () => {
    switch (recurringEnds) {
      case 'specificDate':
        return (
          <>
            <DatePickerButton
              value={endDate}
              dateInputLabel={t('End Date')}
              minDate={visit.startDate || new Date()}
              onChange={date => {
                setEndDate(date);
                updateVisit({ endDate: date });
                updateEditVisit({ endDate: date });
              }}
              buttonClassName={styles.dateSelector}
              wrapperClassName={styles.flexGrow}
              disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
              hideOnSelect
            />
            {!isRecurringVisitValid &&
              !endDate &&
              endDateValidationErrorComponent}
          </>
        );
      case 'occurance':
        return (
          <>
            <ValidatedInput
              type="number"
              value={noOfOccurance}
              placeholder={t('Occurrences')}
              onChange={value => {
                setNoOfOccurance(Number(value));
                updateEditVisit({ occurance: Number(value) });
              }}
              showClear={defaultVisitor && !isEditableVisit(defaultVisitor)}
              disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
            />
            {!isRecurringVisitValid &&
              !noOfOccurance &&
              numberOfOccurencesValidationErrorComponent}
          </>
        );
      default:
        return <></>;
    }
  };

  function processVisitBeforeSubmit(
    visit: VisitType & { visitors: VisitorType[] }
  ) {
    const visitorsReqData: ContactPerson[] = visit.visitors.map(visitor => {
      delete visitor.deletable;
      delete visitor.id;
      delete visitor.status;
      return visitor;
    });
    const processedVisit: VisitReqData = {
      contact_persons: visitorsReqData,
      space_id: visit.spaceId,
      building_id: buildingId,
      organization_id: visit.organizationId,
      from: visit.startDate!,
      is_recurring: recurringVisit,
      schedule: scheduleWithRRule({
        startDate: visit.startDate!,
        endDate: visit.endDate!,
        freq: mapFrequencyValue(frequency),
        occurance: noOfOccurance,
        weekDays: frequency === 'WorkDays',
        interval: frequency === 'Quarterly' ? 3 : undefined,
      }),
      type: VISIT_TYPE,
      instructions: visit.instructions,
    };
    if (emails) {
      processedVisit.email_recipients = emails.map(email => ({ email }));
    }
    if (visit.instructions) {
      processedVisit.instructions = visit.instructions;
      if (hideFromVisitor) {
        processedVisit.guard_instructions = visit.instructions;
      }
    }
    if (visit.isTimeRange) {
      processedVisit.start_time = getOnlyTime(startTime);
      processedVisit.end_time = getOnlyTime(endTime);
    }
    return processedVisit;
  }

  async function createNewVisit() {
    setHasAttemptedSubmit(true);

    if (!validate()) {
      return;
    }
    const cloneVisitors = cloneDeep(visitors);
    const processedVisit = processVisitBeforeSubmit({
      ...visit,
      visitors: cloneVisitors,
    });
    visitClient
      .create(storedToken!, processedVisit)
      .then(_response => {
        setSubmitLoading(true);
        history.goBack();
        refetchFocus();

        window.Alert.alert({
          title: t('Visit has been created'),
          message: t('Congrats! Your visit was successfully created.'),
        });

        if (user && primaryChannel) {
          const payload = {
            userId: user._id,
            channelId: primaryChannel._id,
            integrationId: content.integration._id,
            analytics,
          };

          emitVisitCreated(payload);
        }
      })
      .catch(err => {
        window.Alert.alert({
          title: t('Something went wrong'),
          error: String(getErrorMessage(err.cause, '')),
        });
      });

    setTimeout(() => setSubmitLoading(false), 1000);
  }

  const handleCancel = async (visitorId: string) => {
    visitClient
      .cancel(storedToken!, visitorId)
      .then(_response => {
        setSubmitLoading(true);
        history.goBack();

        window.Alert.alert({
          title: t('Visit has been cancelled'),
          message: t('Your visit has been successfully cancelled.'),
        });
      })
      .catch(err => {
        window.Alert.alert({
          title: t('Something went wrong'),
          error: err,
        });
      });
    setTimeout(() => setSubmitLoading(false), 1000);
  };

  const processEditData = () => {
    const procesedEditData: VisitPatchData = {};

    if (editVisitData.organizationId) {
      procesedEditData.organization_id = editVisitData.organizationId;
    }
    if (editVisitData.spaceId) {
      procesedEditData.space_id = editVisitData.spaceId;
    }
    procesedEditData.is_recurring = editVisitData.isRecurring;
    if (
      editVisitData.startDate ||
      editVisitData.endDate ||
      editVisitData.recurringEnds ||
      editVisitData.occurance ||
      editVisitData.frequency
    ) {
      const startDate = editVisitData.startDate
        ? editVisitData.startDate
        : visit.startDate;
      procesedEditData.schedule = scheduleWithRRule({
        startDate: startDate!,
        endDate: endDate!,
        freq: mapFrequencyValue(frequency),
        occurance: noOfOccurance,
        weekDays: frequency === 'WorkDays',
        interval: frequency === 'Quarterly' ? 3 : undefined,
      });
    }
    if (editVisitData.instructions === '' || editVisitData.instructions) {
      procesedEditData.instructions = editVisitData.instructions;
      if (hideFromVisitor) {
        procesedEditData.guard_instructions = editVisitData.instructions;
      } else {
        procesedEditData.guard_instructions = null;
      }
    }
    if (editVisitData.startTime) {
      procesedEditData.start_time = getOnlyTime(editVisitData.startTime);
    }
    if (editVisitData.endTime) {
      procesedEditData.end_time = getOnlyTime(editVisitData.endTime);
    }
    if (editVisitData.emailRecipients) {
      procesedEditData.email_recipients = editVisitData.emailRecipients;
    }
    return procesedEditData;
  };

  const handleUpdate = async (visitId: string) => {
    setHasAttemptedSubmit(true);
    setSubmitLoading(true);
    const processedData = processEditData();
    visitClient
      .update(storedToken!, visitId, processedData)
      .then(_response => {
        history.goBack();

        window.Alert.alert({
          title: t('Visit has been updated'),
          message: t('Your visit has been successfully updated.'),
        });
      })
      .catch(err => {
        window.Alert.alert({
          title: t('Something went wrong'),
          error: String(getErrorMessage(err.cause, '')),
        });
      });
    setTimeout(() => setSubmitLoading(false), 1000);
  };

  const submitButton = () => {
    return (
      <div className={styles.buttonContainer}>
        {defaultVisitor && isEditableVisit(defaultVisitor) ? (
          <Button
            onClick={() => handleUpdate(interaction!.visitId)}
            loading={submitLoading}
            className={styles.updateButton}
            disabled={disableButton}
          >
            {t('Edit Visit')}
          </Button>
        ) : (
          defaultVisitor === undefined && (
            <Button
              onClick={() => createNewVisit()}
              loading={submitLoading}
              className={styles.updateButton}
            >
              {t('Create Visit')}
            </Button>
          )
        )}
      </div>
    );
  };

  if (loading) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form}>
          {defaultVisitor ? (
            <>{headerContent(defaultVisitor)}</>
          ) : (
            <h3 className={styles.title}>{t('Create New Visit')}</h3>
          )}
          <section className={styles.section}>
            {!defaultVisitor ? (
              <>
                {validVisitors(visitors).map(
                  (visitor: VisitorType, index: number) => (
                    <VisitorForm
                      visitor={visitor}
                      index={index}
                      showRemove={checkForRemoveButton()}
                      key={visitor.id}
                      update={updateVisitor}
                      removeVisitor={removeVisitor}
                      hasAttemptedSubmit={hasAttemptedSubmit}
                      isEditableVisitor={!defaultVisitor}
                      validationErrors={validationErrors}
                    />
                  )
                )}
                <Button
                  onClick={() => {
                    const newVisitor = buildVisitor();
                    const updatedVisitorsList = visitors.concat(newVisitor);
                    setVisitors(updatedVisitorsList);
                  }}
                  variant="text-icon"
                  startIcon={<Icon name="Plus" />}
                >
                  {' '}
                  {t('Add another visitor')}
                </Button>
              </>
            ) : (
              <>
                <VisitorForm
                  visitor={defaultVisitor}
                  index={0}
                  showRemove={checkForRemoveButton()}
                  key={defaultVisitor.id}
                  update={updateVisitor}
                  removeVisitor={removeVisitor}
                  hasAttemptedSubmit={hasAttemptedSubmit}
                  isEditableVisitor={!defaultVisitor}
                  validationErrors={validationErrors}
                />
                {validVisitors(visitors) && showVisitorCard(visitors)}
              </>
            )}
          </section>

          <section className={styles.section}>
            <h3 className={styles.sectionHeader}> {t('Location')} </h3>
            <div className={styles.sectionContainer}>
              <ValidatedDropdown
                name="organizationId"
                validation={validationShape.organizationId}
                isPristine={!hasAttemptedSubmit}
                containerClassName={styles.dropdown}
                placeholder={t('Organization')}
                value={visit.organizationId}
                items={organizations}
                onValueChange={value => {
                  updateVisit({ organizationId: value, spaceId: '' });
                  getRequiredSpaces(value, spacesResponse!);
                }}
                isFullWidth
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
              />
              <ValidatedDropdown
                name="spaceId"
                validation={validationShape.spaceId}
                isPristine={!hasAttemptedSubmit}
                containerClassName={styles.dropdown}
                placeholder={t('Floor/Space')}
                value={visit.spaceId}
                items={spaces}
                onValueChange={value => {
                  updateVisit({ spaceId: value });
                  updateEditVisit({ spaceId: value });
                }}
                isFullWidth
                disabled={
                  !visit.organizationId ||
                  (defaultVisitor && !isEditableVisit(defaultVisitor))
                }
              />
            </div>
          </section>
          <section className={styles.section}>
            <h3 className={styles.sectionHeader}> {t('Visit Schedule')} </h3>
            <div className={styles.sectionContainer}>
              <DatePickerButton
                value={visit.startDate ? visit.startDate : null}
                dateInputLabel={t('Select Start Date')}
                minDate={new Date()}
                onChange={date => {
                  updateVisit({
                    startDate: date,
                  });
                  updateEditVisit({ startDate: date });
                  if (endDate && date > endDate) {
                    updateVisit({
                      endDate: null,
                      startDate: date,
                    });
                    setEndDate(null);
                  }
                }}
                wrapperClassName={styles.flexGrow}
                buttonClassName={styles.dateSelector}
                dateError={
                  getValidationMessages(validationErrors, 'startDate') || []
                }
                className={styles.datePicker}
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
                hideOnSelect
              />
              <div className={styles.radioGroupSection}>
                <RadioGroup
                  items={dataOptions}
                  selected={visit.isTimeRange ? '2' : '1'}
                  onChange={selected => {
                    if (selected === '1') {
                      updateVisit({ isTimeRange: false });
                      updateEditVisit({ isTimeRange: false });
                    } else {
                      updateVisit({ isTimeRange: true });
                      updateEditVisit({ isTimeRange: true });
                    }
                  }}
                  disabled={
                    defaultVisitor &&
                    (!isEditableVisit(defaultVisitor) || visit.isTimeRange)
                  }
                />
              </div>
              <DatePickerButton
                value={endDate}
                dateInputLabel={t('Select End Date')}
                minDate={visit.startDate || new Date()}
                onChange={date => {
                  setEndDate(date);
                  updateVisit({ endDate: date });
                  updateEditVisit({ endDate: date });
                }}
                buttonClassName={styles.dateSelector}
                wrapperClassName={styles.flexGrow}
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
                hideOnSelect
              />
              {visit.isTimeRange && (
                <>
                  <TimePicker
                    value={startTime}
                    timeZone={timeZone}
                    label={t('Select Start Time')}
                    buttonClassName={styles.timeSelector}
                    className={styles.timeSelector}
                    showClear={false}
                    onChange={time => {
                      setStartTime(time);
                      updateEditVisit({ startTime: time });
                      setIsEndTimeValid(true);
                    }}
                    disabled={
                      defaultVisitor && !isEditableVisit(defaultVisitor)
                    }
                  />
                  <TimePicker
                    value={endTime}
                    timeZone={timeZone}
                    label={t('Select End Time')}
                    buttonClassName={styles.timeSelector}
                    showClear={false}
                    className={styles.timeSelector}
                    onChange={time => {
                      setEndTime(time);
                      updateEditVisit({ endTime: time });
                      setIsEndTimeValid(true);
                    }}
                    disabled={
                      defaultVisitor && !isEditableVisit(defaultVisitor)
                    }
                  />
                  {!isEndTimeValid && endTimeValidationErrorComponent}
                </>
              )}
              <Checkbox
                className={styles.checkbox}
                text={t('Recurring Visit')}
                value={recurringVisit}
                selected={recurringVisit}
                onChange={selected => {
                  setRecurringVisit(!selected);
                  updateEditVisit({ isRecurring: !selected });
                  if (selected) {
                    setNoOfOccurance(null);
                    setRecurringEnds(null);
                    setFrequency(null);
                  }
                }}
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
              />
              {recurringVisit && (
                <>
                  <Dropdown
                    className={styles.timeSelector}
                    value={frequency}
                    items={recurringFrequency}
                    placeholder={t('Frequency')}
                    onValueChange={value => {
                      setFrequency(value);
                      updateEditVisit({ frequency: value });
                    }}
                    name="frequency"
                  />
                  {!isRecurringVisitValid &&
                    !frequency &&
                    frequencyValidationErrorComponent}
                  <Dropdown
                    className={styles.timeSelector}
                    value={recurringEnds}
                    items={recurringEndsValue}
                    placeholder={t('Ends')}
                    onValueChange={value => {
                      setRecurringEnds(value);
                      updateEditVisit({ recurringEnds: value });
                      if (value === 'specificDate') {
                        setNoOfOccurance(null);
                      } else if (value === 'occurance') {
                        setEndDate(null);
                      } else if (value === 'never') {
                        setNoOfOccurance(null);
                        setEndDate(null);
                      }
                    }}
                    name="recurringEnds"
                  />
                  {!isRecurringVisitValid &&
                    !recurringEnds &&
                    visitEndOnValidationErrorComponent}
                  {renderRelevantInput()}
                </>
              )}
            </div>
          </section>
          <section className={styles.section}>
            <h3 className={styles.sectionHeader}>{t('Additional Details')}</h3>
            <div className={styles.sectionContainer}>
              <ValidatedTextArea
                placeholder={
                  definition.interactionData?.instructions?.description
                }
                className="textArea"
                onChange={value => {
                  updateVisit({ instructions: value });
                  updateEditVisit({ instructions: value });
                  if (!value) {
                    setHideFromVisitor(false);
                  }
                }}
                minRows={5}
                value={visit.instructions}
                validation={validationShape.instructions}
                isPristine={!hasAttemptedSubmit}
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
                showClear={false}
              />
              <Checkbox
                className={styles.checkbox}
                text={t('Hide instructions from visitors')}
                value={hideFromVisitor}
                selected={hideFromVisitor}
                onChange={selected => {
                  setHideFromVisitor(!selected);
                }}
                disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
              />
            </div>
          </section>
          <section className={styles.section}>
            <h3 className={styles.sectionHeader}>
              {t('Notify when visitor checks in')}
            </h3>
            <div className={styles.sectionContainer}>
              <div className={styles.emailContainer}>
                {emails.map((email, index) => (
                  <EmailChip
                    email={email}
                    removeEmail={updateEmails}
                    key={index}
                    isEditableVisit={
                      defaultVisitor && !isEditableVisit(defaultVisitor)
                    }
                  />
                ))}
                <Input
                  inputClassName={styles.transparentBorder}
                  type="text"
                  showClear={false}
                  value={currentEmail}
                  onChange={value => {
                    setCurrentEmail(value);
                  }}
                  onKeyPress={event => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                      verfiyAndUpdateEmailList(event.target.value);
                    }
                  }}
                  onBlur={event => {
                    verfiyAndUpdateEmailList(event.target.value);
                  }}
                  dataCy="emailInput"
                  disabled={defaultVisitor && !isEditableVisit(defaultVisitor)}
                />
              </div>
            </div>
          </section>
          {submitButton()}
        </div>
      </div>
    </div>
  );
}
