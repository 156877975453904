import React, { useMemo } from 'react';

import { Control } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { HookFormCheckBox } from '../../../../components/HookFormCheckBox';

const DEFAULT_AGREEMENT_LINKS_THRESHOLD = 2;

const TRANSLATION_KEYS = {
  customLabel: 'shared.onboarding.userProfile.customerAgreements',
  defaultLabel: 'shared.onboarding.userProfile.defaultAgreements',
};

type ReducedWhiteLabel = Pick<
  WhiteLabelType,
  'termsAndConditionsUrls' | 'privacyPolicyUrls' | 'name'
>;

type Props = {
  control: Control<any>;
  whitelabel: ReducedWhiteLabel;
};

function isMissingTermsOrPrivacyURLs(whitelabel: ReducedWhiteLabel) {
  const hasTermsAndConditionsUrls =
    whitelabel.termsAndConditionsUrls?.length > 0;
  const hasPrivacyPolicyUrls = whitelabel.privacyPolicyUrls?.length > 0;

  return !(hasTermsAndConditionsUrls && hasPrivacyPolicyUrls);
}

export function TermsAndConditionsOptIn({ control, whitelabel }: Props) {
  const agreementLinks = useMemo(() => {
    const links: JSX.Element[] = [];

    if (isMissingTermsOrPrivacyURLs(whitelabel)) {
      return links;
    }

    links.push(createLink(whitelabel.termsAndConditionsUrls[0]));
    links.push(createLink(whitelabel.privacyPolicyUrls[0]));

    if (whitelabel.privacyPolicyUrls.length > 1) {
      links.unshift(createLink(whitelabel.privacyPolicyUrls[1]));
    }

    if (whitelabel.termsAndConditionsUrls.length > 1) {
      links.unshift(createLink(whitelabel.termsAndConditionsUrls[1]));
    }

    return links;
  }, [whitelabel]);

  function createLink(redirectUrl?: string) {
    return (
      <Link
        key={redirectUrl}
        data-testid="link-to-parent"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'inherit' }}
        to={{ pathname: redirectUrl }}
      />
    );
  }

  if (agreementLinks.length === 0) {
    return null;
  }

  const hasCustomerLinks =
    agreementLinks.length > DEFAULT_AGREEMENT_LINKS_THRESHOLD;
  const label = hasCustomerLinks
    ? TRANSLATION_KEYS.customLabel
    : TRANSLATION_KEYS.defaultLabel;

  return (
    <HookFormCheckBox
      control={control}
      name="termsAndConditionsOptIn"
      text={
        <Trans
          i18nKey={label}
          components={agreementLinks}
          values={{
            whiteLabelName: whitelabel?.name,
          }}
        />
      }
      isRequired
      labelStyles={{
        fontSize: '14px',
        fontWeight: 400,
        marginLeft: '10px',
      }}
    />
  );
}
