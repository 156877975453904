import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, IconButton } from 'components';
import { Button } from 'design-system-web';
import {
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
  ProductItem,
  ExternalPayerType,
} from 'lane-shared/domains/billingPayments/types';
import { SupportedLocaleEnum } from 'localization';

import { AddProductFormModal } from '../AddProductFormModal';
import { ChargeSummary } from '../ChargeSummary/ChargeSummary';
import { DraftItemsTable } from '../DraftItemsTable/DraftItemsTable';

import styles from './DraftChargeEditor.scss';
import { ConfirmationModal } from 'lane-web/src/components/general/ConfirmationModal';
import { useBillingPaymentsPermissions } from 'lane-shared/domains/billingPayments/hooks';

export function DraftChargeEditor({
  items,
  showEmpty,
  locale,
  currency,
  validation,
  payerId,
  payerType,
  submitDraftCharge,
  addDraftProductItem,
  updateDraftProductItem,
  deleteDraftProductItem,
}: {
  items: ProductItem[];
  showEmpty: boolean;
  locale: SupportedLocaleEnum;
  currency: SupportedPaymentCurrency;
  validation?: string;
  payerId: string;
  payerType: ExternalPayerType;
  submitDraftCharge: () => Promise<void>;
  addDraftProductItem: (product: AddProductToChargeDetails) => void;
  updateDraftProductItem: (index: number, updatedProduct: ProductItem) => void;
  deleteDraftProductItem: (index: number) => void;
}) {
  const { t } = useTranslation();
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [submitChargeModalOpen, setSubmitChargeModalOpen] = useState(false);

  const { canSubmitCharges } = useBillingPaymentsPermissions();

  const onAddProduct = (product: AddProductToChargeDetails) => {
    addDraftProductItem(product);
  };

  const handleSubmit = async () => {
    submitDraftCharge();
    setSubmitChargeModalOpen(false);
    await window.Toast.show(t('abp.charges.chargeManager.draft.submitToast'));
  };

  const addProductButton = (
    <IconButton
      icon="Plus"
      className={styles.AddProductButton}
      size="large"
      onClick={() => setAddProductModalOpen(true)}
      text={t('abp.productsServices.table.add')}
      dataCy="add-product-button"
    />
  );

  return (
    <>
      {showEmpty && items.length === 0 ? (
        <Flex p={[2, 0, 0, 0]}>{addProductButton}</Flex>
      ) : (
        <Flex className={styles.DraftCharge} gap={5}>
          <DraftItemsTable
            items={items}
            locale={locale}
            addProductButton={addProductButton}
            updateDraftProductItem={updateDraftProductItem}
            deleteDraftProductItem={deleteDraftProductItem}
            currency={currency}
          />
          <ChargeSummary items={items} locale={locale} currency={currency}>
            {canSubmitCharges && items.length > 0 ? (
              <Button
                variant="primary"
                className={styles.SubmitChargeButton}
                onClick={() => setSubmitChargeModalOpen(true)}
                style={{ borderColor: 'transparent' }}
                dataCy="submit-charge-button"
                disabled={Boolean(validation)}
              >
                {t('abp.charges.chargeManager.draftCharge.submitChargeButton')}
              </Button>
            ) : undefined}
          </ChargeSummary>
        </Flex>
      )}
      <AddProductFormModal
        onAddProduct={onAddProduct}
        currency={currency}
        isOpen={addProductModalOpen}
        onClose={() => setAddProductModalOpen(false)}
        payerId={payerId}
        payerType={payerType}
        dataCy="add-product-form-modal"
      />
      <ConfirmationModal
        isOpen={submitChargeModalOpen}
        onClose={() => setSubmitChargeModalOpen(false)}
        handleConfirm={handleSubmit}
        cancelText={t(
          'abp.charges.chargeManager.draftCharge.submitModal.cancelButton'
        )}
        confirmText={t(
          'abp.charges.chargeManager.draftCharge.submitModal.submitButton'
        )}
        title={t('abp.charges.chargeManager.draftCharge.submitModal.title')}
        description={t(
          'abp.charges.chargeManager.draftCharge.submitModal.description'
        )}
      />
    </>
  );
}
