import {
  ExternalPayerType,
  MarkupAmountType,
  TaxAmountType,
  ProductType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import React from 'react';
import useUserLocale from 'hooks/useUserLocale';
import { H4, M } from 'components/typography';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { Button } from 'design-system-web';
import { PriceException } from '../PriceException';

import styles from './AddProductForm.scss';
import { useAddProductForm } from 'lane-shared/domains/billingPayments/hooks';
import { useChannelAdminContext } from 'hooks';

export function AddProductForm({
  addProduct,
  closeModal,
  currency,
  payerId,
  payerType,
}: {
  addProduct: Function;
  closeModal: Function;
  currency: SupportedPaymentCurrency;
  payerId: string;
  payerType: ExternalPayerType;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { channel } = useChannelAdminContext();

  const {
    productType,
    productDetails,
    updateProductType,
    getCategoryItems,
    onCategorySelection,
    getProductItems,
    onProductSelection,
    resetProductDetails,
    handleProductDetailsUpdate,
  } = useAddProductForm({
    currency,
    locale,
    channel,
    payerId,
    payerType,
  });

  const resetAndCloseModal = () => {
    resetProductDetails();
    closeModal();
  };

  const onAddProductClick = () => {
    addProduct(productDetails);
    resetAndCloseModal();
  };

  const onCancelClick = () => {
    resetAndCloseModal();
  };

  const productTypes = [
    {
      label: 'abp.charges.addProduct.modal.type.service',
      value: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
    },
    {
      label: 'abp.charges.addProduct.modal.type.product',
      value: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
    },
  ];

  const currencyFormat = currencyFormatter({
    currency,
    locale,
  });

  const isSubmitEnabled =
    productType &&
    productDetails.productCategoryId &&
    productDetails.productId &&
    Number(productDetails.quantity) > 0;

  return (
    <div className={styles.addProductForm}>
      <div className={styles.inputContainer}>
        <H4 mb={5}>{t('abp.charges.addProduct.modal.header')}</H4>
        <Dropdown
          dataCy="typeDropdown"
          value={productType}
          items={productTypes}
          onChange={item => {
            updateProductType(item.value);
          }}
          label={t('abp.productsServices.createProduct.typeLabel')}
          isRequired
          fixedLabel
          className={styles.dropdown}
        />
        <Dropdown
          dataCy="categoryDropdown"
          value={productDetails.productCategoryId}
          placeholder={t(
            'abp.productsServices.createProduct.category.placeholder'
          )}
          doTranslation={false}
          items={getCategoryItems()}
          onChange={onCategorySelection}
          label={t('abp.charges.addProduct.modal.categoryInputLabel')}
          isRequired
          fixedLabel
          className={styles.dropdown}
        />
        <Dropdown
          dataCy="productDropdown"
          value={productDetails.productId}
          placeholder={t(
            'abp.charges.addProduct.modal.productInputPlaceholder'
          )}
          doTranslation={false}
          items={getProductItems()}
          onChange={onProductSelection}
          label={t('abp.charges.addProduct.modal.itemLabel')}
          isRequired
          fixedLabel
          disabled={Boolean(!productDetails.productCategoryId)}
          className={styles.dropdown}
        />
        <div className={styles.pricingContainer}>
          <Input
            label={t('abp.charges.addProduct.modal.quantityLabel')}
            fixedLabel
            isRequired
            value={productDetails.quantity}
            onChange={(value: string) =>
              handleProductDetailsUpdate(
                value.replace(/[^0-9]/g, ''),
                'quantity'
              )
            }
            dataCy="productQuantityInput"
            showClear={false}
            className={styles.quantityInput}
            type="number"
          />
          <div className={styles.amountContainer}>
            <label className={styles.label}>
              {t('abp.charges.addProduct.modal.rateLabel')}
            </label>
            <div className={styles.amount}>
              {productDetails.originalPrice && (
                <PriceException
                  rate={Number(productDetails.netPrice)}
                  taxValue={Number(productDetails.tax.value)}
                  taxType={productDetails.tax.type}
                  originalPrice={{
                    amount: Number(productDetails.originalPrice.rate),
                    tax: {
                      value: Number(productDetails.originalPrice.tax?.value),
                      taxAmountType: productDetails.originalPrice.tax
                        ?.type as TaxAmountType,
                    },
                    markup: {
                      value: Number(productDetails.originalPrice.markup?.value),
                      markupAmountType: productDetails.originalPrice.markup
                        ?.type as MarkupAmountType,
                    },
                  }}
                  currency={currency}
                  locale={locale}
                  dataCy="priceException"
                />
              )}
              <M className={styles.amountText}>
                {productDetails.quantity && Number(productDetails.netPrice) >= 0
                  ? currencyFormat(Number(productDetails.netPrice))
                  : '-'}
              </M>
            </div>
          </div>
          <div className={styles.amountContainer}>
            <label className={styles.label}>
              {t('abp.charges.addProduct.modal.totalLabel')}
            </label>
            <div className={styles.amount}>
              <M className={styles.amountText}>
                {productDetails.quantity && Number(productDetails.total) >= 0
                  ? currencyFormat(Number(productDetails.total))
                  : '-'}
              </M>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          dataCy="cancelButton"
          type="button"
          onClick={onCancelClick}
          size="large"
          variant="secondary"
          className={styles.button}
        >
          {t('abp.cancel')}
        </Button>
        <Button
          dataCy="addButton"
          type="submit"
          onClick={onAddProductClick}
          size="large"
          variant="primary"
          disabled={!isSubmitEnabled}
          className={styles.button}
        >
          {t('abp.charges.addProduct.modal.addButton')}
        </Button>
      </div>
    </div>
  );
}
