import React, { useImperativeHandle, useState, forwardRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import XPlacesAutocomplete, {
  geocodeByAddress,
  getLatLng, // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
} from 'react-places-autocomplete';
import { Key } from 'ts-key-enum';

import { AddressType } from 'lane-shared/types/AddressType';

import useModalPosition, { ModalPositionEnum } from 'hooks/useModalPosition';

import styles from './PlacesAutocompleteInline.scss';

function getComponent(components: any, name: any) {
  const component = components
    .filter((c: any) => c != null)
    .find((component: any) =>
      component.types.find((type: any) => type === name)
    );

  return component && component.long_name ? component.long_name : '';
}

export type PlacesAutocompleteHandle = {
  focus: () => void;
  blur: () => void;
  click: () => void;
};

type Props = {
  style?: React.CSSProperties;
  className?: string;
  value: string;
  fieldname?: string;
  label?: string;
  placeholder?: string;
  onChange: (address: AddressType) => void;
  disabled?: boolean;
  dataCy?: string;
  isRequired?: boolean;
  error?: string;
};

const PlacesAutocompleteV2 = forwardRef<PlacesAutocompleteHandle, Props>(
  (
    {
      style,
      className,
      value,
      fieldname,
      label = 'Address',
      placeholder = 'Search for address',
      onChange,
      disabled,
      dataCy,
      isRequired,
      error,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState(value);
    const {
      buttonRef,
      modalRef,
      childrenRef,
      onOpen,
      position,
    } = useModalPosition({
      autoFocus: false,
      align: ModalPositionEnum.Dropdown,
    });

    function clearHandler() {
      setSearch('');
    }

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.focus();
        }
      },
      blur: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.blur();
        }
      },
      click: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.click();
        }
      },
    }));

    async function selected(item: any) {
      const results = await geocodeByAddress(item);
      const location = await getLatLng(results[0]);
      const components = results[0].address_components;

      const address: AddressType = {
        street1: `${getComponent(components, 'street_number')} ${getComponent(
          components,
          'route'
        )}`,
        city: getComponent(components, 'locality'),
        state: getComponent(components, 'administrative_area_level_1'),
        country: getComponent(components, 'country'),
        code: getComponent(components, 'postal_code'),
        geo: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ longitude: any; latitude: any; }' is not a... Remove this comment to see the full error message
          longitude: location.lng,
          latitude: location.lat,
        },
      };

      setSearch(item);
      onChange(address);
    }

    const isTouched = String(search).length > 0;

    const requiredFieldAsteriskSymbol = '*';

    return (
      <div
        className={cx(styles.PlacesAutocomplete, className)}
        style={style}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
        ref={buttonRef}
        data-cy={dataCy}
      >
        <label htmlFor={fieldname} className={styles.label}>
          {t(label)}
          {isRequired ? (
            <span className={styles.InputLabelRequiredAstrisk}>
              {requiredFieldAsteriskSymbol}
            </span>
          ) : null}
        </label>
        <XPlacesAutocomplete
          value={search}
          onChange={setSearch}
          onSelect={selected}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
            <div className={styles.search}>
              <input
                {...getInputProps({
                  placeholder,
                  className: styles.input,
                })}
                name={fieldname}
                label={label}
                aria-label={!label ? fieldname : undefined}
                value={search}
                onClick={onOpen}
                disabled={disabled}
                ref={childrenRef}
                data-touched={isTouched}
                data-cy="placeAutocompleteSearch"
                data-error={Boolean(error)}
              />
              {suggestions.length > 0 &&
                ReactDOM.createPortal(
                  <ul
                    className={styles.results}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
                    ref={modalRef}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; left: number; height?: number... Remove this comment to see the full error message
                    style={position}
                    data-cy="addressDropdown"
                  >
                    {suggestions.map((suggestion: any) => (
                      <li
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion)}
                        data-is-active={suggestion.active}
                      >
                        <span>{suggestion.description}</span>
                      </li>
                    ))}
                  </ul>,
                  document.body
                )}
              <div className={cx(styles.iconWrapper, styles.leftIconWrapper)}>
                <Icon className={styles.icon} name="search" />
              </div>
              {isTouched && !error && (
                <div
                  className={cx(
                    styles.iconWrapper,
                    styles.rightIconWrapper,
                    styles.interactive
                  )}
                  role="button"
                  tabIndex={0}
                  onKeyDown={e => e.key === Key.Enter && clearHandler()}
                  onClick={clearHandler}
                >
                  <Icon
                    className={styles.icon}
                    name="times"
                    dataCy="clearIcon"
                  />
                </div>
              )}
              {error && (
                <div
                  className={cx(styles.iconWrapper, styles.rightIconWrapper)}
                >
                  <Icon
                    className={cx(styles.icon, styles.errorIcon)}
                    name="exclamation-circle"
                    set="FontAwesome"
                    dataCy="errorIcon"
                  />
                </div>
              )}
            </div>
          )}
        </XPlacesAutocomplete>
        {error && (
          <div className={styles.errorContainer}>
            <p
              className={styles.error}
              data-cy="placesAutocompleteInlineErrorMessage"
            >
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

export default PlacesAutocompleteV2;
