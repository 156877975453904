import React, { useEffect, useState } from 'react';

import { useChannelAdminContext } from 'hooks';
import { useParams } from 'react-router-dom';

import { getInvoiceDetails } from 'lane-shared/domains/billingPayments/helpers/getInvoiceDetails';
import {
  ChargePartial,
  Invoice,
} from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';

import { AdminPage } from 'lane-web/src/components/layout';
import { ChargeList } from 'lane-web/src/domains/billingAndPayments/components/InvoiceDetails/ChargeList';
import { Info } from 'lane-web/src/domains/billingAndPayments/components/InvoiceDetails/Info';
import { InvoiceTotal } from 'lane-web/src/domains/billingAndPayments/components/InvoiceDetails/InvoiceTotal';
import { InvoiceDetailsPageHeader } from './InvoiceDetailsPageHeader';

import Loading from 'components/general/Loading';

import styles from './styles.scss';

export function InvoiceDetails() {
  const { channel } = useChannelAdminContext();
  const channel_id = channel?._id;
  const { invoiceDisplayId } = useParams<{ invoiceDisplayId: string }>();
  const [invoiceDetails, setInvoiceDetails] = useState<{
    invoice: Invoice | undefined;
    charges: ChargePartial[];
  }>();

  useEffect(() => {
    if (channel_id) {
      const fetchData = async () => {
        const invoiceDetailsResponse = await getInvoiceDetails(
          convertToUUID(channel_id),
          invoiceDisplayId
        );
        setInvoiceDetails(invoiceDetailsResponse);
      };

      fetchData().catch(console.error);
    }
  }, [channel_id, invoiceDisplayId]);

  const invoice = invoiceDetails?.invoice;
  const charges = invoiceDetails?.charges || [];
  const statusMap = invoice
    ? getInvoiceStatus(invoice.status, invoice)
    : ['', ''];
  const isPaid = statusMap[0] === 'abp.invoiceList.status.paid';

  return (
    <AdminPage>
      {invoice && channel ? (
        <>
          <InvoiceDetailsPageHeader
            channelId={channel._id}
            invoice={invoice}
            statusMap={statusMap}
          />
          <div className={styles.divider} />
          <Info invoice={invoice} isPaid={isPaid} />
          <ChargeList invoice={invoice} charges={charges} />
          <InvoiceTotal invoice={invoice} charges={charges} />
        </>
      ) : (
        <Loading />
      )}
    </AdminPage>
  );
}
