import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { gql, useLazyQuery } from '@apollo/client';

import { PaymentMethod } from 'graphql-resolver-contracts';
import {
  ListTransactionsRequest,
  Transaction,
  CurrencyFormatMap,
  currencyFromJSON,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { dates } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';
import type { PillColor } from 'lane-shared/types/general/pill';

import { PageSizeType, Sort, Table, Column } from 'design-system-web';
import { TabStrip } from 'lane-web/src/components/general';
import Pill from 'lane-web/src/components/general/Pill';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import ChannelAdminContext from 'lane-web/src/pages/portal/admin/channel/ChannelAdminContext';

import styles from './styles.scss';

enum TransactionsReportsTabs {
  Transactions = 'transactions',
}

const listTransactionsQuery = gql`
  query listTransactions($listTransactionsRequest: ListTransactionsRequest!) {
    accounts {
      listTransactions(listTransactionsRequest: $listTransactionsRequest) {
        transactions {
          paidBy
          productName
          paymentType
          amount
          currency
          createdAt
          updatedAt
          status
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

export function TransactionsReports() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);

  const DEFAULT_SORT_KEY = 'created_at';
  const DEFAULT_SORT_DIRECTION = 'desc';

  const [sort, setSort] = useState<Sort>({
    id: DEFAULT_SORT_KEY,
    direction: DEFAULT_SORT_DIRECTION,
  });

  const paymentTypeFormatMap = {
    [PaymentMethod.PaymentMethodUnknown]: t(
      'abp.transaction.paymentType.Unknown'
    ),
    [PaymentMethod.PaymentMethodCreditCard]: t(
      'abp.transaction.paymentType.CreditCard'
    ),
    [PaymentMethod.PaymentMethodCredits]: t(
      'abp.transaction.paymentType.Credits'
    ),
    [PaymentMethod.PaymentMethodApplePay]: t(
      'abp.transaction.paymentType.ApplePay'
    ),
  };

  const statusMap: { [key: string]: [string, PillColor] } = {
    SUCCEEDED: [t('abp.transaction.status.paid'), 'green'],
    FAILED: [t('abp.transaction.status.failed'), 'red'],
    REFUNDING: [t('abp.transaction.status.refunding'), 'honey'],
    REFUNDED: [t('abp.transaction.status.refunded'), 'red'],
  };

  const { channel } = useContext(ChannelAdminContext);
  const transactionColumns: Column<Transaction>[] = [
    {
      key: 'status',
      header: t('abp.transaction.status'),
      disableSorting: true,
      renderCell: (cell: string) => (
        <Pill
          text={statusMap[cell]?.[0] || t('abp.transaction.status.processing')}
          color={statusMap[cell]?.[1] || 'honey'}
          className={styles.Pill}
        />
      ),
    },
    {
      key: 'paidBy',
      header: t('abp.transaction.paidBy'),
      disableSorting: true,
    },
    { key: 'productName', header: t('abp.transaction.product') },
    {
      key: 'paymentType',
      header: t('abp.transaction.paymentType'),
      renderCell: (cell: string) => (
        <span>{paymentTypeFormatMap[cell as PaymentMethod]}</span>
      ),
    },
    {
      key: 'createdAt',
      header: t('abp.transaction.transactionDate'),
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE_TIME)}</span>
      ),
    },
    {
      key: 'amount',
      header: t('abp.transaction.amount'),
      renderCell: (cell: number, row) => (
        <span>
          {currencyFormatter({
            currency: CurrencyFormatMap[currencyFromJSON(row.currency)],
            currencyDisplay: 'code',
          })(cell)}
        </span>
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState<TransactionsReportsTabs>(
    TransactionsReportsTabs.Transactions
  );

  const [loadTransactionHistory, { data, loading }] = useLazyQuery(
    listTransactionsQuery
  );

  useEffect(() => {
    if (channel) {
      loadTransactionHistory({
        variables: {
          listTransactionsRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
            sortBy: {
              key: 'id' in sort ? sort.id : DEFAULT_SORT_KEY,
              dir:
                'direction' in sort ? sort.direction : DEFAULT_SORT_DIRECTION,
            },
          } as ListTransactionsRequest,
        },
      });
    }
  }, [channel, loadTransactionHistory, page, pageSize, sort]);

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.transactionsAndReports.transactions')}
        headerLevel="h3"
      />
      <TabStrip
        tabs={[
          {
            value: TransactionsReportsTabs.Transactions,
            label: 'Transactions',
            icon: 'money-bill-wave',
          },
        ]}
        selected={{ value: selectedTab }}
        onSelectTab={tab =>
          setSelectedTab(tab.value as TransactionsReportsTabs)
        }
        className={styles.TabStrip}
      />
      <Table
        columns={transactionColumns}
        data={data ? data.accounts.listTransactions.transactions : []}
        isLoading={loading}
        pagination="server"
        totalRows={data ? data.accounts.listTransactions.pageInfo.total : 200}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
      />
    </AdminPage>
  );
}
