import React, { useState, useEffect } from 'react';
import ResidentStatusChip, {
  ResidentStatus,
} from './components/ResidentStatusChip';
import { ResidentStatusEnum } from 'lane-shared/types/Resident';
import { useTranslation } from 'react-i18next';
import {
  listUnits,
  ListUnitsQueryResponse,
} from 'lane-shared/graphql/units/listUnits';
import {
  Button,
  Input,
  Dropdown,
  Text,
  Checkbox,
  DatePickerButton,
  Flex,
  Loading,
} from 'design-system-web';

import styles from './ResidentProfile.scss';
import { useQuery } from '@apollo/client';
import { getResidentProfile } from 'graphql-queries';
import { ErrorMessage } from 'components';

type Props = {
  propertyId: string;
  personId: string;
};

function ResidentProfile({ propertyId, personId }: Props) {
  const { t } = useTranslation();

  const {
    data: unitsData,
    loading: areUnitsLoading,
  } = useQuery<ListUnitsQueryResponse>(listUnits, {
    fetchPolicy: 'network-only',
    variables: {
      propertyId,
    },
  });

  interface ResidentDetails {
    id: string;
    status: string;
    unitIds: string[];
    moveInDate?: Date;
    moveOutDate?: Date;
  }
  type GetResidentProfileQueryResponse = {
    resident: ResidentDetails;
  };

  const {
    data: residentData,
    error,
    loading: isLoading,
  } = useQuery<GetResidentProfileQueryResponse>(getResidentProfile, {
    fetchPolicy: 'network-only',
    variables: {
      personId,
      propertyId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [residentDetails, setResidentDetails] = useState<ResidentDetails>();
  useEffect(() => {
    setResidentDetails(residentData?.resident);
  }, [residentData]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  const hasError = !residentDetails || error;

  if (hasError) {
    return (
      <ErrorMessage
        error={t('web.admin.channel.member.residentProfile.errors.notFound')}
        fullWidth
      />
    );
  }

  const units = unitsData?.property?.units ?? [];
  const unitOptions = units.map(unit => ({ label: unit.name, value: unit.id }));

  const residentStatus = residentDetails.status as ResidentStatus;
  const residentUnitId = residentDetails.unitIds?.[0] ?? '';
  const residentFloor = units.find(unit => unit.id === residentUnitId)
    ?.floors?.[0];
  const residentFloorName = residentFloor ? getFloorName(residentFloor) : '';
  const isUnderEviction =
    residentStatus === ResidentStatusEnum.RESIDENT_STATUS_EVICTION;

  return (
    <div className={styles.main}>
      <div className={styles.detail}>
        <div className={styles.residentStatus}>
          <Text as="label">
            {t('web.admin.channel.member.residentProfile.residentStatus')}
          </Text>
          <ResidentStatusChip t={t} status={residentStatus} />
        </div>
        <div className={styles.row}>
          <Dropdown
            doTranslation={false}
            items={unitOptions}
            label={t('web.admin.channel.member.residentProfile.unit')}
            fixedLabel
            onChange={item =>
              setResidentDetails({ ...residentDetails, unitIds: [item.value] })
            }
            isLoading={areUnitsLoading}
            value={residentUnitId}
            isFullWidth
            isRequired
            dataCy="unitsDropdown"
          />
          <Input
            label={t('web.admin.channel.member.residentProfile.floor')}
            fixedLabel
            onChange={() => {}}
            value={residentFloorName}
            readOnly
            dataCy="floorReadOnlyInput"
          />
        </div>
        <div className={styles.row}>
          <DatePickerButton
            value={residentDetails.moveInDate}
            wrapperClassName={styles.datePicker}
            dateInputLabel={t(
              'web.admin.channel.member.residentProfile.moveInDate'
            )}
            fixedLabel
            isRequired
            onChange={date =>
              setResidentDetails({ ...residentDetails, moveInDate: date })
            }
            helperText={t(
              'web.admin.channel.member.residentProfile.moveInDateHelperText'
            )}
            dataCy="moveInDatePicker"
          />
          <DatePickerButton
            value={residentDetails.moveOutDate}
            wrapperClassName={styles.datePicker}
            dateInputLabel={`${t(
              'web.admin.channel.member.residentProfile.moveOutDate'
            )} (${t('web.admin.channel.member.residentProfile.optional')})`}
            fixedLabel
            onChange={date =>
              setResidentDetails({ ...residentDetails, moveOutDate: date })
            }
            helperText={t(
              'web.admin.channel.member.residentProfile.moveOutDateHelperText'
            )}
            dataCy="moveOutDatePicker"
          />
        </div>
        <div className={styles.underEviction}>
          <Checkbox
            value={1}
            text={t('web.admin.channel.member.residentProfile.underEviction')}
            selected={isUnderEviction}
            dataCy="evictionCheckbox"
          />
        </div>
      </div>
      <Button
        className={styles.saveBtn}
        size="medium"
        variant="primary"
        dataCy="saveButton"
        disabled
      >
        {t('web.admin.channel.member.residentProfile.save')}
      </Button>
    </div>
  );
}

function getFloorName(floor: {
  id: string;
  index?: number;
  name?: string;
}): string {
  // possible cases: <index>-<name> or <index> or <name> or (empty '')
  return [floor.index, floor.name].filter(item => item).join('-');
}

export default ResidentProfile;
