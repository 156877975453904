import React from 'react';

import { Document, Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';
import { getTaskStatusLabel } from 'lane-shared/domains/workOrder/helpers';
import { ClientTask, TaskEquipmentDto } from 'graphql-query-contracts';

import { styles } from './styles';

type PMTaskDetailsPDFProps = {
  taskData: ClientTask;
  createdUserName: string;
  assignedMemberName: string;
  t: (val: string, opts?: any) => string;
};

const line = () => (
  <Svg height="20" width="550" style={styles.line}>
    <Line
      x1="1000"
      y1="0"
      x2="0"
      y2="0"
      strokeWidth={1}
      stroke="rgb(128,128,128)"
    />
  </Svg>
);

const PMTaskDetailsPDF = ({
  taskData,
  createdUserName,
  assignedMemberName,
  t,
}: PMTaskDetailsPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>{taskData?.userFriendlyID}</Text>
        <Text style={[styles.subheadings, styles.byLine]}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.task.details.createdBy',
            {
              date: simpleDate(taskData?.createdAt),
              name: createdUserName,
            }
          )}
        </Text>
        {line()}
        <Text style={styles.header}>
          {t('web.admin.workOrder.preventiveMaintenance.task.details.heading')}
        </Text>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.assignedTo'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{assignedMemberName}</Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t('web.admin.workOrder.preventiveMaintenance.task.status')}
          </Text>
          <Text style={styles.subHeadingValues}>
            {getTaskStatusLabel(taskData?.status)}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.schedule'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {taskData?.schedule?.title}
          </Text>
        </View>
        {taskData?.equipment?.length === 0 ? (
          <View style={styles.fieldContainer}>
            <Text style={styles.subheadings}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.task.details.equipment.location'
              )}
            </Text>
            <Text style={styles.subHeadingValues}>{taskData?.location}</Text>
          </View>
        ) : (
          <>
            <View style={styles.rowFieldsContainer}>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.equipment.category'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.equipment.equipment'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.equipment.location'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.equipment.floor'
                  )}
                </Text>
              </View>
              <View style={styles.columnFieldContainer}>
                <Text style={styles.subheadings}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.equipment.suite'
                  )}
                </Text>
              </View>
            </View>
            {taskData?.equipment?.map(
              (equipmentData: TaskEquipmentDto | null) => (
                <View style={styles.rowFieldsContainer} key={equipmentData?.id}>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {equipmentData?.category}
                    </Text>
                  </View>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {equipmentData?.name}
                    </Text>
                  </View>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {equipmentData?.location}
                    </Text>
                  </View>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {equipmentData?.floor}
                    </Text>
                  </View>
                  <View style={styles.columnFieldContainer}>
                    <Text style={styles.subheadings}>
                      {equipmentData?.suite}
                    </Text>
                  </View>
                </View>
              )
            )}
          </>
        )}

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.scheduleNotes'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{taskData?.scheduleNotes}</Text>
        </View>

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t('web.admin.workOrder.preventiveMaintenance.task.details.notes')}
          </Text>
          <Text style={styles.subHeadingValues}>{taskData?.notes}</Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t('web.admin.serviceRequest.hours')}
          </Text>
          <Text style={styles.subHeadingValues}>
            {taskData?.hours}{' '}
            {t('web.admin.serviceRequest.hours').toLowerCase()}
          </Text>
        </View>

        {line()}

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.dueDate'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {simpleDate(taskData?.dueDate!)}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.completedDate'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {taskData?.completedBy ? simpleDate(taskData?.completedAt!) : '-'}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.repeats'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {taskData?.repeats?.label}
          </Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.timeToComplete'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>
            {taskData?.estimateTimeToComplete}{' '}
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.hoursUnit'
            )}
          </Text>
        </View>

        {line()}
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t('web.admin.workOrder.preventiveMaintenance.task.details.steps')}
          </Text>
          <Text style={styles.subHeadingValues}>{taskData?.steps}</Text>
        </View>
        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.task.details.meterReading'
            )}
          </Text>
          <Text style={styles.subHeadingValues}>{taskData?.meterReading}</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) =>
            t('web.admin.workOrder.pdf.pgNumber', { pageNumber })
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default PMTaskDetailsPDF;
