import styles from './styles.scss';
import {
  ProductsServicesTab,
  CategoriesTab,
  ChargeCodesTab,
  ExceptionsTab,
} from './tabs';
import {
  useFlag,
  useUserDataContext,
  useChannelsContext,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { TabStrip } from 'lane-web/src/components/general';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryString } from 'hooks';
import {
  PERMISSION_CHARGE_CODE_MANAGE,
  PERMISSION_PRODUCTS_MANAGE,
  PERMISSION_PRODUCT_EXCEPTIONS_MANAGE,
} from 'lane-shared/helpers/constants/permissions';
import { hasPermission } from 'lane-shared/helpers';

export enum ProductsServicesTabs {
  ProductsAndServices = 'productsAndServices',
  Categories = 'categories',
  Exceptions = 'exceptions',
  ChargeCodes = 'chargeCodes',
}

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
  productFormRoute?: string;
  categoryFormRoute?: string;
  chargeCodeFormRoute?: string;
  exceptionFormRoute?: string;
};

export function ProductsServices({
  breadcrumbs,
  productFormRoute,
  categoryFormRoute,
  chargeCodeFormRoute,
  exceptionFormRoute,
}: Props) {
  const [selectedTab, setSelectedTab] = useQueryString<{
    tab: ProductsServicesTabs;
  }>({
    tab: ProductsServicesTabs.ProductsAndServices,
  });

  const { t } = useTranslation();
  const { user } = useUserDataContext();
  const { primaryChannel } = useChannelsContext();

  const canManageChargeCode =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_CHARGE_CODE_MANAGE],
      primaryChannel?._id
    );

  const canManageProductsAndServices =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_PRODUCTS_MANAGE],
      primaryChannel?._id
    );

  const canManageExceptions =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_PRODUCT_EXCEPTIONS_MANAGE],
      primaryChannel?._id
    );

  const tabComponents = {
    [ProductsServicesTabs.ProductsAndServices]: (
      <ProductsServicesTab
        formRoute={productFormRoute}
        canManage={canManageProductsAndServices}
      />
    ),
    [ProductsServicesTabs.Categories]: (
      <CategoriesTab
        formRoute={categoryFormRoute}
        canManage={canManageProductsAndServices}
      />
    ),
    [ProductsServicesTabs.Exceptions]: (
      <ExceptionsTab
        formRoute={exceptionFormRoute}
        canManage={canManageExceptions}
      />
    ),
    [ProductsServicesTabs.ChargeCodes]: (
      <ChargeCodesTab
        formRoute={chargeCodeFormRoute}
        canManage={canManageChargeCode}
      />
    ),
  };

  const tabs = [
    {
      value: ProductsServicesTabs.ProductsAndServices,
      label: 'abp.productsServices.tab.productsAndServices',
    },
    {
      value: ProductsServicesTabs.Categories,
      label: 'abp.productsServices.tab.categories',
    },
  ];

  const exceptionsFlagEnabled = useFlag(FeatureFlag.Exceptions, false);

  if (exceptionsFlagEnabled) {
    tabs.push({
      value: ProductsServicesTabs.Exceptions,
      label: 'abp.productsServices.tab.exceptions',
    });
  }

  const YardiChargeCodeFlag = useFlag(FeatureFlag.YardiChargeCode, false);

  if (YardiChargeCodeFlag) {
    tabs.push({
      value: ProductsServicesTabs.ChargeCodes,
      label: 'abp.productsServices.tab.chargeCodes',
    });
  }

  return (
    <AdminPage>
      <PageHeader
        header={t('abp.productsServices.title')}
        headerLevel="h3"
        breadcrumbs={breadcrumbs}
        externalPadding={[0, 0]}
      />
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab.tab }}
        onSelectTab={tab =>
          setSelectedTab({ tab: tab.value as ProductsServicesTabs })
        }
        className={styles.TabStrip}
      />
      {tabComponents[selectedTab.tab]}
    </AdminPage>
  );
}
