import { gql } from '@apollo/client';

export const listInvoicesQuery = gql`
  query listInvoices($listInvoicesRequest: ListInvoicesRequest!) {
    accounts {
      listInvoices(listInvoicesRequest: $listInvoicesRequest) {
        invoices {
          id
          displayId
          amount
          status
          externalPayerId
          currency
          invoiceDate
          dueDate
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

export const listRecurringChargesForChannelsQuery = gql`
  query listRecurringChargesForChannels(
    $listRecurringChargesForChannelsRequest: ListRecurringChargesRequest!
  ) {
    accounts {
      listRecurringChargesForChannels(
        listRecurringChargesForChannelsRequest: $listRecurringChargesForChannelsRequest
      ) {
        recurringCharges {
          recurrence {
            anyoneCanCancel
            recurrenceId
            isActive
            recurrenceInterval
            startDate
            nextOccurrence
            lastOccurrence
          }
          charge {
            name
            amount
            externalPayerId
            currency
          }
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

export const updateInvoiceToPaidMutation = gql`
  mutation updateInvoiceToPaid(
    $updateInvoiceToPaidRequest: UpdateInvoiceToPaidRequest!
  ) {
    accounts {
      updateInvoiceToPaid(
        updateInvoiceToPaidRequest: $updateInvoiceToPaidRequest
      ) {
        invoiceId
      }
    }
  }
`;

export const createChargeMutationQuery = gql`
  mutation createCharge($createChargeRequest: CreateChargeRequest!) {
    accounts {
      createCharge(createChargeRequest: $createChargeRequest) {
        chargeId
      }
    }
  }
`;

export const createRecurringChargeMutationQuery = gql`
  mutation createRecurringCharge(
    $createRecurringChargeRequest: CreateRecurringChargeRequest!
  ) {
    accounts {
      createRecurringCharge(
        createRecurringChargeRequest: $createRecurringChargeRequest
      ) {
        recurrenceId
      }
    }
  }
`;
