import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import routes from 'lane-shared/config/routes';
import { pause } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { EntityTypeEnum } from 'lane-shared/types/attachment';

import { ErrorMessage, Flex } from 'lane-web/src/components';
import BreadCrumbs from 'lane-web/src/components/lds/BreadCrumbs';
import { H3, H4 } from 'lane-web/src/components/typography';

import { getEquipmentQuery, updateEquipmentMutation } from 'graphql-queries';

import { useAttachmentByEntityData } from 'hooks/useAttachmentByEntityData';
import { useAttachmentDelete } from 'hooks/useAttachmentDelete';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';
import EquipmentForm from './EquipmentForm';

import styles from './styles.scss';

function EquipmentEdit({ channel }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data } = useQuery(getEquipmentQuery, {
    variables: {
      equipmentId: convertToUUID(equipmentId),
    },
  });
  const equipment = data?.getEquipment;
  let hyperlinks: any[] = [];
  if (equipment && equipment?.hyperlinks && equipment?.hyperlinks.length > 0) {
    hyperlinks = equipment?.hyperlinks.map((hyperlink: any) => {
      return { name: hyperlink.name, url: hyperlink.url };
    });
  }
  let meterReadingSettings: any[] = [];
  if (
    equipment &&
    equipment?.meterReadingSettings &&
    equipment?.meterReadingSettings.length > 0
  ) {
    meterReadingSettings = equipment?.meterReadingSettings.map(
      (meterReadingSetting: any) => {
        return {
          id: meterReadingSetting.id,
          unit: meterReadingSetting.unit,
          name: meterReadingSetting.name,
          enableNotification: meterReadingSetting.enableNotification,
          notificationTrigger: meterReadingSetting.notificationTrigger,
          upperBound: meterReadingSetting.upperBound,
          lowerBound: meterReadingSetting.lowerBound,
          notificationTypes: meterReadingSetting.notificationTypes,
          equipmentId: meterReadingSetting.equipmentId,
        };
      }
    );
  }
  const initialFormValues = {
    name: equipment?.name,
    category: equipment?.category,
    location: equipment?.location,
    status: equipment?.status,
    notes: equipment?.notes,
    attachments: equipment?.attachments,
    make: equipment?.make,
    model: equipment?.model,
    asset: equipment?.asset,
    serial: equipment?.serial,
    floor: equipment?.floor,
    suite: equipment?.suite,
    warrantyExpirationDate: equipment?.warrantyExpirationDate
      ? new Date(equipment?.warrantyExpirationDate)
      : undefined,
    installDate: equipment?.installDate
      ? new Date(equipment?.installDate)
      : undefined,
    hyperlinks,
    meterReadingSettings,
  };

  const { fetchAttachments, attachments } = useAttachmentByEntityData({
    entityId: convertToUUID(equipmentId),
    editMode: false,
  });

  const { filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated: fetchAttachments,
    entity: {
      type: EntityTypeEnum.Equipment,
      id: convertToUUID(equipmentId),
    },
  });

  const { deleteAttachmentHandler } = useAttachmentDelete({
    fetchAttachments,
  });

  const updateEquipment = async (data: any) => {
    if (!equipment?.id) {
      return;
    }

    try {
      await pause();
      await getClient().mutate({
        mutation: updateEquipmentMutation,
        variables: {
          updateEquipment: {
            id: equipment?.id,
            name: data?.name,
            category: data?.category?.value || data?.category,
            location: data?.location?.value || data?.location,
            status: data?.status?.value || data?.status,
            notes: data?.notes,
            attachments: data?.attachments,
            make: data?.make,
            model: data?.model,
            serial: data?.serial,
            asset: data?.asset,
            floor: data?.floor,
            suite: data?.suite,
            warrantyExpirationDate: data?.warrantyExpirationDate,
            installDate: data?.installDate,
            hyperlinks: data?.hyperlinks,
            meterReadingSettings: data?.meterReadingSettings,
          },
        },
      });
      setLoading(false);
      window.Toast.show(t('web.admin.serviceRequest.equipment.update.success'));
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  const equipmentListPath = routes.channelAdminWorkOrdersEquipment.replace(
    ':id',
    channel?.slug
  );
  const afterSave = () => {
    history.push(equipmentListPath);
  };
  return (
    <div className={styles.container}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.equipment.title'),
            url: equipmentListPath,
          },
          {
            label: t('web.admin.serviceRequest.equipment.edit'),
          },
        ]}
      />
      {error ? <ErrorMessage error={error} /> : null}
      <H3 mt={5} mb={5}>
        {t('web.admin.serviceRequest.equipment.edit')}
      </H3>
      <Flex className={styles.equipmentForm} gap={5} direction="column">
        <H4>{t('web.admin.serviceRequest.equipment.form.title')}</H4>
        <EquipmentForm
          onSave={updateEquipment}
          loading={loading}
          formData={initialFormValues}
          attachmentFiles={attachments}
          addAttachments={filesSelectedHandler}
          deleteAttachment={index =>
            deleteAttachmentHandler(attachments[Number(index)]!.id)
          }
          channel={channel}
          afterSave={afterSave}
        />
      </Flex>
    </div>
  );
}
export default EquipmentEdit;
