import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import { ContextMenu, FileInput, Loading } from 'components';
import { useDatasetSchema, useIsAdminView, useQueryString } from 'hooks';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useHistory, Link, useParams } from 'react-router-dom';

import { ApolloError, isApolloError, gql, useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';

import { Chip, Pagination } from 'components/general';
import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import DatasetSchemaTable from 'components/lane/DatasetSchema/DatasetSchemaTable';
import { BreadCrumbs, Grid, Col, Modal } from 'components/lds';
import { H4, S } from 'components/typography';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './DatasetSchema.scss';

export const upsertDatasetRecordsMutation = gql`
  mutation UpsertDatasetRecords(
    $datasetSchemaId: UUID!
    $datasetRecords: [DatasetRecordUpdateInput!]!
  ) {
    upsertDatasetRecords(
      datasetSchemaId: $datasetSchemaId
      datasetRecords: $datasetRecords
    ) {
      _id
    }
  }
`;

/**
 * Page for displaying info related to a datasetSchema.
 *
 * @TODO: for exporting the csv file, first use the datasetRecords resolver to get all records
 */
export default function DatasetSchema() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [page, setPage] = useState(0);
  const [perPage] = useState(25);
  const params = useParams<{
    datasetSchemaId: string;
    id: string;
  }>();

  const [, channelSlug] = useIsAdminView() as [boolean, string];

  const {
    exportCSVFile,
    loading,
    datasetRecords,
    datasetSchema,
    datasetProperties,
    contentGenerator,
    error: fetchDatasetSchemaError,
  } = useDatasetSchema(params.datasetSchemaId, {
    datasetRecordsPagination: { perPage, start: page * perPage },
  });

  const history = useHistory();
  const [query] = useQueryString({ download: 'false' });

  useEffect(() => {
    if (
      Boolean(query.download === 'true') &&
      Boolean(datasetRecords) &&
      Boolean(datasetSchema) &&
      !loading
    ) {
      history.replace({
        search: `?download=false`,
      });
      exportCSVFile();
    }
  }, [datasetRecords, datasetSchema, loading]);

  const [importCSVError, setImportCSVError] = useState<ApolloError | undefined>(
    undefined
  );

  const [upsertDatasetRecords] = useMutation(upsertDatasetRecordsMutation, {
    refetchQueries: ['getDatasetSchema'],
    awaitRefetchQueries: true,
  });

  function renderCSVImportErrorModal() {
    return (
      <Modal
        isOpen={Boolean(importCSVError)}
        onClose={() => setImportCSVError(undefined)}
        title={t('Failed to Import CSV')}
        size="large"
      >
        {importCSVError?.graphQLErrors.map(graphqlError =>
          // @ts-ignore
          graphqlError.extensions?.exception?.inner?.map(
            (fieldError: any, index: any) => (
              <div key={index}>
                {`${fieldError.path}: ${fieldError.message}`}
              </div>
            )
          )
        )}
      </Modal>
    );
  }

  async function onCSVImport(csv: FileReturnType, fileName: string) {
    try {
      await window.Alert.confirm({
        title: 'Import data from CSV',
        children: (
          <span>
            This database will be replaced with the data in{' '}
            <span className={styles.bold}>{fileName}</span>. Would you like to
            overwrite it.
          </span>
        ),
        confirmText: 'Import',
      });
    } catch (err) {
      return;
    }

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const parsed = Papa.parse(csv, {
      header: true,
      skipEmptyLines: 'greedy',
    });

    const propertiesToCheck = datasetProperties.map(property => property.name);

    for (const field of (parsed as any).meta.fields) {
      if (field === '_id') {
        continue;
      }
      if (!propertiesToCheck.includes(field)) {
        await window.Alert.alert({
          title: 'Invalid field found',
          confirmText: 'Cancel',
          children: (
            <span>
              <span className={styles.bold}>[{field}]</span> does not exist in
              the database.
            </span>
          ),
        });
        return;
      }
    }

    const rows = (parsed as any).data.map((item: any) => ({
      _id: item?._id || undefined,
      value: item,
    }));

    if (rows.length === 0) {
      await window.Alert.alert({
        title: 'Missing data',
        confirmText: 'Cancel',
        message: 'There is no data to import',
      });
      return;
    }

    try {
      await upsertDatasetRecords({
        variables: {
          datasetSchemaId: params.datasetSchemaId,
          datasetRecords: rows,
        },
      });
      window.Toast.show('Database updated');
    } catch (e) {
      if (isApolloError(e)) {
        setImportCSVError(e);
      } else {
        window.Toast.show('An error occurred. Please try again in a bit.');
      }
    }
  }

  if (fetchDatasetSchemaError) {
    return <ErrorMessage error={fetchDatasetSchemaError} />;
  }

  if (!datasetSchema) {
    return <Loading />;
  }

  return (
    <Grid>
      <Col columns={[1, -1]} mb={5}>
        <BreadCrumbs
          links={[
            {
              label: 'Data Library',
              url: `${routes.channelAdminLibrary}/data`.replace(
                ':id',
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                channel?.slug
              ),
            },
            { label: datasetSchema.name },
          ]}
        />
      </Col>
      <Col columns={[1, -1]}>
        <H4 v2 mb={4}>
          {datasetSchema.name}
        </H4>
      </Col>
      {contentGenerator && (
        <Col columns={[1, 7]} className={styles.card} mb={7}>
          <h6 className={styles.cardHeader}>Linked Content</h6>
          <Link
            to={generatePath(routes.channelAdminGeneratorContent, {
              id: channelSlug,
              contentId: contentGenerator._id,
            })}
          >
            <S>{contentGenerator.name}</S>
          </Link>
        </Col>
      )}
      <Col columns={[7, -1]} className={styles.card} mb={7}>
        <h6 className={styles.cardHeader}>Tags</h6>
        <div className={styles.tagsContainer}>
          {contentGenerator?.tags?.map((tag: any) => (
            <Chip key={tag} label={tag} />
          ))}
        </div>
      </Col>
      <Col columns={[1, -1]} className={styles.actionIcons}>
        <Pagination
          total={datasetSchema.datasetRecords.pageInfo.total}
          page={page}
          perPage={25}
          onPage={setPage}
          pagesToShow={5}
        />
        <ContextMenu
          id="action-menu"
          autoClose
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"left"' is not assignable to type 'ModalPosi... Remove this comment to see the full error message
          align="left"
          items={[
            <div className={styles.csvFileButtons} key="1">
              <FileInput
                accept=".csv"
                type={FileReturnTypeEnum.Text}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(csv: FileReturnType, fileName: string) => P... Remove this comment to see the full error message
                onFileSelected={onCSVImport}
              >
                <button className={styles.csvButton}>
                  <Icon set="FontAwesome" name="file-import" type="far" />
                  Import CSV
                </button>
              </FileInput>
              <button className={styles.csvButton} onClick={exportCSVFile}>
                <Icon set="FontAwesome" name="file-export" type="far" />
                Export CSV
              </button>
            </div>,
          ]}
          hasOpaqueBackground={false}
        >
          <span className={styles.iconContainer}>
            <Icon
              set="FontAwesome"
              name="file-csv"
              type="far"
              className={styles.icon}
            />
          </span>
        </ContextMenu>
      </Col>
      <Col columns={[1, -1]}>
        {datasetProperties?.length > 0 && (
          <DatasetSchemaTable datasetSchema={datasetSchema} />
        )}
        {datasetRecords.length === 0 && (
          <div className={styles.datasetRecordsEmpty}>
            <H4 mb={6}>This database is a little lonely.</H4>
            <FileInput
              accept=".csv"
              type={FileReturnTypeEnum.Text}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(csv: FileReturnType, fileName: string) => P... Remove this comment to see the full error message
              onFileSelected={onCSVImport}
            >
              <Button variant="contained">Import a CSV file</Button>
            </FileInput>
          </div>
        )}
      </Col>
      {renderCSVImportErrorModal()}
    </Grid>
  );
}
