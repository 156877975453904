import React from 'react';

import { Button, IconButton, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { WizardStepOneParams } from '../WizardStepOne';

import { FaPlusCircle } from 'react-icons/fa';

import styles from '../styles.scss';

export function SFTP({
  currentValue,
  onAddDataSourceLocation,
  onChangeDataSourceLocation,
  onDeleteSourceLocation,
}: WizardStepOneParams) {
  const { t } = useTranslation();
  const { sourceLocations } = currentValue;

  return (
    <>
      <Button
        startIcon={<FaPlusCircle />}
        onClick={onAddDataSourceLocation}
        className={styles.SourceButton}
      >
        {t`web.admin.channel.autoSetupWizard.addFolder`}
      </Button>
      {sourceLocations.map(location => (
        <div className={styles.InputRow} key={`input-row-${location.index}`}>
          <Input
            value={location.value}
            key={`sftp-folder-input-${location.index}`}
            onChange={(value: string) =>
              onChangeDataSourceLocation({ index: location.index, value })
            }
            placeholder={t`web.admin.channel.autoSetupWizard.sftpFolder`}
            data-testid="sftp-folder-input"
            className={styles.SourceInput}
          />
          <IconButton
            icon="times"
            inverted
            onClick={() => {
              onDeleteSourceLocation(location.index);
            }}
          />
        </div>
      ))}
    </>
  );
}
