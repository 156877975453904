import React, { useState, useContext, useEffect } from 'react';

import {
  Loading,
  TabStrip,
  ControlMenu,
  Button,
  ErrorMessage,
  WhitelabelInfoEdit,
  ResizableWindow,
  TemplateLibrary,
} from 'components';
import { useQueryString } from 'hooks';
import { Key } from 'ts-key-enum';

import { getAdminClient } from 'lane-shared/apollo';
import { RendererContext, UserDataContext } from 'lane-shared/contexts';
import { toSchema, pause, castForUpdate } from 'lane-shared/helpers';
import { CONTENT_TYPES } from 'lane-shared/helpers/constants/content';
import { fetchTemplate } from 'lane-shared/helpers/templates';
import { useUpdatedData, useWhitelabelQuery } from 'lane-shared/hooks';
import { validateWhitelabel } from 'lane-shared/validation';

import Builder from 'components/renderers/Builder';

import updateWhitelabelMutation from '../updateWhitelabelMutation';
import confirmWhitelabelSave from 'helpers/confirmWhitelabelSave';

import styles from './styles.scss';

const tabs = ['Profile', 'Info'];
const [TAB_PROFILE, TAB_INFO] = tabs;

export default function WhitelabelEdit({ match }: any) {
  const { user } = useContext(UserDataContext);
  const { constructBaseBlock } = useContext(RendererContext);
  const { whitelabel, loading } = useWhitelabelQuery({
    whitelabelId: match?.params?.whitelabelId,
  });

  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [
    updatedWhitelabel,
    setUpdatedWhitelabel,
    hasChanged,
    getPatch,
  ] = useUpdatedData(whitelabel);

  const [query, goToUrl] = useQueryString({
    tab: TAB_PROFILE,
  });

  const selectedTab = query.tab || TAB_PROFILE;

  useEffect(() => {
    if (whitelabel?._id) {
      setUpdatedWhitelabel(castForUpdate(whitelabel), true);
    }
  }, [whitelabel?._id]);

  async function onSave() {
    try {
      setValidation(null);
      await validateWhitelabel.validate(updatedWhitelabel, {
        abortEarly: false,
      });
    } catch (err) {
      setValidation(err);
      return;
    }

    if (!(await confirmWhitelabelSave())) {
      return;
    }

    try {
      setSaving(true);
      setError(null);
      await pause();
      await getAdminClient().mutate({
        refetchQueries: ['getWhiteLabel'],
        mutation: updateWhitelabelMutation,
        variables: {
          whiteLabel: {
            _id: whitelabel._id,
            ...getPatch(),
          },
        },
      });

      window.Toast.show(`${whitelabel.name} updated.`);
    } catch (err) {
      setError(err);
      await window.Alert.alert({
        title: `I wasn't able to save this whitelabel.`,
        message: `See the error below and please try again.`,
        error: err,
      });
    }

    setSaving(false);
  }

  async function onTemplateSelected(toLoad: any) {
    setIsTemplateOpen(false);
    setError(null);
    // check to see if some editing has been done, this will clear out any content.
    try {
      await window.Alert.confirm({
        title: `Use ${toLoad.templateName} template?`,
        message:
          'Choosing this template will clear out any work you have done.',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setSaving(true);

    try {
      // get the full template
      const { block, properties, renderer, version } = await fetchTemplate(
        toLoad._id
      );

      // copy template to current content.

      setUpdatedWhitelabel({
        profile: {
          ...whitelabel.profile,
          block,
          properties,
          renderer,
          version,
        },
      });
    } catch (err) {
      setError(err);
      await window.Alert.alert({
        title: `I wasn't able to load this template.`,
        message: `See the error below and please try again.`,
        error: err,
      });
    }

    setSaving(false);
  }

  function onContentUpgrade() {
    setUpdatedWhitelabel({
      profile: {
        ...whitelabel.profile,
        renderer: 5,
        block: { ...constructBaseBlock(), name: 'New Profile' },
        version: (whitelabel.profile.version || 0) + 1,
        properties: {},
      },
    });
  }

  if (!whitelabel) {
    return <Loading />;
  }

  const libraries: any = [];
  const whitelabelData = hasChanged ? updatedWhitelabel : whitelabel;

  return (
    <div className={styles.WhitelabelEdit}>
      <ControlMenu>
        {selectedTab === TAB_PROFILE && (
          <Button
            loading={loading || saving}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; loading: boolean; invert... Remove this comment to see the full error message
            inverted
            onClick={() => setIsTemplateOpen(true)}
          >
            Choose Template
          </Button>
        )}

        <Button
          dataCy="saveWhitelabel"
          loading={loading || saving}
          disabled={!hasChanged}
          onClick={onSave}
        >
          Save
        </Button>
      </ControlMenu>

      <TabStrip
        disabled={loading || saving}
        tabs={tabs.map(toSchema)}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'SchemaType<string | number>' is not assignab... Remove this comment to see the full error message
        selected={toSchema(selectedTab)}
        onSelectTab={tab => goToUrl({ tab: tab.value })}
      />

      <ErrorMessage error={error} />
      <ErrorMessage error={validation} />

      {selectedTab === TAB_INFO && (
        <WhitelabelInfoEdit
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ loading: boolean; validation: null; whitel... Remove this comment to see the full error message
          loading={loading || saving}
          validation={validation}
          whitelabel={whitelabelData}
          onWhitelabelUpdated={props => setUpdatedWhitelabel(props)}
        />
      )}

      {selectedTab === TAB_PROFILE && (
        <Builder
          user={user}
          content={whitelabelData.profile}
          onContentUpdated={(profile: any) =>
            setUpdatedWhitelabel({
              profile: { ...whitelabelData.profile, ...profile },
            })
          }
          onContentUpgrade={onContentUpgrade}
          onContentMigrate={() => null}
        />
      )}

      {isTemplateOpen && (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'name' is missing in type '{ children: El... Remove this comment to see the full error message
        <ResizableWindow
          className={styles.window}
          onClose={() => setIsTemplateOpen(false)}
          showHeader
          defaultPosition={ResizableWindow.fullScreen()}
        >
          <TemplateLibrary
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
            userLibraryEnabled
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            libraries={libraries}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'import("/Users/william/lane/lane-next/packag... Remove this comment to see the full error message
            allowedTypes={[CONTENT_TYPES.STATIC]}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '({ template, children }: any) => JSX.Element... Remove this comment to see the full error message
            TemplateWrapper={({ template, children }: any) => (
              <div
                tabIndex={0}
                role="button"
                onKeyPress={e =>
                  e.key === Key.Enter && onTemplateSelected(template)
                }
                onClick={() => onTemplateSelected(template)}
              >
                {children}
              </div>
            )}
          />
        </ResizableWindow>
      )}
    </div>
  );
}
