import React, { useState, useEffect } from 'react';

import { Input } from 'components';
import { Button, Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import resetPasswordMutation from 'lane-shared/graphql/user/resetPassword';
import { getValidationMessages } from 'lane-shared/helpers';
import { validateResetPassword } from 'lane-shared/validation';

import { H4, S } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import ErrorMessage from '../general/ErrorMessage';

import styles from './ResetPassword.scss';

type Props = {
  guid: string;
  verify: string;
  onResetPassword?: () => void;
  onResetPasswordFailed?: (err: Error) => void;
};

export default function ResetPassword({
  guid,
  verify,
  onResetPassword,
  onResetPasswordFailed,
}: Props) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [validation, setValidation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [resetPassword] = useMutation(resetPasswordMutation);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    validate();
  }, [password1, password2]);

  async function validate() {
    setValidation(null);

    if (!password1 || !password2) {
      return;
    }

    try {
      // After updating the state, see if its valid.
      await validateResetPassword.validate({ password1, password2 });
    } catch (validation) {
      setValidation(validation);
    }
  }

  async function submitResetPassword(resetPassword: any) {
    setLoading(true);
    setError(null);

    try {
      await resetPassword({
        variables: {
          password: password1,
          verify,
          guid,
        },
      });

      window.Toast.show(t('Password was reset successfully'));
      if (onResetPassword) {
        onResetPassword();
      }
    } catch (err) {
      setError(err);
      await window.Alert.alert({
        title: t('Your password reset link has expired.'),
        message: t(
          'This password reset link has expired. Please try using the forgot password link and try again.'
        ),
      });
      if (onResetPasswordFailed) {
        onResetPasswordFailed(err);
      }
    }

    setLoading(false);
  }

  return (
    <form
      className={styles.resetPasswordForm}
      onSubmit={e => {
        e.preventDefault();
        submitResetPassword(resetPassword);
      }}
    >
      <div className={styles.innerWidth}>
        <Button
          variant="text"
          className={welcomeFormStyles.returnButton}
          style={{
            color: 'inherit',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
          startIcon={
            <Icon name="chevron-left" set="FontAwesome" size="small" />
          }
          onClick={() => history.push(routes.login)}
        >
          <S>{t('Back to home')}</S>
        </Button>
        <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
          {t('Change password')}
        </H4>
        <S mb={6} block>
          {t(
            'Choose a secure password that is at least 10 characters long with upper, lower case, numerical and special characters.'
          )}
        </S>
      </div>
      <Input
        className={welcomeFormStyles.inputMargin}
        type="password"
        label={t('New password')}
        onChange={password1 => setPassword1(password1)}
        onFocus={() => setValidation(null)}
        value={password1}
        error={getValidationMessages(validation, 'password1')}
      />
      <Input
        className={welcomeFormStyles.inputMargin}
        type="password"
        label={t('Confirm password')}
        onChange={password2 => setPassword2(password2)}
        onFocus={() => setValidation(null)}
        value={password2}
        error={getValidationMessages(validation, 'password2')}
      />
      <ErrorMessage className={welcomeFormStyles.errorMessage} error={error} />
      <div className={welcomeFormStyles.menu}>
        <Button
          className={welcomeFormStyles.actionButton}
          loading={loading}
          dataCy="changePasswordButton"
          variant="primary"
          disabled={!password1 || !password2 || Boolean(validation) || loading}
          size="large"
          type="submit"
        >
          {t('Change password')}
        </Button>
      </div>
    </form>
  );
}
