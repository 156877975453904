import { Credential } from 'graphql-query-contracts';

const PROVIDERS = {
  HID_2_2: 'HID_2_2',
  CCURE_2_8__2_9: 'CCURE_2_8__2_9',
  HID_3_0: 'HID_3_0',
  BRAXOS_1_0: 'BRAXOS_1_0',
  GENEA_2_0: 'GENEA_2_0',
  OPENPATH_1_0: 'OPENPATH_1_0',
  AMAG_9_0: 'AMAG_9_0',
  BRIVO_1_0: 'BRIVO_1_0',
  DATAWATCH_1_0: 'DATAWATCH_1_0',
  MAXXESS_6_0: 'MAXXESS_6_0',
  GENETEC_5_9: 'GENETEC_5_9',
  GALLAGHER_8_9: 'GALLAGHER_8_9',
  DSX_6_0: 'DSX_6_0',
  SALTO_SVN_1_35: 'SALTO_SVN_1_35',
};

export type AvailableCredentialActions = {
  canSuspendOrRevoke: () => boolean;
  canResume: () => boolean;
  canSuspend: () => boolean;
};

export const availableCredentialActions = (credential: Credential) => {
  function canSuspendOrRevoke() {
    return isIdP() && isActive();
  }

  function canResume() {
    return isIdP() && isAppleWallet() && isSuspended();
  }

  function canSuspend() {
    return isAppleWallet();
  }

  function isIdP() {
    return [
      PROVIDERS.HID_2_2,
      PROVIDERS.HID_3_0,
      PROVIDERS.SALTO_SVN_1_35,
    ].includes(credential.provider);
  }

  function isAppleWallet() {
    return ['MOBILE_APPLE_WALLET', 'WATCH_APPLE_WALLET'].includes(
      credential.credentialType ?? ''
    );
  }

  function isSuspended() {
    return credential.status === 'SUSPENDED';
  }

  function isActive() {
    return credential.status === 'ACTIVE';
  }

  const availableActions: AvailableCredentialActions = {
    canSuspendOrRevoke,
    canResume,
    canSuspend,
  };

  return availableActions;
};
