import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { deleteAudience } from 'lane-shared/graphql/mutation';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';
import type { Audience } from 'lane-shared/types/audiences/Audience';
import type { ChannelType } from 'lane-shared/types/ChannelType';

import { H3, M } from 'lane-web/src/components/typography';

import { Modal } from 'components/lds';

import styles from './DeletionConfirmationModal.scss';

export function DeletionConfirmationModal({
  isModalOpen,
  setIsModalOpen,
  channel,
  audienceId,
  audienceName,
}: {
  channel: ChannelType;
  isModalOpen: boolean;
  audienceId: string;
  audienceName: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { audienceTracker } = useAudienceAnalytics({
    audience: { id: audienceId, name: audienceName } as Audience,
    channels: [channel],
  });

  const onDeleteAudience = async () => {
    const { data } = await getClient().mutate({
      mutation: deleteAudience,
      variables: {
        audienceId,
        channelId: channel?._id,
      },
    });

    if (data.deleteAudience) {
      audienceTracker.Delete.End();
      window.Toast.show(
        t('web.admin.audience.deleteSuccess', { audienceName })
      );
      setIsModalOpen(false);
      history.push(
        `${routes.channelAdminAudiences.replace(':id', channel?.slug!)}`
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        audienceTracker.Delete.Cancel();
        setIsModalOpen(false);
      }}
      className={styles.modalContainer}
      disableContentPadding
    >
      <div className={styles.modalContent}>
        <H3 mb={2}>{t('web.admin.audience.deleteModalTitle')}</H3>
        <M mb={5} variant="secondary">
          {t('web.admin.audience.deleteModaDescription')}
        </M>
      </div>
      <div className={styles.modalFooter}>
        <Button
          size="large"
          className={styles.deleteButton}
          onClick={onDeleteAudience}
          dataCy="deleteAudienceConfirmation"
          fullWidth
        >
          {t('web.admin.audience.deleteButtonText')}
        </Button>
      </div>
    </Modal>
  );
}
