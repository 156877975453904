import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Invoice,
  CurrencyFormatMap,
  currencyFromJSON,
} from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';

import { Column, Button } from 'design-system-web';

import { Chip, ChipStyle } from 'components/ads';

import { routes } from 'lane-shared/config';

export function useGetPaymentHistoryColumns() {
  const { t } = useTranslation();
  const history = useHistory();

  const InvoiceIdLink = (cell: string, invoice: Invoice) => {
    return (
      <Button
        onClick={() => {
          history.push(
            routes.meCompanyInvoiceDetails.replace(':invoiceDisplayId', cell),
            { invoice }
          );
        }}
        variant="text"
        dataCy="InvoiceIdLinkButton"
      >
        {cell}
      </Button>
    );
  };

  const invoiceTableColumns: Column<Invoice>[] = [
    {
      key: 'displayId',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.displayId'),
      disableSorting: true,
      renderCell: InvoiceIdLink,
    },
    {
      key: 'externalPayerName',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.invoiceTo'),
      disableSorting: true,
      renderCell: (cell: string) => <span>{cell}</span>,
    },
    {
      key: 'groupName',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.invoiceFrom'),
      disableSorting: true,
      renderCell: (cell: string) => <span>{cell}</span>,
    },
    {
      key: 'amount',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.invoiceAmount'),
      disableSorting: true,
      renderCell: (cell: number, row) => (
        <span>
          {currencyFormatter({
            currency: CurrencyFormatMap[currencyFromJSON(row.currency)],
            currencyDisplay: 'code',
          })(cell)}
        </span>
      ),
    },
    {
      key: 'status',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.invoiceStatus'),
      disableSorting: true,
      renderCell: (cell: string, row) => {
        const statusMap = getInvoiceStatus(cell, row);
        const color = statusMap[1] as ChipStyle;
        return <Chip value={statusMap[0]} type={color} size="xs" />;
      },
    },
    {
      key: 'invoiceDate',
      header: t(
        'abp.accountInvoices.invoiceTableColumnHeader.invoiceIssueDate'
      ),
      disableSorting: true,
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE_TIME)}</span>
      ),
    },
    {
      key: 'dueDate',
      header: t('abp.accountInvoices.invoiceTableColumnHeader.invoiceDueDate'),
      disableSorting: true,
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE_TIME)}</span>
      ),
    },
  ];

  return invoiceTableColumns;
}
