import React from 'react';

import { ChannelIntegrationEditorProps } from './ChannelIntegrationEditorProps';
import { SSOIntegrationEditor } from './SSOIntegrationEditor/SSOIntegrationEditor';
import gql from 'graphql-tag';

export const queryAllServiceProviders = gql`
  query oidcServiceProviderDefinitionList {
    oidcServiceProviderDefinitionList {
      id
      name
      entityId
      customFields
    }
  }
`;

export const createNewProviderDefinition = gql`
  mutation createOIDCServiceProviderDefinition(
    $provider: OIDCServiceProviderDefinitionInput!
    $channelId: UUID!
  ) {
    createOIDCServiceProviderDefinition(
      oidcServiceProviderDefinition: $provider
      channelId: $channelId
    ) {
      id
      name
      entityId
      customFields
    }
  }
`;

export const QUERY_DATA_KEY = 'oidcServiceProviderDefinitionList';
export const CREATE_DATA_KEY = 'createOIDCServiceProviderDefinition';
const EDITOR = 'OIDC';

export function OIDCIntegrationEditor({
  channelIntegration,
  onUpdateChannelIntegration,
}: ChannelIntegrationEditorProps) {
  return (
    <SSOIntegrationEditor
      channelIntegration={channelIntegration}
      onUpdateChannelIntegration={onUpdateChannelIntegration}
      integrationQuery={queryAllServiceProviders}
      createIntegrationQuery={createNewProviderDefinition}
      queryDataKey={QUERY_DATA_KEY}
      createDataKey={CREATE_DATA_KEY}
      editorType={EDITOR}
    />
  );
}
