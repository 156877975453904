import React from 'react';

import { Modal } from 'components/lds';
import { Button, Flex } from 'components';
import { H4, P } from 'design-system-web';

import styles from './ConfirmationModal.scss';

export function ConfirmationModal({
  isOpen,
  onClose,
  handleConfirm,
  title,
  description,
  cancelText,
  confirmText,
  dataCy,
}: {
  isOpen: boolean;
  onClose: Function;
  handleConfirm: Function;
  title: string;
  description: string;
  cancelText: string;
  confirmText: string;
  dataCy?: string;
}) {
  return (
    <Modal
      className={styles.ConfirmationModal}
      isOpen={isOpen}
      onClose={() => onClose()}
      dataCy={dataCy}
      actions={[
        <Button
          className={styles.CancelButton}
          key="cancel"
          onClick={() => onClose()}
        >
          {cancelText}
        </Button>,
        <Button
          variant="contained-dark"
          className={styles.ConfirmButton}
          key="submit"
          onClick={() => handleConfirm()}
        >
          {confirmText}
        </Button>,
      ]}
    >
      <Flex direction="column" gap={2}>
        <H4>{title}</H4>
        <P className={styles.Description}>{description}</P>
      </Flex>
    </Modal>
  );
}
