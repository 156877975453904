import React, { ReactElement, useRef } from 'react';

import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import styles from './index.scss';

type Props<T = string | number | boolean> = {
  className?: string;
  style?: React.CSSProperties;
  interfaceStyle?: 'light' | 'dark' | 'activate';
  selectedType?: 'simple' | 'color';
  value: T;
  text?: string | ReactElement;
  disabled?: boolean;
  dataCy?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onChange?: (value: T, name?: string) => void;
  name?: string;
  mb?: number;
  mt?: number;
  selected: boolean;
  labelStyles?: React.CSSProperties;
};

export function CheckBox<T>({
  className,
  style,
  interfaceStyle = 'light',
  selectedType = 'simple',
  text,
  disabled = false,
  selected,
  dataCy,
  onClick,
  onChange,
  value,
  name,
  mb,
  mt,
  labelStyles,
  ...props
}: Props<T>) {
  const id = useRef(uuid()).current;

  function changeHandler(e: any) {
    if (onChange) {
      onChange(value, name);
    }

    e.stopPropagation();
  }

  function labelClickHandler(e: any) {
    e.stopPropagation();
  }

  function inputClickHandler(e: any) {
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  }

  function renderText(text: string | ReactElement | undefined) {
    if (text) {
      const textElement = (
        <label htmlFor={id} onClick={labelClickHandler} style={labelStyles}>
          {text}
        </label>
      );

      return textElement;
    }

    return null;
  }

  return (
    <div
      className={cx(styles.Checkbox, className)}
      style={style}
      data-interface-style={interfaceStyle}
      data-selecte-type={selectedType}
      data-disabled={disabled}
      data-has-text={!!text}
      data-margin-top={mt}
      data-margin-bottom={mb}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        disabled={disabled}
        data-cy={dataCy}
        onClick={inputClickHandler}
        onChange={changeHandler}
        checked={selected}
        {...props}
      />
      {renderText(text)}
    </div>
  );
}
