export type LookerEmbedOptions = {
  embedURL: string;
  channelList: string[];
  permissions: string[];
  models: string[];
  lookerGroupIDs: number[];
  forceLogout: boolean;
  userTimezone: string;
};

export enum LookerReportEnum {
  Content = 'content',
  User = 'user',
  Portfolio = 'portfolio',
  Reservation = 'reservation',
  UserSearch = 'user_search',
  PostAnalytics = 'post_analytics',
  CompanyAnalytics = 'company_analytics',
  WorkOrdersAnalytics = 'work_orders_analytics',
  UserEngagementAnalytics = 'user_engagement_analytics',
  AdminDashboardAnalytics = 'admin_dashboard_analytics',
  TenantSentimentAnalytics = 'tenant_sentiment_analytics',
  AccessAnalytics = 'access_analytics',
  EventAnalytics = 'event_analytics',
}
