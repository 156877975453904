import React from 'react';

import { useTranslation } from 'react-i18next';
import { Thumbnail } from 'components';
import { XL, L, S } from 'components/typography';
import CardContainer from 'components/cards/CardContainer';
import getMediaUrl from 'lane-shared/helpers/formatters/mediaUrl';

import { CreditWallet } from 'graphql-resolver-contracts';
import styles from './styles.scss';

export function CreditWallets({
  creditWallets,
}: {
  creditWallets: CreditWallet[];
}) {
  const { t } = useTranslation();

  return (
    <>
      {creditWallets.map((c: CreditWallet) => (
        <CardContainer key={c.id} className={styles.CreditWallet}>
          <div className={styles.ChannelLogo}>
            <Thumbnail
              className={styles.CreditWalletThumbnail}
              src={getMediaUrl(c.channelProfile.logo)}
              name={c.channelProfile.name || 'Channel'}
              highlight={false}
              outline={false}
            />
          </div>
          <div className={styles.ChannelName}>
            <L>{c.channelProfile.name || t('abp.credits.channelUnknown')}</L>
          </div>
          <div className={styles.CreditWalletBalance}>
            <XL className={styles.BalanceAmount}>{c.numberOfCredits}</XL>
            <S className={styles.BalanceRemain}>
              {t('abp.credits.remainingCredits')}
            </S>
          </div>
        </CardContainer>
      ))}
    </>
  );
}
