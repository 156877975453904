import React from 'react';

import cx from 'classnames';

import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';

import TimePicker from 'components/form/DatePickers/TimePicker';
import ValidationMessage from 'components/general/ValidationMessage';

import styleMap from '../../styleMap';

import styles from './TimeInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function TimeInputBlock({
  value,
  style,
  onInput,
  disabled,
  isValid,
  placeholder,
  validationMessage,
  className,
}: any) {
  return (
    <div
      className={cx(className, styles.TimeInputBlock)}
      style={styleMap(false, style)}
    >
      <TimePicker
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any) => any' is not assignable to ty... Remove this comment to see the full error message
        onChange={(value: any) => onInput(dateFormatter(value, 'h:mm a'))}
      />

      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
      <div className={styles.wrapper} />
    </div>
  );
}

TimeInputBlock.defaultProps = {
  value: null,
  style: {},
  onInput: () => {},
  disabled: false,
  isValid: true,
  placeholder: 'Select',
  validationMessage: 'Required.',
};
