import React, { useState } from 'react';

import cx from 'classnames';
import { Input, ListExpandableForm, ErrorMessage, Button } from 'components';

import { getClient } from 'lane-shared/apollo';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { portalQueries, getFilteredQueries } from 'lane-shared/helpers/portal';

import { AdminPage } from 'components/layout';

import makeFileDownload from '../../../../helpers/makeFileDownload';

import styles from './styles.scss';

function DisplayQueryResults(props: any) {
  const {
    error,
    queryResponse,
    clearSelections,
    selectedQuery,
    onDownload,
  } = props;

  const headers = !queryResponse[0]
    ? []
    : Object.keys(queryResponse[0]).filter(key => key !== '__typename');

  return (
    <div className={styles.DisplayQueryResults}>
      <ErrorMessage error={error} />
      <div className={styles.resultsHeader}>
        <div>
          <strong>Results</strong>
        </div>
        <div>
          {selectedQuery && headers.length && (
            <>
              <Button onClick={clearSelections}>Clear</Button>
              <Button onClick={() => onDownload(headers)}>Download</Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.resultsBody}>
        {!selectedQuery && <p>Run query to see results.</p>}
        {selectedQuery && !headers.length && <p>No Results Found.</p>}
        {selectedQuery && headers.length && (
          <div>
            <table>
              <thead>
                <tr>
                  {headers.map(header => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {queryResponse.map((query: any, i: any) => (
                  <tr key={i}>
                    {headers.map(header => (
                      <td key={query[header]}>{query[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default function PortalManagementQueries() {
  const [queriesToRender, setQueriesToRender] = useState(portalQueries);
  const [search, setSearch] = useState('');
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [queryResponse, setQueryResponse] = useState([]);
  const [error, setError] = useState(null);

  async function handleQuerySelect(query: any, formValues: any) {
    setSelectedQuery(query.slug);

    try {
      // to accommodate how the DatePickerRangeButton component works
      // may move into ListExpandableForm in the future
      if (formValues.dateRange) {
        formValues.startDate = formValues.dateRange.startDate;
        formValues.endDate = formValues.dateRange.endDate;
        delete formValues.dateRange;
      }

      const { data } = await getClient().query({
        query: query.query,
        variables: formValues,
      });

      if (data?.portal?.[query.queryResponseObjectName]?.length > 0) {
        const val = data.portal[query.queryResponseObjectName];
        setQueryResponse(val);
      }
    } catch (err) {
      setError(err);
    }
  }

  async function onDownload(headers = []) {
    let str = `${headers.join(',')}\n`;

    queryResponse.forEach(query => {
      headers.forEach(header => {
        str += `${query[header]},`;
      });
      str = `${str.substring(0, str.length - 1)}\n`;
    });

    const today = dateFormatter(new Date(), 'yyyy-MM-DD_HH-mm');

    makeFileDownload({
      name: `${selectedQuery}-${today}.csv`,
      contents: str,
      type: 'text/csv',
    });
  }

  function handleSearch(value: any) {
    setSearch(value);
    setQueriesToRender(getFilteredQueries(search));
  }

  function clearSelections() {
    setSelectedQuery(null);
    setQueryResponse([]);
    setError(null);
  }

  return (
    <AdminPage className={styles.PortalManagementQueries}>
      <div className={styles.queries}>
        <div className={styles.queriesHeader}>
          <strong>Queries</strong>
          <Input
            icon="search"
            showClear
            value={search}
            onChange={value => handleSearch(value)}
            placeholder="Search…"
          />
        </div>
        <div className={styles.queriesBody}>
          {!queriesToRender && <p>No queries found.</p>}
          {queriesToRender.map(query => (
            <div
              className={cx(styles.queryItem, styles.queryItemActive)}
              key={query.slug}
            >
              <ListExpandableForm
                title={query.name}
                description={query.description}
                values={query.conditions}
                buttonText="Query"
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(formValues: any) => Promise<void>' is not a... Remove this comment to see the full error message
                onClick={(formValues: any) =>
                  handleQuerySelect(query, formValues)
                }
              />
            </div>
          ))}
        </div>
      </div>
      <DisplayQueryResults
        error={error}
        queryResponse={queryResponse}
        clearSelections={clearSelections}
        selectedQuery={selectedQuery}
        onDownload={onDownload}
      />
    </AdminPage>
  );
}
