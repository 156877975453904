import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ContentRendererContext, UserDataContext } from 'lane-shared/contexts';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';
import { withQuantity } from './withQuantity';

import Button from '../../../general/Button';

import styles from './PurchaseBlock.scss';

export function PurchaseBlock({ style, ...otherProps }: WebBlockProps) {
  const { t } = useTranslation();
  const {
    loading,
    disabled,
    editMode,
    content,
    onSubmit,
    preventSubmission,
    hideSubmissionBlocks,
    blockContext,
  } = useContext(ContentRendererContext);
  const { user } = useContext(UserDataContext);
  const props = useEditModeProps(otherProps);
  const { menuFeature, quantityFeature } = explodeFeatures(content?.features);

  let buttonLabel = t(
    'web.admin.channel.content.layout.editor.components.purchaseBlock.buttons.purchase'
  );

  if (menuFeature) {
    buttonLabel = t(
      'web.admin.channel.content.layout.editor.components.purchaseBlock.buttons.viewCart'
    );
  }

  if (quantityFeature && disabled) {
    buttonLabel = t(
      'web.admin.channel.content.layout.editor.components.purchaseBlock.buttons.soldOut'
    );
  }

  if (!editMode && onSubmit) {
    props.onClick = onSubmit;
  }

  const {
    shouldDisableContentSubmission: isDisabledByUnmetRequirements,
  } = useHasMetRequirements({
    content,
    user,
    isEditMode: editMode,
    isVerificationEmailSent: blockContext.isVerificationEmailSent,
  });

  if (hideSubmissionBlocks) {
    return null;
  }

  return (
    <Button
      variant="contained"
      startIcon={<Icon name="credit-card" />}
      className={styles.PurchaseBlock}
      style={style}
      disabled={disabled || preventSubmission || isDisabledByUnmetRequirements}
      loading={loading}
      dataCy="PurchaseButton"
      {...props}
    >
      <span>{buttonLabel}</span>
    </Button>
  );
}

export default withQuantity(PurchaseBlock);
