import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';

import { routes } from 'lane-shared/config';

import { Button, M, TextArea } from 'design-system-web';
import { Dropdown, FileInput } from 'components';
import { AdminPage, Flex, PageHeader } from 'components/layout';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import makeFileDownload from 'helpers/makeFileDownload';

import { CustomImporterMapping, MappingDisplay } from './components';
import { Mapping, RowData } from './types';
import { IMPORT_TYPES } from './constants';
import styles from './styles.scss';

type Props = {
  channel: any;
};

export const ImportConverter = ({ channel }: Props) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [sourceCSV, setSourceCSV] = useState<Papa.ParseResult<unknown>>();
  const [destination, setDestination] = useState<string>('');
  const [importType, setImportType] = useState<string>();
  const [mapping, setMapping] = useState<Mapping>({});
  const [results, setResults] = useState<RowData[]>([]);

  const pageHeaderProps = {
    header: t('web.admin.sidebar.dataImport.converter.title'),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.importer.workorders.breadcrumb.data.import'),
        url: routes.channelAdminDataImportListView.replace(
          ':id',
          channel?.slug
        ),
      },
      {
        label: t('web.admin.sidebar.dataImport.converter.title'),
      },
    ],
  };

  const transformData = (str: string, index: number, transforms: string[]) => {
    let newStr = str;

    transforms.forEach(transform => {
      switch (transform) {
        case 'date': {
          const parsedDate = new Date(str);
          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : str;
          break;
        }
        case 'hours_to_number': {
          const time = str.split(':');
          newStr = (Number(time?.[0]) + Number(time?.[1]) / 60).toString();
          break;
        }
        case 'index': {
          newStr = String(index);
          break;
        }
        case 'date_range_start': {
          const dateRange = str.split('/');
          const parsedDate = new Date(dateRange?.[0]);
          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : '-';
          break;
        }
        case 'date_range_end': {
          const dateRange = str.split('/');
          const parsedDate = new Date(dateRange?.[1]);
          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : '-';
          break;
        }
        default:
          break;
      }
    });

    return newStr;
  };

  const convert = () => {
    const convertedData: RowData[] = [];

    sourceCSV?.data?.forEach((row, index) => {
      const convertedRow: RowData = {};
      const sourceRow = row as any;

      Object.keys(mapping).forEach(key => {
        const convertedField = mapping[key];

        if (convertedField.keys.length > 0) {
          convertedField.keys.forEach(field => {
            if (sourceRow[field]) {
              convertedRow[key] = transformData(
                sourceRow[field],
                index + 1,
                convertedField.transforms || []
              );
            } else {
              convertedRow[key] = '';
            }
          });
        } else if (convertedField.default) {
          convertedRow[key] = convertedField.default;
        } else {
          convertedRow[key] = transformData(
            '',
            index + 1,
            convertedField.transforms || []
          );
        }
      });

      convertedData.push(convertedRow);
    });

    setResults(convertedData);
  };

  const handleUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setDestination(name);

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    Papa.parse(file, {
      header: true,
      complete: result => {
        setSourceCSV(result);
        setInput(Papa.unparse(result.data));
      },
    });
  };

  const handleDownload = () => {
    makeFileDownload({
      name: `${destination} - Converted.csv`,
      contents: output,
      type: 'application/csv',
    });
  };

  const handleConvert = () => {
    convert();
  };

  useEffect(() => {
    setOutput(Papa.unparse(results));
  }, [results]);

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader {...pageHeaderProps} />
      </div>

      <Flex className={styles.bodyWrapper} direction="column" gap={5}>
        <M variant="secondary" className={styles.description}>
          {t('web.admin.sidebar.dataImport.converter.description')}
        </M>

        <FileInput
          accept="*/*"
          type={FileReturnTypeEnum.File}
          // @ts-expect-error
          onFileSelected={handleUpload}
        >
          <Button
            className={styles.convertButton}
            variant="secondary"
            onClick={() => setOutput(input)}
            type="submit"
          >
            {t('web.admin.sidebar.dataImport.converter.upload')}
          </Button>
        </FileInput>

        <TextArea
          label={t('web.admin.sidebar.dataImport.converter.input')}
          value={input}
          className={styles.inputField}
          onChange={() => {}}
          showLengthIndicator
          minRows={10}
          showClear={false}
        />

        <Flex direction="row">
          <Dropdown
            className={styles.importDropdown}
            items={Object.keys(IMPORT_TYPES).map(key => ({
              label: t(`web.admin.sidebar.dataImport.converter.import.${key}`),
              value: key,
            }))}
            value={importType}
            onValueChange={value => {
              setImportType(value);
              setMapping(IMPORT_TYPES[value]);
            }}
            placeholder="Select Import Type"
          />

          <Button
            className={styles.convertButton}
            variant="primary"
            onClick={handleConvert}
            type="submit"
            disabled={!sourceCSV || !importType}
          >
            {t('web.admin.sidebar.dataImport.converter.convert')}
          </Button>
        </Flex>

        {Object.keys(mapping).length > 0 ? (
          <MappingDisplay mapping={mapping} setMapping={setMapping} />
        ) : null}

        {importType === 'custom' ? (
          <CustomImporterMapping setCustomMapping={setMapping} />
        ) : null}

        <TextArea
          label={t('web.admin.sidebar.dataImport.converter.output')}
          value={output}
          className={styles.inputField}
          onChange={() => {}}
          showLengthIndicator
          minRows={10}
          showClear={false}
        />

        <Button
          className={styles.convertButton}
          variant="secondary"
          onClick={handleDownload}
          type="submit"
        >
          {t('web.admin.sidebar.dataImport.converter.download')}
        </Button>
      </Flex>
    </AdminPage>
  );
};
