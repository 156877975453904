import React from 'react';

import cx from 'classnames';

import CheckboxGroup from '../../../../form/CheckboxGroup';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './CheckboxesInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function CheckboxesInputBlock({
  keyPass,
  options,
  selected,
  style,
  onInput,
  disabled,
  isValid,
  validationMessage,
  className,
}: any) {
  return (
    <div
      className={cx(className, styles.CheckboxesInputBlock)}
      style={styleMap(false, style)}
    >
      <CheckboxGroup
        name={keyPass}
        schema={{ id: 'name', text: 'value' }}
        disabled={disabled}
        selected={selected ? selected.map((option: any) => option.value) : []}
        items={options}
        onChange={values =>
          onInput(
            options.filter((option: any) => values.includes(option.value))
          )
        }
      />
      {!isValid && <ValidationMessage errors={[validationMessage]} />}
    </div>
  );
}

CheckboxesInputBlock.defaultProps = {
  keyPass: '',
  options: [],
  selected: null,
  style: {},
  onInput: () => {},
  disabled: false,
  isValid: true,
  validationMessage: 'Required.',
};
