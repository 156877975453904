import React from 'react';

import { Button, TemplateLibrary, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { getDisplayName } from 'lane-shared/helpers';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './ChannelTemplateLibrary.scss';

export default function ChannelTemplateLibrary() {
  const location = useLocation();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return <Loading />;
  }

  return (
    <div className={styles.Templates}>
      <TemplateLibrary
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        userLibraryEnabled={channel?.settings?.areUserTemplatesAllowed}
        // @ts-expect-error
        library={{
          type: 'Channel',
          _id: channel?._id,
          name: getDisplayName(channel),
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '({ template, children }: any) => JSX.Element... Remove this comment to see the full error message
        TemplateWrapper={({ template, children }: any) => (
          <Link
            to={{
              pathname: `templates/${template._id}/edit`,
              state: { from: location.pathname },
            }}
          >
            {children}
          </Link>
        )}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'never'.
        additionalMenu={
          <Link
            to={{
              pathname: 'templates/new',
              state: { from: location.pathname },
            }}
          >
            <Button
              dataCy="newTemplate"
              variant="contained"
              size="small"
              className={styles.newTemplateButton}
            >
              {t('New Template')}
            </Button>
          </Link>
        }
      />
    </div>
  );
}
