import React, { useState } from 'react';

import { Icon, PopupMenu, Button } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  AddItemType,
  AddProductToChargeDetails,
  Payer,
  ProductType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { AddProductModalForm } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddProductForm/AddProductModalForm';
import { AddedItemsTable } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddedItemsTable/AddedItemsTable';
import { AddedProductsTotal } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-items-to-charge/AddedProductsTotal';

import { Modal } from 'components/lds';
import { H4 } from 'components/typography';

import styles from './addItemsToChargeStyles.scss';
import { AddCreditBundleModalForm } from './AddCreditBundleForm/AddCreditBundleModalForm';

interface AddItemsToChargeProps {
  listOfProducts: AddProductToChargeDetails[];
  setListOfProducts: React.Dispatch<
    React.SetStateAction<AddProductToChargeDetails[]>
  >;
  currency: SupportedPaymentCurrency;
  payer: Payer;
  inclusivePricing?: boolean;
  className?: string;
  showTotals?: boolean;
}

export function AddItemsToCharge({
  listOfProducts,
  setListOfProducts,
  currency,
  payer,
  className,
  inclusivePricing = false,
  showTotals = true,
}: AddItemsToChargeProps) {
  const { t } = useTranslation();

  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isAddCreditBundleModalOpen, setIsAddCreditBundleModalOpen] = useState(
    false
  );
  const [formType, setFormType] = useState<AddItemType | undefined>(undefined);
  const [productToEditDetails, setProductToEditDetails] = useState<
    AddProductToChargeDetails | undefined
  >(undefined);

  const closeAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const openAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  const closeAddCreditBundleModal = () => {
    setIsAddCreditBundleModalOpen(false);
  };

  const openAddCreditBundleModal = () => {
    setIsAddCreditBundleModalOpen(true);
  };

  const actionButtonDropdownItems = [
    {
      label: t('abp.charges.addProduct.addItemButton.addExistingItem'),
      onSelect: () => {
        setFormType(AddItemType.EXISTING);
        openAddProductModal();
      },
    },
    {
      label: t('abp.charges.addProduct.addItemButton.addCustomItem'),
      onSelect: () => {
        setFormType(AddItemType.CUSTOM);
        openAddProductModal();
      },
    },
  ];

  const handleEditExistingProduct = (product: AddProductToChargeDetails) => {
    setProductToEditDetails(product);
    if (
      product.productCategoryType === ProductType.PRODUCT_TYPE_CREDIT_BUNDLE
    ) {
      openAddCreditBundleModal();
    } else {
      setFormType(product.tableProductType);
      openAddProductModal();
    }
  };

  const handleRemoveExistingProduct = (product: AddProductToChargeDetails) => {
    setListOfProducts(prev =>
      prev.filter(
        existingProduct => existingProduct.tableRowId !== product.tableRowId
      )
    );
  };

  const addProduct = (product: AddProductToChargeDetails) => {
    setListOfProducts(prev => [...prev, product]);
  };

  const editProduct = async (updatedProduct: AddProductToChargeDetails) => {
    if (updatedProduct) {
      setListOfProducts(prev =>
        prev.map(existingProduct => {
          if (existingProduct.tableRowId === updatedProduct.tableRowId) {
            return updatedProduct;
          }
          return existingProduct;
        })
      );
    }
    setProductToEditDetails(undefined);
  };

  const isProductsPresent = listOfProducts.length > 0;

  return (
    <div className={cx(styles.addProductToChargeContainer, className)}>
      <H4 className={styles.addItemsHeader}>{t('abp.charges.lineItems')}</H4>
      <div className={styles.addItemsButtons}>
        <PopupMenu
          items={actionButtonDropdownItems}
          trigger={
            <Button
              endIcon={<Icon name="chevron-down" />}
              startIcon={<Icon name="plus" />}
              variant="secondary"
              size="medium"
              dataCy="addProductServiceButton"
            >
              {t('abp.charges.addItems.addProductServiceButton')}
            </Button>
          }
        />
        <Button
          startIcon={<Icon name="plus" />}
          variant="secondary"
          size="medium"
          dataCy="addCreditBundleButton"
          onClick={() => openAddCreditBundleModal()}
        >
          {t('abp.charges.addItems.addCreditBundleButton')}
        </Button>
      </div>
      {isProductsPresent && (
        <>
          <AddedItemsTable
            listOfProducts={listOfProducts}
            currency={currency}
            editProduct={handleEditExistingProduct}
            removeProduct={handleRemoveExistingProduct}
          />
          {showTotals && (
            <AddedProductsTotal
              currency={currency}
              listOfProducts={listOfProducts}
            />
          )}
        </>
      )}
      <Modal
        isCloseButtonHidden
        isOpen={isAddProductModalOpen}
        onClose={() => closeAddProductModal()}
        dataCy="addProductModal"
        style={{ width: '50%', maxWidth: '50vw' }}
        disableCloseOnBackgroundClick
      >
        <AddProductModalForm
          formType={formType}
          addProduct={addProduct}
          editProduct={editProduct}
          closeModal={closeAddProductModal}
          currency={currency}
          productToEdit={productToEditDetails}
          inclusivePricing={inclusivePricing}
          payer={payer}
        />
      </Modal>
      <Modal
        isCloseButtonHidden
        isOpen={isAddCreditBundleModalOpen}
        onClose={() => closeAddCreditBundleModal()}
        dataCy="addCreditBundleModal"
        style={{ width: '50%', maxWidth: '50vw' }}
        disableCloseOnBackgroundClick
      >
        <AddCreditBundleModalForm
          addProduct={addProduct}
          editProduct={editProduct}
          closeModal={closeAddCreditBundleModal}
          currency={currency}
          productToEdit={productToEditDetails}
          inclusivePricing={inclusivePricing}
        />
      </Modal>
    </div>
  );
}
