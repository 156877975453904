import React from 'react';
import type { Price } from 'graphql-query-contracts';
import {
  SupportedPaymentCurrency,
  TaxAmountType,
} from 'lane-shared/domains/billingPayments/types';
import { Icon, Tooltip, P } from 'design-system-web';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';
import { useTranslation } from 'react-i18next';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers/getPricing';
import cx from 'classnames';
import styles from './PriceException.scss';

type Props = {
  rate: number;
  taxValue?: number;
  taxType?: TaxAmountType;
  originalPrice: Price;
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  className?: string;
  dataCy?: string;
};

const ARROW = ' → ';

export function PriceException({
  rate,
  taxValue,
  taxType,
  originalPrice,
  currency,
  locale,
  className,
  dataCy,
}: Props) {
  const { t } = useTranslation();

  const currencyFormat = currencyFormatter({
    currency,
    locale,
  });

  const hasTax = taxValue !== undefined;

  const { listPrice } = getPricing({
    currency,
    locale,
    priceComponents: {
      amount: originalPrice.amount,
      markup: originalPrice.markup ?? undefined,
      tax: originalPrice.tax ?? undefined,
    },
  });

  const tooltipComponent = () => {
    return (
      <div className={styles.priceExceptionToolTip}>
        <P className={styles.textColor}>
          {t('abp.productServices.priceException.label')}
        </P>
        <P className={styles.textColor}>
          {t('abp.productServices.priceException.rate.label')}
          <span className={cx(styles.textColor, styles.striked)}>
            {currencyFormat(listPrice)}
          </span>
          {ARROW}
          {currencyFormat(rate)}
        </P>
        {hasTax && (
          <P className={styles.textColor}>
            {taxType === TaxAmountType.TaxAmountTypeFixed
              ? t(
                  'abp.productServices.priceException.conditional.tax.fixed.noComma.label',
                  {
                    taxAmount: currencyFormat(taxValue),
                  }
                )
              : t(
                  'abp.productServices.priceException.conditional.tax.percentage.noComma.label',
                  {
                    taxAmount: taxValue,
                  }
                )}
          </P>
        )}
      </div>
    );
  };

  return (
    <div className={cx(styles.priceException, className)} data-cy={dataCy}>
      <Tooltip
        TooltipComponent={tooltipComponent()}
        zIndex={100}
        placement="left"
        wrapperClassName={styles.container}
      >
        <Icon
          name="exclamation-circle"
          set="FontAwesome"
          type="far"
          size="medium"
        />
      </Tooltip>
    </div>
  );
}
