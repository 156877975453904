import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import {
  ControlMenu,
  Button,
  IconButton,
  ResizableWindow,
  ModalBackground,
  HorizontalContentCard,
  ContentFilterStatus,
  ContentSelector,
  TabStrip,
  Loading,
  Input,
} from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { toSchema } from 'lane-shared/helpers';
import {
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_SEARCH_TYPE_CHANNEL,
  CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useSectionContentForAdmin } from 'lane-shared/hooks';
import { SectionTypeEnum } from 'lane-shared/types/sections/SectionTypeEnum';

import { UCIs } from 'components/interactions/UCIs';

import ChannelAdminContext from '../../ChannelAdminContext';
import SectionContentTable from './SectionContentTable';

import styles from './styles.scss';

const views = ['list', 'th-large'];
const [VIEW_LIST, VIEW_GRID] = views;
const sorts = [
  '_created',
  '_updated',
  'name',
  'tags',
  'description',
  'interactive',
];

const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_NAME,
  ,
  SORT_DESCRIPTION,
  SORT_INTERACTIVE,
] = sorts;

const sortOrders = ['asc', 'desc'];
const [SORT_ASC, SORT_DESC] = sortOrders;

const headers = [
  { label: 'Created', sort: SORT_CREATED },
  { label: 'Updated', sort: SORT_UPDATED },
  { label: 'Name', sort: SORT_NAME },
  { label: 'Description', sort: SORT_DESCRIPTION },
  { label: 'Interactive', sort: SORT_INTERACTIVE },
  { label: 'Tags' },
  { label: 'Warnings' },
];

export default function SectionView() {
  const { t } = useTranslation();
  const availableTabs = [
    'web.admin.content.sections.view.availableTabs.pages',
    'web.admin.content.sections.view.availableTabs.interactions',
  ];
  const [TAB_PAGES, TAB_INTERACTIONS] = availableTabs;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sectionId' does not exist on type '{}'.
  const { sectionId } = useParams();
  const { channel } = useContext(ChannelAdminContext);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [query, goToUrl, makeUrl] = useQueryString({
    view: VIEW_GRID,
    sort: SORT_NAME,
    order: SORT_ASC,
    tab: TAB_PAGES,
  });

  const selectedTab = query.tab || TAB_PAGES;
  const selectedView = query.view || VIEW_LIST;
  const selectedSort = query.sort || SORT_NAME;
  const selectedOrder = query.order || SORT_ASC;
  const reverseOrder = selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC;

  const {
    section,
    sectionContent,
    loading,
    addContent,
    removeContent,
  } = useSectionContentForAdmin({
    sectionId,
    searchOptions: {
      areFiltersApplied: false,
      search,
      filters: [],
      sorts: [],
      metatagFilters: [],
      channelId: null,
    },
  });

  async function onAddContent(content: any) {
    setIsAddOpen(false);

    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.view.addContent.title', {
          contentName: content.name,
        }),
        message: t('web.admin.content.sections.view.addContent.message', {
          contentName: content.name,
        }),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    window.Alert.loading({
      title: t('web.admin.content.sections.view.adding'),
    });

    try {
      await addContent(content);
      window.Toast.show(
        t('web.admin.content.sections.view.addContent.toast', {
          contentName: content.name,
        })
      );
      window.Alert.hide();
    } catch (error) {
      window.Alert.alert({
        title: t('web.admin.content.sections.view.addContentError.title', {
          contentName: content.name,
        }),
        message: t('web.admin.content.sections.view.addContentError.message'),
        error,
      });
    }
  }

  async function onRemoveContent(sectionContent: any) {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.view.removeContent.title', {
          contentName: sectionContent.content.name,
        }),
        message: t('web.admin.content.sections.view.removeContent.title', {
          contentName: sectionContent.content.name,
          sectionName: section.name,
        }),
      });
    } catch (err) {
      return;
    }

    window.Alert.loading({
      title: t('web.admin.content.sections.view.removing'),
    });

    try {
      await removeContent(sectionContent);
      window.Toast.show(
        t('web.admin.content.sections.view.removeContent.toast', {
          contentName: sectionContent.content.name,
        })
      );
      window.Alert.hide();
    } catch (error) {
      await window.Alert.alert({
        title: t('web.admin.content.sections.view.removeContentError.title', {
          contentName: sectionContent.content.name,
        }),
        error,
      });
    }
  }

  sectionContent.sort((a: any, b: any) => {
    if (selectedSort === SORT_INTERACTIVE) {
      if (a.content.isInteractive && b.content.isInteractive) {
        return 0;
      }

      if (a.content.isInteractive) {
        return -1;
      }

      return 1;
    }

    if (query.view === VIEW_LIST) {
      return (
        // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
        a.content[selectedSort].localeCompare(b.content[selectedSort]) *
        (selectedOrder === SORT_ASC ? 1 : -1)
      );
    }
    return a.content.name.localeCompare(b.content.name);
  });

  return (
    <div className={styles.SectionView}>
      <ControlMenu>
        <TabStrip
          disabled={loading}
          tabs={availableTabs.map(toSchema)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'SchemaType<string | number>' is not assignab... Remove this comment to see the full error message
          selected={toSchema(selectedTab)}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
        />
        <hr />
        {selectedTab === TAB_PAGES &&
          views.map(view => (
            <Link key={view} to={makeUrl({ view })}>
              <IconButton
                icon={view}
                className={styles.viewIcon}
                selected={selectedView === view}
                inverted
              />
            </Link>
          ))}
        <Link to="edit">
          <Button>{t`web.admin.content.sections.view.editSection`}</Button>
        </Link>

        {section?.type === SectionTypeEnum.Static && (
          <>
            <Link to="../../page-center" className="linkNewPage">
              <Button variant="contained">{t`web.admin.content.sections.view.newPage`}</Button>
            </Link>

            <Button
              variant="contained"
              className="btnAddPage"
              onClick={() => setIsAddOpen(true)}
            >
              {t`web.admin.content.sections.view.addPage`}
            </Button>
          </>
        )}
      </ControlMenu>

      {selectedTab === TAB_INTERACTIONS && (
        <UCIs channelId={channel?._id} sectionId={section?._id} />
      )}

      {selectedTab === TAB_PAGES && (
        <>
          <Input
            value={search}
            onChange={search => setSearch(search)}
            icon="search"
            showClear
          />

          {selectedView === VIEW_LIST && (
            <SectionContentTable
              headers={headers}
              section={section}
              sectionContent={sectionContent}
              channel={channel}
              selectedOrder={selectedOrder}
              selectedSort={selectedSort}
              reverseOrder={reverseOrder}
              onRemoveContent={onRemoveContent}
              makeUrl={makeUrl}
            />
          )}

          <ul>
            {selectedView === VIEW_GRID &&
              sectionContent?.map((sectionContent: any) => (
                <li key={sectionContent._id}>
                  <Link
                    to={`/l/channel/${channel?.slug}/admin/post/${sectionContent.content._id}`}
                  >
                    <HorizontalContentCard
                      content={sectionContent.content}
                      className={styles.contentCard}
                    />
                  </Link>
                  <ContentFilterStatus
                    content={sectionContent.content}
                    metatags={section?.sectionMetatags?.map(
                      ({ metatag }: any) => metatag
                    )}
                    filters={section?.filters}
                    sorts={section?.sorts}
                  />
                  {section?.type === SectionTypeEnum.Static && (
                    <Icon
                      className={styles.deleteButton}
                      name="times-circle"
                      set={ICON_SET_FONTAWESOME}
                      dataCy="buttonRemove"
                      onClick={() => onRemoveContent(sectionContent)}
                    />
                  )}
                </li>
              ))}
          </ul>
        </>
      )}
      {loading && <Loading className={styles.loading} />}
      <ModalBackground
        onClose={() => setIsAddOpen(false)}
        isOpen={isAddOpen}
        className={styles.background}
      >
        <ResizableWindow
          showHeader
          onClose={() => setIsAddOpen(false)}
          name="channelSectionView"
          className={styles.window}
          defaultPosition={ResizableWindow.fullScreen()}
        >
          <ContentSelector
            className={styles.search}
            channelId={channel?._id}
            onContentSelected={onAddContent}
            availableTypes={[CONTENT_TYPES.STATIC]}
            contentSearchLocations={[CONTENT_LOCATION_PAGE_CENTER]}
            contentQueryType={CONTENT_SEARCH_TYPE_CHANNEL}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
            filterIds={sectionContent.map(
              (sectionContent: any) => sectionContent.content._id
            )}
            includeGeneratedBatchContent
            headerComponent={
              <h1 className={styles.header}>
                {t`web.admin.content.sections.view.newPage`}
                {section?.name}
              </h1>
            }
            emptyComponent={
              <div className={styles.noResults}>
                <h1>
                  {t('web.admin.content.sections.view.addToComponent.title', {
                    sectionName: section?.name,
                  })}
                </h1>
                <p>{t`web.admin.content.sections.view.addToComponent.body`}</p>
              </div>
            }
          />
        </ResizableWindow>
      </ModalBackground>
    </div>
  );
}
