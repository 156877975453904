import React from 'react';

import { ContextMenu, IconButton } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import {
  resumeCredential as resumeCredentialMutation,
  revokeCredential as revokeCredentialMutation,
  suspendCredential as suspendCredentialMutation,
} from 'lane-shared/graphql/accessControl';

import { H5 } from 'components/typography';

import { availableCredentialActions } from './availableCredentialActions';
 
import { Credential } from 'graphql-query-contracts';
import { ModalPositionEnum } from 'hooks/useModalPosition';

import styles from './BuildingAccess.scss';

type Props = {
  credential: Credential;
  channelId: string;
  refetch: () => void;
};

export const CredentialActionCell = ({
  credential,
  channelId,
  refetch,
}: Props) => {
  const { t } = useTranslation();

  function showConfirmationMessage(message: string) {
    window.Toast.show(
      <div>
        <H5>{message}</H5>
        <p>
          {t(
            'Please note that this may take several minutes to update in the table after a refresh.'
          )}
        </p>
      </div>,
      5_000
    );
  }

  async function confirmRevokeCredential(mapId: string) {
    try {
      await window.Alert.confirm({
        title: t('Confirm revoking access'),
        message: t(
          'Revoking access is a permanent action. The user will no longer be able to use this credential.'
        ),
        confirmText: t('Revoke'),
      });
    } catch (e) {
      // user canceled
      return;
    }

    try {
      await getClient().mutate({
        mutation: revokeCredentialMutation,
        variables: {
          channelId,
          mapId,
        },
      });

      showConfirmationMessage(t('Access revoked for credential.'));
      refetch();
    } catch (e) {
      console.error(e);
      window.Alert.alert({
        message: t('There was an error revoking this credential.'),
      });
    }
  }

  async function suspendCredential(mapId: string) {
    try {
      await getClient().mutate({
        mutation: suspendCredentialMutation,
        variables: {
          channelId,
          mapId,
        },
      });

      showConfirmationMessage(t('Access suspended for credential.'));
      refetch();
    } catch (e) {
      console.error(e);
      window.Alert.alert({
        message: t('There was an error suspending this credential.'),
      });
    }
  }

  async function resumeCredential(mapId: string) {
    try {
      await getClient().mutate({
        mutation: resumeCredentialMutation,
        variables: {
          channelId,
          mapId,
        },
      });

      showConfirmationMessage(t('Access resumed for credential.'));
      refetch();
    } catch (e) {
      console.error(e);
      window.Alert.alert({
        message: t('There was an error resuming this credential.'),
      });
    }
  }
  const availableActions = availableCredentialActions(credential);

  return (
    <>
      {availableActions.canSuspendOrRevoke() && (
        <ContextMenu
          autoClose
          items={
            <div className={styles.actions}>
              {availableActions.canSuspend() && (
                <button
                  className={styles.actionButton}
                  onClick={() => suspendCredential(credential.mapId)}
                >
                  {t('Suspend access')}
                </button>
              )}
              <button
                className={styles.actionButton}
                onClick={() => confirmRevokeCredential(credential.mapId)}
              >
                {t('Revoke access')}
              </button>
            </div>
          }
          hasOpaqueBackground={false}
          align={ModalPositionEnum.Left}
        >
          <IconButton
            icon="ellipsis-v"
            iconSet="FontAwesome"
            testId="row-actions"
          />
        </ContextMenu>
      )}
      {availableActions.canResume() && (
        <a
          role="button"
          onClick={() => resumeCredential(credential.mapId)}
          tabIndex={0}
          href="#"
        >
          {t('Resume')}
        </a>
      )}
    </>
  );
};
