import React, { useContext, useState } from 'react';

import {
  ChannelCircleListView,
  ChannelRelationshipsSearch,
  IconButton,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { deleteChannelRelationship } from 'lane-shared/graphql/mutation';
import { pause, getDisplayName } from 'lane-shared/helpers';
import { shortAddress } from 'lane-shared/helpers/formatters';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import { AdminPage } from 'components/layout';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';

const PROPERTY_TYPES = [ChannelTypeEnum.Property];

const TRANSLATION_KEYS = {
  removeConfirmTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.title',
  removeConfirmMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.message',
  removeConfirmText:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeConfirm.confirmText',
  removeSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeSuccessToast',
  removeErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeError.title',
  removeErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.removeError.message',
};

export default function NonPropertyChannelRelationships() {
  const { user } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function removeRelationship(relationship: any) {
    try {
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.removeConfirmTitle, {
          channelName: getDisplayName(relationship.relatedTo),
        }),
        message: t(TRANSLATION_KEYS.removeConfirmMessage, {
          channelName: getDisplayName(relationship.relatedTo),
        }),
        confirmText: t(TRANSLATION_KEYS.removeConfirmText),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: deleteChannelRelationship,
        variables: { id: relationship._id },
        refetchQueries: ['ChannelsByRelationship'],
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.removeSuccessToast, {
            channelName: getDisplayName(relationship.relatedTo),
          })}
        </p>
      );
    } catch (err: any) {
      await window.Alert.show({
        title: t(TRANSLATION_KEYS.removeErrorTitle, {
          channelName: getDisplayName(relationship.relatedTo),
        }),
        message: t(TRANSLATION_KEYS.removeErrorMessage, {
          errorMessage: err.message,
        }),
      });
    }

    setLoading(false);
  }

  function getLink(rel: any) {
    if (user?.isSuperUser) {
      return routes.channelAdmin.replace(':id', rel.slug);
    }

    return routes.channel.replace(':id', rel.slug);
  }

  return (
    <AdminPage className={styles.ChannelRelationships}>
      <ChannelRelationshipsSearch
        channelId={channel?._id}
        mode="relatedTo"
        types={PROPERTY_TYPES}
        renderRelationship={relationship => (
          <div className={styles.channel} key={relationship._id}>
            <Link to={getLink(relationship.relatedTo)}>
              <ChannelCircleListView
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
                channel={relationship.relatedTo}
                description={shortAddress(relationship.relatedTo.address!)}
              />
            </Link>
            <IconButton
              dataCy="removeTenant"
              icon="times"
              inverted
              disabled={loading}
              className={styles.remove}
              onClick={() => removeRelationship(relationship)}
            />
          </div>
        )}
      />
    </AdminPage>
  );
}
