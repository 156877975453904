import React, { useEffect, useMemo, useState } from 'react';

import {
  AdminPage,
  Button,
  Input,
  PageNavigationAlert,
  PageHeader,
  MultiselectField,
} from 'components';
import { history } from 'helpers';
import { useFormServerErrorHandler } from 'hooks';
import { useTranslation } from 'react-i18next';
import { object, number, ValidationError } from 'yup';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { floorErrorCodeTypes } from 'activate-errors';
import {
  createFloor,
  CreateFloorMutationResponse,
} from 'lane-shared/graphql/floors';
import { getAggregatedValidationMessage } from 'lane-shared/helpers';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { useGetUnitsFieldOptions } from '../hooks';
import {
  FloorInputFieldKey,
  FloorInputFields,
  ServerErrorCodeToFieldMapping,
} from '../types';

import styles from './styles.scss';

type Props = {
  channel: Pick<ChannelType, '_id' | 'name' | 'slug'>;
};

export const CreateFloor = ({ channel }: Props) => {
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const { t } = useTranslation();

  const [isFloorCreated, setIsFloorCreated] = useState(false);
  const [floorInputFields, setFloorInputFields] = useState<FloorInputFields>({
    index: undefined,
    name: '',
    units: [],
  });
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationError | null>(null);

  const { options, error, loading: isFetchingUnits } = useGetUnitsFieldOptions(
    channel._id
  );
  const genericServerErrorMessage = t('shared.floors.errors.generic');

  const unitsDropdownNoOptionsMessage = error
    ? genericServerErrorMessage
    : t('web.admin.channel.floors.create.form.units.noUnitsFound');

  const showToast = (message: string) => {
    window.Toast.show(message);
  };

  const {
    serverFormFieldErrors,
    resetServerFormFieldErrors,
    handleServerError,
  } = useFormServerErrorHandler({
    errorCodeTypes: floorErrorCodeTypes,
    errorCodeToFormFieldMap: ServerErrorCodeToFieldMapping,
    show: showToast,
    defaultErrorMessage: genericServerErrorMessage,
  });

  const [
    createFloorMutation,
    { loading },
  ] = useMutation<CreateFloorMutationResponse>(createFloor);

  const floorInputLabel = t('web.admin.channel.floors.create.form.floor.label');

  const floorValidator = object().shape({
    index: number().required().label(floorInputLabel),
  });

  const isPristine = useMemo(() => {
    return (
      (floorInputFields.name?.length === 0 ||
        floorInputFields.name === undefined) &&
      (floorInputFields.index === undefined || floorInputFields.index === null)
    );
  }, [floorInputFields]);

  useEffect(() => {
    if (isFloorCreated) {
      history.push(
        routes.channelAdminFloorsListView.replace(':id', channel.slug)
      );
    }
  }, [isFloorCreated, channel.slug]);

  const handleOnChange = (
    key: FloorInputFieldKey,
    value: FloorInputFields[FloorInputFieldKey]
  ) => {
    setFloorInputFields({
      ...floorInputFields,
      [key]: value,
    });
  };

  const handleOnCreate = async () => {
    try {
      floorValidator.validateSync(
        { index: floorInputFields.index },
        { abortEarly: false }
      );

      setValidationError(null);
      resetServerFormFieldErrors();

      const floorInput = {
        ...floorInputFields,
        index: Number(floorInputFields.index),
        units: floorInputFields.units?.map(unit => unit.value),
      };

      await createFloorMutation({
        variables: { propertyId: channel._id, floor: floorInput },
      });

      window.Toast.show(t('web.admin.channel.floors.create.successToast'));

      setIsFloorCreated(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationError(error);
      } else {
        handleServerError(error);
      }
    }
  };

  const handleOnCancel = () => {
    const url = routes.channelAdminFloorsListView.replace(':id', channel.slug);
    history.push(url);
  };

  const getErrorMessage = (field: FloorInputFieldKey) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError,
      field
    );

    if (validationMessage) {
      return [validationMessage];
    }

    // check server errors
    const errorMessages = serverFormFieldErrors[field];
    if (errorMessages) {
      return [errorMessages];
    }

    return [];
  };

  const renderCTAButtons = () => {
    return (
      <>
        <Button
          onClick={handleOnCreate}
          dataCy="createButton"
          variant="contained"
          disabled={isPristine}
          loading={loading}
        >
          {t('web.admin.channel.floors.create.createButton')}
        </Button>
        <Button
          onClick={handleOnCancel}
          dataCy="cancelButton"
          variant="outlined"
        >
          {t('web.admin.channel.floors.create.cancelButton')}
        </Button>
      </>
    );
  };

  if (!unitsFloorsEnabled) return null;

  return (
    <AdminPage className={styles.adminPage}>
      <PageNavigationAlert
        when={!isPristine && !isFloorCreated}
        dataCy="pageNavigationAlert"
      />
      <PageHeader
        headerLevel="h3"
        header={t('web.admin.channel.floors.create.header', {
          propertyChannelName: channel.name,
        })}
        breadcrumbs={[
          {
            label: t('web.admin.channel.floors.create.breadcrumb.floors'),
            url: routes.channelAdminFloorsListView.replace(':id', channel.slug),
          },
          {
            label: t('web.admin.channel.floors.create.breadcrumb.addFloor'),
          },
        ]}
      />
      <div className={styles.addFloorContainer}>
        <div className={styles.formContainer}>
          <Input
            fieldName="index"
            label={floorInputLabel}
            type="number"
            fixedLabel
            dataCy="indexInput"
            value={floorInputFields.index}
            error={getErrorMessage('index')}
            onChange={value => handleOnChange('index', value)}
            placeholder={t(
              'web.admin.channel.floors.create.form.floor.placeholder'
            )}
            isRequired
          />
          <Input
            fieldName="name"
            label={t('web.admin.channel.floors.create.form.name.label')}
            fixedLabel
            dataCy="nameInput"
            value={floorInputFields.name}
            error={getErrorMessage('name')}
            onChange={value => handleOnChange('name', value)}
            placeholder={t(
              'web.admin.channel.floors.create.form.name.placeholder'
            )}
          />
          <MultiselectField
            label={t('web.admin.channel.floors.create.form.units.label')}
            fixedLabel
            isFullWidth
            dataCy="unitsDropdown"
            items={options}
            onChange={value => handleOnChange('units', value)}
            value={floorInputFields.units}
            placeholder={t(
              'web.admin.channel.floors.create.form.units.placeholder'
            )}
            noOptionsMessage={unitsDropdownNoOptionsMessage}
            doTranslation={false}
            isLoading={isFetchingUnits}
          />
          <div className={styles.buttonContainer}>{renderCTAButtons()}</div>
        </div>
      </div>
    </AdminPage>
  );
};
