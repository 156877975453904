import React, { useEffect, useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { getInvoiceDetails } from 'lane-shared/domains/billingPayments/helpers/getInvoiceDetails';
import {
  ChargePartial,
  Invoice,
} from 'lane-shared/domains/billingPayments/types';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';

import { PageHeader } from 'lane-web/src/components/layout';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';

import { ChipStyle } from 'components/ads';

import { ChargeList } from './ChargeList';
import { Info } from './Info';
import { InvoiceTotal } from './InvoiceTotal';

import styles from './styles.scss';

export function InvoiceDetails() {
  const location = useLocation<{ invoice: Invoice }>();
  const groupId =
    location.state?.invoice.groupId ||
    new URLSearchParams(location.search).get('groupId');
  const { invoiceDisplayId } = useParams<{ invoiceDisplayId: string }>();
  const [invoiceDetails, setInvoiceDetails] = useState<{
    invoice: Invoice | undefined;
    charges: ChargePartial[];
  }>();

  useEffect(() => {
    if (groupId) {
      const fetchData = async () => {
        const invoiceDetailsResponse = await getInvoiceDetails(
          groupId,
          invoiceDisplayId
        );
        setInvoiceDetails(invoiceDetailsResponse);
      };

      fetchData();
    }
  }, [groupId, invoiceDisplayId]);

  const { invoiceDisplayName } = useBillingPaymentSettingsForChannel({
    channelId: groupId || undefined,
  });

  const invoice = invoiceDetails?.invoice;
  const charges = invoiceDetails?.charges || [];
  const displayId = invoice?.displayId || '';
  const statusMap = invoice
    ? getInvoiceStatus(invoice.status, invoice)
    : ['', ''];
  const isPaid = statusMap[0] === 'abp.invoiceList.status.paid';
  const headerLabel = `${invoiceDisplayName} ${displayId}`;

  return (
    <span>
      {invoice && (
        <>
          <PageHeader
            header={headerLabel}
            headerLevel="h3"
            status={statusMap[0]}
            statusType={statusMap[1] as ChipStyle}
          />
          <div className={styles.divider} />
          <Info invoice={invoice} isPaid={isPaid} />
          <ChargeList invoice={invoice} charges={charges} />
          <InvoiceTotal invoice={invoice} charges={charges} />
        </>
      )}
    </span>
  );
}
