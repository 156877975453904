import React from 'react';

import { useTranslation } from 'react-i18next';

import { Currency } from 'lane-shared/domains/billingPayments/types';

import CardContainer from 'components/cards/CardContainer';
import Dropdown from 'components/form/Dropdown';
import { H5, M, S } from 'components/typography';

import styles from './styles.scss';

interface CurrencySettingProps {
  currency: Currency;
  updateCurrency: (currency: Currency) => void;
}

const currencyOptions = [
  {
    label: 'abp.billingAndPaymentSettings.currency.aud',
    value: Currency.CURRENCY_AUD,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.brl',
    value: Currency.CURRENCY_BRL,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.cad',
    value: Currency.CURRENCY_CAD,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.eur',
    value: Currency.CURRENCY_EUR,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.gbp',
    value: Currency.CURRENCY_GBP,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.hkd',
    value: Currency.CURRENCY_HKD,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.huf',
    value: Currency.CURRENCY_HUF,
  },
  {
    label: 'abp.billingAndPaymentSettings.currency.usd',
    value: Currency.CURRENCY_USD,
  },
];

export function CurrencySetting({
  currency,
  updateCurrency,
}: CurrencySettingProps) {
  const { t } = useTranslation();

  return (
    <CardContainer className={styles.PaymentSettings}>
      <div className={styles.SectionHeader}>
        <H5>{t('abp.billingAndPaymentSettings.currency.header')}</H5>
        <S>{t('abp.billingAndPaymentSettings.currency.description')}</S>
      </div>

      <M mt={2} className={styles.FieldLabel}>
        <label>{t('abp.billingAndPaymentSettings.currency.label')}</label>
      </M>
      <div className={styles.CurrencyValueContainer}>
        <Dropdown
          className={styles.CurrencyDropdown}
          value={currency}
          items={currencyOptions}
          onChange={option => updateCurrency(option.value)}
          dataCy="currencyDropdownInput"
        />
      </div>
    </CardContainer>
  );
}
