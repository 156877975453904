import React from 'react';

import { MediaLibrary } from 'components';

import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { MediaType } from 'lane-shared/hooks/useMediaLibrary';

import styles from './styles.scss';

export default function MyMedia({ user, history }: any) {
  return (
    <div className={styles.MyMedia}>
      <MediaLibrary
        className={styles.mediaLibrary}
        storageKey={user._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(media: any) => any' is not assignable to ty... Remove this comment to see the full error message
        onMediaDoubleClicked={(media: MediaType, libraryItemId: string) =>
          history.push(
            `${history.location.pathname}/${media._id}/edit?libraryItemId=${libraryItemId}`
          )
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; _id: any; name: string; }' i... Remove this comment to see the full error message
        library={{
          type: 'User',
          _id: user._id,
          name: getDisplayName(user),
        }}
      />
    </div>
  );
}
