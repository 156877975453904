import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { useEssensysUserMetadata } from 'lane-shared/hooks';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { AccountInvoices } from 'lane-web/src/domains/billingAndPayments/components/AccountInvoices/index';
import { InvoiceDetails } from 'lane-web/src/domains/billingAndPayments/components/InvoiceDetails/index';
import { RecurringCharges } from 'lane-web/src/domains/billingAndPayments/components/RecurringCharges/index';
import { Credits } from 'lane-web/src/domains/billingAndPayments/components/Credits/index';
import { WalletV2 } from 'lane-web/src/domains/billingAndPayments/components/WalletV2';

import { NavLinks } from 'components/general';
import Essensys from 'components/integrations/Essensys/Home';
import Invoice from 'components/integrations/Essensys/Invoice';
import EssensysCreditsBlock from 'components/renderers/v5/primitives/EssensysCreditsBlock';
import { H3 } from 'components/typography';

import { InvoiceOrderSummary } from './InvoiceOrderSummary';
import {
  essensysRoutes,
  essensysAdminRoutes,
  wallet,
  accountInvoices,
  recurringCharges,
  credits,
} from './profileRoutes';

import styles from './styles.scss';

export function BillingAndPayments() {
  const { isEssensysAdmin, isEssensysUser } = useEssensysUserMetadata();
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);

  const chargesInvoiceFlagEnabled = useFlag(FeatureFlag.ChargesInvoice, false);
  const creditsFlagEnabled = useFlag(FeatureFlag.Credits, false);

  const menuItems = [];
  menuItems.push(...wallet);

  if (isEssensysAdmin) {
    menuItems.push(...essensysRoutes);
    menuItems.push(...essensysAdminRoutes);
  } else if (isEssensysUser) {
    menuItems.push(...essensysRoutes);
  } else {
    if (chargesInvoiceFlagEnabled) {
      menuItems.push(...accountInvoices);
      menuItems.push(...recurringCharges);
    }

    if (creditsFlagEnabled) {
      menuItems.push(...credits);
    }
  }

  return (
    <div className={styles.billingPaymentsTab}>
      <H3 className={styles.billingPaymentsHeader}>
        {t('abp.routes.billingsAndPayments')}
      </H3>
      <NavLinks className={styles.billingPaymentsTabs} routes={menuItems} />
      <div>
        <Switch>
          <Route exact path={routes.meWallet} render={() => <WalletV2 />} />

          <Route
            exact
            path={routes.meAccountInvoices}
            render={() => <AccountInvoices />}
          />

          <Route
            exact
            path={routes.meCompanyInvoiceDetails}
            render={() => <InvoiceDetails />}
          />

          <Route
            exact
            path={routes.meInvoicePayment}
            render={() => <InvoiceOrderSummary />}
          />

          <Route
            exact
            path={routes.meEssensysInvoices}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ content: {}; }' is not assignable to type ... Remove this comment to see the full error message
            render={() => <Essensys content={{}} />}
          />

          <Route
            exact
            path={routes.meRecurringCharges}
            render={() => <RecurringCharges />}
          />

          <Route
            exact
            path={routes.meCredits}
            render={() => <Credits user={user} />}
          />

          <Route
            exact
            path={routes.meEssensysCredits}
            render={() => (
              // @ts-expect-error ts-migrate(2740) FIXME: Type '{ autoHide: false; printingCredits: true; cr... Remove this comment to see the full error message
              <EssensysCreditsBlock
                autoHide={false}
                printingCredits
                credits
                profile
              />
            )}
          />

          <Route
            exact
            path={routes.essensysInvoice}
            render={({ match }) => (
              <Invoice invoiceId={match.params.invoiceId} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
