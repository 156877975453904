/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Flex } from 'components';
import { Button, Icon } from 'design-system-web';

import { StepSelector } from './StepSelector';

import { MeterReadingOption, MREquipment, StepType } from './types';
import { StepTemplate } from './StepTemplate';
import styles from './ScheduleSteps.scss';

interface ScheduleStepsProps {
  currentSteps: StepType[];
  setCurrentSteps: (steps: StepType[]) => void;
  meterReadings: MeterReadingOption[];
  mrEquipments: MREquipment[];
  onAddStep?: () => void;
}

export const ScheduleSteps = ({
  currentSteps,
  setCurrentSteps,
  meterReadings,
  mrEquipments,
  onAddStep = () => {},
}: ScheduleStepsProps) => {
  const { t } = useTranslation();
  const [stepSelectorOpen, setStepSelectorOpen] = useState(false);

  const handleAddStep = () => {
    setStepSelectorOpen(true);
    onAddStep();
  };

  const handleSelect = (type: string) => {
    setStepSelectorOpen(false);
    switch (type) {
      case 'instruction':
        setCurrentSteps([
          ...currentSteps,
          {
            id: Date.now().toString(),
            type: 'instruction',
            options: {},
          },
        ]);
        break;
      case 'acknowledge':
        setCurrentSteps([
          ...currentSteps,
          {
            id: Date.now().toString(),
            type: 'acknowledge',
            options: {},
          },
        ]);
        break;
      case 'response':
        setCurrentSteps([
          ...currentSteps,
          {
            id: Date.now().toString(),
            type: 'response',
            options: { subtype: 'short' },
          },
        ]);
        break;
      case 'choice':
        setCurrentSteps([
          ...currentSteps,
          {
            id: Date.now().toString(),
            type: 'choice',
            options: {
              subtype: 'single',
              choices: ['Option 1', 'Option 2'],
            },
          },
        ]);
        break;
      case 'meter':
        setCurrentSteps([
          ...currentSteps,
          {
            id: Date.now().toString(),
            type: 'meter',
            options: {},
          },
        ]);
        break;
      default:
        break;
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.source || !result.destination) return;

    const newSteps = Array.from(currentSteps);
    const [reorderedItem] = newSteps.splice(result.source.index, 1);

    newSteps.splice(result.destination.index, 0, reorderedItem);
    setCurrentSteps(newSteps);
  };

  const handleUpdate = (step: StepType) => {
    const updatedSteps = currentSteps.map(s => {
      if (s.id === step.id) {
        return step;
      }

      return s;
    });

    setCurrentSteps(updatedSteps);
  };

  const handleCopy = (step: StepType) => {
    const newStep = {
      ...step,
      id: `${Date.now()}`,
    };

    setCurrentSteps([...currentSteps, newStep]);
  };

  const handleDelete = (id: string) => {
    const newSteps = currentSteps.filter(step => step.id !== id);
    setCurrentSteps(newSteps);
  };

  return (
    <Flex direction="column" className={styles.stepsSection}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <ul
              ref={provided.innerRef}
              className={styles.stepsList}
              {...provided.droppableProps}
            >
              {currentSteps.map((step, index) => (
                <Draggable key={step.id} draggableId={step.id} index={index}>
                  {provided => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StepTemplate
                        index={index}
                        step={step}
                        updateStep={handleUpdate}
                        meterReadings={meterReadings}
                        mrEquipments={mrEquipments}
                        onCopyStep={handleCopy}
                        onDeleteStep={handleDelete}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {stepSelectorOpen ? (
        <StepSelector
          meterReadings={meterReadings}
          onSelect={handleSelect}
          onCancel={() => setStepSelectorOpen(false)}
        />
      ) : (
        <div className={styles.addStepButton}>
          <Button
            variant="text"
            startIcon={<Icon name="plus" />}
            onClick={() => handleAddStep()}
            fullWidth={false}
            size="medium"
            dataCy="add-step-button"
          >
            {t('web.admin.workOrder.steps.addSteps')}
          </Button>
        </div>
      )}
    </Flex>
  );
};
