import React from 'react';

import { Input, Radio, Label } from 'components';
import { useTranslation } from 'react-i18next';

import { H3, P } from 'components/typography';

import { Template } from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import styles from '../../styles.scss';

import { CesOptionsType } from '../../types';

type CesAutoSetupOptionsParams = {
  cloningChannelTemplateId: string | null;
  onCloningTemplateChange: (templateId: string) => void;
  templateType: Template | null;
  onTemplateTypeChange: (template: Template) => void;
} & CesOptionsType;

export function CesAutoSetupOptions(params: CesAutoSetupOptionsParams) {
  const {
    cloningChannelTemplateId,
    templateType,
    onCloningTemplateChange,
    onTemplateTypeChange,
    cesOrganizationId,
    setCesOrganizationId,
    vcmBuildingIds,
    setVcmBuildingIds,
  } = params;
  const { t } = useTranslation();

  const selectSettingsTitle = t`web.admin.channel.autoSetupWizard.leaseTitle`;
  const selectSettingsDescription = t`web.admin.channel.autoSetupWizard.leaseSubtitle`;
  const allPropsOption = t`web.admin.channel.autoSetupWizard.allPropertiesOption`;
  const selectTemplateDescription = t`web.admin.channel.autoSetupWizard.selectATemplateDescription`;
  const channelIdPlaceholder = t`web.admin.channel.autoSetupWizard.channelId`;
  const blanckChannelTitle = t`web.admin.channel.autoSetupWizard.createBlank`;
  const vcmBuildingIdsTitle = 'Enter comma-seperated list of VCM buildings';

  if (!setCesOrganizationId || !setVcmBuildingIds) {
    return null;
  }

  return (
    <section className={styles.Section} data-testid="wizard-template-id-page">
      <H3>{selectSettingsTitle}</H3>
      <P mb={4}>{selectSettingsDescription}</P>
      <Label>{allPropsOption}</Label>
      <Input
        value={cesOrganizationId}
        onChange={v => setCesOrganizationId(v)}
        style={{ width: '30%', marginLeft: '2rem' }}
        placeholder="Enter VCM Account Id"
        error={undefined}
      />

      <P mt={4}>{selectTemplateDescription}</P>
      <div className={styles.Section__RadioGroup}>
        <Radio
          value={Template.EXISTING}
          text="web.admin.channel.autoSetupWizard.fromExisting"
          selected={
            templateType === Template.EXISTING ? Template.EXISTING : undefined
          }
          onChange={() => onTemplateTypeChange(Template.EXISTING)}
          style={{ marginTop: '1.5rem' }}
        />
        {templateType === Template.EXISTING && (
          <Input
            value={cloningChannelTemplateId}
            onChange={v => onCloningTemplateChange(v)}
            style={{ width: '30%', marginLeft: '2rem' }}
            placeholder={channelIdPlaceholder}
            error={undefined}
          />
        )}
        <Radio
          value={Template.BLANK}
          text={blanckChannelTitle}
          selected={
            templateType === Template.BLANK ? Template.BLANK : undefined
          }
          onChange={() => {
            onTemplateTypeChange(Template.BLANK);
          }}
          style={{ marginTop: '1.5rem' }}
        />
      </div>
      <Label>{vcmBuildingIdsTitle}</Label>
      <Input
        value={vcmBuildingIds}
        onChange={setVcmBuildingIds}
        style={{ width: '30%', marginLeft: '2rem' }}
        placeholder="e.g. 129495, 141191"
        error={undefined}
      />
    </section>
  );
}
