import React, { useContext, useState } from 'react';

import { ControlMenu, Button, ErrorMessage, Toggle } from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { TypeContextEnum } from 'lane-shared/types/properties/TypeContextEnum';
import { validateUpdateMetatag } from 'lane-shared/validation';

import FriendlyPropertyCreate from 'components/builder/properties/creation/FriendlyPropertyCreate';
import { ValidatedInput, ValidatedTextArea } from 'components/form';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { H4 } from 'components/typography';

import MetatagUsage from '../MetatagUsage';
import history from 'helpers/history';

import styles from '../new/styles.scss';

const TAB_EDIT = 'edit';
const TAB_USAGE = 'usage';

const tabs: TabItem[] = [
  {
    value: TAB_EDIT,
    label: 'web.admin.content.metatag.tabItem.edit',
    icon: 'edit',
  },
  {
    value: TAB_USAGE,
    label: 'web.admin.content.metatag.tabItem.usage',
    icon: 'info',
  },
];

export default function MetatagEditSimple({
  hasAttemptedSubmit,
  metatag,
  error,
  validation,
  updating,
  updateName,
  updateMetatag,
  resetMetatag,
  saveMetatag,
  deleteMetatag,
  channel,
}: any) {
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const { user } = useContext(UserDataContext);
  const [errors, setErrors] = useState(null);
  const [query, goToUrl] = useQueryString({ tab: TAB_EDIT });
  const { t } = useTranslation();

  async function onSave() {
    try {
      const result = await saveMetatag();

      if (result) {
        window.Toast.show(
          t('web.admin.content.metatag.tabItem.edit.toastUpdated', {
            metatagName: metatag.name,
          })
        );
      }
    } catch (err) {
      // err handled in hook
    }
  }

  async function onDelete() {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.metatag.tabItem.edit.modalDeleteConfirm', {
          metatagName: metatag.name,
        }),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      const result = await deleteMetatag();

      if (result) {
        window.Toast.show(
          t('web.admin.content.metatag.tabItem.edit.toastDeleted', {
            metatagName: metatag.name,
          })
        );
      }
      const filtersIndexRoute = areContentTagsEnabled
        ? routes.channelAdminMetatags
        : routes.legacyChannelAdminMetatags;
      history.push(filtersIndexRoute.replace(':id', channel.slug));
    } catch (err) {
      // err handled in hook
    }
  }

  return (
    <div className={styles.metatagEdit}>
      <ControlMenu>
        <TabStrip
          tabs={tabs}
          selected={tabs.find(tab => query.tab === tab.value)}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
        />
        <hr />
        <Button loading={updating} onClick={resetMetatag}>
          {t('web.admin.content.metatag.tabItem.edit.reset')}
        </Button>
        <Button loading={updating} onClick={onDelete}>
          {t('web.admin.content.metatag.tabItem.edit.delete')}
        </Button>
        <Button loading={updating} onClick={onSave} variant="contained">
          {t('web.admin.content.metatag.tabItem.edit.save')}
        </Button>
      </ControlMenu>
      <ErrorMessage error={error} />
      <ErrorMessage error={errors} />

      {query.tab === TAB_EDIT && (
        <>
          <ErrorMessage error={validation} />

          <section className={styles.setup}>
            <H4 mb={2}>{t('web.admin.content.metatag.tabItem.edit.name')}</H4>
            <ValidatedInput
              onChange={updateName}
              placeholder={t(
                'web.admin.content.metatag.tabItem.edit.namePlaceholder'
              )}
              value={metatag.name}
              validation={(validateUpdateMetatag as any).name}
              isPristine={!hasAttemptedSubmit}
              data-cy="metatag-name"
            />

            <H4 mb={2}>
              {t('web.admin.content.metatag.tabItem.edit.description')}
            </H4>
            <ValidatedTextArea
              minRows={4}
              placeholder={t(
                'web.admin.content.metatag.tabItem.edit.descriptionPlaceholder'
              )}
              value={metatag.description}
              isPristine={!hasAttemptedSubmit}
              validation={(validateUpdateMetatag as any).description}
              onChange={description => updateMetatag({ description })}
              data-cy="metatag-description"
            />

            <Toggle
              text={t('web.admin.content.metatag.tabItem.edit.showDescription')}
              onChange={showDescription => updateMetatag({ showDescription })}
              value={metatag.showDescription}
            />
          </section>

          <FriendlyPropertyCreate
            className={styles.simpleCreate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
            user={user}
            channel={channel}
            contexts={[TypeContextEnum.Metatag]}
            property={metatag.properties}
            onPropertyUpdated={properties =>
              updateMetatag({
                properties: {
                  ...metatag.properties,
                  ...properties,
                },
              })
            }
            showSecurity={false}
            onValidation={(errors: any) => setErrors(errors)}
            forCreate={false}
          />
        </>
      )}

      {query.tab === TAB_USAGE && <MetatagUsage metatagId={metatag?._id} />}
    </div>
  );
}
