import { ApolloError } from '@apollo/client';

import { AddressType } from 'lane-shared/types/AddressType';
import { ChannelType } from 'lane-shared/types/ChannelType';

export const formattedAddress = (address: AddressType | undefined) =>
  [
    address?.street1,
    address?.city,
    address?.state,
    address?.code,
    address?.country,
  ]
    .filter(e => !!e)
    .join(', ');

export const getChannelInfoById = (
  channels: ChannelType[],
  channelId: string | undefined
) => channels.find(({ _id }: ChannelType) => channelId === _id);

export function doesChannelRowHaveParent(rowInfo: any): boolean {
  return rowInfo.parentChannel?.parentChannelName !== undefined;
}

export type ChannelManagementErrorType = ApolloError | string | null;
