import React, { useState, useEffect } from 'react';

import { ValidatedInput, IconPicker, ErrorMessage, Loading } from 'components';
import { history } from 'helpers';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { updateChannel } from 'lane-shared/graphql/mutation';
import { castForUpdate, pause } from 'lane-shared/helpers';
import { CONTENT_LOCATION_PAGE_CENTER } from 'lane-shared/helpers/constants/content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { slugify } from 'lane-shared/helpers/formatters';
import {
  useChannelPagesForAdmin,
  useUpdatedData,
  useChannelTheme,
} from 'lane-shared/hooks';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { validateChannelPage } from 'lane-shared/validation/channel';

import { ControlMenu, Button } from 'components/general';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import { ContentSelectorButton, ContentPreview } from 'components/lane';
import { AdminPage } from 'components/layout';
import { H5 } from 'components/typography';

import styles from './TabForm.scss';

type Props = {
  channelPageId?: string;
  formFor: 'create' | 'edit';
};

const TRANSLATION_KEYS = {
  createNewPageLink: 'web.admin.channel.tabManagement.tabForm.newPage',
  deleteButton: 'web.admin.channel.tabManagement.tabForm.delete',
  iconLabel: 'web.admin.channel.tabManagement.tabForm.icon',
  linkUrlLabel: 'web.admin.channel.tabManagement.tabForm.linkUrl',
  navigateToPageLink: 'web.admin.channel.tabManagement.tabForm.navigate',
  saveButton: 'web.admin.channel.tabManagement.tabForm.save',
  selectPageLabel: 'web.admin.channel.tabManagement.tabForm.selectPage',
  tabLabelLabel: 'web.admin.channel.tabManagement.tabForm.tabLabel',
  tabNameHeader: 'web.admin.channel.tabManagement.tabCenter.newTab',
  tabNameLabel: 'web.admin.channel.tabManagement.tabForm.tabName',
  translationCopyText:
    'web.admin.content.draftContent.info.generalInfo.translation',
};

export default function TabForm({ channelPageId, formFor }: Props) {
  const { t } = useTranslation();
  const requiredSpan = <span className={styles.required}>*</span>;

  const { channel } = useChannelAdminContext();
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    updatedChannelPage,
    setUpdatedChannelPage,
    hasChanged,
  ] = useUpdatedData({});
  const [debouncedChannelPage] = useDebounce(updatedChannelPage, 500);
  const { channelPages } = useChannelPagesForAdmin({
    channelId: channel?._id,
  });
  const theme = useChannelTheme(channel ?? undefined);

  useEffect(() => {
    if (formFor === 'create') {
      setUpdatedChannelPage(
        {
          _order: channelPages.length - 1,
          content: null,
          name: '',
          slug: '',
          label: '',
          icon: 'home',
          iconSet: ICON_SET_FONTAWESOME,
        },
        true
      );
    }
  }, [formFor, channelPages.length]);

  useEffect(() => {
    if (
      (formFor === 'edit' && !updatedChannelPage) ||
      channelPageId !== (updatedChannelPage as any)?._id
    ) {
      setUpdatedChannelPage(
        castForUpdate(channelPages?.find(({ _id }) => _id === channelPageId)),
        true
      );
    }
  }, [formFor, channelPageId, channelPages]);

  useEffect(() => {
    if ((updatedChannelPage as any)?.name) {
      setUpdatedChannelPage({
        slug: slugify((updatedChannelPage as any).name),
      });
    }
  }, [(updatedChannelPage as any)?.name]);

  async function onSave() {
    setSubmitAttempted(true);

    try {
      setValidationError(null);
      await validateChannelPage.validate(updatedChannelPage, {
        abortEarly: false,
      });
    } catch (err: any) {
      setValidationError(err);
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await pause();
      await getClient().mutate({
        mutation: updateChannel,
        variables: {
          channel: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: channel._id,
            pages: [updatedChannelPage],
          },
        },
      });
      // redirecting to page-centre
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      history.push(routes.channelAdminTabs.replace(':id', channel.slug));
    } catch (err: any) {
      setError(err);
    }

    setLoading(false);
  }

  async function onDelete() {
    try {
      await window.Alert.confirm({
        title: `Delete tab ${(updatedChannelPage as any).name}?`,
        message: `Are you sure you want to delete "${
          (updatedChannelPage as any).name
        }"? This cannot be undone`,
        confirmText: 'Delete',
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    await pause();
    try {
      setError(null);
      setLoading(true);
      await pause();
      await getClient().mutate({
        mutation: updateChannel,
        variables: {
          channel: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: channel._id,
            pages: [
              {
                _pull: true,
                _id: channelPageId,
              },
            ],
          },
        },
      });
      // redirecting to page-centre
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      history.push(routes.channelAdminTabs.replace(':id', channel.slug));
    } catch (err: any) {
      setError(err);
    }
    // redirecting to page-centre
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    history.push(routes.channelAdminTabs.replace(':id', channel.slug));
  }

  async function createNewPage() {
    if (hasChanged) {
      try {
        await window.Alert.confirm({
          title: `Navigating away`,
          message: `Are you sure you want to leave this page? Your progress will not be saved.`,
          confirmText: 'Confirm',
        });
      } catch (err) {
        // user cancelled.
        return;
      }
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    history.push(routes.channelAdminCreatePage.replace(':id', channel.slug));
  }

  async function navigateToPage() {
    if (hasChanged) {
      try {
        await window.Alert.confirm({
          title: `Navigating away`,
          message: `Are you sure you want to leave this page? Your progress will not be saved.`,
          confirmText: 'Confirm',
        });
      } catch (err) {
        // user cancelled.
        return;
      }
    }

    history.push(
      routes.channelAdminContent
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        .replace(':id', channel.slug)
        .replace(':contentId', (updatedChannelPage as any)?.content?._id)
    );
  }

  if (!updatedChannelPage) {
    return null;
  }

  return (
    <AdminPage className={styles.TabForm}>
      <ControlMenu className={styles.actionButtonsContainer}>
        <h2>
          {formFor === 'edit'
            ? `${(updatedChannelPage as any).name} Tab`
            : t(TRANSLATION_KEYS.tabNameHeader)}
        </h2>
        <hr />
        {formFor === 'edit' && (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"inh... Remove this comment to see the full error message
          <Button color="secondary" onClick={onDelete}>
            {t(TRANSLATION_KEYS.deleteButton)}
          </Button>
        )}
        <Button dataCy="saveButton" onClick={onSave} variant="contained">
          {t(TRANSLATION_KEYS.saveButton)}
        </Button>
      </ControlMenu>
      {loading && <Loading />}
      {error && <ErrorMessage error={error} />}
      {validationError && <ErrorMessage error={validationError} />}
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.form}>
            <div className={styles.formField}>
              <H5 className={styles.fieldLabel}>
                {t(TRANSLATION_KEYS.tabNameLabel)}
                {requiredSpan}
              </H5>
              <ValidatedInput
                isPristine={!submitAttempted}
                value={(updatedChannelPage as any).name}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                validation={validateChannelPage.fields.name}
                className={styles.validatedInput}
                dataCy="tabName"
                placeholder={t(TRANSLATION_KEYS.tabNameLabel)}
                onChange={name => setUpdatedChannelPage({ name })}
              />
            </div>
            <MultiLanguageWrapper
              channel={channel}
              model={updatedChannelPage}
              column="label"
            >
              {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => {
                return (
                  <div className={styles.formField}>
                    <H5>
                      {labelMaker({
                        label: t(TRANSLATION_KEYS.tabLabelLabel),
                        required: true,
                      })}
                    </H5>
                    <ValidatedInput
                      isPristine={!submitAttempted}
                      value={valueGetter()}
                      validation={
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        isPrimary && validateChannelPage.fields.label
                      }
                      className={styles.validatedInput}
                      dataCy={isPrimary ? 'tabLabel' : 'tabLabel_l10n'}
                      placeholder={t(TRANSLATION_KEYS.tabLabelLabel)}
                      onChange={(label: string) => {
                        const updates = onChangeUpdates(label);
                        setUpdatedChannelPage(updates);
                      }}
                    />
                  </div>
                );
              }}
            </MultiLanguageWrapper>
            <div className={styles.formField}>
              <H5 className={styles.fieldLabel}>
                {t(TRANSLATION_KEYS.linkUrlLabel)}
                {requiredSpan}
              </H5>
              <ValidatedInput
                disabled
                isPristine={!submitAttempted}
                value={(updatedChannelPage as any).slug}
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                validation={validateChannelPage.fields.slug}
                className={styles.validatedInput}
                dataCy="linkURL"
                placeholder={t(TRANSLATION_KEYS.linkUrlLabel)}
                onChange={slug => setUpdatedChannelPage({ slug })}
              />
            </div>
            <div className={styles.funInput}>
              <H5 className={styles.fieldLabel}>
                {t(TRANSLATION_KEYS.iconLabel)}
              </H5>
              <IconPicker
                palette={theme!.palette!}
                onChange={({ icon, iconSet, iconWeight }: any) =>
                  setUpdatedChannelPage({ icon, iconSet, iconWeight })
                }
                icon={(updatedChannelPage as any).icon}
                iconSet={(updatedChannelPage as any).iconSet}
                iconWeight={(updatedChannelPage as any).iconWeight}
              />
            </div>
            <div className={styles.funInput}>
              <H5 className={styles.fieldLabel}>
                {t(TRANSLATION_KEYS.selectPageLabel)}
              </H5>
              <ContentSelectorButton
                className={styles.contentButton}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                channelId={channel?._id}
                contentId={(updatedChannelPage as any)?.content?._id}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(content: any) => Partial<unknown> | undefin... Remove this comment to see the full error message
                onContentSelected={(content: any) =>
                  setUpdatedChannelPage({
                    content: content
                      ? {
                          _id: content._id,
                        }
                      : null,
                  })
                }
                // @ts-expect-error ts-migrate(2322) FIXME: Type '"Page Center"' is not assignable to type '"C... Remove this comment to see the full error message
                contentSearchLocations={[CONTENT_LOCATION_PAGE_CENTER]}
                contentTypes={[
                  ContentTypeEnum.Static,
                  ContentTypeEnum.WorkOrder,
                ]}
              />

              {(updatedChannelPage as any)?.content?._id && (
                <span role="navigation" onClick={navigateToPage}>
                  {t(TRANSLATION_KEYS.navigateToPageLink)}
                </span>
              )}

              <span role="navigation" onClick={createNewPage}>
                {t(TRANSLATION_KEYS.createNewPageLink)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <ContentPreview
            contentId={(updatedChannelPage as any)?.content?._id}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type '{ icon: ... Remove this comment to see the full error message
            tab={debouncedChannelPage}
          />
        </div>
      </div>
    </AdminPage>
  );
}
