import React from 'react';

import { useTranslation } from 'react-i18next';

import { H4 } from 'components/typography';

import { ChargeCodesTable } from '../chargeCodes/ChargeCodeTable';

import styles from '../styles.scss';
import { Button } from 'design-system-web';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'lane-shared/config';

type Props = {
  formRoute?: string;
  canManage?: boolean;
};

export function ChargeCodesTab({ formRoute, canManage }: Props) {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();

  const history = useHistory();

  const handleCreateChargeCode = () => {
    history.push(
      formRoute ?? routes.channelAdminCreateChargeCodeForm.replace(':id', id)
    );
  };

  return (
    <>
      <div className={styles.heading}>
        <H4>{t('abp.productsServices.chargeCodes.heading')}</H4>
        {canManage && (
          <Button
            dataCy="newCategory"
            variant="primary"
            size="large"
            onClick={handleCreateChargeCode}
          >
            {t('abp.productsServices.chargeCodes.button')}
          </Button>
        )}
      </div>
      <ChargeCodesTable />
    </>
  );
}
