import { Input, Line } from 'components';
import { Alert, AlertType } from 'components/lds';
import { H3, M, S } from 'components/typography';
import type { Price, Receivable } from 'graphql-query-contracts';
import { useChannelAdminContext } from 'hooks';
import useUserLocale from 'hooks/useUserLocale';
import {
  priceInputToComponents,
  setFinalPriceOnProduct,
} from 'lane-shared/domains/billingPayments/helpers';
import { useFinalPriceQuery } from 'lane-shared/domains/billingPayments/hooks';
import {
  AddItemType,
  AddProductToChargeDetails,
  MarkupAmountType,
  Payer,
  ProductType,
  SupportedPaymentCurrency,
  TaxAmountType,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { AddProductButtonGroup } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-product-to-charge/AddProductForm/AddProductButtonGroup';
import { AddProductCategoryAndProductSelection } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-product-to-charge/AddProductForm/AddProductCategoryAndProductSelection';
import {
  ModifierType,
  RateModifierInput,
} from 'lane-web/src/pages/portal/admin/channel/products-services/products/RateModifierInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { isNumberTwoDecimalPlaces } from '../../../products-services/helpers';
import styles from '../addProductToChargeStyles.scss';
import { AddProductRadioGroup } from './AddProductRadioGroup';

const EMPTY_PRODUCT_DETAILS = {
  tableRowId: '',
  tableProductType: '',

  productId: '',
  productGroupId: '',
  name: '',
  description: '',
  rate: '',
  markup: {
    type: MarkupAmountType.MarkupAmountTypePercentage,
    value: '',
  },
  tax: {
    type: TaxAmountType.TaxAmountTypePercentage,
    value: '',
  },
  originalPrice: undefined,

  productCategoryId: '',
  productCategoryName: '',
  productCategoryType: '' as ProductType,

  netPrice: '',
  quantity: '1',
  total: '',

  chargeCode: '',
  chargeCodeId: '',

  receivables: [] as Receivable[],
};

export function AddProductModalForm({
  addProduct,
  editProduct,
  closeModal,
  formType,
  currency,
  payer,
  productToEdit,
  inclusivePricing = false,
}: {
  addProduct: Function;
  editProduct: Function;
  closeModal: Function;
  formType: AddItemType | undefined;
  currency: SupportedPaymentCurrency;
  payer: Payer;
  productToEdit?: AddProductToChargeDetails;
  inclusivePricing?: boolean;
}) {
  const exceptionsFlag = useFlag(FeatureFlag.Exceptions, false);

  const { t } = useTranslation();
  const locale = useUserLocale();
  const { channel } = useChannelAdminContext();

  const [
    productDetails,
    setProductDetails,
  ] = useState<AddProductToChargeDetails>(() => {
    if (productToEdit) {
      return productToEdit;
    }
    return {
      ...EMPTY_PRODUCT_DETAILS,
      tableRowId: uuid(),
      tableProductType: formType,
    };
  });

  const handleProductDetailsUpdate = (value: any, field: string) => {
    if (!field) {
      setProductDetails({ ...productDetails, ...value });
      return;
    }
    setProductDetails({ ...productDetails, [field]: value });
  };

  const resetProductDetails = () => {
    setProductDetails({
      ...EMPTY_PRODUCT_DETAILS,
      tableRowId: uuid(),
      tableProductType: formType,
    });
  };

  const [productType, setProductType] = useState(() => {
    if (productToEdit) {
      return productToEdit.productCategoryType;
    }
    return ProductType.PRODUCT_TYPE_SERVICE_LABOUR;
  });

  const updateProductType = (value: string) => {
    setProductType(prev => {
      if (value !== prev) {
        resetProductDetails();
      }
      return value as ProductType;
    });
  };

  useEffect(() => {
    const priceComponents = priceInputToComponents(productDetails);
    const { netPrice, formatPrice, rate } = getPricing({
      currency,
      locale,
      priceComponents,
      inclusivePricing,
    });

    setProductDetails(prev => ({
      ...prev,
      total: formatPrice(netPrice * Number(productDetails.quantity)),
      netPrice: netPrice.toString(),
      rate: rate.toString(),
    }));
  }, [
    productDetails?.markup?.value,
    productDetails?.tax?.value,
    productDetails.quantity,
    productDetails.rate,
    productDetails.netPrice,
    currency,
    locale,
  ]);

  const { finalPriceList } = useFinalPriceQuery({
    productIds: productDetails.productId
      ? [convertToUUID(productDetails.productId)]
      : [],
    payer,
    channel,
  });
  const finalPriceProductId = finalPriceList.length
    ? finalPriceList[0]?.productId
    : undefined;

  useEffect(() => {
    if (
      exceptionsFlag &&
      finalPriceProductId &&
      productDetails.productId &&
      finalPriceList[0].exceptionId &&
      finalPriceProductId === convertToUUID(productDetails.productId)
    ) {
      const finalPrice = finalPriceList[0].finalPrice as Price;

      setProductDetails(prev =>
        setFinalPriceOnProduct(finalPrice, prev, inclusivePricing)
      );
    }
  }, [
    channel?._id,
    finalPriceProductId,
    productDetails.productId,
    exceptionsFlag,
  ]);

  const resetAndCloseModal = () => {
    resetProductDetails();
    closeModal();
  };

  const onAddProductClick = () => {
    if (productToEdit) {
      editProduct(productDetails);
      resetAndCloseModal();
      return;
    }

    addProduct(productDetails);
    resetAndCloseModal();
  };

  const onCancelClick = () => {
    if (productToEdit) {
      editProduct(undefined);
    }
    resetAndCloseModal();
  };

  const rateInputError = () => {
    if (!isNumberTwoDecimalPlaces(parseFloat(productDetails.rate))) {
      return [t('abp.productsServices.createProduct.validation')];
    }

    return null;
  };

  const netPriceInputError = () => {
    if (!isNumberTwoDecimalPlaces(parseFloat(productDetails.netPrice))) {
      return [t('abp.productsServices.createProduct.validation')];
    }

    return null;
  };

  return (
    <div className={styles.addProductModal}>
      <H3 className={styles.modalHeader} mb={4} bold>
        {t('abp.charges.addProduct.modal.header')}
      </H3>
      <div className={styles.modalFormContent}>
        <AddProductRadioGroup
          productType={productType}
          updateProductType={updateProductType}
        />
        <AddProductCategoryAndProductSelection
          formType={formType}
          productDetails={productDetails}
          productType={productType}
          handleProductDetailsUpdate={handleProductDetailsUpdate}
          currency={currency}
        />
        {exceptionsFlag && productDetails.originalPrice && (
          <Alert type={AlertType.info} fullWidth>
            <S>{t('abp.charges.addProduct.modal.exceptionInfo')}</S>
          </Alert>
        )}
        <div className={styles.rateInputs}>
          {inclusivePricing ? (
            <Input
              className={styles.inputFields}
              label={t('abp.productsServices.createProduct.totalPrice.label')}
              fixedLabel
              isRequired
              value={productDetails.netPrice}
              onChange={(value: string) => {
                handleProductDetailsUpdate(value, 'netPrice');
              }}
              dataCy="productNetPriceInput"
              error={netPriceInputError()}
              disabled={!!productDetails.originalPrice}
            />
          ) : (
            <Input
              className={styles.inputFields}
              label={t('abp.productsServices.createProduct.rate.label')}
              fixedLabel
              isRequired
              value={productDetails.rate}
              onChange={(value: string) => {
                handleProductDetailsUpdate(value, 'rate');
              }}
              dataCy="productRateInput"
              error={rateInputError()}
              disabled={!!productDetails.originalPrice}
            />
          )}

          <RateModifierInput
            modifierType={productDetails.markup?.type}
            modifierValue={productDetails.markup?.value}
            type={ModifierType.MARKUP}
            updateDetails={handleProductDetailsUpdate}
            disabled={!!productDetails.originalPrice}
          />
          <RateModifierInput
            modifierType={productDetails.tax?.type}
            modifierValue={productDetails.tax?.value}
            type={ModifierType.TAX}
            updateDetails={handleProductDetailsUpdate}
            disabled={!!productDetails.originalPrice}
          />
        </div>

        <Input
          className={styles.quantityInput}
          label="Quantity"
          fixedLabel
          isRequired
          value={productDetails.quantity}
          onChange={(value: string) =>
            handleProductDetailsUpdate(value, 'quantity')
          }
          dataCy="productQuantityInput"
        />
        <div className={styles.totalSection}>
          <M bold>{t('Total')}</M>
          <M mb={2} data-cy="totalCostText">
            {currencyFormatter({
              currency,
            })(Number(productDetails.total))}
          </M>
        </div>
      </div>

      <Line className={styles.buttonGroupLine} />

      <AddProductButtonGroup
        productDetails={productDetails}
        formType={formType}
        addProduct={onAddProductClick}
        onCancel={onCancelClick}
      />
    </div>
  );
}
