import { TurnOnAutopaymentModal } from './TurnOnAutopaymentModal';
import styles from './invoiceAutopayment.scss';
import { Icon } from 'design-system-web';
import { Modal } from 'components/lds';
import { M } from 'components/typography';
import { usePaymentMethodFeature } from 'lane-shared/domains/billingPayments/hooks';
import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutopaymentCta } from './AutopaymentCta';
import { TurnOffAutopaymentModal } from './TurnOffAutopaymentModal';

export function InvoiceAutopayment({ user }: { user: any }) {
  const { t } = useTranslation();
  const {
    invoiceAutopayment,
    saveAutopaymentMethod,
    savedPaymentMethods,
    removeAutopaymentMethod,
    paymentMethodError,
  } = usePaymentMethodFeature({
    user,
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isTurnOffModalOpen, setIsTurnOffModalOpen] = useState(false);

  const isAutopaymentEnabled = Boolean(
    invoiceAutopayment?.isInvoiceAutopaymentEnabled
  );

  const autopaymentStatus = () => {
    return `${t('abp.routes.accountInvoices.autopayment.automaticPayments')}:
      ${
        isAutopaymentEnabled
          ? t('abp.routes.accountInvoices.autopayment.on')
          : t('abp.routes.accountInvoices.autopayment.off')
      }`;
  };

  const autopaymentDetails = () => {
    if (isAutopaymentEnabled) {
      const {
        brand,
        last4,
        expiryMonth,
        expiryYear,
      } = invoiceAutopayment!.paymentSource!;

      return (
        <>
          <M bold>
            {t('abp.routes.accountInvoices.autopayment.paymentMethod')}
          </M>
          {` ${brand.toUpperCase()} ****${last4} ${t(
            'abp.payment.exp'
          )} ${expiryMonth}/${expiryYear}`}
        </>
      );
    }
    return t(
      'abp.routes.accountInvoices.autopayment.automaticPaymentsDescription'
    );
  };

  useEffect(() => {
    if (paymentMethodError) {
      window.Toast.show(
        t('abp.routes.accountInvoices.autopayment.error', {
          errorMessage: paymentMethodError.message,
        }),
        30000
      );
    }
  }, [paymentMethodError]);

  return (
    <div className={styles.invoiceAutopaymentContainer}>
      <div className={styles.autopaymentInfo}>
        <Icon
          name="credit-card"
          size="large"
          type={FONT_AWESOME_LIGHT}
          className={styles.creditCardIcon}
        />
        <div className={styles.autopaymentStatus}>
          <M>{autopaymentStatus()}</M>
          <M className={isAutopaymentEnabled && styles.autopaymentCardDetails}>
            {autopaymentDetails()}
          </M>
        </div>
      </div>
      <div className={styles.autopaymentCta}>
        <AutopaymentCta
          isAutopaymentEnabled={isAutopaymentEnabled}
          setIsAddModalOpen={setIsAddModalOpen}
          setIsTurnOffModalOpen={setIsTurnOffModalOpen}
        />
      </div>
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        className={styles.addAutopaymentModal}
        disableCloseOnBackgroundClick
        dataCy="AddAutopaymentModal"
      >
        <TurnOnAutopaymentModal
          paymentSources={savedPaymentMethods}
          saveAutopaymentMethod={saveAutopaymentMethod}
          closeModal={() => setIsAddModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isTurnOffModalOpen}
        onClose={() => setIsTurnOffModalOpen(false)}
        className={styles.turnOffAutopaymentModal}
        disableCloseOnBackgroundClick
        dataCy="TurnOffAutopaymentModal"
      >
        <TurnOffAutopaymentModal
          closeModal={() => setIsTurnOffModalOpen(false)}
          removeAutopayment={removeAutopaymentMethod}
          existingAutopaymentMethod={
            invoiceAutopayment?.paymentSource?.paymentMethodId || ''
          }
        />
      </Modal>
    </div>
  );
}
