import React, { Dispatch, SetStateAction } from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/lds';
import { S } from 'components/typography';

type ChannelManagementModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClick: () => Promise<void>;
  onClose?: () => void;
  title: string;
  description?: string;
  body?: React.ReactNode;
  size?: 'small' | 'large';
  disableConfirm?: boolean;
};

export function ChannelManagementModal({
  isOpen,
  setIsOpen,
  onClick,
  onClose,
  title,
  description = '',
  body = <></>,
  size = 'small',
  disableConfirm = false,
}: ChannelManagementModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      }}
      title={title}
      size={size}
    >
      <S mb={6}>{description}</S>
      {body}
      <Button variant="contained" onClick={onClick} disabled={disableConfirm}>
        {t('web.admin.portalManagement.channelManagement.modal.confirm')}
      </Button>
    </Modal>
  );
}
