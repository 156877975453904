import React, { useEffect, useRef, useState } from 'react';

import { Button, Icon, PopupMenu } from 'design-system-web';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { BlobProvider } from '@react-pdf/renderer';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailsTab from './tabs/details/Details';
import LogHistoryTab from './tabs/logHistory/LogHistory';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { deleteEquipmentMutation, getEquipmentQuery } from 'graphql-queries';
import { Equipment } from 'graphql-query-contracts';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getPublicUser } from 'lane-shared/graphql/query';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT } from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { UserType } from 'lane-shared/types/User';
import { ErrorMessage, Flex, TabStrip } from 'lane-web/src/components';
import { Alert, BreadCrumbs, Modal } from 'lane-web/src/components/lds';
import { H3, M, P } from 'lane-web/src/components/typography';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';

import { TabItem } from 'components/general/TabStrip';

import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import EquipmentPDF from '../export/EquipmentPDF';

import styles from './styles.scss';
import MeterReadings from './tabs/meterReadings/MeterReadings';

enum EquipmentDetailsTabsEnum {
  Details = 'details',
  LogHistory = 'log-history',
  MeterReadings = 'meter-readings',
}
export interface EquipmentDetailsProps {
  channel?: UseChannelForAdminQueryResponse['channel'];
  user: UserType;
}
function EquipmentDetails({ channel, user }: EquipmentDetailsProps) {
  const [
    isEquipmentDeleteModalOpen,
    setEquipmentDeleteModalOpen,
  ] = useState<boolean>(false);

  const [error, setError] = useState(null);
  const [equipment, setEquipment] = useState<Equipment | null | undefined>(
    null
  );
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const equipmentListPath = routes.channelAdminWorkOrdersEquipment.replace(
    ':id',
    channel?.slug || ''
  );
  const { t } = useTranslation();
  const [getEquipment, { data }] = useLazyQuery(getEquipmentQuery, {
    onCompleted: () => {
      setEquipment(data?.getEquipment);
    },
  });
  const isWorkplaceEnablementEnabled = useFlag(
    FeatureFlag.WorkOrdersWorkplaceEnablement,
    false
  );

  const canCreateEditEquipment =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT],
      channel?._id,
      false
    );

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  const pdfDownloadLinkElement = useRef<HTMLAnchorElement>(null);

  const tabs: TabItem[] = [
    {
      label: t('web.admin.serviceRequest.equipment.tabs.details'),
      value: EquipmentDetailsTabsEnum.Details,
    },
    {
      label: t('web.admin.serviceRequest.equipment.tabs.logHistory'),
      value: EquipmentDetailsTabsEnum.LogHistory,
    },
    {
      label: t('web.admin.serviceRequest.equipment.tabs.meterReadings'),
      value: EquipmentDetailsTabsEnum.MeterReadings,
    },
  ];

  // hide log history tab as it is useless if SR or PM is disabled
  if (
    isWorkplaceEnablementEnabled &&
    !channel?.settings?.hasWorkOrderServiceRequestsEnabled &&
    !channel?.settings?.hasWorkOrderPreventiveMaintenanceEnabled
  ) {
    tabs.splice(1, 1);
  }

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]!);

  const [_, setSearchParams] = useQueryString();

  const handleTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
    setSearchParams({ page: 0 });
  };

  const [publicUser, { data: userData }] = useLazyQuery(getPublicUser);
  useEffect(() => {
    if (equipmentId) {
      getEquipment({
        variables: {
          equipmentId: convertToUUID(equipmentId),
        },
      });
      publicUser({
        variables: {
          id: equipment?.updatedBy,
        },
      });
    }
  }, [equipmentId, getEquipment, equipment, publicUser]);

  const isArchived = equipment?.isArchived;

  const history = useHistory();
  let actionArray: any[] = [];
  if (canCreateEditEquipment) {
    actionArray = [
      {
        label: t('web.admin.serviceRequest.equipment.edit'),
        onClick: () => {
          history.push(
            routes.channelAdminWorkOrdersEquipmentEdit
              .replace(':id', channel?.slug || '')
              .replace(':equipmentId', equipment?.id ?? '')
          );
        },
      },
    ];
  }
  actionArray.push(
    {
      label: t('web.admin.serviceRequest.equipment.delete'),
      onClick: () => {
        setEquipmentDeleteModalOpen(true);
      },
    },
    {
      label: t('web.admin.serviceRequest.export'),
      onClick: () => pdfDownloadLinkElement.current!.click(),
    }
  );
  const deleteEquipment = async () => {
    setEquipmentDeleteModalOpen(false);
    try {
      await getClient().mutate({
        mutation: deleteEquipmentMutation,
        variables: { equipmentId: equipment?.id },
      });
      window.Toast.show(t('web.admin.serviceRequest.equipment.delete.success'));
      history.push(
        routes.channelAdminWorkOrdersEquipment.replace(
          ':id',
          channel?.slug || ''
        )
      );
    } catch (err: any) {
      setError(err);
    }
  };

  return (
    <div className={styles.EquipmentDetailsPage}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.equipment.title'),
            url: equipmentListPath,
          },
          {
            label: `${equipment?.name}`,
          },
        ]}
      />
      {error ? <ErrorMessage error={error} /> : null}
      <Flex
        className={styles.EquipmentDetailsHeader}
        direction="row"
        justify="space-between"
      >
        <Flex direction="column" gap={5}>
          <H3>{equipment?.name}</H3>
          <P variant="secondary">
            {t('Updated')}: {simpleDate(equipment?.updatedAt)}{' '}
            {t('web.admin.workOrder.by.title')}
            {userData?.user?.name}
          </P>
        </Flex>
        <Flex gap={4} className={styles.header}>
          <Flex align="center">
            {isArchived ? (
              <Button
                variant="secondary"
                dataCy="actionsButton"
                onClick={() => pdfDownloadLinkElement.current!.click()}
              >
                <Flex className={styles.exportButton}>
                  <FontAwesomeIcon icon={faArrowToBottom} />
                  {t('web.admin.serviceRequest.export')}
                </Flex>
              </Button>
            ) : (
              <PopupMenu
                trigger={
                  <Button
                    variant="secondary"
                    endIcon={<Icon name="chevron-down" />}
                    dataCy="actionsButton"
                  >
                    {t('web.admin.serviceRequest.equipment.action.title')}
                  </Button>
                }
                items={actionArray.map(option => ({
                  label: option.label,
                  onSelect: option.onClick,
                }))}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      {isArchived ? (
        <Flex direction="row">
          <Alert type="warning" className={styles.alert}>
            {t(
              'web.admin.serviceRequest.equipment.isArchived.warning.one'
            )}
            <br />
            {t(
              'web.admin.serviceRequest.equipment.isArchived.warning.two'
            )}
          </Alert>
        </Flex>
      ) : null}

      <Flex direction="column">
        <TabStrip
          tabs={tabs}
          selected={selectedTab}
          onSelectTab={handleTabChange}
          className={styles.EquipmentDetailsTabStrip}
          fullWidth
          skipLabelTranslation
        />
      </Flex>
      {selectedTab.value === EquipmentDetailsTabsEnum.Details && (
        <DetailsTab
          channel={channel}
          user={user}
          equipment={equipment}
          userData={userData}
        />
      )}
      {selectedTab.value === EquipmentDetailsTabsEnum.LogHistory && (
        <LogHistoryTab searchID={equipmentId} channel={channel} />
      )}
      {selectedTab.value === EquipmentDetailsTabsEnum.MeterReadings && (
        <MeterReadings channel={channel} equipment={equipment} />
      )}

      {isEquipmentDeleteModalOpen && (
        <Flex>
          <Modal
            isOpen={isEquipmentDeleteModalOpen}
            onClose={() => setEquipmentDeleteModalOpen(false)}
            dataCy="equipmentDeleteModal"
            className={styles.deleteModal}
          >
            <H3 bold className={styles.modalHeader} mb={4}>
              {t('web.admin.serviceRequest.equipment.delete.modal.header')}
            </H3>
            <M variant="secondary" mb={4}>
              {t('web.admin.serviceRequest.equipment.delete.modal.message', {
                equipmentName: equipment?.name,
              })}
            </M>
            <hr />
            <Flex m={3}>
              <Button
                variant="secondary"
                size="medium"
                dataCy="equipmentCancelButton"
                onClick={() => setEquipmentDeleteModalOpen(false)}
                className={styles.modalButtons}
              >
                {t('web.admin.serviceRequest.cancel')}
              </Button>
              <Button
                variant="secondary"
                size="medium"
                dataCy="equipmentDeleteButton"
                onClick={deleteEquipment}
                className={styles.modalButtons}
              >
                {t(
                  'web.admin.serviceRequest.equipment.delete.modal.button.text'
                )}
              </Button>
            </Flex>
          </Modal>
        </Flex>
      )}

      <BlobProvider
        document={
          <EquipmentPDF
            equipmentData={equipment}
            userData={userData}
            textData={{
              details: t`web.admin.serviceRequest.details`,
              category: t`web.admin.serviceRequest.equipment.category.title`,
              location: t`web.admin.serviceRequest.equipment.location.title`,
              status: t`web.admin.serviceRequest.equipment.status.title`,
              make: t`web.admin.serviceRequest.equipment.make.title`,
              model: t`web.admin.serviceRequest.equipment.model.title`,
              asset: t`web.admin.serviceRequest.equipment.asset.title`,
              serial: t`web.admin.serviceRequest.equipment.serial.title`,
              floor: t`web.admin.serviceRequest.equipment.floor.title`,
              suite: t`web.admin.serviceRequest.equipment.suite.title`,
              warrantyExpirationDate: t`web.admin.serviceRequest.equipment.warrantyExpirationDate.title`,
              installDate: t`web.admin.serviceRequest.equipment.installDate.title`,
              notes: t`web.admin.serviceRequest.equipment.notes.title`,
              updatedAt: t`web.admin.serviceRequest.updatedAt`,
              by: t`web.admin.workOrder.by.title`,
              meterReadingName: t`web.admin.serviceRequest.equipment.MeterReading.title`,
              meterReadingUnit: t`web.admin.serviceRequest.equipment.MeterReading.unit`,
              meterReadingEnableNotification: t`web.admin.serviceRequest.equipment.MeterReading.enableNotification.title`,
              meterReadingEnableNotificationOn: t`web.admin.serviceRequest.equipment.MeterReading.enableNotification.on`,
              meterReadingEnableNotificationOff: t`web.admin.serviceRequest.equipment.MeterReading.enableNotification.off`,
              meterReadingNotificationTrigger: t`web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.title`,
              meterReadingBoundValue: t`web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.value.label`,
              meterReadingNotificationTypes: t`web.admin.serviceRequest.equipment.MeterReading.notificationType.title`,
              meterReadingNotificationTypesEmail: t`web.admin.serviceRequest.equipment.MeterReading.notificationType.email`,
              meterReadingNotificationTypesPush: t`web.admin.serviceRequest.equipment.MeterReading.notificationType.push`,
            }}
            isPropertyChannel={isPropertyChannel}
            t={t}
          />
        }
      >
        {/* @ts-expect-error */}
        {({ url }) => {
          return (
            <a
              hidden
              ref={pdfDownloadLinkElement}
              href={url!}
              download={`${equipment?.name}.pdf`}
            >
              {t('web.admin.serviceRequest.export')}
            </a>
          );
        }}
      </BlobProvider>
    </div>
  );
}

export default EquipmentDetails;
