export enum recurrenceTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'bi-weekly',
  MONTHLY = 'monthly',
  BIMONTHLY = 'bi-monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export const recurrenceOptions: { label: string; value: string }[] = [
  {
    label: 'Daily',
    value: recurrenceTypes.DAILY,
  },
  {
    label: 'Weekly',
    value: recurrenceTypes.WEEKLY,
  },
  {
    label: 'Bi-weekly',
    value: recurrenceTypes.BIWEEKLY,
  },
  {
    label: 'Monthly',
    value: recurrenceTypes.MONTHLY,
  },
  {
    label: 'Bi-monthly',
    value: recurrenceTypes.BIMONTHLY,
  },
  {
    label: 'Quarterly',
    value: recurrenceTypes.QUARTERLY,
  },
  {
    label: 'Annually',
    value: recurrenceTypes.ANNUALLY,
  },
];
