import React from 'react';

import cx from 'classnames';

import DateRangePickerButton from '../../../../form/DatePickers/DateRangePickerButton';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './DateRangeInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function DateRangeInputBlock({
  value,
  style,
  onInput,
  disabled,
  isValid,
  minRangeSize,
  maxRangeSize,
  placeholder,
  validationMessage,
  className,
}: any) {
  return (
    <div
      className={cx(className, styles.DateRangeInputBlock)}
      style={styleMap(false, style)}
    >
      <DateRangePickerButton
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        disabled={disabled}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        placeholder={placeholder}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        onChange={onInput}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        includeTime={false}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        minRangeSize={minRangeSize}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        maxRangeSize={maxRangeSize}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        startDate={value && value.startDate}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        endDate={value && value.endDate}
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
      <div className={styles.wrapper} />
    </div>
  );
}

DateRangeInputBlock.defaultProps = {
  value: null,
  style: {},
  onInput: () => {},
  disabled: false,
  isValid: true,
  placeholder: 'Select Date Range',
  validationMessage: 'Required.',
};
