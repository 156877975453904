import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName, hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_ANALYTICS_ADVANCED,
  PERMISSION_ANALYTICS_ACCESS,
  PERMISSION_ANALYTICS_WORK_ORDERS,
} from 'lane-shared/helpers/constants/permissions';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { LookerReportEnum } from 'lane-shared/types/integrations/Looker';

import { AdminPage } from 'components/layout';
import { H3 } from 'components/typography';

import ChannelAdminContext from '../ChannelAdminContext';
import availableReports from './constants';

import styles from './styles.scss';

export default function ChannelInsightsIndex() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { channel } = useContext(ChannelAdminContext);

  const workOrdersAnalyticsFlag = useFlag(
    'activate-data.work-orders-analytics',
    false
  );

  const eventAnalyticsFlag = useFlag(
    FeatureFlag.EventAnalyticsLookerReport,
    false
  );

  // It's possible the user got here without having this permission
  // rather than draw graphs they can't see, just show the name
  // of the channel.
  const hasAdminPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_ADMIN, PERMISSION_ANALYTICS_ADVANCED],
      channel?._id
    );

  const hasWorkOrderAnalyticsPermission = hasPermission(
    user?.roles,
    [PERMISSION_ANALYTICS_WORK_ORDERS],
    channel?._id
  );

  const hasAccessAnalyticsPermission = hasPermission(
    user?.roles,
    [PERMISSION_ANALYTICS_ACCESS],
    channel?._id
  );

  const hasTenantSentimentAnalyticsPermission =
    channel?.settings.hasSurveysEnabled;

  return (
    <AdminPage className={styles.ChannelInsights}>
      {hasAdminPermission && (
        <>
          <div className={styles.header}>
            <H3>{t('web.admin.channel.insightsReports.title')}</H3>
          </div>
          <div className={styles.breakRow} />
          {Object.keys(availableReports)
            .filter(report => {
              if (report === LookerReportEnum.WorkOrdersAnalytics)
                return (
                  workOrdersAnalyticsFlag && hasWorkOrderAnalyticsPermission
                );
              if (report === LookerReportEnum.TenantSentimentAnalytics) {
                return hasTenantSentimentAnalyticsPermission;
              }
              if (report === LookerReportEnum.AccessAnalytics) {
                return hasAccessAnalyticsPermission;
              }
              if (report === LookerReportEnum.EventAnalytics) {
                return eventAnalyticsFlag;
              }
              return true;
            })
            .map(key => (
              <Link
                key={key}
                to={routes.channelAdminInsightsReport
                  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                  .replace(':id', channel?.slug)
                  .replace(':selectedReport', key)}
                className={styles.reportLink}
              >
                <div>
                  {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                  <H3>{t(availableReports[key].label)}</H3>
                  <p className={styles.body}>
                    {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                    {t(availableReports[key].description)}
                  </p>
                </div>
              </Link>
            ))}
        </>
      )}
      {!hasAdminPermission && <h1>{getDisplayName(channel)}</h1>}
    </AdminPage>
  );
}
