import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { Input, InputProps } from 'design-system-web';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<any>;
  errorMessage?: string[] | null;
  name: string;
  isRequired?: boolean;
  dataCy?: string;
  ariaLabel?: string;
  readOnly?: boolean;
  inputClassName?: string;
  label?: string;
  className?: string;
  minLength?: number;
} & Pick<InputProps, 'type' | 'disabled'>;

export function HookFormInput({
  control,
  name,
  errorMessage,
  minLength,
  isRequired = false,
  ...inputProps
}: Props) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
        minLength,
        pattern: {
          value: /(?!\s*$).+/,
          message: t('web.admin.serviceRequest.field.required'),
        },
      }}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <Input
          {...inputProps}
          ref={ref}
          fieldName={name}
          onChange={onChange}
          value={value}
          error={errorMessage}
          onBlur={onBlur}
        />
      )}
    />
  );
}
