import React, { ReactElement, useContext } from 'react';

import cx from 'classnames';

import ContentRendererContext from 'lane-shared/contexts/ContentRendererContext';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useChannelProfileQuery } from 'lane-shared/hooks';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';
import Features from 'lane-shared/renderers/v5/features';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import VisitorManagement from 'lane-web/src/domains/visitorManagement/content-blocks';

import { UserNotes } from 'components/features/Reservable/UserNotes';

import Property from '../../../builder/properties/input/Property';
import GuestInviteFeature from '../../../features/GuestInviteFeature';
import MenuOrder from '../../../features/Menu/MenuOrder';
import ReservableInput from '../../../features/ReservableInput';
import QuantityInput from '../../../form/QuantityInput';
import ValidationMessage from '../../../general/ValidationMessage';
import { WebBlockProps } from '../WebBlockProps';
import useEditModeProps from './useEditModeProps';

import styles from './PropertyInputBlock.scss';

type OwnBlockProps = {
  propertyKey: string;
  featureKey: string;
  value: any;
  isPreview: boolean;
};

export type BlockProps = OwnBlockProps &
  WebBlockProps & {
    onInput: (() => null) & ((value: any) => void);
  };

export default function PropertyInputBlock({
  className,
  style,
  propertyKey,
  featureKey,
  value,
  onInput,
  isValid = true,
  validationMessage = 'Required.',
  theme,
  ...otherProps
}: BlockProps) {
  const {
    loading,
    disabled,
    editMode,
    content,
    interaction,
    submissionCompletedAt,
    submitAttempted,
  } = useContext(ContentRendererContext);
  const channelId = content?.channel?._id;
  const { channel } = useChannelProfileQuery({
    channelId,
  });
  const props = useEditModeProps(otherProps);
  const isUserNotesEnabled = useUserNotesEnabled();

  const wrapper = editMode && <div className={styles.wrapper} />;
  let propertyComponent: ReactElement | null = null;

  // there are some special cases for Features.
  if (featureKey) {
    const {
      quantityFeature,
      guestInviteFeature,
      reservableFeature,
      visitorManagementFeature,
    } = explodeFeatures(content?.features);

    switch (featureKey) {
      case FeatureNameEnum.GuestInvite:
        if (propertyKey === 'guests' && guestInviteFeature) {
          propertyComponent = (
            <GuestInviteFeature
              onChange={onInput}
              value={value}
              showLaneUsers={guestInviteFeature.showLaneUsers}
              maxGuests={guestInviteFeature.maxGuests}
              minGuests={guestInviteFeature.minGuests}
            />
          );
        }
        break;
      case FeatureNameEnum.VisitorManagement:
        if (visitorManagementFeature) {
          propertyComponent = VisitorManagement.VisitorBlockHandler({
            propertyKey,
            show: channel?.settings.hasVisitorManagementEnabled as boolean,
            value,
            content,
            properties: visitorManagementFeature,
            submissionCompletedAt,
            submitAttempted,
            isPreview: otherProps.isPreview,
            onChange: onInput,
          });
        }
        break;
      case FeatureNameEnum.Quantity:
        if (propertyKey === 'quantity') {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const max = Math.min(quantityFeature.max, quantityFeature.quantity);

          propertyComponent = (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ min: num... Remove this comment to see the full error message
            <QuantityInput
              min={0}
              max={max}
              quantity={value || 0}
              onChange={onInput}
              disabled={disabled}
            />
          );
        }
        break;
      case FeatureNameEnum.Menu:
        propertyComponent = (
          <MenuOrder
            theme={theme}
            disabled={disabled}
            content={content}
            existingOrder={value}
            onOrderUpdated={onInput}
          />
        );
        break;

      case FeatureNameEnum.Reservable:
        if (propertyKey === 'reservation') {
          propertyComponent = (
            <ReservableInput
              value={value}
              content={content}
              disabled={disabled}
              onInput={onInput}
              existingValue={interaction?.features?.Reservable?.reservation}
            />
          );
        } else if (propertyKey === 'userNotes' && isUserNotesEnabled) {
          const userNotes = reservableFeature?.userNotes;

          propertyComponent = (
            <UserNotes
              value={value}
              onChange={onInput}
              label={userNotes?.labelText}
              placeholder={userNotes?.placeholderText}
            />
          );
        }
        break;
      case FeatureNameEnum.Scheduled:
        propertyComponent = null;
    }
  }

  if (!propertyComponent) {
    if (
      featureKey === FeatureNameEnum.VisitorManagement ||
      featureKey === FeatureNameEnum.Reservable
    ) {
      return null;
    } // returns null if visitor management toggle is disabled in channel settings

    const property = featureKey
      ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Features[featureKey].interactionData[propertyKey]
      : content?.data?.[propertyKey];

    // this should not be null, but could be due to invalid content. would
    // rather render null than crash.
    if (!property) {
      return null;
    }
    propertyComponent = (
      <Property
        theme={theme}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ theme: ThemeType; loading: boolean; disabl... Remove this comment to see the full error message
        loading={loading}
        disabled={disabled}
        value={value}
        property={property}
        onChange={onInput}
        channel={content.channel as ChannelType}
      />
    );
  }

  return (
    <div
      className={cx(styles.PropertyInputBlock, className)}
      style={style}
      {...props}
    >
      {propertyComponent}
      {!isValid && <ValidationMessage errors={[validationMessage]} />}
      {wrapper}
    </div>
  );
}

PropertyInputBlock.defaultProps = {
  onInput: () => null,
};
