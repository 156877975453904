/* eslint-disable react/forbid-component-props */
import React, { useEffect, useRef, useState } from 'react';

import { Icon, PopupMenu, Button } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useHistory } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
import { BlobProvider } from '@react-pdf/renderer';
import { compact } from 'lodash';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { getPublicUser } from 'lane-shared/graphql/query';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW } from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentResponse,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { Flex, Box, AddAttachment, Label, TabStrip } from 'components';
import { Alert, BreadCrumbs } from 'components/lds';
import { H3, P, H4 } from 'components/typography';
import { TabItem } from 'components/general/TabStrip';

import { recurrenceOptions } from '../../constants/recurentTypes';
import { Equipment } from 'graphql-query-contracts';
import {
  getEquipmentQuery,
  deletePMScheduleMutation,
  getPMScheduleByIdQuery,
  updatePMScheduleMutation,
} from 'graphql-queries';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClientPMSchedule } from 'activate-modules/work-order/schedule/server/interfaces/coreModels';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UpdateScheduleRequestDTO } from 'activate-modules/work-order/schedule/types';

import AssignedMemberDropdown from 'pages/portal/admin/channel/service-requests/details/components/AssignedMemberDropdown';
import { StaffTeamsMultiSelectDropdown } from 'pages/portal/admin/channel/service-requests/details/components/StaffTeamsMultiSelectDropdown';

import { StepExecutionForm, StepExecutionType } from '../../components';
import { TinyTaskList } from '../../../task/components/TinyTaskList';
import ScheduleDetailsPDF from '../../components/ScheduleDetailsPDF';

import styles from './index.scss';

enum ScheduleTabsEnum {
  Details = 'details',
  Steps = 'steps',
  Tasks = 'tasks',
}

function ScheduleDetails({ channel, hasAnyPermission }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const newScheduleDetailsForm = useFlag(
    FeatureFlag.InteractiveStepsDetails,
    false
  );

  const isWorkplaceEnablementEnabled = useFlag(
    FeatureFlag.WorkOrdersWorkplaceEnablement,
    false
  );

  const isEquipmentEnabled =
    !isWorkplaceEnablementEnabled ||
    channel?.settings?.hasWorkOrderEquipmentEnabled;

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  const [stepsCollapsed, setStepsCollapsed] = useState(false);
  const [scheduleEquipments, setScheduleEquipments] = useState<Equipment[]>([]);
  const [meterReadingsCollapsed, setMeterReadingsCollapsed] = useState(false);
  const [currentSteps, setCurrentSteps] = useState<StepExecutionType[]>([]);

  const [assignedMember, setAssignedMember] = useState<{
    label: string;
    profile: string;
    value: string;
    id: string;
  }>();

  const [assignedTeams, setAssignedTeams] = useState<
    {
      label: string;
      profile: any;
      value: string;
    }[]
  >([]);

  const scheduleListPath = routes.channelAdminWorkOrdersPMSchedules.replace(
    ':id',
    channel?.slug
  );

  const { data, refetch } = useQuery(getPMScheduleByIdQuery, {
    variables: {
      scheduleId: convertToUUID(scheduleId),
    },
    onCompleted: () => {
      getEquipmentsOnSchedule();
      setCurrentSteps(data?.pmSchedule?.stepTemplate || []);
    },
  });

  const schedule: ClientPMSchedule = data?.pmSchedule;
  const [publicUser, { data: userData }] = useLazyQuery(getPublicUser);
  const [adminUsers, _, assignableTeams] = useAssignableMembers(channel?._id);
  const assignableUsers = adminUsers.map(adminUser => ({
    ...adminUser,
    id: safeConvertToUUID(adminUser.value),
  }));

  const pdfDownloadLinkElement = useRef<HTMLAnchorElement>(null);
  const hasEquipmentAccess = hasAnyPermission([
    PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
  ]);

  const tabs: TabItem[] = [
    {
      label: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.tabs.details'
      ),
      value: ScheduleTabsEnum.Details,
    },
    ...(newScheduleDetailsForm
      ? [
          {
            label: t(
              'web.admin.workOrder.preventiveMaintenance.schedule.tabs.steps'
            ),
            value: ScheduleTabsEnum.Steps,
          },
        ]
      : []),
    {
      label: t('web.admin.workOrder.preventiveMaintenance.schedule.tabs.tasks'),
      value: ScheduleTabsEnum.Tasks,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]!);

  const handleTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
  };

  const handleStepResponseChange = (step: StepExecutionType) => {
    setCurrentSteps(currentSteps.map(cs => (cs.id === step.id ? step : cs)));
  };

  const onDeleteClick = async () => {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.workOrder.preventiveMaintenance.schedule.details.delete.confirm.title'
        ),
        message: t(
          'web.admin.workOrder.preventiveMaintenance.schedule.details.delete.confirm.message',
          { scheduleTitle: schedule?.title }
        ),
      });
      await deleteSchedule();
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.deleteSuccess`
      );
      history.push(scheduleListPath);
    } catch (err) {
      // do nothing
    }
  };

  const deleteSchedule = async () => {
    try {
      await getClient().mutate({
        mutation: deletePMScheduleMutation,
        variables: {
          scheduleId: convertToUUID(schedule?.scheduleId),
        },
      });
    } catch (err: any) {
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.deleteError`
      );
    }
  };

  useEffect(() => {
    if (schedule?.createdBy) {
      publicUser({
        variables: {
          id: convertToUUID(schedule?.createdBy),
        },
      });
    }
  }, [schedule?.createdBy, publicUser]);

  const getEquipmentsOnSchedule = async () => {
    if (schedule?.equipmentIds?.length > 0) {
      const equipmentQueries = schedule.equipmentIds.map(equipmentId => {
        return getClient().query({
          query: getEquipmentQuery,
          variables: {
            equipmentId,
          },
          fetchPolicy: 'network-only',
        });
      });
      await Promise.all(equipmentQueries).then(data => {
        const equipmentsOnSchedule = data.map(
          ({ data: { getEquipment: equipment } }) => {
            return equipment;
          }
        );
        setScheduleEquipments(compact(equipmentsOnSchedule));
      });
    }
  };

  useEffect(() => {
    if (
      schedule?.assignee &&
      assignableUsers?.length > 0 &&
      schedule?.assignee.id !== assignedMember?.id
    ) {
      const assignedUser = assignableUsers.find(
        ({ id }) => id === schedule?.assignee?.id
      );
      setAssignedMember(assignedUser);
    }
  }, [
    adminUsers,
    setAssignedMember,
    assignedMember?.value,
    assignableUsers,
    schedule?.assignee,
  ]);

  useEffect(() => {
    if (assignableTeams?.length > 0 && schedule?.assigneeGroups) {
      const assignedGroups = assignableTeams.filter(t =>
        schedule.assigneeGroups.find(
          id => convertToUUID(id) === convertToUUID(t.value)
        )
      );

      setAssignedTeams(assignedGroups);
    }
  }, [schedule?.assigneeGroups, assignableTeams?.length]);

  const actionArray = [
    {
      label: t`web.admin.workOrder.preventiveMaintenance.schedule.details.editSchedule`,
      onClick: () => {
        history.push(
          routes.channelAdminWorkOrdersPMScheduleEdit
            .replace(':id', channel?.slug)
            .replace(':scheduleId', scheduleId)
        );
      },
    },
    {
      label: t`web.admin.workOrder.preventiveMaintenance.schedule.details.actions.export`,
      onClick: () => pdfDownloadLinkElement.current!.click(),
    },
    {
      label: t`web.admin.workOrder.preventiveMaintenance.schedule.details.actions.delete`,
      onClick: onDeleteClick,
    },
  ];

  const scheduleUpdateData = (): UpdateScheduleRequestDTO => ({
    scheduleId: convertToUUID(schedule.scheduleId),
    title: schedule.title,
    nextDueDate: schedule.nextDueDate,
    untilDate: schedule.untilDate,
    repeats: schedule.repeats,
    daysAhead: schedule.daysAhead,
    weekday: schedule.weekday,
    weekNo: schedule.weekNo,
    monthNo: schedule.monthNo,
    interval: schedule.interval,
    assignee: schedule.assignee?.id ? schedule.assignee?.id : undefined,
    equipmentIds: schedule.equipmentIds,
    notes: schedule.notes,
    timeToComplete: schedule.timeToComplete,
    completeWithin: schedule.completeWithin,
    steps: schedule.steps,
    meterReading: schedule.meterReading,
    location: schedule.location,
    attachments: schedule.attachments,
    assigneeGroups: schedule.assigneeGroups,
  });

  const handleAssigneeChanged = async (newAssignee: { value: string }) => {
    try {
      updateSchedule({
        ...scheduleUpdateData(),
        assignee: safeConvertToUUID(newAssignee?.value),
      });

      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.success`
      );
    } catch (err: any) {
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.error`
      );
    }
  };

  const handleAssignedTeamsChanged = async (newTeams: { value: string }[]) => {
    try {
      updateSchedule({
        ...scheduleUpdateData(),
        assigneeGroups:
          newTeams?.map(team => safeConvertToUUID(team.value)) ?? [],
      });

      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.success`
      );
    } catch (err: any) {
      window.Toast.show(
        t`web.admin.workOrder.preventiveMaintenance.schedule.details.toast.error`
      );
    }
  };

  const handleAttachmentCreated = async (attachments: AttachmentResponse[]) => {
    updateSchedule({
      ...scheduleUpdateData(),
      attachments: attachments.map(attachment => attachment.id),
    });
  };

  const updateSchedule = async (scheduleData: UpdateScheduleRequestDTO) => {
    await getClient().mutate({
      mutation: updatePMScheduleMutation,
      variables: {
        updatePMSchedule: {
          ...scheduleData,
        },
      },
    });
    await refetch();
  };

  const recurrenceLabel = recurrenceOptions.find(
    ({ value }) => schedule?.repeats === value
  )?.label;

  const isArchived = schedule?.isArchived;

  return (
    <div className={styles.ScheduleDetailsPage}>
      <BreadCrumbs
        links={[
          {
            label: t`web.admin.workOrder.preventiveMaintenance.title`,
            url: scheduleListPath,
          },
          {
            label: t`web.admin.workOrder.preventiveMaintenance.scheduleTabHeading`,
            url: scheduleListPath,
          },
          {
            label: `${schedule?.title}`,
          },
        ]}
      />
      <Flex
        className={styles.ScheduleDetailsHeader}
        direction="row"
        justify="space-between"
        align="flex-start"
      >
        <Flex direction="column" gap={4}>
          <H3>{schedule?.title}</H3>
          <span>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.createdBy',
              {
                date: simpleDate(schedule?.createdAt),
                name: userData?.user?.name,
              }
            )}
          </span>
        </Flex>
        <Flex align="center">
          {!isArchived ? (
            <PopupMenu
              trigger={
                <Button
                  variant="secondary"
                  className={styles.actionButton}
                  endIcon={<Icon name="chevron-down" />}
                  dataCy="actionsButton"
                >
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.details.actions.label'
                  )}
                </Button>
              }
              items={actionArray.map(option => ({
                label: option.label,
                onSelect: option.onClick,
              }))}
            />
          ) : (
            <Flex m={2}>
              <Button
                className={styles.exportButton}
                variant="text"
                onClick={() => pdfDownloadLinkElement.current!.click()}
              >
                <Flex>
                  <FontAwesomeIcon icon={faArrowToBottom} />
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.task.details.actions.export'
                  )}
                </Flex>
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isArchived && (
        <Flex direction="row">
          <Alert type="warning" className={styles.alert}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.isArchived.warning'
            )}
          </Alert>
        </Flex>
      )}
      <Flex gap={4} className={styles.assigneeSection} align="flex-start">
        <div className={styles.SelectDropdown}>
          <StaffTeamsMultiSelectDropdown
            items={assignableTeams}
            isSearchable
            value={assignedTeams as any}
            onChange={handleAssignedTeamsChanged}
            placeholder={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.select.teams'
            )}
            label={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.assign.teams'
            )}
            className={styles.SelectDropdown}
            doTranslate={false}
            disabled={isArchived}
          />
        </div>

        <div className={styles.SelectDropdown}>
          <AssignedMemberDropdown
            fieldName="assign-to-person"
            users={adminUsers}
            setAssignedMember={handleAssigneeChanged}
            assignedMember={assignedMember}
            placeholder={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.select.person'
            )}
            label={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.assign.person'
            )}
            className={styles.SelectDropdown}
            doTranslate={false}
            disabled={isArchived}
          />
        </div>
      </Flex>

      <Flex direction="column">
        <TabStrip
          tabs={tabs}
          selected={selectedTab}
          onSelectTab={handleTabChange}
          className={styles.ScheduleTabStrip}
          fullWidth
          skipLabelTranslation
        />
      </Flex>

      {selectedTab.value === ScheduleTabsEnum.Details ? (
        <>
          <Flex
            className={styles.ScheduleDetailsBlock}
            width="full"
            direction="column"
            gap={4}
          >
            <H4 mt={2}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.schedule.details.heading'
              )}
            </H4>
            <Box className={styles.ScheduleDetailsRecurrenceGrid} width="full">
              <Flex direction="column">
                <Label className={styles.detailsText}>
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.details.repeats`}
                </Label>
                <P>{recurrenceLabel}</P>
              </Flex>
              <Flex direction="column">
                <Label className={styles.detailsText}>
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.details.timeToComplete`}
                </Label>
                <P>
                  {schedule?.timeToComplete.toFixed(2)}{' '}
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.details.hoursUnit`}
                </P>
              </Flex>
              <Flex direction="column">
                <Label className={styles.detailsText}>
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.Form.firstDueDate`}
                </Label>
                <P>{simpleDate(schedule?.nextDueDate!)}</P>
              </Flex>
              {schedule?.equipmentIds?.length === 0 && (
                <Flex direction="column">
                  <Label className={styles.detailsText}>
                    {t`web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location`}
                  </Label>
                  <P mb={2}>{schedule?.location}</P>
                </Flex>
              )}
            </Box>
            <Flex direction="column">
              <Label mt={3} className={styles.detailsText}>
                {t`web.admin.workOrder.preventiveMaintenance.schedule.details.notes`}
              </Label>
              <P className={styles.multilineText} mb={2}>
                {schedule?.notes}
              </P>
            </Flex>
          </Flex>
          {schedule?.equipmentIds?.length > 0 && (
            <Flex
              className={styles.ScheduleDetailsBlock}
              width="full"
              direction="column"
            >
              <H4 mb={3} mt={2}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.heading'
                )}
              </H4>
              {scheduleEquipments.map(
                ({ category, name, id, location, floor, suite }, index) => (
                  <Flex
                    key={id}
                    direction="row"
                    className={cx(
                      styles.equipmentRow,
                      index !== scheduleEquipments.length - 1 && styles.divider
                    )}
                  >
                    <Flex direction="column" className={styles.equipmentCol}>
                      <P className={styles.categoryText}>{category}</P>
                      {hasEquipmentAccess && isEquipmentEnabled ? (
                        <Link
                          to={routes.channelAdminWorkOrdersEquipmentDetails
                            .replace(':id', channel?.slug)
                            .replace(':equipmentId', id)}
                        >
                          {name}
                        </Link>
                      ) : (
                        <P>{name}</P>
                      )}
                    </Flex>
                    <Flex direction="column" className={styles.equipmentCol}>
                      <span>
                        <P className={styles.detailsText}>
                          {t(
                            'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location'
                          )}
                        </P>
                        <P>{location}</P>
                      </span>
                      {floor ? (
                        <span>
                          <P className={styles.detailsText}>
                            {t(
                              'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.floor'
                            )}
                          </P>
                          <P>{floor}</P>
                        </span>
                      ) : null}
                      {suite ? (
                        <span>
                          <P className={styles.detailsText}>
                            {t(
                              'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.suite'
                            )}
                          </P>
                          <P>{suite}</P>
                        </span>
                      ) : null}
                    </Flex>
                  </Flex>
                )
              )}
            </Flex>
          )}
          {!newScheduleDetailsForm && schedule?.steps && (
            <Flex
              className={styles.ScheduleDetailsBlock}
              width="full"
              direction="column"
            >
              <Flex direction="row" align="center" justify="space-between">
                <H4 mb={3} mt={2}>
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.details.steps`}
                </H4>
                <Button
                  variant="text"
                  onClick={() => setStepsCollapsed(!stepsCollapsed)}
                >
                  {stepsCollapsed
                    ? t`web.admin.workOrder.preventiveMaintenance.schedule.details.expand`
                    : t`web.admin.workOrder.preventiveMaintenance.schedule.details.collapse`}
                </Button>
              </Flex>
              <p
                className={cx(styles.CollapsableText, styles.multilineText)}
                data-closed={stepsCollapsed}
              >
                {schedule?.steps}
              </p>
            </Flex>
          )}
          {!newScheduleDetailsForm && schedule?.meterReading && (
            <Flex
              className={styles.ScheduleDetailsBlock}
              width="full"
              direction="column"
            >
              <Flex direction="row" align="center" justify="space-between">
                <H4 mb={3} mt={2}>
                  {t`web.admin.workOrder.preventiveMaintenance.schedule.details.meterReading`}
                </H4>
                <Button
                  variant="text"
                  onClick={() =>
                    setMeterReadingsCollapsed(!meterReadingsCollapsed)
                  }
                >
                  {meterReadingsCollapsed
                    ? t`web.admin.workOrder.preventiveMaintenance.schedule.details.expand`
                    : t`web.admin.workOrder.preventiveMaintenance.schedule.details.collapse`}
                </Button>
              </Flex>
              <P
                className={cx(styles.CollapsableText, styles.multilineText)}
                data-closed={meterReadingsCollapsed}
              >
                {schedule?.meterReading}
              </P>
            </Flex>
          )}
          <Flex
            className={styles.ScheduleDetailsBlock}
            width="full"
            direction="column"
          >
            <Flex direction="column">
              <H4 mb={4} mt={2}>
                {t`web.admin.workOrder.preventiveMaintenance.schedule.attachments.title`}
              </H4>
              <AddAttachment
                entityId={convertToUUID(scheduleId)}
                entityType="PMSchedule"
                editMode={false}
                variant={AttachmentVariantEnum.WorkOrder}
                afterAttachmentCreated={handleAttachmentCreated}
                acceptedFileTypes={[
                  ...Object.values(AttachmentImageContentTypeEnum),
                  AttachmentDocumentContentTypeEnum.pdf,
                ]}
                canAddAttachments={!isArchived}
                preventDelete={isArchived}
              />
            </Flex>
          </Flex>
        </>
      ) : null}

      {selectedTab.value === ScheduleTabsEnum.Steps ? (
        <StepExecutionForm
          channel={channel}
          steps={currentSteps}
          onStepChange={handleStepResponseChange}
          disabled
        />
      ) : null}

      {selectedTab.value === ScheduleTabsEnum.Tasks ? (
        <TinyTaskList
          parentType="SCHEDULE"
          searchID={scheduleId}
          channel={channel}
        />
      ) : null}

      <BlobProvider
        document={
          <ScheduleDetailsPDF
            scheduleData={schedule}
            assignedMemberName={assignedMember?.label || ''}
            equipmentData={scheduleEquipments}
            createdUserName={userData?.user?.name || ''}
            recurrenceLabel={recurrenceLabel || ''}
            isPropertyChannel={isPropertyChannel}
            t={t}
          />
        }
      >
        {/* @ts-expect-error */}
        {({ url }) => {
          return (
            <a
              hidden
              ref={pdfDownloadLinkElement}
              href={url!}
              download={`${schedule?.title}.pdf`}
            >
              {t('web.admin.serviceRequest.export')}
            </a>
          );
        }}
      </BlobProvider>
    </div>
  );
}
export default ScheduleDetails;
