import React from 'react';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import WhitelabelTermsAndPolicy from '../WhitelabelTermsAndPolicy';
import SignUpWithEmailForm from './SignUpWithEmailForm';
import SignUpWithSSO from './SignUpWithSSO';

import styles from './index.scss';

export interface SignUpUserData {
  fullName: string;
  email: string;
  password: string;
  emailOptIn: boolean;
}

interface Props {
  userData: {
    email: string;
    name?: string;
  };
  whitelabel: WhiteLabelType;
  onManualSubmit: (data: SignUpUserData) => void;
  onSSOSuccess: () => void;
  onSSOError: (err: Error | null) => void;
}

function SignUpForm({
  userData,
  whitelabel,
  onManualSubmit,
  onSSOSuccess,
  onSSOError,
}: Props) {
  const hasOAuthAvailable = Boolean(
    whitelabel?.oAuthConfig?.some(({ enabled }) => enabled)
  );

  return (
    <div className={styles.container}>
      <div>
        <SignUpWithEmailForm
          email={userData.email}
          name={userData.name}
          onSubmit={onManualSubmit}
          whitelabel={whitelabel}
        />

        {hasOAuthAvailable && (
          <SignUpWithSSO
            whitelabel={whitelabel}
            onSuccess={onSSOSuccess}
            onError={onSSOError}
          />
        )}
      </div>

      <div className={styles.bottomSection}>
        <div data-testid="terms-and-policy-section">
          <WhitelabelTermsAndPolicy whitelabel={whitelabel} />
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;
