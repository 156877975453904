import React, { useState, useContext, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';

import { UserDataContext } from 'lane-shared/contexts';
import { getValidationMessages } from 'lane-shared/helpers';
import { CURRENCY_USD } from 'lane-shared/helpers/constants/currencyCodes';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  ContractUnitEnum,
  ChannelContractLineItemType,
  ChannelContractType,
} from 'lane-shared/types/ChannelContract';
import { validateCreateChannelContract } from 'lane-shared/validation';

import SimpleInput from 'components/form/SimpleInput';
import Label from 'components/general/Label';
import ValidationMessage from 'components/general/ValidationMessage';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import ChannelSelectorButton from 'components/lane/ChannelSelectorButton';
import UserCircleListView from 'components/lane/UserCircleListView';
import UserSelectorButton from 'components/lane/UserSelectorButton';

import ChannelContractLineItemEdit from './ChannelContractLineItemEdit';

import styles from './ChannelContractEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  contract: ChannelContractType;
  onChannelContractUpdated: (update: Partial<ChannelContractType>) => void;
  onValidation: (validation: ValidationError | null) => void;
};

export default function ChannelContractEdit({
  className,
  style,
  contract,
  onChannelContractUpdated,
  onValidation,
}: Props) {
  const { user } = useContext(UserDataContext);
  const [validation, setValidation] = useState(null);

  async function validate() {
    try {
      await validateCreateChannelContract.validate(contract, {
        abortEarly: false,
      });
      setValidation(null);
      onValidation(null);
      return true;
    } catch (err) {
      setValidation(err);
      onValidation(err);
      return false;
    }
  }

  useEffect(() => {
    validate();
  }, [contract]);

  function updateLineItem(
    lineItem: ChannelContractLineItemType,
    update: Partial<ChannelContractLineItemType>
  ) {
    const ix = contract.lineItems.findIndex(({ _id }) => lineItem._id === _id);
    const lineItems = [...contract.lineItems];
    lineItems[ix] = { ...lineItem, ...update };
    onChannelContractUpdated({ lineItems });
  }

  function removeLineItem(lineItem: ChannelContractLineItemType) {
    onChannelContractUpdated({
      lineItems: contract.lineItems.filter(li => li._id !== lineItem._id),
    });
  }

  function addLineItem() {
    onChannelContractUpdated({
      lineItems: [
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelContractLineItemType | { _id: string;... Remove this comment to see the full error message
        ...contract.lineItems,
        {
          _id: uuid(),
          _created: new Date(),
          _createdBy: user?._id,
          _updated: new Date(),
          _updatedBy: user?._id,
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"Platform"' is not assignable to type '(stri... Remove this comment to see the full error message
          name: 'Platform',
          unitType: ContractUnitEnum.SquareFoot,
          units: 1,
          pricePerUnit: 3.5,
          currency: CURRENCY_USD,
          subscriptionStartDate: null,
          // @ts-expect-error ts-migrate(2740) FIXME: Type 'DateTime' is missing the following propertie... Remove this comment to see the full error message
          estimatedSubscriptionStartDate: DateTime.local()
            .startOf('day')
            .plus({ month: 1 }),
          termLengthMonths: 12,
        },
      ],
    });
  }

  function mapToId(props: any) {
    for (const key of Object.keys(props)) {
      if (props[key]) {
        props[key] = {
          _id: props[key]._id,
        };
      }
    }

    return props;
  }

  return (
    <div className={cx(styles.ChannelContractEdit, className)} style={style}>
      <section className={styles.panel}>
        <Label className={styles.label}>Select a channel</Label>
        <ChannelSelectorButton
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
          onChannelSelected={(channel: any) =>
            onChannelContractUpdated(mapToId({ channel }))
          }
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
          channelId={contract?.channel?._id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
          renderChannel={channel => <ChannelCircleListView channel={channel} />}
        />
        <ValidationMessage
          errors={getValidationMessages(validation, 'channel')}
        />
      </section>

      <Label h1 className={styles.header}>
        Key points of contact
      </Label>
      <section className={styles.panel}>
        <div className={styles.row}>
          <div className={cx(styles.group, styles.group50)}>
            <Label className={styles.label}>Main Client Contact</Label>
            <UserSelectorButton
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              userId={contract?.contactUser?._id}
              channelId={contract?.channel?._id}
              onUserSelected={contactUser =>
                onChannelContractUpdated(mapToId({ contactUser }))
              }
              renderUser={user => <UserCircleListView user={user} />}
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'contactUser')}
            />
          </div>

          <div className={cx(styles.group, styles.group50)}>
            <Label className={styles.label}>Account Manager</Label>
            <UserSelectorButton
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              userId={contract?.salesUser?._id}
              onUserSelected={salesUser =>
                onChannelContractUpdated(mapToId({ salesUser }))
              }
              renderUser={user => <UserCircleListView user={user} />}
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'salesUser')}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={cx(styles.group, styles.group50)}>
            <Label className={styles.label}>Customer Success Manager</Label>
            <UserSelectorButton
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              userId={contract?.managerUser?._id}
              onUserSelected={managerUser =>
                onChannelContractUpdated(mapToId({ managerUser }))
              }
              renderUser={user => <UserCircleListView user={user} />}
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'managerUser')}
            />
          </div>

          <div className={cx(styles.group, styles.group50)}>
            <Label className={styles.label}>Customer Success</Label>
            <UserSelectorButton
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              userId={contract?.supportUser?._id}
              onUserSelected={supportUser =>
                onChannelContractUpdated(mapToId({ supportUser }))
              }
              renderUser={user => <UserCircleListView user={user} />}
            />
            <ValidationMessage
              errors={getValidationMessages(validation, 'supportUser')}
            />
          </div>
        </div>
      </section>

      <Label h1 className={styles.header}>
        Contract Details
      </Label>
      <section className={styles.panel}>
        <Label className={styles.label}>Contract ID</Label>
        <SimpleInput
          onChange={name => onChannelContractUpdated({ name })}
          value={contract?.name}
          placeholder="Contract ID"
          errors={getValidationMessages(validation, 'name')}
        />

        <hr />

        <Label className={styles.label}>
          Line Items{' '}
          <Icon
            name="plus-circle"
            set={ICON_SET_FONTAWESOME}
            className={styles.addIcon}
            onClick={addLineItem}
          />
        </Label>
        <ul className={styles.lineItems}>
          <ValidationMessage
            errors={getValidationMessages(validation, 'lineItems')}
          />

          {contract.lineItems.map((lineItem, i) => (
            <ChannelContractLineItemEdit
              key={lineItem._id}
              lineItem={lineItem}
              validationPath={`lineItems[${i}]`}
              onLineItemUpdated={updateLineItem}
              onLineItemDeleted={removeLineItem}
            />
          ))}
        </ul>
      </section>
    </div>
  );
}
