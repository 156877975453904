import ChannelAdminContext from '../../ChannelAdminContext';
import { createProductMutation } from '../helpers/createProductMutation';
import styles from './CreateProductForm.scss';
import { CreateProductFormHeader } from './CreateProductFormHeader';
import { CreateProductFormInputFields } from './CreateProductFormInputFields';
import { CreateProductSummary } from './CreateProductSummary';
import { ErrorMessage } from 'components';
import { GraphQLError } from 'graphql';
import useUserLocale from 'hooks/useUserLocale';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  NewProductDetails,
  currencyToPaymentCurrency,
  TAX_VALUE_CONVERSION,
  TaxPolicy,
  ProductType,
} from 'lane-shared/domains/billingPayments/types';
import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { AdminPage } from 'lane-web/src/components/layout';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isNumberTwoDecimalPlaces } from '../helpers';
import { Button, H4 } from 'design-system-web';
import { CreditPricingSummary } from './CreditPricingSummary';

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
};

export function CreateProductForm({ breadcrumbs }: Props) {
  const { channel } = useContext(ChannelAdminContext);
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const locale = useUserLocale();
  const [pageError, setPageError] = useState<
    readonly GraphQLError[] | Error | undefined
  >(undefined);

  const {
    paymentSettingsError,
    taxDetails,
    currency,
  } = useBillingPaymentSettingsForChannel({ channelId: channel?._id });

  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;
  const { t } = useTranslation();

  const [newProductDetails, setNewProductDetails] = useState<NewProductDetails>(
    {
      productCategoryId: '',
      productCategoryName: '',
      productType: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      chargeCodeId: undefined,
      name: '',
      description: '',
      rate: '',
      markup: undefined,
      tax: undefined,
      taxDefault: undefined,
      creditAmount: undefined,
    }
  );

  // set tax default if it has been set in channel payment settings
  useEffect(() => {
    if (taxDetails && taxDetails.taxAmountValue) {
      const taxValue = {
        type: taxDetails.taxAmountType,
        value: (taxDetails.taxAmountValue / TAX_VALUE_CONVERSION).toString(),
      };
      setNewProductDetails(prev => ({
        ...prev,
        tax: taxValue,
        taxDefault: taxValue,
      }));
    }
  }, [taxDetails]);

  const handleProductDetailsUpdate = (value: any, field: string) => {
    const emptyCategory =
      field === 'productType'
        ? { productCategoryId: '', productCategoryName: '' }
        : {};

    setNewProductDetails({
      ...newProductDetails,
      [field]: value,
      ...emptyCategory,
    });
  };

  const handleSaveProduct = async () => {
    const priceComponents = priceInputToComponents(newProductDetails);
    const { rate, grossTax, grossMarkup, listPrice, netPrice } = getPricing({
      currency: currencyToPaymentCurrency(currency),
      locale,
      priceComponents,
      inclusivePricing,
    });

    if (inclusivePricing) {
      newProductDetails.rate = rate.toString();
    }

    try {
      const result = await createProductMutation(
        newProductDetails,
        { grossTax, grossMarkup, listPrice, netPrice },
        channel?._id || ''
      );
      if (result.errors) {
        setPageError(result.errors);
      }
      window.Toast.show(t('abp.productsServices.createProduct.successToast'));
      history.goBack();
    } catch (err: any) {
      window.Toast.show(t('abp.productsServices.createProduct.failToast'));
      setPageError(err);
    }
  };

  const handleCancel = async () => {
    history.goBack();
  };

  const isSaveButtonDisabled = () => {
    const {
      name,
      rate,
      markup,
      tax,
      netPrice,
      creditAmount,
    } = newProductDetails;

    // Check if rate and netPrice are greater than or equal to 0
    const rateIsCorrect =
      (Boolean(rate) && Number(rate) >= 0) ||
      (Boolean(netPrice) && Number(netPrice) >= 0);

    // Check if markup is greater than or equal to 0 and has two or fewer decimal places
    const markupRateCorrect =
      !markup ||
      (markup.value &&
        Number(markup.value) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(markup.value)));

    // Check if tax is greater than or equal to 0 and has two or fewer decimal places
    const taxRateCorrect =
      !tax ||
      (tax.value &&
        Number(tax.value) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(tax.value)));

    // Check if a category is selected
    const categorySelected = Boolean(newProductDetails.productCategoryId);

    // Check if a name is entered
    const nameEntered = Boolean(name);

    // Check if rate and netPrice have two or fewer decimal places
    const amountsAre2dp =
      isNumberTwoDecimalPlaces(parseFloat(rate)) &&
      (netPrice ? isNumberTwoDecimalPlaces(parseFloat(netPrice)) : true);

    // credit amount can be undefined, or a number greater than 0 with two or fewer decimal places
    const isValidCreditAmount =
      creditAmount === undefined ||
      (creditAmount &&
        parseFloat(creditAmount) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(creditAmount)));

    // Return false if all conditions are met, otherwise return true
    return !(
      rateIsCorrect &&
      markupRateCorrect &&
      taxRateCorrect &&
      categorySelected &&
      nameEntered &&
      amountsAre2dp &&
      isValidCreditAmount
    );
  };

  const paymentCurrency = currencyToPaymentCurrency(currency);

  return (
    <AdminPage>
      <CreateProductFormHeader
        id={id}
        handleCancel={handleCancel}
        handleSaveProduct={handleSaveProduct}
        isSaveButtonDisabled={isSaveButtonDisabled}
        breadcrumbs={breadcrumbs}
      />
      <div className={styles.createProductPage}>
        <CreateProductFormInputFields
          productDetails={newProductDetails}
          updateProductDetails={handleProductDetailsUpdate}
          currency={paymentCurrency}
          inclusivePricing={inclusivePricing}
        />
        <div className={styles.productPricingSummary}>
          <H4>
            {t('abp.productsServices.createProduct.pricingSummary.title')}
          </H4>
          <CreateProductSummary
            productDetails={newProductDetails}
            currency={paymentCurrency}
            inclusivePricing={inclusivePricing}
          />
          {newProductDetails.creditAmount !== undefined && (
            <CreditPricingSummary
              creditAmount={newProductDetails.creditAmount}
            />
          )}
        </div>
      </div>
      <div className={styles.buttonAndErrorGroup}>
        {pageError && <ErrorMessage error={pageError} />}
        {paymentSettingsError && <ErrorMessage error={paymentSettingsError} />}
        <div className={styles.buttonGroup}>
          <Button
            dataCy="saveNewProductButton"
            onClick={() => handleSaveProduct()}
            disabled={isSaveButtonDisabled()}
            size="large"
          >
            {t('abp.productsServices.createProduct.save')}
          </Button>
          <Button size="large" onClick={handleCancel} variant="secondary">
            {t('abp.cancel')}
          </Button>
        </div>
      </div>
    </AdminPage>
  );
}
