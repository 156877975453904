import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { useAuth0 } from '@auth0/auth0-react';

import routes from 'lane-shared/config/routes';
import { AppContext } from 'lane-shared/contexts';
import getAuth0Parameter from 'lane-shared/helpers/auth0/getAuth0Parameter';
import { mediaUrl } from 'lane-shared/helpers/formatters';
import {
  InviteOnlyError,
  useIdentifyUser,
} from 'lane-shared/hooks/auth/useIdentifyUser';

import { ErrorMessage, Loading } from 'components/general';
import { UserIdentificationAndOnboardingTemplate as Template } from 'components/pageTemplate/UserIdentificationAndOnboarding';

import { EmailForm } from './EmailForm';
import { useInviteContext } from 'lane-shared/contexts/InviteContext';
import { useHideAuth0LoginAlertBox } from 'lane-shared/hooks/auth0/useHideAuth0LoginAlertBox';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';

const TRANSLATION_KEYS = {
  corruptedInviteEmail:
    'shared.identifyUser.loginEmail.error.corruptedInviteEmail',
  inviteOnlyError: 'shared.identifyUser.loginEmail.error.inviteOnly',
};

const INVITE_ID_QUERY_PARAM = 'inviteId';
const CONTENT_PATH_QUERY_PARAM = 'contentPath';

export const IdentifyUser = () => {
  const { whitelabel } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<
    ApolloError | GraphQLErrors | Error | undefined
  >(undefined);

  const { t } = useTranslation();
  const { loginWithRedirect, isAuthenticated, error: auth0Error } = useAuth0();
  const { identifyUser } = useIdentifyUser();
  const { search } = useLocation();
  const hideAuth0LoginAlertBox = useHideAuth0LoginAlertBox();
  const isAuth0Enabled = useAuth0Enabled();

  const searchParams = new URLSearchParams(search);
  const searchParamInviteId = searchParams.get(INVITE_ID_QUERY_PARAM);
  const searchParamContentPath = searchParams.get(CONTENT_PATH_QUERY_PARAM);

  const {
    setInviteId,
    email: inviteEmail,
    error: inviteError,
  } = useInviteContext();

  if (searchParamInviteId && setInviteId) {
    setInviteId(searchParamInviteId);
  }

  const fetchAndRedirectToAuth0 = async (email: string) => {
    const {
      loginEmailExists,
      invitation: auth0Invitation,
      connection,
    } = await identifyUser(email);

    if (!whitelabel.auth0OrganizationId && auth0Invitation?.organizationId) {
      whitelabel.auth0OrganizationId = auth0Invitation.organizationId;
    }

    const isInvitedByActivateAdmin = Boolean(inviteEmail);

    if (isInvitedByActivateAdmin && email !== inviteEmail) {
      setError(new Error(t(TRANSLATION_KEYS.corruptedInviteEmail)));

      return;
    }

    return loginWithRedirect({
      authorizationParams: getAuth0Parameter(
        whitelabel,
        email,
        loginEmailExists,
        auth0Invitation?.ticketId,
        searchParamInviteId || '',
        connection,
        hideAuth0LoginAlertBox
      ),
      appState: {
        inviteId: searchParamInviteId,
        contentPath: searchParamContentPath,
      },
    });
  };

  const onSubmit = async (email: string) => {
    setIsLoading(true);
    setError(undefined);

    try {
      await fetchAndRedirectToAuth0(email);
    } catch (err) {
      if (err instanceof InviteOnlyError) {
        setError(new Error(t(TRANSLATION_KEYS.inviteOnlyError)));
      } else {
        setError(err);
      }
    }

    setIsLoading(false);
  };

  if (isAuthenticated) {
    return <Redirect to={routes.home} />;
  }

  if (!isAuth0Enabled) {
    return <Redirect to={routes.login} />;
  }

  if (searchParamInviteId && !inviteEmail && !inviteError) {
    return <Loading />;
  }

  return (
    <Template
      logo={mediaUrl(whitelabel.profile.logo)}
      name={whitelabel.profile.name}
    >
      <EmailForm
        isLoading={isLoading}
        email={inviteEmail}
        onSubmit={onSubmit}
      />
      {Boolean(error) && <ErrorMessage error={error} />}
      {Boolean(inviteError) && <ErrorMessage error={inviteError} />}
      {Boolean(auth0Error) && <ErrorMessage error={auth0Error} />}
    </Template>
  );
};
