import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input, Loading } from 'components';
import { useTranslation } from 'react-i18next';

import { CompanyLocationsSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { ChannelSearchCircleListView } from 'components/lane';
import { H5 } from 'components/typography';

import styles from '../index.scss';

const TRANSLATION_KEYS = {
  companyLabel: 'shared.onboarding.invite.companyName.label',
  companySearchLabel: 'shared.onboarding.invite.selectCompany.label',
  companyPlaceholder: 'shared.onboarding.invite.selectCompany.placeholder',
};

type Props = {
  selectedCompany: ChannelType | undefined;
  handleCompanySelectionChange: (data: {
    company: ChannelType | undefined;
  }) => void;
  handleCompanyLocationSearchTextChange: (
    companyLocationSearch: string
  ) => void;
  companyLocationSearch: string;
  companyLocationsResult: CompanyLocationsSearchResult;
  isCompanyLocked: boolean;
};

export const CompanySelection = ({
  selectedCompany,
  handleCompanySelectionChange,
  handleCompanyLocationSearchTextChange,
  companyLocationSearch,
  companyLocationsResult,
  isCompanyLocked,
}: Props) => {
  const { t } = useTranslation();

  if (selectedCompany) {
    return (
      <>
        <H5>{t(TRANSLATION_KEYS.companyLabel)}</H5>
        <div className={styles.companySelectedContainer}>
          <ChannelSearchCircleListView
            className={cx([styles.selectedElement])}
            key={selectedCompany._id}
            channel={selectedCompany}
            onClick={() => handleCompanySelectionChange({ company: undefined })}
            hideDescription
          />
          <Icon
            testID="onboarding-company-icon"
            name={isCompanyLocked ? 'lock' : 'edit'}
            className={styles.lockIcon}
            set={ICON_SET_FONTAWESOME}
            type={FONT_AWESOME_REGULAR}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <H5>{t(TRANSLATION_KEYS.companySearchLabel)}</H5>
      <div className={styles.companySearchContainer}>
        <Input
          onChange={handleCompanyLocationSearchTextChange}
          value={companyLocationSearch}
          placeholder={t(TRANSLATION_KEYS.companyPlaceholder)}
          dataCy="buildingLocation"
          icon="search"
          showClear
        />
        <div className={styles.companySearchResults}>
          {companyLocationsResult.locations.map((location, index) => (
            <ChannelSearchCircleListView
              className={cx([styles.companyResult, styles.selected])}
              key={(location as any)._id || index}
              channel={location.channel}
              hideDescription
              onClick={() =>
                handleCompanySelectionChange({
                  company: location.channel,
                })
              }
            />
          ))}
          {companyLocationsResult.loading && (
            <Loading className={styles.loader} />
          )}
        </div>
      </div>
    </>
  );
};
