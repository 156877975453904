import React from 'react';

import { Button, Icon } from 'design-system-web';
import { Flex, ValidatedInput } from 'lane-web/src/components';

import { useTranslation } from 'react-i18next';
import { validateWorkorder } from 'lane-shared/validation';

import styles from './EquipmentURLForm.scss';

export interface EquipmentURLFormProps {
  hyperlink: any;
  index: number;
  handleHyperlinkChange: (field: string, value: string, index: number) => void;
  handleRemoveHyperlink: (index: number) => void;
  validated?: boolean;
}

function EquipmentURLForm({
  handleHyperlinkChange,
  handleRemoveHyperlink,
  hyperlink,
  index,
  validated = false,
}: EquipmentURLFormProps) {
  const { t } = useTranslation();

  const { urlValidator, stringValidator } = validateWorkorder(t);

  return (
    <Flex gap={3} className={styles.HyperlinkLabels}>
      <div className={styles.HyperlinkInput}>
        <ValidatedInput
          className={styles.ValidatedInput}
          dataCy={`hyperlink-name-input-${index}`}
          value={hyperlink.name}
          onChange={(value: string) =>
            handleHyperlinkChange('name', value, index)
          }
          placeholder={t(
            'web.admin.serviceRequest.equipment.LinkedDocumentation.name.placeholder'
          )}
          isPristine={false}
          validation={validated ? stringValidator : undefined}
        />
      </div>
      <div className={styles.HyperlinkInput}>
        <ValidatedInput
          className={styles.ValidatedInput}
          icon="link"
          dataCy={`hyperlink-url-input-${index}`}
          value={hyperlink.url}
          onChange={(value: string) =>
            handleHyperlinkChange('url', value, index)
          }
          placeholder={t(
            'web.admin.serviceRequest.equipment.LinkedDocumentation.url.placeholder'
          )}
          isPristine={false}
          validation={validated ? urlValidator : undefined}
        />
      </div>
      <Button
        variant="text"
        startIcon={<Icon name="trash-alt" />}
        dataCy="remove-option"
        onClick={() => handleRemoveHyperlink(index)}
      >
        {t('web.admin.serviceRequest.equipment.LinkedDocumentation.remove')}
      </Button>
    </Flex>
  );
}

export default EquipmentURLForm;
