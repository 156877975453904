import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'localization';

import { Button } from 'design-system-web';

import { ContentRendererContext } from 'lane-shared/contexts';
import {
  useContentWaitlistActions,
  useWaitlistEnrollmentsByContent,
} from 'lane-shared/hooks/contentState';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';

import { WebBlockProps } from '../WebBlockProps';

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = Object.freeze({
  joinWaitlist: 'shared.content.feature.reservable.waitlist.join',
  leaveWaitlist: 'shared.content.feature.reservable.waitlist.leave',
  joinWaitlistContentId:
    'shared.content.feature.reservable.waitlist.join.contentid.required',
  joinWaitlistSuccess:
    'shared.content.feature.reservable.waitlist.join.success',
  leaveWaitlistSuccess:
    'shared.content.feature.reservable.waitlist.leave.success',
  leaveWaitlistEnrolledId:
    'shared.content.feature.reservable.waitlist.leave.enrolledId.required',
});

export function withQuantity<T extends WebBlockProps>(Component: React.FC<T>) {
  function WithQuantityFeature({ ...props }: T) {
    const { t } = useTranslation();
    const { content, disabled } = useContext(ContentRendererContext);

    const {
      enrollmentId,
      getWaitlistEnrollmentForUser,
      isLoading: contentWaitlistEnrollmentLoading,
    } = useWaitlistEnrollmentsByContent(content?._id);

    const {
      joinWaitlist,
      leaveWaitlist,
      isLoading: contentWaitlistActionLoading,
    } = useContentWaitlistActions();

    const { quantityFeature } = explodeFeatures(content?.features);

    const handleClick = async () => {
      if (enrollmentId) {
        try {
          if (!enrollmentId) {
            throw new Error(t(TRANSLATION_KEYS.leaveWaitlistEnrolledId));
          }

          await leaveWaitlist(enrollmentId, content._id);
          await getWaitlistEnrollmentForUser();
          window.Toast.show(t(TRANSLATION_KEYS.leaveWaitlistSuccess));
        } catch (e) {
          window.Toast.show(e.message);
        }

        return;
      }

      const isEvent = Boolean(content?.startDate && content?.endDate);
      const timeSlot = {
        startDate: isEvent ? content?.startDate : content?.publishedAt,
        endDate: isEvent ? content?.endDate : content?.unpublishDate,
      };

      try {
        if (!content?._id) {
          throw new Error(t(TRANSLATION_KEYS.joinWaitlistContentId));
        }

        await joinWaitlist(content._id, timeSlot);
        await getWaitlistEnrollmentForUser();
        window.Toast.show(t(TRANSLATION_KEYS.joinWaitlistSuccess));
      } catch (e) {
        window.Toast.show(e.message);
      }
    };

    if (quantityFeature && quantityFeature?.useWaitlist && disabled) {
      return (
        <Button
          onClick={handleClick}
          fullWidth
          variant="secondary"
          loading={
            contentWaitlistEnrollmentLoading || contentWaitlistActionLoading
          }
        >
          {enrollmentId
            ? t(TRANSLATION_KEYS.leaveWaitlist)
            : t(TRANSLATION_KEYS.joinWaitlist)}
        </Button>
      );
    }

    return <Component {...props} />;
  }

  return WithQuantityFeature;
}
