import React, { useContext, useState } from 'react';
import { AdminPage } from 'components/layout';
import styles from './UserLanguageSettings.scss';
import { Dropdown, Label, LanguagePicker } from 'components';
import { graphql } from 'lane-shared';
import { useTranslation } from 'react-i18next';
import { useChannelProfileQuery, useCurrentChannel } from 'lane-shared/hooks';
import { getClient } from 'lane-shared/apollo';

import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { useUserChannelSettings } from 'lane-shared/hooks/useUserChannelSettings';
import { useIsAdminView } from 'hooks';
import i18n, { SupportedLocalesFriendlyNames } from 'localization';
import { getDynamicTranslationFunction } from 'lane-shared/helpers/dynamicLanguages';
import { M } from 'design-system-web';
import { errorCodes } from 'activate-errors';

export function UserLanguageSettings() {
  const { t } = useTranslation();
  const { user, refetch } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    autoUpdateChannelLanguageSent,
    setAutoUpdateChannelLanguageSent,
  ] = useState<boolean>(false);
  const currentChannel = useCurrentChannel();

  const channelName = getDisplayName(currentChannel);
  const {
    channelSettings,
    userChannelSettingsLoading,
    updateChannelSettings,
  } = useUserChannelSettings({
    channel: primaryChannel,
    eventSubscriptions: [],
    onUpdateEventSubscriptions: () => null,
  });

  const [, channelSlug] = useIsAdminView();
  const { channel: adminChannel } = useChannelProfileQuery({
    channelId: channelSlug,
  });

  const isMultiLanguageEnabledForMembers =
    Object.values(primaryChannel?.settings?.enabledLanguagesForMembers || {})
      .length > 1;

  // if there is an admin channel, and the admin channel id is different from
  // primary channel, we shouldn't show the language dropdown
  const isAdminChannelSameAsPrimaryChannel =
    (adminChannel?._id || primaryChannel?._id) === primaryChannel?._id;
  const shouldSeeChannelLanguageDropdown =
    isAdminChannelSameAsPrimaryChannel &&
    user &&
    primaryChannel?.settings?.multiLanguageEnabled &&
    isMultiLanguageEnabledForMembers;

  const languageDropdownItems = Object.entries(
    primaryChannel?.settings?.enabledLanguagesForMembers || {}
  )
    .filter(([_, value]) => value)
    .map(([key, _]) => ({
      label: (SupportedLocalesFriendlyNames as any)[key!] as string,
      value: key!,
    }));

  if (
    shouldSeeChannelLanguageDropdown &&
    !userChannelSettingsLoading &&
    !channelSettings?.language &&
    !autoUpdateChannelLanguageSent
  ) {
    setAutoUpdateChannelLanguageSent(true);
    const { translationKey } = getDynamicTranslationFunction({
      userSelectedChannelLanguage: null,
      userLocale: user!.locale,
      textInPrimaryLanguage: '',
      channelSettings: primaryChannel!.settings,
    });
    if (primaryChannel) {
      if (
        !primaryChannel.settings.enabledLanguagesForMembers![translationKey]
      ) {
        updateChannelSettings({
          language: primaryChannel.settings.channelLanguages?.primary!,
        });
      }
    } else {
      updateChannelSettings({ language: translationKey });
    }
  }

  async function changeUserLocale(locale: any) {
    setLoading(true);
    await i18n.changeLanguage(locale);

    try {
      await getClient().mutate({
        mutation: graphql.user.updateUser,
        variables: {
          user: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: user._id,
            locale,
          },
        },
      });
      window.Toast.show(
        t('web.pages.portal.settings.userLanguageSettings.update.success.toast')
      );
      await refetch();
    } catch (err) {
      window.Alert.alert({
        title: t(errorCodes.internalError.message),
        error: err,
      });
    }

    setLoading(false);
  }

  async function changeChannelLocale({ value }: { value: string }) {
    const result = await updateChannelSettings({
      language: value,
    });
    if (result) {
      window.Toast.show(
        t('web.pages.portal.settings.userLanguageSettings.update.success.toast')
      );
    }
  }

  return (
    <AdminPage className={styles.UserLanguageSettings}>
      <div className={styles.headerDiv}>
        <Label h1>
          {t('web.pages.portal.settings.userLanguageSettings.header.title')}
        </Label>
      </div>
      <M className={styles.chooseLanguage} variant="secondary">
        {t(
          'web.pages.portal.settings.userLanguageSettings.chooseLanguage.text'
        )}
      </M>

      <div className={styles.myLanguageDiv}>
        <Label h4 className={styles.myLanguageTitle}>
          {t('web.pages.portal.settings.userLanguageSettings.myLanguage.title')}
        </Label>
        <LanguagePicker
          className={styles.myLanguagePicker}
          disabled={loading}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          value={user?.locale}
          onChange={changeUserLocale}
          isIconEnabled={false}
          isFullWidth
        />
        <M className={styles.myLanguageDescription} variant="secondary">
          {t(
            'web.pages.portal.settings.userLanguageSettings.myLanguage.description'
          )}
        </M>
      </div>
      {shouldSeeChannelLanguageDropdown && (
        <div className={styles.channelLanguageDiv}>
          <Label h4 className={styles.channelLanguageTitle}>
            {t(
              'web.pages.portal.settings.userLanguageSettings.channelLanguage.title',
              { channelName }
            )}
          </Label>

          <Dropdown
            dataCy="channelLanguagePicker"
            className={styles.channelLanguagePicker}
            onChange={changeChannelLocale}
            items={languageDropdownItems}
            value={channelSettings?.language}
            doTranslation={false}
          />
          <M className={styles.channelLanguageDescription} variant="secondary">
            {t(
              'web.pages.portal.settings.userLanguageSettings.channelLanguage.description',
              { channelName }
            )}
          </M>
        </div>
      )}
    </AdminPage>
  );
}
