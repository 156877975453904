import React, { useContext } from 'react';

import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';

import { ContentRendererContext, UserDataContext } from 'lane-shared/contexts';
import { isContentBetweenInteractionTimes } from 'lane-shared/helpers/content';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';
import { withQuantity } from './withQuantity';

import Button from 'components/general/Button';

import { WebBlockProps } from '../WebBlockProps';
import useEditModeProps from './useEditModeProps';

import styles from './ButtonBlock.scss';

type ButtonBlockProps = WebBlockProps & {
  text: string;
};

export function ButtonBlock({
  style,
  text,
  theme,
  ...otherProps
}: ButtonBlockProps) {
  const { t } = useTranslation();
  const {
    loading,
    disabled,
    editMode,
    content,
    onSubmit,
    preventSubmission,
    hideSubmissionBlocks,
    blockContext,
  } = useContext(ContentRendererContext);
  const { user } = useContext(UserDataContext);
  const props = useEditModeProps(otherProps);
  const { menuFeature, quantityFeature } = explodeFeatures(content?.features);

  const [isAdminView] = useIsAdminView();

  let buttonLabel = t(text);

  if (menuFeature) {
    buttonLabel = t(
      'web.admin.channel.content.layout.editor.components.buttonBlock.viewCart'
    );
  }

  if (quantityFeature && disabled) {
    buttonLabel = t(
      'web.admin.channel.content.layout.editor.components.buttonBlock.unavailable'
    );
  }

  if (!editMode && onSubmit) {
    props.onClick = onSubmit;
  }

  const {
    shouldDisableContentSubmission: isDisabledByUnmetRequirements,
  } = useHasMetRequirements({
    content,
    user,
    isEditMode: editMode,
    isVerificationEmailSent: blockContext.isVerificationEmailSent,
  });

  if (hideSubmissionBlocks) {
    return null;
  }

  const buttonStyle = { ...style };
  const isDisabled =
    disabled ||
    preventSubmission ||
    isDisabledByUnmetRequirements ||
    !isContentBetweenInteractionTimes(content, isAdminView);
  if (!isDisabled) {
    buttonStyle.backgroundColor = theme.palette!.primary;
  }
  return (
    <Button
      className={styles.ButtonBlock}
      style={buttonStyle}
      loading={loading}
      disabled={isDisabled}
      variant="contained"
      dataCy="SubmitButton"
      {...props}
    >
      {buttonLabel}
    </Button>
  );
}

export default withQuantity(ButtonBlock);
