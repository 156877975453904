import React from 'react';

import { MediaLibrary } from 'components';
import { useHistory } from 'react-router-dom';

import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { MediaType } from 'lane-shared/hooks/useMediaLibrary';

import { AdminPage } from 'components/layout';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './styles.scss';

export default function ChannelMedia() {
  const history = useHistory();
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return null;
  }

  return (
    <AdminPage className={styles.ChannelMedia}>
      <MediaLibrary
        className={styles.mediaLibrary}
        storageKey={channel._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(media: any) => void' is not assignable to t... Remove this comment to see the full error message
        onMediaDoubleClicked={(media: MediaType, libraryItemId: string) =>
          history.push(
            `${history.location.pathname}/${media._id}/edit?libraryItemId=${libraryItemId}`
          )
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; _id: string; name: string; }... Remove this comment to see the full error message
        library={{
          type: 'Channel',
          _id: channel._id,
          name: getDisplayName(channel),
        }}
      />
    </AdminPage>
  );
}
