import React, { useState } from 'react';

import { Button, Checkbox, Input } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { getValidationMessages } from 'lane-shared/helpers';
import {
  validateCreateSAMLServiceProviderDefinition,
  validateCreateOIDCServiceProviderDefinition,
} from 'lane-shared/validation';
import ErrorMessage from 'components/general/ErrorMessage';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './SSOAddNewProvider.scss';
import { DocumentNode, useMutation } from '@apollo/client';
import { EditorType, Provider } from './SSOIntegrationEditor';

export interface SSOAddNewProviderProps {
  onCancel: () => void;
  onComplete: (provider?: Provider) => void;
  createIntegrationQuery: DocumentNode;
  createDataKey: string;
  defaultMutationValue?: Record<string, any>;
  editorType: EditorType;
  existingProviders: string[];
}

export function SSOAddNewProvider({
  onCancel,
  onComplete,
  createIntegrationQuery,
  createDataKey,
  defaultMutationValue,
  editorType,
  existingProviders,
}: SSOAddNewProviderProps) {
  const [name, setName] = useState('');
  const [entityId, setEntityId] = useState('');
  const [validation, setValidation] = useState(null);
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();

  const [customInputFields, setCustomInputFields] = useState({});
  const [
    createProvider,
    { error: createProviderErrorMessage, loading: createProvidersLoading },
  ] = useMutation(createIntegrationQuery, {
    onCompleted: data => onComplete(data[createDataKey]),
  });

  function addCustomField() {
    setCustomInputFields({
      ...customInputFields,
      [uuid()]: { name: '', required: false, inAssertion: false },
    });
  }

  function setCustomFieldValue(id: any, value: any) {
    const updatedFields = { ...customInputFields };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedFields[id].name = value;
    setCustomInputFields(updatedFields);
  }

  function toggleCustomFieldPlacement(id: any) {
    const updatedFields = { ...customInputFields };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedFields[id].inAssertion = !updatedFields[id].inAssertion;
    setCustomInputFields(updatedFields);
  }

  async function onAddButtonClick() {
    setValidation(null);

    const provider: Provider = {
      name,
      entityId,
      customFields: Object.values(customInputFields),
    };

    if (defaultMutationValue) {
      provider.idp = defaultMutationValue.idp;
    }

    if (editorType === 'OIDC') {
      validateCreateOIDCServiceProviderDefinition(existingProviders)
        .validate(provider, { abortEarly: false })
        .then(() => addNewProvider(provider))
        .catch(err => {
          setValidation(err);
        });
    } else {
      validateCreateSAMLServiceProviderDefinition
        .validate(provider)
        .then(() => addNewProvider(provider))
        .catch(err => {
          setValidation(err);
        });
    }
  }

  function addNewProvider(provider: Provider) {
    createProvider({
      variables: { provider, channelId: channel?._id },
    }).then(() => onComplete());
  }

  return (
    <div className={styles.SAMLAddNewProvider}>
      <p className={styles.subTitle}>
        {t`web.admin.channel.integrations.saml.choose-provider.add.subtitle`}
      </p>

      <Input
        isRequired
        fixedLabel
        label="Name"
        value={name}
        dataCy="addProviderName"
        // eslint-disable-next-line react/forbid-component-props
        className={styles.input}
        onChange={name => setName(name)}
        error={getValidationMessages(validation, 'name')}
      />
      <Input
        isRequired
        fixedLabel
        value={entityId}
        label="Entity ID"
        // eslint-disable-next-line react/forbid-component-props
        className={styles.input}
        dataCy="addProviderEntityId"
        onChange={entityId => setEntityId(entityId)}
        error={getValidationMessages(validation, 'entityId')}
      />

      {Object.keys(customInputFields).map(key => (
        <div key={key}>
          <Input
            fixedLabel
            // eslint-disable-next-line react/forbid-component-props
            className={styles.input}
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            value={customInputFields[key].name}
            onChange={value => setCustomFieldValue(key, value)}
            label={t`web.admin.channel.integrations.saml.choose-provider.add.custom-field`}
            placeholder={t`web.admin.channel.integrations.saml.choose-provider.add.custom-field.placeholder`}
            helperText={t(
              'web.admin.channel.integrations.custom-input.helper-text',
              { editorType }
            )}
          />

          {editorType !== 'OIDC' ? (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ style: { pa... Remove this comment to see the full error message
            <Checkbox
              style={{ paddingLeft: 0 }}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              selected={customInputFields[key].inAssertion}
              onChange={() => toggleCustomFieldPlacement(key)}
              text={t`web.admin.channel.integrations.saml.choose-provider.add.custom-field.include-in-assertion`}
            />
          ) : (
            ''
          )}
        </div>
      ))}

      <hr />

      <ErrorMessage error={createProviderErrorMessage} />

      <div className={styles.menu}>
        <div className={styles.menuLeft}>
          <Button
            variant="primary"
            dataCy="addProvider"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={onAddButtonClick}
            loading={createProvidersLoading}
          >
            {t`web.admin.channel.integrations.saml.choose-provider.add.save`}
          </Button>

          <Button onClick={onCancel} loading={createProvidersLoading}>
            {t`web.admin.channel.integrations.saml.choose-provider.add.cancel`}
          </Button>
        </div>

        <div className={styles.menuRight}>
          <Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={addCustomField}
            variant="text"
          >
            {t`web.admin.channel.integrations.saml.choose-provider.add.custom-field.add`}
          </Button>
        </div>
      </div>
    </div>
  );
}
