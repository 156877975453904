import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import { deleteUser, disableUser } from 'lane-shared/graphql/user';

import Button from 'components/general/Button';
import { Modal } from 'components/lds';

import { PortalManagementUserEditType } from '../types';
import { ModalType } from './DisableUser';

import styles from '../styles.scss';

export const DisableUserModal = ({
  user,
  parentLoading,
  modalType,
  isModalOpen,
  setIsModalOpen,
  setDropdownOpen,
}: {
  user: PortalManagementUserEditType;
  parentLoading: boolean;
  modalType: ModalType;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element | null => {
  const [loading, setLoading] = useState(parentLoading);

  const toastAction = modalType === ModalType.Disable ? 'disabled' : 'deleted';

  const [mutateUser, { loading: mutationLoading }] = useMutation(
    modalType === ModalType.Disable ? disableUser : deleteUser,
    {
      client: getAdminClient() as any,
      refetchQueries: ['getUser'],
      variables: {
        userId: user._id,
      },
      onCompleted: () =>
        window.Toast.show(
          `${user.profile.name} has been successfully ${toastAction}.`
        ),
      onError: error =>
        window.Alert.alert({
          title: 'Failed to update user',
          error,
        }),
    }
  );

  return (
    <Modal
      className={styles.window}
      isOpen={isModalOpen}
      title={`${modalType} User`}
      onClose={() => {
        setIsModalOpen(false);
        setDropdownOpen(false);
      }}
      actions={
        <>
          <Button
            loading={loading}
            onClick={() => {
              setIsModalOpen(false);
              setDropdownOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            variant="contained"
            dataCy={`${modalType.toLowerCase()}UserButtonConfirm`}
            onClick={() => {
              setLoading(mutationLoading);
              mutateUser();
              setIsModalOpen(false);
              setDropdownOpen(false);
            }}
          >
            {modalType}
          </Button>
        </>
      }
    >
      {modalCopy(modalType, user.profile.name)}
    </Modal>
  );
};

const modalCopy = (modalType: ModalType, name: string) => {
  return modalType === ModalType.Disable ? disableCopy(name) : deleteCopy(name);
};

const disableCopy = (name: string) => {
  return (
    <span className={styles.modelSpacing}>
      Are you sure you wanted to disable {name}. Once disabled, the user will
      not be able to access their organizations or locations.
    </span>
  );
};

const deleteCopy = (name: string) => {
  return (
    <span className={styles.modelSpacing}>
      Are you sure you want to disable {name} and remove their PII? The user
      will not be able to access their Organizations or Locations, and all
      identifiable information will be removed.
      <br />
      <br />
      <span className={styles.redFont}>This action cannot be reversed.</span>
    </span>
  );
};
